const IDX_WIND = 0;
const IDX_PV = 1;
const IDX_BUILDING = 2;
const IDX_FACTORY = 3;
const IDX_GRID = 4;

const SESU_METER_ID = [
  '202002000003',
  '202002000004',
  '202002000006',
  '202002000007',
  '202002000005',
];

export { IDX_WIND, IDX_PV, IDX_BUILDING, IDX_FACTORY, IDX_GRID, SESU_METER_ID };
export type MeterIdx = 0 | 1 | 2 | 3 | 4;
