
import Vue from 'vue';

export default Vue.extend({
  created() {
    sessionStorage.setItem('rmenudash', 'true');
    sessionStorage.setItem('rmenudropdash', 'ic-up-arrow');
    sessionStorage.setItem('rmenustyledash', 'block');
    sessionStorage.setItem('rmenuppa', 'false');
    sessionStorage.setItem('rmenudropppa', 'ic-down-arrow');
    sessionStorage.setItem('rmenustyleppa', 'none');
    sessionStorage.setItem('rmenumoni', 'false');
    sessionStorage.setItem('rmenudropmoni', 'ic-down-arrow');
    sessionStorage.setItem('rmenurepo', 'false');
    sessionStorage.setItem('rmenudroprepo', 'ic-down-arrow');
    sessionStorage.setItem('rmenuroad', 'false');
    sessionStorage.setItem('rmenudroproad', 'ic-down-arrow');
    sessionStorage.setItem('rmenuproj', 'false');
    sessionStorage.setItem('rmenudropproj', 'ic-down-arrow');
    sessionStorage.setItem('pmenudash', 'true');
    sessionStorage.setItem('pmenudropdash', 'ic-up-arrow');
    sessionStorage.setItem('pmenustyledash', 'block');
    sessionStorage.setItem('pmenurepo', 'false');
    sessionStorage.setItem('pmenudroprepo', 'ic-down-arrow');
    sessionStorage.setItem('pmenustylerepo', 'none');
    sessionStorage.setItem('pmenumoni', 'false');
    sessionStorage.setItem('pmenudropmoni', 'ic-down-arrow');
    sessionStorage.setItem('pmenuroad', 'false');
    sessionStorage.setItem('pmenudroproad', 'ic-down-arrow');
    sessionStorage.setItem('pmenuproj', 'false');
    sessionStorage.setItem('pmenudropproj', 'ic-down-arrow');
  },
  methods: {
    onClickLogoutButton() {
      sessionStorage.clear();
      this.$router.push('/');
    },
  },
});
