const WIND1 = [
  ['2020-08-02 00:00', 13.28],
  ['2020-08-02 00:10', 13.67],
  ['2020-08-02 00:20', 13.7],
  ['2020-08-02 00:30', 13.23],
  ['2020-08-02 00:40', 13.18],
  ['2020-08-02 00:50', 13.41],
  ['2020-08-02 01:00', 12.5],
  ['2020-08-02 01:10', 10.89],
  ['2020-08-02 01:20', 11.69],
  ['2020-08-02 01:30', 12.25],
  ['2020-08-02 01:40', 11.8],
  ['2020-08-02 01:50', 12.86],
  ['2020-08-02 02:00', 13.28],
  ['2020-08-02 02:10', 12.75],
  ['2020-08-02 02:20', 12.71],
  ['2020-08-02 02:30', 11.57],
  ['2020-08-02 02:40', 12.48],
  ['2020-08-02 02:50', 13.16],
  ['2020-08-02 03:00', 12.75],
  ['2020-08-02 03:10', 12.13],
  ['2020-08-02 03:20', 13.71],
  ['2020-08-02 03:30', 14.3],
  ['2020-08-02 03:40', 14.27],
  ['2020-08-02 03:50', 14.14],
  ['2020-08-02 04:00', 14.41],
  ['2020-08-02 04:10', 14.35],
  ['2020-08-02 04:20', 13.82],
  ['2020-08-02 04:30', 13.76],
  ['2020-08-02 04:40', 14.03],
  ['2020-08-02 04:50', 14.65],
  ['2020-08-02 05:00', 14.69],
  ['2020-08-02 05:10', 14.39],
  ['2020-08-02 05:20', 14.08],
  ['2020-08-02 05:30', 14.06],
  ['2020-08-02 05:40', 13.6],
  ['2020-08-02 05:50', 13.7],
  ['2020-08-02 06:00', 13.31],
  ['2020-08-02 06:10', 13.74],
  ['2020-08-02 06:20', 13.77],
  ['2020-08-02 06:30', 13.89],
  ['2020-08-02 06:40', 14.2],
  ['2020-08-02 06:50', 13.89],
  ['2020-08-02 07:00', 13.75],
  ['2020-08-02 07:10', 13.53],
  ['2020-08-02 07:20', 13.66],
  ['2020-08-02 07:30', 13.45],
  ['2020-08-02 07:40', 12.95],
  ['2020-08-02 07:50', 12.84],
  ['2020-08-02 08:00', 12.77],
  ['2020-08-02 08:10', 12.85],
  ['2020-08-02 08:20', 13.42],
  ['2020-08-02 08:30', 13.78],
  ['2020-08-02 08:40', 13.52],
  ['2020-08-02 08:50', 13.15],
  ['2020-08-02 09:00', 13.7],
  ['2020-08-02 09:10', 13.51],
  ['2020-08-02 09:20', 13.21],
  ['2020-08-02 09:30', 12.97],
  ['2020-08-02 09:40', 12.62],
  ['2020-08-02 09:50', 13.13],
  ['2020-08-02 10:00', 13.3],
  ['2020-08-02 10:10', 13.71],
  ['2020-08-02 10:20', 13.81],
  ['2020-08-02 10:30', 13.84],
  ['2020-08-02 10:40', 14.54],
  ['2020-08-02 10:50', 14.46],
  ['2020-08-02 11:00', 14.67],
  ['2020-08-02 11:10', 14.9],
  ['2020-08-02 11:20', 14.64],
  ['2020-08-02 11:30', 14.48],
  ['2020-08-02 11:40', 14.27],
  ['2020-08-02 11:50', 13.8],
  ['2020-08-02 12:00', 13.77],
  ['2020-08-02 12:10', 13.32],
  ['2020-08-02 12:20', 12.83],
  ['2020-08-02 12:30', 13.38],
  ['2020-08-02 12:40', 13.47],
  ['2020-08-02 12:50', 14.05],
  ['2020-08-02 13:00', 14.61],
  ['2020-08-02 13:10', 14.51],
  ['2020-08-02 13:20', 13.95],
  ['2020-08-02 13:30', 13.85],
  ['2020-08-02 13:40', 13.76],
  ['2020-08-02 13:50', 12.97],
  ['2020-08-02 14:00', 13.87],
  ['2020-08-02 14:10', 14.87],
  ['2020-08-02 14:20', 15.63],
  ['2020-08-02 14:30', 15.9],
  ['2020-08-02 14:40', 16.23],
  ['2020-08-02 14:50', 15.88],
  ['2020-08-02 15:00', 16.06],
  ['2020-08-02 15:10', 16.48],
  ['2020-08-02 15:20', 16.81],
  ['2020-08-02 15:30', 16.84],
  ['2020-08-02 15:40', 16.16],
  ['2020-08-02 15:50', 15.49],
  ['2020-08-02 16:00', 14.81],
  ['2020-08-02 16:10', 14.56],
  ['2020-08-02 16:20', 15.58],
  ['2020-08-02 16:30', 16.08],
  ['2020-08-02 16:40', 16.22],
  ['2020-08-02 16:50', 16.66],
  ['2020-08-02 17:00', 16.56],
  ['2020-08-02 17:10', 16.92],
  ['2020-08-02 17:20', 16.43],
  ['2020-08-02 17:30', 15.59],
  ['2020-08-02 17:40', 15.14],
  ['2020-08-02 17:50', 14.35],
  ['2020-08-02 18:00', 14.63],
  ['2020-08-02 18:10', 15.66],
  ['2020-08-02 18:20', 15.72],
  ['2020-08-02 18:30', 15.63],
  ['2020-08-02 18:40', 15.46],
  ['2020-08-02 18:50', 15.33],
  ['2020-08-02 19:00', 15.42],
  ['2020-08-02 19:10', 15.28],
  ['2020-08-02 19:20', 15.5],
  ['2020-08-02 19:30', 15.11],
  ['2020-08-02 19:40', 14.22],
  ['2020-08-02 19:50', 12.62],
  ['2020-08-02 20:00', 12.99],
  ['2020-08-02 20:10', 13.64],
  ['2020-08-02 20:20', 14.59],
  ['2020-08-02 20:30', 15.01],
  ['2020-08-02 20:40', 15.66],
  ['2020-08-02 20:50', 15.87],
  ['2020-08-02 21:00', 15.22],
  ['2020-08-02 21:10', 14.91],
  ['2020-08-02 21:20', 14.81],
  ['2020-08-02 21:30', 14.65],
  ['2020-08-02 21:40', 14.68],
  ['2020-08-02 21:50', 14.47],
  ['2020-08-02 22:00', 14.77],
  ['2020-08-02 22:10', 14.74],
  ['2020-08-02 22:20', 14.39],
  ['2020-08-02 22:30', 14.41],
  ['2020-08-02 22:40', 14.83],
  ['2020-08-02 22:50', 15.65],
  ['2020-08-02 23:00', 15.47],
  ['2020-08-02 23:10', 14.57],
  ['2020-08-02 23:20', 14.11],
  ['2020-08-02 23:30', 14.17],
  ['2020-08-02 23:40', 14.55],
  ['2020-08-02 23:50', 15.15],
  ['2020-08-03 00:00', 15.37],
  ['2020-08-03 00:10', 14.96],
  ['2020-08-03 00:20', 15.14],
  ['2020-08-03 00:30', 14.86],
  ['2020-08-03 00:40', 15.03],
  ['2020-08-03 00:50', 15.11],
  ['2020-08-03 01:00', 15.52],
  ['2020-08-03 01:10', 15.22],
  ['2020-08-03 01:20', 14.84],
  ['2020-08-03 01:30', 14.63],
  ['2020-08-03 01:40', 15.67],
  ['2020-08-03 01:50', 16.22],
  ['2020-08-03 02:00', 16.29],
  ['2020-08-03 02:10', 16.08],
  ['2020-08-03 02:20', 15.61],
  ['2020-08-03 02:30', 16.03],
  ['2020-08-03 02:40', 15.97],
  ['2020-08-03 02:50', 15.41],
  ['2020-08-03 03:00', 14.66],
  ['2020-08-03 03:10', 14.71],
  ['2020-08-03 03:20', 14.5],
  ['2020-08-03 03:30', 14.69],
  ['2020-08-03 03:40', 13.9],
  ['2020-08-03 03:50', 14.57],
  ['2020-08-03 04:00', 15.13],
  ['2020-08-03 04:10', 15.45],
  ['2020-08-03 04:20', 14.61],
  ['2020-08-03 04:30', 13.56],
  ['2020-08-03 04:40', 13.1],
  ['2020-08-03 04:50', 13.1],
  ['2020-08-03 05:00', 13.56],
  ['2020-08-03 05:10', 14.08],
  ['2020-08-03 05:20', 14.45],
  ['2020-08-03 05:30', 14.14],
  ['2020-08-03 05:40', 14.77],
  ['2020-08-03 05:50', 14.54],
  ['2020-08-03 06:00', 14.41],
  ['2020-08-03 06:10', 14.03],
  ['2020-08-03 06:20', 13.75],
  ['2020-08-03 06:30', 13.79],
  ['2020-08-03 06:40', 14.18],
  ['2020-08-03 06:50', 14.78],
  ['2020-08-03 07:00', 14.75],
  ['2020-08-03 07:10', 14.1],
  ['2020-08-03 07:20', 14.23],
  ['2020-08-03 07:30', 14.47],
  ['2020-08-03 07:40', 13.37],
  ['2020-08-03 07:50', 12.72],
  ['2020-08-03 08:00', 12.8],
  ['2020-08-03 08:10', 12.49],
  ['2020-08-03 08:20', 12.7],
  ['2020-08-03 08:30', 12.85],
  ['2020-08-03 08:40', 13.18],
  ['2020-08-03 08:50', 13.6],
  ['2020-08-03 09:00', 14.03],
  ['2020-08-03 09:10', 14.08],
  ['2020-08-03 09:20', 13.58],
  ['2020-08-03 09:30', 13.62],
  ['2020-08-03 09:40', 13.97],
  ['2020-08-03 09:50', 13.82],
  ['2020-08-03 10:00', 13.67],
  ['2020-08-03 10:10', 12.99],
  ['2020-08-03 10:20', 13.54],
  ['2020-08-03 10:30', 14.68],
  ['2020-08-03 10:40', 14.94],
  ['2020-08-03 10:50', 15.55],
  ['2020-08-03 11:00', 15.47],
  ['2020-08-03 11:10', 14.53],
  ['2020-08-03 11:20', 14.4],
  ['2020-08-03 11:30', 14.19],
  ['2020-08-03 11:40', 14.2],
  ['2020-08-03 11:50', 13.62],
  ['2020-08-03 12:00', 13.88],
  ['2020-08-03 12:10', 13.77],
  ['2020-08-03 12:20', 13.73],
  ['2020-08-03 12:30', 13.46],
  ['2020-08-03 12:40', 13.82],
  ['2020-08-03 12:50', 14.69],
  ['2020-08-03 13:00', 14.33],
  ['2020-08-03 13:10', 14.51],
  ['2020-08-03 13:20', 14.94],
  ['2020-08-03 13:30', 15.03],
  ['2020-08-03 13:40', 14.86],
  ['2020-08-03 13:50', 15.59],
  ['2020-08-03 14:00', 15.87],
  ['2020-08-03 14:10', 15.07],
  ['2020-08-03 14:20', 15.52],
  ['2020-08-03 14:30', 15.46],
  ['2020-08-03 14:40', 15.4],
  ['2020-08-03 14:50', 15.02],
  ['2020-08-03 15:00', 15.37],
  ['2020-08-03 15:10', 15.68],
  ['2020-08-03 15:20', 16.39],
  ['2020-08-03 15:30', 16.07],
  ['2020-08-03 15:40', 15.7],
  ['2020-08-03 15:50', 16.38],
  ['2020-08-03 16:00', 15.98],
  ['2020-08-03 16:10', 15.75],
  ['2020-08-03 16:20', 15.59],
  ['2020-08-03 16:30', 15.06],
  ['2020-08-03 16:40', 15.5],
  ['2020-08-03 16:50', 15.44],
  ['2020-08-03 17:00', 15.33],
  ['2020-08-03 17:10', 15.32],
  ['2020-08-03 17:20', 15.94],
  ['2020-08-03 17:30', 15.21],
  ['2020-08-03 17:40', 16.09],
  ['2020-08-03 17:50', 16.52],
  ['2020-08-03 18:00', 17.08],
  ['2020-08-03 18:10', 16.86],
  ['2020-08-03 18:20', 16.9],
  ['2020-08-03 18:30', 16.63],
  ['2020-08-03 18:40', 16.64],
  ['2020-08-03 18:50', 15.63],
  ['2020-08-03 19:00', 16.14],
  ['2020-08-03 19:10', 16.38],
  ['2020-08-03 19:20', 16.3],
  ['2020-08-03 19:30', 16.01],
  ['2020-08-03 19:40', 15.57],
  ['2020-08-03 19:50', 15.68],
  ['2020-08-03 20:00', 15.61],
  ['2020-08-03 20:10', 15.67],
  ['2020-08-03 20:20', 15.1],
  ['2020-08-03 20:30', 15.54],
  ['2020-08-03 20:40', 15.88],
  ['2020-08-03 20:50', 16.43],
  ['2020-08-03 21:00', 16.04],
  ['2020-08-03 21:10', 15.89],
  ['2020-08-03 21:20', 15.55],
  ['2020-08-03 21:30', 15.33],
  ['2020-08-03 21:40', 15.26],
  ['2020-08-03 21:50', 15.91],
  ['2020-08-03 22:00', 16.84],
  ['2020-08-03 22:10', 16.72],
  ['2020-08-03 22:20', 16.81],
  ['2020-08-03 22:30', 16.97],
  ['2020-08-03 22:40', 16.11],
  ['2020-08-03 22:50', 16.17],
  ['2020-08-03 23:00', 15.84],
  ['2020-08-03 23:10', 15.21],
  ['2020-08-03 23:20', 15],
  ['2020-08-03 23:30', 14.2],
  ['2020-08-03 23:40', 13.03],
  ['2020-08-03 23:50', 14.02],
  ['2020-08-04 00:00', 14.4],
  ['2020-08-04 00:10', 14.64],
  ['2020-08-04 00:20', 14.87],
  ['2020-08-04 00:30', 14.76],
  ['2020-08-04 00:40', 13.89],
  ['2020-08-04 00:50', 13.74],
  ['2020-08-04 01:00', 13.25],
  ['2020-08-04 01:10', 13.73],
  ['2020-08-04 01:20', 12.86],
  ['2020-08-04 01:30', 12.08],
  ['2020-08-04 01:40', 11.98],
  ['2020-08-04 01:50', 11.49],
  ['2020-08-04 02:00', 10.37],
  ['2020-08-04 02:10', 10.31],
  ['2020-08-04 02:20', 11.58],
  ['2020-08-04 02:30', 11.32],
  ['2020-08-04 02:40', 9.43],
  ['2020-08-04 02:50', 9.87],
  ['2020-08-04 03:00', 11.95],
  ['2020-08-04 03:10', 12.96],
  ['2020-08-04 03:20', 13.46],
  ['2020-08-04 03:30', 11.95],
  ['2020-08-04 03:40', 11.72],
  ['2020-08-04 03:50', 11.35],
  ['2020-08-04 04:00', 11.39],
  ['2020-08-04 04:10', 11.03],
  ['2020-08-04 04:20', 10.98],
  ['2020-08-04 04:30', 10.76],
  ['2020-08-04 04:40', 10.9],
  ['2020-08-04 04:50', 11.08],
  ['2020-08-04 05:00', 10.93],
  ['2020-08-04 05:10', 10.84],
  ['2020-08-04 05:20', 11.51],
  ['2020-08-04 05:30', 11.8],
  ['2020-08-04 05:40', 12.76],
  ['2020-08-04 05:50', 12.93],
  ['2020-08-04 06:00', 12.1],
  ['2020-08-04 06:10', 12.44],
  ['2020-08-04 06:20', 12.37],
  ['2020-08-04 06:30', 12.86],
  ['2020-08-04 06:40', 12.81],
  ['2020-08-04 06:50', 13.03],
  ['2020-08-04 07:00', 13.31],
  ['2020-08-04 07:10', 13.1],
  ['2020-08-04 07:20', 13.41],
  ['2020-08-04 07:30', 13.78],
  ['2020-08-04 07:40', 13.88],
  ['2020-08-04 07:50', 14.16],
  ['2020-08-04 08:00', 13.72],
  ['2020-08-04 08:10', 13.69],
  ['2020-08-04 08:20', 13.34],
  ['2020-08-04 08:30', 13.17],
  ['2020-08-04 08:40', 12.82],
  ['2020-08-04 08:50', 12.41],
  ['2020-08-04 09:00', 12.14],
  ['2020-08-04 09:10', 12.87],
  ['2020-08-04 09:20', 13.34],
  ['2020-08-04 09:30', 12.72],
  ['2020-08-04 09:40', 12.07],
  ['2020-08-04 09:50', 12.22],
  ['2020-08-04 10:00', 12.9],
  ['2020-08-04 10:10', 12.6],
  ['2020-08-04 10:20', 12.01],
  ['2020-08-04 10:30', 11.63],
  ['2020-08-04 10:40', 11.6],
  ['2020-08-04 10:50', 12.84],
  ['2020-08-04 11:00', 12.96],
  ['2020-08-04 11:10', 12.85],
  ['2020-08-04 11:20', 12.79],
  ['2020-08-04 11:30', 12.33],
  ['2020-08-04 11:40', 11.54],
  ['2020-08-04 11:50', 11.29],
  ['2020-08-04 12:00', 11.18],
  ['2020-08-04 12:10', 11.78],
  ['2020-08-04 12:20', 11.73],
  ['2020-08-04 12:30', 11.46],
  ['2020-08-04 12:40', 11.98],
  ['2020-08-04 12:50', 12.74],
  ['2020-08-04 13:00', 12.76],
  ['2020-08-04 13:10', 13],
  ['2020-08-04 13:20', 12.37],
  ['2020-08-04 13:30', 12.77],
  ['2020-08-04 13:40', 12.64],
  ['2020-08-04 13:50', 12.33],
  ['2020-08-04 14:00', 12.35],
  ['2020-08-04 14:10', 12.38],
  ['2020-08-04 14:20', 12.23],
  ['2020-08-04 14:30', 12.45],
  ['2020-08-04 14:40', 12.87],
  ['2020-08-04 14:50', 13.33],
  ['2020-08-04 15:00', 13.76],
  ['2020-08-04 15:10', 13.59],
  ['2020-08-04 15:20', 13.32],
  ['2020-08-04 15:30', 12.92],
  ['2020-08-04 15:40', 13.02],
  ['2020-08-04 15:50', 13.15],
  ['2020-08-04 16:00', 13.5],
  ['2020-08-04 16:10', 13.8],
  ['2020-08-04 16:20', 13.03],
  ['2020-08-04 16:30', 12.77],
  ['2020-08-04 16:40', 13.19],
  ['2020-08-04 16:50', 13.65],
  ['2020-08-04 17:00', 13.59],
  ['2020-08-04 17:10', 12.73],
  ['2020-08-04 17:20', 13.01],
  ['2020-08-04 17:30', 13.27],
  ['2020-08-04 17:40', 13.49],
  ['2020-08-04 17:50', 13.45],
  ['2020-08-04 18:00', 13.23],
  ['2020-08-04 18:10', 13.06],
  ['2020-08-04 18:20', 12.75],
  ['2020-08-04 18:30', 12.9],
  ['2020-08-04 18:40', 13.23],
  ['2020-08-04 18:50', 13.39],
  ['2020-08-04 19:00', 13.32],
  ['2020-08-04 19:10', 13.79],
  ['2020-08-04 19:20', 13.91],
  ['2020-08-04 19:30', 14.06],
  ['2020-08-04 19:40', 14.06],
  ['2020-08-04 19:50', 14.06],
  ['2020-08-04 20:00', 14.1],
  ['2020-08-04 20:10', 13.74],
  ['2020-08-04 20:20', 13.72],
  ['2020-08-04 20:30', 13.97],
  ['2020-08-04 20:40', 14.63],
  ['2020-08-04 20:50', 14.72],
  ['2020-08-04 21:00', 14.37],
  ['2020-08-04 21:10', 14.13],
  ['2020-08-04 21:20', 14.28],
  ['2020-08-04 21:30', 13.76],
  ['2020-08-04 21:40', 13.15],
  ['2020-08-04 21:50', 12.81],
  ['2020-08-04 22:00', 12.84],
  ['2020-08-04 22:10', 12.23],
  ['2020-08-04 22:20', 11.75],
  ['2020-08-04 22:30', 11.61],
  ['2020-08-04 22:40', 11.3],
  ['2020-08-04 22:50', 11.12],
  ['2020-08-04 23:00', 11.04],
  ['2020-08-04 23:10', 11.16],
  ['2020-08-04 23:20', 10.23],
  ['2020-08-04 23:30', 9.97],
  ['2020-08-04 23:40', 9.81],
  ['2020-08-04 23:50', 8.32],
  ['2020-08-05 00:00', 9.26],
  ['2020-08-05 00:10', 9.43],
  ['2020-08-05 00:20', 9.69],
  ['2020-08-05 00:30', 10.48],
  ['2020-08-05 00:40', 10.31],
  ['2020-08-05 00:50', 10.96],
  ['2020-08-05 01:00', 11.14],
  ['2020-08-05 01:10', 10.98],
  ['2020-08-05 01:20', 11.04],
  ['2020-08-05 01:30', 11.56],
  ['2020-08-05 01:40', 10.81],
  ['2020-08-05 01:50', 10.36],
  ['2020-08-05 02:00', 10.31],
  ['2020-08-05 02:10', 10.5],
  ['2020-08-05 02:20', 10.62],
  ['2020-08-05 02:30', 10.51],
  ['2020-08-05 02:40', 10.48],
  ['2020-08-05 02:50', 11.29],
  ['2020-08-05 03:00', 11.16],
  ['2020-08-05 03:10', 11.02],
  ['2020-08-05 03:20', 11.49],
  ['2020-08-05 03:30', 11.61],
  ['2020-08-05 03:40', 12.07],
  ['2020-08-05 03:50', 12.76],
  ['2020-08-05 04:00', 12.43],
  ['2020-08-05 04:10', 12.05],
  ['2020-08-05 04:20', 11.66],
  ['2020-08-05 04:30', 11.52],
  ['2020-08-05 04:40', 11.66],
  ['2020-08-05 04:50', 11.63],
  ['2020-08-05 05:00', 11.03],
  ['2020-08-05 05:10', 10.57],
  ['2020-08-05 05:20', 11.11],
  ['2020-08-05 05:30', 11.63],
  ['2020-08-05 05:40', 11.53],
  ['2020-08-05 05:50', 10.84],
  ['2020-08-05 06:00', 10.21],
  ['2020-08-05 06:10', 10.6],
  ['2020-08-05 06:20', 11.39],
  ['2020-08-05 06:30', 11.58],
  ['2020-08-05 06:40', 11.98],
  ['2020-08-05 06:50', 11.84],
  ['2020-08-05 07:00', 12.12],
  ['2020-08-05 07:10', 12.16],
  ['2020-08-05 07:20', 12.02],
  ['2020-08-05 07:30', 11.96],
  ['2020-08-05 07:40', 11.84],
  ['2020-08-05 07:50', 11.46],
  ['2020-08-05 08:00', 10.59],
  ['2020-08-05 08:10', 11.95],
  ['2020-08-05 08:20', 12.42],
  ['2020-08-05 08:30', 12.33],
  ['2020-08-05 08:40', 12.49],
  ['2020-08-05 08:50', 12.72],
  ['2020-08-05 09:00', 12.83],
  ['2020-08-05 09:10', 13.39],
  ['2020-08-05 09:20', 13.63],
  ['2020-08-05 09:30', 12.99],
  ['2020-08-05 09:40', 12.72],
  ['2020-08-05 09:50', 13.49],
  ['2020-08-05 10:00', 14.27],
  ['2020-08-05 10:10', 14.39],
  ['2020-08-05 10:20', 13.74],
  ['2020-08-05 10:30', 13.66],
  ['2020-08-05 10:40', 13.93],
  ['2020-08-05 10:50', 13.75],
  ['2020-08-05 11:00', 13.48],
  ['2020-08-05 11:10', 13.01],
  ['2020-08-05 11:20', 13.64],
  ['2020-08-05 11:30', 12.91],
  ['2020-08-05 11:40', 12.94],
  ['2020-08-05 11:50', 13.13],
  ['2020-08-05 12:00', 13.67],
  ['2020-08-05 12:10', 13.19],
  ['2020-08-05 12:20', 12.78],
  ['2020-08-05 12:30', 11.94],
  ['2020-08-05 12:40', 11.87],
  ['2020-08-05 12:50', 11.46],
  ['2020-08-05 13:00', 11.4],
  ['2020-08-05 13:10', 11.13],
  ['2020-08-05 13:20', 11.11],
  ['2020-08-05 13:30', 11.24],
  ['2020-08-05 13:40', 11.54],
  ['2020-08-05 13:50', 11.86],
  ['2020-08-05 14:00', 12.21],
  ['2020-08-05 14:10', 12.54],
  ['2020-08-05 14:20', 12.1],
  ['2020-08-05 14:30', 12.07],
  ['2020-08-05 14:40', 12.4],
  ['2020-08-05 14:50', 12.18],
  ['2020-08-05 15:00', 11.74],
  ['2020-08-05 15:10', 11.43],
  ['2020-08-05 15:20', 11.08],
  ['2020-08-05 15:30', 11],
  ['2020-08-05 15:40', 10.52],
  ['2020-08-05 15:50', 10.59],
  ['2020-08-05 16:00', 10.51],
  ['2020-08-05 16:10', 10.27],
  ['2020-08-05 16:20', 9.49],
  ['2020-08-05 16:30', 9.12],
  ['2020-08-05 16:40', 9.13],
  ['2020-08-05 16:50', 9.31],
  ['2020-08-05 17:00', 9.68],
  ['2020-08-05 17:10', 9.64],
  ['2020-08-05 17:20', 10.23],
  ['2020-08-05 17:30', 10.05],
  ['2020-08-05 17:40', 10.48],
  ['2020-08-05 17:50', 11.36],
  ['2020-08-05 18:00', 10.91],
  ['2020-08-05 18:10', 10.38],
  ['2020-08-05 18:20', 10.53],
  ['2020-08-05 18:30', 10.27],
  ['2020-08-05 18:40', 9.99],
  ['2020-08-05 18:50', 10.3],
  ['2020-08-05 19:00', 10.36],
  ['2020-08-05 19:10', 10.39],
  ['2020-08-05 19:20', 9.57],
  ['2020-08-05 19:30', 9.61],
  ['2020-08-05 19:40', 9.57],
  ['2020-08-05 19:50', 9.15],
  ['2020-08-05 20:00', 10.1],
  ['2020-08-05 20:10', 9.92],
  ['2020-08-05 20:20', 10.08],
  ['2020-08-05 20:30', 10.33],
  ['2020-08-05 20:40', 11.29],
  ['2020-08-05 20:50', 11.48],
  ['2020-08-05 21:00', 12.13],
  ['2020-08-05 21:10', 12.65],
  ['2020-08-05 21:20', 12.81],
  ['2020-08-05 21:30', 12.79],
  ['2020-08-05 21:40', 13],
  ['2020-08-05 21:50', 12.4],
  ['2020-08-05 22:00', 12.59],
  ['2020-08-05 22:10', 12.61],
  ['2020-08-05 22:20', 12.37],
  ['2020-08-05 22:30', 12.38],
  ['2020-08-05 22:40', 12.57],
  ['2020-08-05 22:50', 12.64],
  ['2020-08-05 23:00', 12.31],
  ['2020-08-05 23:10', 12.47],
  ['2020-08-05 23:20', 12.98],
  ['2020-08-05 23:30', 13.25],
  ['2020-08-05 23:40', 13.74],
  ['2020-08-05 23:50', 13.97],
  ['2020-08-06 00:00', 13.82],
  ['2020-08-06 00:10', 13.86],
  ['2020-08-06 00:20', 14.53],
  ['2020-08-06 00:30', 14.25],
  ['2020-08-06 00:40', 13.41],
  ['2020-08-06 00:50', 13.43],
  ['2020-08-06 01:00', 13.94],
  ['2020-08-06 01:10', 14.9],
  ['2020-08-06 01:20', 15.6],
  ['2020-08-06 01:30', 15.48],
  ['2020-08-06 01:40', 15.74],
  ['2020-08-06 01:50', 15.68],
  ['2020-08-06 02:00', 16.31],
  ['2020-08-06 02:10', 16.06],
  ['2020-08-06 02:20', 16.15],
  ['2020-08-06 02:30', 16.65],
  ['2020-08-06 02:40', 16.6],
  ['2020-08-06 02:50', 16.94],
  ['2020-08-06 03:00', 16.44],
  ['2020-08-06 03:10', 15.52],
  ['2020-08-06 03:20', 15.7],
  ['2020-08-06 03:30', 16.57],
  ['2020-08-06 03:40', 16.53],
  ['2020-08-06 03:50', 16.78],
  ['2020-08-06 04:00', 16.85],
  ['2020-08-06 04:10', 17.08],
  ['2020-08-06 04:20', 17.53],
  ['2020-08-06 04:30', 17.21],
  ['2020-08-06 04:40', 17.29],
  ['2020-08-06 04:50', 17.09],
  ['2020-08-06 05:00', 17.65],
  ['2020-08-06 05:10', 18.15],
  ['2020-08-06 05:20', 17.98],
  ['2020-08-06 05:30', 18.01],
  ['2020-08-06 05:40', 18.07],
  ['2020-08-06 05:50', 18.4],
  ['2020-08-06 06:00', 17.98],
  ['2020-08-06 06:10', 17.26],
  ['2020-08-06 06:20', 17.68],
  ['2020-08-06 06:30', 17.78],
  ['2020-08-06 06:40', 17.73],
  ['2020-08-06 06:50', 17.18],
  ['2020-08-06 07:00', 16.93],
  ['2020-08-06 07:10', 17.5],
  ['2020-08-06 07:20', 17.02],
  ['2020-08-06 07:30', 17.24],
  ['2020-08-06 07:40', 17.55],
  ['2020-08-06 07:50', 17.8],
  ['2020-08-06 08:00', 19.12],
  ['2020-08-06 08:10', 19.51],
  ['2020-08-06 08:20', 19.7],
  ['2020-08-06 08:30', 19.88],
  ['2020-08-06 08:40', 20.27],
  ['2020-08-06 08:50', 19.89],
  ['2020-08-06 09:00', 19.98],
  ['2020-08-06 09:10', 19.22],
  ['2020-08-06 09:20', 19.67],
  ['2020-08-06 09:30', 19.55],
  ['2020-08-06 09:40', 19.52],
  ['2020-08-06 09:50', 20.12],
  ['2020-08-06 10:00', 20.47],
  ['2020-08-06 10:10', 20.63],
  ['2020-08-06 10:20', 19.99],
  ['2020-08-06 10:30', 20.49],
  ['2020-08-06 10:40', 20.7],
  ['2020-08-06 10:50', 20.17],
  ['2020-08-06 11:00', 20.25],
  ['2020-08-06 11:10', 20.47],
  ['2020-08-06 11:20', 19.73],
  ['2020-08-06 11:30', 19.73],
  ['2020-08-06 11:40', 20.07],
  ['2020-08-06 11:50', 19.76],
  ['2020-08-06 12:00', 19.01],
  ['2020-08-06 12:10', 19.46],
  ['2020-08-06 12:20', 19.32],
  ['2020-08-06 12:30', 18.68],
  ['2020-08-06 12:40', 19.31],
  ['2020-08-06 12:50', 19.16],
  ['2020-08-06 13:00', 19.55],
  ['2020-08-06 13:10', 19.47],
  ['2020-08-06 13:20', 19.38],
  ['2020-08-06 13:30', 20.29],
  ['2020-08-06 13:40', 20.79],
  ['2020-08-06 13:50', 20.68],
  ['2020-08-06 14:00', 20.72],
  ['2020-08-06 14:10', 21.8],
  ['2020-08-06 14:20', 21.28],
  ['2020-08-06 14:30', 21.46],
  ['2020-08-06 14:40', 21.45],
  ['2020-08-06 14:50', 21.91],
  ['2020-08-06 15:00', 21.26],
  ['2020-08-06 15:10', 22.48],
  ['2020-08-06 15:20', 21.21],
  ['2020-08-06 15:30', 21.75],
  ['2020-08-06 15:40', 21.07],
  ['2020-08-06 15:50', 20.85],
  ['2020-08-06 16:00', 19.88],
  ['2020-08-06 16:10', 19.71],
  ['2020-08-06 16:20', 19.56],
  ['2020-08-06 16:30', 20.05],
  ['2020-08-06 16:40', 19.72],
  ['2020-08-06 16:50', 19.11],
  ['2020-08-06 17:00', 20.22],
  ['2020-08-06 17:10', 19.3],
  ['2020-08-06 17:20', 19.4],
  ['2020-08-06 17:30', 20.73],
  ['2020-08-06 17:40', 20.75],
  ['2020-08-06 17:50', 20.44],
  ['2020-08-06 18:00', 18.87],
  ['2020-08-06 18:10', 19.67],
  ['2020-08-06 18:20', 20.47],
  ['2020-08-06 18:30', 18.31],
  ['2020-08-06 18:40', 18.13],
  ['2020-08-06 18:50', 17.82],
  ['2020-08-06 19:00', 17.02],
  ['2020-08-06 19:10', 16.97],
  ['2020-08-06 19:20', 14.9],
  ['2020-08-06 19:30', 15.11],
  ['2020-08-06 19:40', 14.86],
  ['2020-08-06 19:50', 15.94],
  ['2020-08-06 20:00', 16.73],
  ['2020-08-06 20:10', 16.46],
  ['2020-08-06 20:20', 17.65],
  ['2020-08-06 20:30', 18.75],
  ['2020-08-06 20:40', 17.79],
  ['2020-08-06 20:50', 17.56],
  ['2020-08-06 21:00', 18.39],
  ['2020-08-06 21:10', 18.94],
  ['2020-08-06 21:20', 18.88],
  ['2020-08-06 21:30', 17.99],
  ['2020-08-06 21:40', 17.07],
  ['2020-08-06 21:50', 16.05],
  ['2020-08-06 22:00', 15.26],
  ['2020-08-06 22:10', 15.01],
  ['2020-08-06 22:20', 16.07],
  ['2020-08-06 22:30', 16.49],
  ['2020-08-06 22:40', 16.06],
  ['2020-08-06 22:50', 14.99],
  ['2020-08-06 23:00', 14.96],
  ['2020-08-06 23:10', 15.37],
  ['2020-08-06 23:20', 13.59],
  ['2020-08-06 23:30', 13.28],
  ['2020-08-06 23:40', 16.37],
  ['2020-08-06 23:50', 15.7],
  ['2020-08-07 00:00', 16.17],
  ['2020-08-07 00:10', 10.86],
  ['2020-08-07 00:20', 14.19],
  ['2020-08-07 00:30', 13.59],
  ['2020-08-07 00:40', 15.3],
  ['2020-08-07 00:50', 15.21],
  ['2020-08-07 01:00', 15.68],
  ['2020-08-07 01:10', 14.87],
  ['2020-08-07 01:20', 16.96],
  ['2020-08-07 01:30', 17.55],
  ['2020-08-07 01:40', 17.57],
  ['2020-08-07 01:50', 17.65],
  ['2020-08-07 02:00', 18.34],
  ['2020-08-07 02:10', 18.88],
  ['2020-08-07 02:20', 18.85],
  ['2020-08-07 02:30', 18.81],
  ['2020-08-07 02:40', 18.61],
  ['2020-08-07 02:50', 18.67],
  ['2020-08-07 03:00', 17.73],
  ['2020-08-07 03:10', 17.35],
  ['2020-08-07 03:20', 15.28],
  ['2020-08-07 03:30', 15.11],
  ['2020-08-07 03:40', 14.44],
  ['2020-08-07 03:50', 14.28],
  ['2020-08-07 04:00', 14.38],
  ['2020-08-07 04:10', 14.68],
  ['2020-08-07 04:20', 15.39],
  ['2020-08-07 04:30', 16.23],
  ['2020-08-07 04:40', 14.88],
  ['2020-08-07 04:50', 15.12],
  ['2020-08-07 05:00', 14.83],
  ['2020-08-07 05:10', 15.18],
  ['2020-08-07 05:20', 14.45],
  ['2020-08-07 05:30', 14.05],
  ['2020-08-07 05:40', 12.23],
  ['2020-08-07 05:50', 14.66],
  ['2020-08-07 06:00', 14.61],
  ['2020-08-07 06:10', 14.53],
  ['2020-08-07 06:20', 13.8],
  ['2020-08-07 06:30', 14.09],
  ['2020-08-07 06:40', 13.13],
  ['2020-08-07 06:50', 12.28],
  ['2020-08-07 07:00', 12.55],
  ['2020-08-07 07:10', 13.12],
  ['2020-08-07 07:20', 13.72],
  ['2020-08-07 07:30', 14.22],
  ['2020-08-07 07:40', 13.52],
  ['2020-08-07 07:50', 13.05],
  ['2020-08-07 08:00', 13.05],
  ['2020-08-07 08:10', 11.58],
  ['2020-08-07 08:20', 10.71],
  ['2020-08-07 08:30', 10.43],
  ['2020-08-07 08:40', 10.25],
  ['2020-08-07 08:50', 10.56],
  ['2020-08-07 09:00', 12.3],
  ['2020-08-07 09:10', 12.63],
  ['2020-08-07 09:20', 13.15],
  ['2020-08-07 09:30', 13.03],
  ['2020-08-07 09:40', 12.96],
  ['2020-08-07 09:50', 12.98],
  ['2020-08-07 10:00', 14.07],
  ['2020-08-07 10:10', 16.42],
  ['2020-08-07 10:20', 17.22],
  ['2020-08-07 10:30', 17.91],
  ['2020-08-07 10:40', 18.31],
  ['2020-08-07 10:50', 17.86],
  ['2020-08-07 11:00', 17.6],
  ['2020-08-07 11:10', 17.99],
  ['2020-08-07 11:20', 18.71],
  ['2020-08-07 11:30', 15.58],
  ['2020-08-07 11:40', 14.97],
  ['2020-08-07 11:50', 15.45],
  ['2020-08-07 12:00', 15.25],
  ['2020-08-07 12:10', 13.64],
  ['2020-08-07 12:20', 13.87],
  ['2020-08-07 12:30', 13.3],
  ['2020-08-07 12:40', 12.44],
  ['2020-08-07 12:50', 11.58],
  ['2020-08-07 13:00', 11.39],
  ['2020-08-07 13:10', 10.59],
  ['2020-08-07 13:20', 10.34],
  ['2020-08-07 13:30', 10.68],
  ['2020-08-07 13:40', 11.46],
  ['2020-08-07 13:50', 11.42],
  ['2020-08-07 14:00', 11.57],
  ['2020-08-07 14:10', 12.23],
  ['2020-08-07 14:20', 12.59],
  ['2020-08-07 14:30', 13.82],
  ['2020-08-07 14:40', 14.74],
  ['2020-08-07 14:50', 14.14],
  ['2020-08-07 15:00', 14.6],
  ['2020-08-07 15:10', 14.53],
  ['2020-08-07 15:20', 14.28],
  ['2020-08-07 15:30', 14.92],
  ['2020-08-07 15:40', 14.76],
  ['2020-08-07 15:50', 14.38],
  ['2020-08-07 16:00', 14.78],
  ['2020-08-07 16:10', 15.22],
  ['2020-08-07 16:20', 15.04],
  ['2020-08-07 16:30', 15],
  ['2020-08-07 16:40', 15.5],
  ['2020-08-07 16:50', 15.18],
  ['2020-08-07 17:00', 15.11],
  ['2020-08-07 17:10', 14.71],
  ['2020-08-07 17:20', 14.36],
  ['2020-08-07 17:30', 14.27],
  ['2020-08-07 17:40', 13.84],
  ['2020-08-07 17:50', 13.62],
  ['2020-08-07 18:00', 13.35],
  ['2020-08-07 18:10', 13.31],
  ['2020-08-07 18:20', 13.59],
  ['2020-08-07 18:30', 14.02],
  ['2020-08-07 18:40', 14.5],
  ['2020-08-07 18:50', 14.92],
  ['2020-08-07 19:00', 14.57],
  ['2020-08-07 19:10', 14.2],
  ['2020-08-07 19:20', 14.33],
  ['2020-08-07 19:30', 14.29],
  ['2020-08-07 19:40', 13.52],
  ['2020-08-07 19:50', 11.59],
  ['2020-08-07 20:00', 11.92],
  ['2020-08-07 20:10', 11.34],
  ['2020-08-07 20:20', 14.17],
  ['2020-08-07 20:30', 16.63],
  ['2020-08-07 20:40', 13.18],
  ['2020-08-07 20:50', 10.14],
  ['2020-08-07 21:00', 8.36],
  ['2020-08-07 21:10', 11.16],
  ['2020-08-07 21:20', 11.28],
  ['2020-08-07 21:30', 10.57],
  ['2020-08-07 21:40', 9.15],
  ['2020-08-07 21:50', 9.81],
  ['2020-08-07 22:00', 11.61],
  ['2020-08-07 22:10', 15.79],
  ['2020-08-07 22:20', 15.43],
  ['2020-08-07 22:30', 13.34],
  ['2020-08-07 22:40', 15.08],
  ['2020-08-07 22:50', 15.01],
  ['2020-08-07 23:00', 16.76],
  ['2020-08-07 23:10', 12.65],
  ['2020-08-07 23:20', 13.86],
  ['2020-08-07 23:30', 13.61],
  ['2020-08-07 23:40', 12.1],
  ['2020-08-07 23:50', 12.83],
  ['2020-08-08 00:00', 13.66],
  ['2020-08-08 00:10', 12.67],
  ['2020-08-08 00:20', 15.67],
  ['2020-08-08 00:30', 16.37],
  ['2020-08-08 00:40', 13.06],
  ['2020-08-08 00:50', 11.22],
  ['2020-08-08 01:00', 13.1],
  ['2020-08-08 01:10', 13.08],
  ['2020-08-08 01:20', 15.68],
  ['2020-08-08 01:30', 10.75],
  ['2020-08-08 01:40', 10.48],
  ['2020-08-08 01:50', 11.62],
  ['2020-08-08 02:00', 11.7],
  ['2020-08-08 02:10', 11.26],
  ['2020-08-08 02:20', 13.01],
  ['2020-08-08 02:30', 12.93],
  ['2020-08-08 02:40', 14.05],
  ['2020-08-08 02:50', 14.56],
  ['2020-08-08 03:00', 15.8],
  ['2020-08-08 03:10', 15.94],
  ['2020-08-08 03:20', 15.45],
  ['2020-08-08 03:30', 15.27],
  ['2020-08-08 03:40', 14.86],
  ['2020-08-08 03:50', 11.09],
  ['2020-08-08 04:00', 12.23],
  ['2020-08-08 04:10', 12.79],
  ['2020-08-08 04:20', 14.87],
  ['2020-08-08 04:30', 11.85],
  ['2020-08-08 04:40', 12.9],
  ['2020-08-08 04:50', 15.69],
  ['2020-08-08 05:00', 14.81],
  ['2020-08-08 05:10', 15.46],
  ['2020-08-08 05:20', 15.51],
  ['2020-08-08 05:30', 15.77],
  ['2020-08-08 05:40', 15.28],
  ['2020-08-08 05:50', 15.42],
  ['2020-08-08 06:00', 13.27],
  ['2020-08-08 06:10', 15.11],
  ['2020-08-08 06:20', 15.91],
  ['2020-08-08 06:30', 15.25],
  ['2020-08-08 06:40', 16.15],
  ['2020-08-08 06:50', 16.07],
  ['2020-08-08 07:00', 15.51],
  ['2020-08-08 07:10', 15.44],
  ['2020-08-08 07:20', 15.41],
  ['2020-08-08 07:30', 16.9],
  ['2020-08-08 07:40', 18.77],
  ['2020-08-08 07:50', 17.01],
  ['2020-08-08 08:00', 16.09],
  ['2020-08-08 08:10', 15.05],
  ['2020-08-08 08:20', 14.21],
  ['2020-08-08 08:30', 14.6],
  ['2020-08-08 08:40', 14.78],
  ['2020-08-08 08:50', 15.15],
  ['2020-08-08 09:00', 14.74],
  ['2020-08-08 09:10', 14.49],
  ['2020-08-08 09:20', 13.39],
  ['2020-08-08 09:30', 13.31],
  ['2020-08-08 09:40', 15.15],
  ['2020-08-08 09:50', 13.32],
  ['2020-08-08 10:00', 13.92],
  ['2020-08-08 10:10', 16.56],
  ['2020-08-08 10:20', 11.65],
  ['2020-08-08 10:30', 10.77],
  ['2020-08-08 10:40', 14.36],
  ['2020-08-08 10:50', 17.49],
  ['2020-08-08 11:00', 16.5],
  ['2020-08-08 11:10', 15.87],
  ['2020-08-08 11:20', 15.8],
  ['2020-08-08 11:30', 15.48],
  ['2020-08-08 11:40', 15.25],
  ['2020-08-08 11:50', 15.63],
  ['2020-08-08 12:00', 15.19],
  ['2020-08-08 12:10', 14.25],
  ['2020-08-08 12:20', 14.29],
  ['2020-08-08 12:30', 13.95],
  ['2020-08-08 12:40', 12.91],
  ['2020-08-08 12:50', 13.56],
  ['2020-08-08 13:00', 13.64],
  ['2020-08-08 13:10', 13.53],
  ['2020-08-08 13:20', 13.45],
  ['2020-08-08 13:30', 13.82],
  ['2020-08-08 13:40', 14.99],
  ['2020-08-08 13:50', 15.47],
  ['2020-08-08 14:00', 14.81],
  ['2020-08-08 14:10', 16.23],
  ['2020-08-08 14:20', 15.06],
  ['2020-08-08 14:30', 12.68],
  ['2020-08-08 14:40', 13.98],
  ['2020-08-08 14:50', 14.68],
  ['2020-08-08 15:00', 14.34],
  ['2020-08-08 15:10', 13.7],
  ['2020-08-08 15:20', 14.2],
  ['2020-08-08 15:30', 14.06],
  ['2020-08-08 15:40', 14.49],
  ['2020-08-08 15:50', 15.03],
  ['2020-08-08 16:00', 15.77],
  ['2020-08-08 16:10', 15.27],
  ['2020-08-08 16:20', 15.8],
  ['2020-08-08 16:30', 16.43],
  ['2020-08-08 16:40', 15.76],
  ['2020-08-08 16:50', 16.36],
  ['2020-08-08 17:00', 16.08],
  ['2020-08-08 17:10', 17.35],
  ['2020-08-08 17:20', 15.59],
  ['2020-08-08 17:30', 16.95],
  ['2020-08-08 17:40', 13.7],
  ['2020-08-08 17:50', 13.12],
  ['2020-08-08 18:00', 16.61],
  ['2020-08-08 18:10', 19.92],
  ['2020-08-08 18:20', 17],
  ['2020-08-08 18:30', 16.07],
  ['2020-08-08 18:40', 15.1],
  ['2020-08-08 18:50', 15.32],
  ['2020-08-08 19:00', 15.32],
  ['2020-08-08 19:10', 15.29],
  ['2020-08-08 19:20', 13.23],
  ['2020-08-08 19:30', 11.06],
  ['2020-08-08 19:40', 9.23],
  ['2020-08-08 19:50', 9.34],
  ['2020-08-08 20:00', 9.21],
  ['2020-08-08 20:10', 9.46],
  ['2020-08-08 20:20', 8.87],
  ['2020-08-08 20:30', 8.91],
  ['2020-08-08 20:40', 8.85],
  ['2020-08-08 20:50', 8.54],
  ['2020-08-08 21:00', 7.79],
  ['2020-08-08 21:10', 7.77],
  ['2020-08-08 21:20', 8.12],
  ['2020-08-08 21:30', 7.18],
  ['2020-08-08 21:40', 6.74],
  ['2020-08-08 21:50', 6.16],
  ['2020-08-08 22:00', 6.07],
  ['2020-08-08 22:10', 7.44],
  ['2020-08-08 22:20', 5.17],
  ['2020-08-08 22:30', 6.11],
  ['2020-08-08 22:40', 9.22],
  ['2020-08-08 22:50', 8.37],
  ['2020-08-08 23:00', 10.29],
  ['2020-08-08 23:10', 13.37],
  ['2020-08-08 23:20', 15.34],
  ['2020-08-08 23:30', 15.32],
  ['2020-08-08 23:40', 15.52],
  ['2020-08-08 23:50', 15.9],
];

const WIND2 = [
  ['2020-08-09 00:00', 16.34],
  ['2020-08-09 00:10', 16.45],
  ['2020-08-09 00:20', 17.15],
  ['2020-08-09 00:30', 17.22],
  ['2020-08-09 00:40', 16.71],
  ['2020-08-09 00:50', 16.49],
  ['2020-08-09 01:00', 16.79],
  ['2020-08-09 01:10', 16.6],
  ['2020-08-09 01:20', 17.3],
  ['2020-08-09 01:30', 18.07],
  ['2020-08-09 01:40', 18.5],
  ['2020-08-09 01:50', 18.26],
  ['2020-08-09 02:00', 18.17],
  ['2020-08-09 02:10', 18.21],
  ['2020-08-09 02:20', 18.1],
  ['2020-08-09 02:30', 17.82],
  ['2020-08-09 02:40', 17.61],
  ['2020-08-09 02:50', 16.62],
  ['2020-08-09 03:00', 16.67],
  ['2020-08-09 03:10', 16.18],
  ['2020-08-09 03:20', 16.66],
  ['2020-08-09 03:30', 16.76],
  ['2020-08-09 03:40', 16.46],
  ['2020-08-09 03:50', 16.46],
  ['2020-08-09 04:00', 15.48],
  ['2020-08-09 04:10', 15.26],
  ['2020-08-09 04:20', 14.21],
  ['2020-08-09 04:30', 15.07],
  ['2020-08-09 04:40', 14.8],
  ['2020-08-09 04:50', 15.68],
  ['2020-08-09 05:00', 16.48],
  ['2020-08-09 05:10', 16.53],
  ['2020-08-09 05:20', 16.54],
  ['2020-08-09 05:30', 16.79],
  ['2020-08-09 05:40', 16.66],
  ['2020-08-09 05:50', 16.45],
  ['2020-08-09 06:00', 17.15],
  ['2020-08-09 06:10', 17.26],
  ['2020-08-09 06:20', 16.82],
  ['2020-08-09 06:30', 16.85],
  ['2020-08-09 06:40', 16.59],
  ['2020-08-09 06:50', 16.14],
  ['2020-08-09 07:00', 16.01],
  ['2020-08-09 07:10', 16.31],
  ['2020-08-09 07:20', 15.61],
  ['2020-08-09 07:30', 16.09],
  ['2020-08-09 07:40', 15.28],
  ['2020-08-09 07:50', 13.99],
  ['2020-08-09 08:00', 15.43],
  ['2020-08-09 08:10', 16.97],
  ['2020-08-09 08:20', 17.28],
  ['2020-08-09 08:30', 16.9],
  ['2020-08-09 08:40', 16.52],
  ['2020-08-09 08:50', 16.63],
  ['2020-08-09 09:00', 15.98],
  ['2020-08-09 09:10', 16.12],
  ['2020-08-09 09:20', 15.21],
  ['2020-08-09 09:30', 15.68],
  ['2020-08-09 09:40', 16.15],
  ['2020-08-09 09:50', 15.57],
  ['2020-08-09 10:00', 15.25],
  ['2020-08-09 10:10', 14.19],
  ['2020-08-09 10:20', 13.11],
  ['2020-08-09 10:30', 14.28],
  ['2020-08-09 10:40', 13.79],
  ['2020-08-09 10:50', 14.03],
  ['2020-08-09 11:00', 14],
  ['2020-08-09 11:10', 14.13],
  ['2020-08-09 11:20', 15.24],
  ['2020-08-09 11:30', 15.44],
  ['2020-08-09 11:40', 14.55],
  ['2020-08-09 11:50', 15.42],
  ['2020-08-09 12:00', 15.01],
  ['2020-08-09 12:10', 15.37],
  ['2020-08-09 12:20', 16.03],
  ['2020-08-09 12:30', 15.23],
  ['2020-08-09 12:40', 16.55],
  ['2020-08-09 12:50', 15.36],
  ['2020-08-09 13:00', 15],
  ['2020-08-09 13:10', 13.6],
  ['2020-08-09 13:20', 13.98],
  ['2020-08-09 13:30', 15.03],
  ['2020-08-09 13:40', 15.55],
  ['2020-08-09 13:50', 14.42],
  ['2020-08-09 14:00', 14.04],
  ['2020-08-09 14:10', 13.9],
  ['2020-08-09 14:20', 14.55],
  ['2020-08-09 14:30', 14.68],
  ['2020-08-09 14:40', 15.08],
  ['2020-08-09 14:50', 14.89],
  ['2020-08-09 15:00', 15.22],
  ['2020-08-09 15:10', 14.67],
  ['2020-08-09 15:20', 14.06],
  ['2020-08-09 15:30', 15.33],
  ['2020-08-09 15:40', 14.83],
  ['2020-08-09 15:50', 14.85],
  ['2020-08-09 16:00', 15.14],
  ['2020-08-09 16:10', 15.64],
  ['2020-08-09 16:20', 16.23],
  ['2020-08-09 16:30', 16.46],
  ['2020-08-09 16:40', 14.63],
  ['2020-08-09 16:50', 14.73],
  ['2020-08-09 17:00', 17.45],
  ['2020-08-09 17:10', 16.28],
  ['2020-08-09 17:20', 15.18],
  ['2020-08-09 17:30', 15.35],
  ['2020-08-09 17:40', 15.84],
  ['2020-08-09 17:50', 16.54],
  ['2020-08-09 18:00', 16.94],
  ['2020-08-09 18:10', 16.74],
  ['2020-08-09 18:20', 16.36],
  ['2020-08-09 18:30', 16.53],
  ['2020-08-09 18:40', 17.63],
  ['2020-08-09 18:50', 16.78],
  ['2020-08-09 19:00', 16.39],
  ['2020-08-09 19:10', 15.67],
  ['2020-08-09 19:20', 15.41],
  ['2020-08-09 19:30', 15],
  ['2020-08-09 19:40', 15.69],
  ['2020-08-09 19:50', 16.94],
  ['2020-08-09 20:00', 15.04],
  ['2020-08-09 20:10', 16.31],
  ['2020-08-09 20:20', 16.31],
  ['2020-08-09 20:30', 16.99],
  ['2020-08-09 20:40', 17.94],
  ['2020-08-09 20:50', 17.81],
  ['2020-08-09 21:00', 16.71],
  ['2020-08-09 21:10', 16.99],
  ['2020-08-09 21:20', 16.43],
  ['2020-08-09 21:30', 16.48],
  ['2020-08-09 21:40', 16.11],
  ['2020-08-09 21:50', 15.27],
  ['2020-08-09 22:00', 16.58],
  ['2020-08-09 22:10', 16.31],
  ['2020-08-09 22:20', 16.33],
  ['2020-08-09 22:30', 14.9],
  ['2020-08-09 22:40', 15.9],
  ['2020-08-09 22:50', 16.86],
  ['2020-08-09 23:00', 16.44],
  ['2020-08-09 23:10', 18.03],
  ['2020-08-09 23:20', 18.5],
  ['2020-08-09 23:30', 17.73],
  ['2020-08-09 23:40', 18.71],
  ['2020-08-09 23:50', 17.42],
  ['2020-08-10 00:00', 16.84],
  ['2020-08-10 00:10', 14.71],
  ['2020-08-10 00:20', 12.37],
  ['2020-08-10 00:30', 12.19],
  ['2020-08-10 00:40', 12.11],
  ['2020-08-10 00:50', 13.08],
  ['2020-08-10 01:00', 13.84],
  ['2020-08-10 01:10', 13.89],
  ['2020-08-10 01:20', 14.24],
  ['2020-08-10 01:30', 14.15],
  ['2020-08-10 01:40', 13.79],
  ['2020-08-10 01:50', 13.63],
  ['2020-08-10 02:00', 12.65],
  ['2020-08-10 02:10', 12.65],
  ['2020-08-10 02:20', 12.81],
  ['2020-08-10 02:30', 13.23],
  ['2020-08-10 02:40', 13.44],
  ['2020-08-10 02:50', 13.22],
  ['2020-08-10 03:00', 14.34],
  ['2020-08-10 03:10', 14.52],
  ['2020-08-10 03:20', 13.43],
  ['2020-08-10 03:30', 13.47],
  ['2020-08-10 03:40', 13.78],
  ['2020-08-10 03:50', 13.95],
  ['2020-08-10 04:00', 13.2],
  ['2020-08-10 04:10', 13.01],
  ['2020-08-10 04:20', 13.94],
  ['2020-08-10 04:30', 14.67],
  ['2020-08-10 04:40', 14.54],
  ['2020-08-10 04:50', 15.17],
  ['2020-08-10 05:00', 15.96],
  ['2020-08-10 05:10', 15.8],
  ['2020-08-10 05:20', 15.52],
  ['2020-08-10 05:30', 15.89],
  ['2020-08-10 05:40', 16.15],
  ['2020-08-10 05:50', 15.65],
  ['2020-08-10 06:00', 15.75],
  ['2020-08-10 06:10', 15.73],
  ['2020-08-10 06:20', 15.58],
  ['2020-08-10 06:30', 14.98],
  ['2020-08-10 06:40', 15.58],
  ['2020-08-10 06:50', 14.91],
  ['2020-08-10 07:00', 15.29],
  ['2020-08-10 07:10', 15.35],
  ['2020-08-10 07:20', 15],
  ['2020-08-10 07:30', 14.97],
  ['2020-08-10 07:40', 15.33],
  ['2020-08-10 07:50', 15.69],
  ['2020-08-10 08:00', 14.85],
  ['2020-08-10 08:10', 15.02],
  ['2020-08-10 08:20', 14.36],
  ['2020-08-10 08:30', 13.88],
  ['2020-08-10 08:40', 14.71],
  ['2020-08-10 08:50', 15.16],
  ['2020-08-10 09:00', 14.04],
  ['2020-08-10 09:10', 12.59],
  ['2020-08-10 09:20', 12.32],
  ['2020-08-10 09:30', 12.52],
  ['2020-08-10 09:40', 12.92],
  ['2020-08-10 09:50', 12.43],
  ['2020-08-10 10:00', 12.47],
  ['2020-08-10 10:10', 13.18],
  ['2020-08-10 10:20', 12.74],
  ['2020-08-10 10:30', 13.33],
  ['2020-08-10 10:40', 13.2],
  ['2020-08-10 10:50', 13.24],
  ['2020-08-10 11:00', 13.66],
  ['2020-08-10 11:10', 13.71],
  ['2020-08-10 11:20', 13],
  ['2020-08-10 11:30', 11.99],
  ['2020-08-10 11:40', 12.08],
  ['2020-08-10 11:50', 12.06],
  ['2020-08-10 12:00', 12.71],
  ['2020-08-10 12:10', 12.86],
  ['2020-08-10 12:20', 12.81],
  ['2020-08-10 12:30', 13.21],
  ['2020-08-10 12:40', 14.15],
  ['2020-08-10 12:50', 14.23],
  ['2020-08-10 13:00', 14.12],
  ['2020-08-10 13:10', 13.57],
  ['2020-08-10 13:20', 12.2],
  ['2020-08-10 13:30', 10.97],
  ['2020-08-10 13:40', 10.89],
  ['2020-08-10 13:50', 11.82],
  ['2020-08-10 14:00', 11.55],
  ['2020-08-10 14:10', 11.77],
  ['2020-08-10 14:20', 13.99],
  ['2020-08-10 14:30', 13.95],
  ['2020-08-10 14:40', 15.72],
  ['2020-08-10 14:50', 17.99],
  ['2020-08-10 15:00', 19.23],
  ['2020-08-10 15:10', 18.81],
  ['2020-08-10 15:20', 18.91],
  ['2020-08-10 15:30', 20.62],
  ['2020-08-10 15:40', 20.88],
  ['2020-08-10 15:50', 20.05],
  ['2020-08-10 16:00', 20.96],
  ['2020-08-10 16:10', 22.86],
  ['2020-08-10 16:20', 21.99],
  ['2020-08-10 16:30', 20.71],
  ['2020-08-10 16:40', 21.41],
  ['2020-08-10 16:50', 21.1],
  ['2020-08-10 17:00', 21.73],
  ['2020-08-10 17:10', 21.4],
  ['2020-08-10 17:20', 22.17],
  ['2020-08-10 17:30', 22.54],
  ['2020-08-10 17:40', 22.77],
  ['2020-08-10 17:50', 23.2],
  ['2020-08-10 18:00', 24.05],
  ['2020-08-10 18:10', 25.51],
  ['2020-08-10 18:20', 25.47],
  ['2020-08-10 18:30', 25.88],
  ['2020-08-10 18:40', 24.84],
  ['2020-08-10 18:50', 23.93],
  ['2020-08-10 19:00', 24.19],
  ['2020-08-10 19:10', 24.66],
  ['2020-08-10 19:20', 27.21],
  ['2020-08-10 19:30', 25.34],
  ['2020-08-10 19:40', 23.5],
  ['2020-08-10 19:50', 21.97],
  ['2020-08-10 20:00', 21.78],
  ['2020-08-10 20:10', 22.46],
  ['2020-08-10 20:20', 22.1],
  ['2020-08-10 20:30', 20.51],
  ['2020-08-10 20:40', 20.54],
  ['2020-08-10 20:50', 21.38],
  ['2020-08-10 21:00', 20.57],
  ['2020-08-10 21:10', 20.57],
  ['2020-08-10 21:20', 19.48],
  ['2020-08-10 21:30', 19.12],
  ['2020-08-10 21:40', 17.56],
  ['2020-08-10 21:50', 17.53],
  ['2020-08-10 22:00', 16.66],
  ['2020-08-10 22:10', 15.01],
  ['2020-08-10 22:20', 14.23],
  ['2020-08-10 22:30', 13.67],
  ['2020-08-10 22:40', 13.83],
  ['2020-08-10 22:50', 14.27],
  ['2020-08-10 23:00', 15.7],
  ['2020-08-10 23:10', 15.73],
  ['2020-08-10 23:20', 16.48],
  ['2020-08-10 23:30', 17.21],
  ['2020-08-10 23:40', 17.47],
  ['2020-08-10 23:50', 18.49],
  ['2020-08-11 00:00', 18.57],
  ['2020-08-11 00:10', 19.3],
  ['2020-08-11 00:20', 18.48],
  ['2020-08-11 00:30', 17.6],
  ['2020-08-11 00:40', 17.22],
  ['2020-08-11 00:50', 18.95],
  ['2020-08-11 01:00', 19.19],
  ['2020-08-11 01:10', 18.95],
  ['2020-08-11 01:20', 19.23],
  ['2020-08-11 01:30', 19.37],
  ['2020-08-11 01:40', 19.02],
  ['2020-08-11 01:50', 19.28],
  ['2020-08-11 02:00', 17.53],
  ['2020-08-11 02:10', 17.41],
  ['2020-08-11 02:20', 17.5],
  ['2020-08-11 02:30', 17.85],
  ['2020-08-11 02:40', 18.39],
  ['2020-08-11 02:50', 17.99],
  ['2020-08-11 03:00', 17.96],
  ['2020-08-11 03:10', 18.48],
  ['2020-08-11 03:20', 18.56],
  ['2020-08-11 03:30', 17.84],
  ['2020-08-11 03:40', 18.04],
  ['2020-08-11 03:50', 17.42],
  ['2020-08-11 04:00', 17.57],
  ['2020-08-11 04:10', 18.28],
  ['2020-08-11 04:20', 18.53],
  ['2020-08-11 04:30', 18.47],
  ['2020-08-11 04:40', 18.63],
  ['2020-08-11 04:50', 18.57],
  ['2020-08-11 05:00', 17.61],
  ['2020-08-11 05:10', 17.05],
  ['2020-08-11 05:20', 17.3],
  ['2020-08-11 05:30', 17.13],
  ['2020-08-11 05:40', 17.48],
  ['2020-08-11 05:50', 17.53],
  ['2020-08-11 06:00', 17.87],
  ['2020-08-11 06:10', 17.66],
  ['2020-08-11 06:20', 16.52],
  ['2020-08-11 06:30', 17.23],
  ['2020-08-11 06:40', 17.67],
  ['2020-08-11 06:50', 16.87],
  ['2020-08-11 07:00', 16.21],
  ['2020-08-11 07:10', 16.37],
  ['2020-08-11 07:20', 16.87],
  ['2020-08-11 07:30', 17.15],
  ['2020-08-11 07:40', 16.89],
  ['2020-08-11 07:50', 16.75],
  ['2020-08-11 08:00', 16.45],
  ['2020-08-11 08:10', 15.91],
  ['2020-08-11 08:20', 15.4],
  ['2020-08-11 08:30', 15.74],
  ['2020-08-11 08:40', 15.74],
  ['2020-08-11 08:50', 15.34],
  ['2020-08-11 09:00', 16.12],
  ['2020-08-11 09:10', 15.82],
  ['2020-08-11 09:20', 15.96],
  ['2020-08-11 09:30', 15.26],
  ['2020-08-11 09:40', 15.17],
  ['2020-08-11 09:50', 15.05],
  ['2020-08-11 10:00', 16.34],
  ['2020-08-11 10:10', 15.74],
  ['2020-08-11 10:20', 15.76],
  ['2020-08-11 10:30', 15.85],
  ['2020-08-11 10:40', 15.25],
  ['2020-08-11 10:50', 15.58],
  ['2020-08-11 11:00', 14.07],
  ['2020-08-11 11:10', 13.59],
  ['2020-08-11 11:20', 13.98],
  ['2020-08-11 11:30', 14.01],
  ['2020-08-11 11:40', 14.12],
  ['2020-08-11 11:50', 14.57],
  ['2020-08-11 12:00', 14.33],
  ['2020-08-11 12:10', 14.27],
  ['2020-08-11 12:20', 14.8],
  ['2020-08-11 12:30', 14.77],
  ['2020-08-11 12:40', 14.97],
  ['2020-08-11 12:50', 14.95],
  ['2020-08-11 13:00', 15.2],
  ['2020-08-11 13:10', 15.51],
  ['2020-08-11 13:20', 16.16],
  ['2020-08-11 13:30', 15.82],
  ['2020-08-11 13:40', 16.97],
  ['2020-08-11 13:50', 17.84],
  ['2020-08-11 14:00', 18.22],
  ['2020-08-11 14:10', 18.39],
  ['2020-08-11 14:20', 18.56],
  ['2020-08-11 14:30', 18.91],
  ['2020-08-11 14:40', 17.99],
  ['2020-08-11 14:50', 17.63],
  ['2020-08-11 15:00', 17.34],
  ['2020-08-11 15:10', 16],
  ['2020-08-11 15:20', 15.66],
  ['2020-08-11 15:30', 16.82],
  ['2020-08-11 15:40', 16.63],
  ['2020-08-11 15:50', 16.95],
  ['2020-08-11 16:00', 17.13],
  ['2020-08-11 16:10', 15.02],
  ['2020-08-11 16:20', 14.56],
  ['2020-08-11 16:30', 14.89],
  ['2020-08-11 16:40', 15.55],
  ['2020-08-11 16:50', 16.01],
  ['2020-08-11 17:00', 16.07],
  ['2020-08-11 17:10', 15.44],
  ['2020-08-11 17:20', 14.22],
  ['2020-08-11 17:30', 13.55],
  ['2020-08-11 17:40', 14.47],
  ['2020-08-11 17:50', 14.75],
  ['2020-08-11 18:00', 13.95],
  ['2020-08-11 18:10', 12.71],
  ['2020-08-11 18:20', 11.93],
  ['2020-08-11 18:30', 12.15],
  ['2020-08-11 18:40', 13.47],
  ['2020-08-11 18:50', 13.23],
  ['2020-08-11 19:00', 13.34],
  ['2020-08-11 19:10', 13.14],
  ['2020-08-11 19:20', 13.33],
  ['2020-08-11 19:30', 13.66],
  ['2020-08-11 19:40', 13.71],
  ['2020-08-11 19:50', 13.93],
  ['2020-08-11 20:00', 13.5],
  ['2020-08-11 20:10', 12.23],
  ['2020-08-11 20:20', 12.56],
  ['2020-08-11 20:30', 13.2],
  ['2020-08-11 20:40', 13.38],
  ['2020-08-11 20:50', 13.99],
  ['2020-08-11 21:00', 13.81],
  ['2020-08-11 21:10', 12.61],
  ['2020-08-11 21:20', 12.72],
  ['2020-08-11 21:30', 12.38],
  ['2020-08-11 21:40', 13.26],
  ['2020-08-11 21:50', 13.98],
  ['2020-08-11 22:00', 14.21],
  ['2020-08-11 22:10', 14.88],
  ['2020-08-11 22:20', 15.32],
  ['2020-08-11 22:30', 15.53],
  ['2020-08-11 22:40', 15.29],
  ['2020-08-11 22:50', 14.37],
  ['2020-08-11 23:00', 14.34],
  ['2020-08-11 23:10', 14.16],
  ['2020-08-11 23:20', 13.31],
  ['2020-08-11 23:30', 14.22],
  ['2020-08-11 23:40', 14.67],
  ['2020-08-11 23:50', 15.94],
  ['2020-08-12 00:00', 15.34],
  ['2020-08-12 00:10', 14.24],
  ['2020-08-12 00:20', 14.43],
  ['2020-08-12 00:30', 14.57],
  ['2020-08-12 00:40', 14.98],
  ['2020-08-12 00:50', 14.96],
  ['2020-08-12 01:00', 14.25],
  ['2020-08-12 01:10', 13.61],
  ['2020-08-12 01:20', 14.09],
  ['2020-08-12 01:30', 12.4],
  ['2020-08-12 01:40', 12.09],
  ['2020-08-12 01:50', 12.99],
  ['2020-08-12 02:00', 14.82],
  ['2020-08-12 02:10', 13.84],
  ['2020-08-12 02:20', 13.02],
  ['2020-08-12 02:30', 14.32],
  ['2020-08-12 02:40', 15.42],
  ['2020-08-12 02:50', 15.43],
  ['2020-08-12 03:00', 14.29],
  ['2020-08-12 03:10', 14.52],
  ['2020-08-12 03:20', 15.36],
  ['2020-08-12 03:30', 16.33],
  ['2020-08-12 03:40', 16.24],
  ['2020-08-12 03:50', 17.12],
  ['2020-08-12 04:00', 17.98],
  ['2020-08-12 04:10', 17.92],
  ['2020-08-12 04:20', 17.58],
  ['2020-08-12 04:30', 16.85],
  ['2020-08-12 04:40', 16.6],
  ['2020-08-12 04:50', 17.21],
  ['2020-08-12 05:00', 16.77],
  ['2020-08-12 05:10', 17.87],
  ['2020-08-12 05:20', 18.13],
  ['2020-08-12 05:30', 17.46],
  ['2020-08-12 05:40', 15.68],
  ['2020-08-12 05:50', 14.55],
  ['2020-08-12 06:00', 14.84],
  ['2020-08-12 06:10', 16.03],
  ['2020-08-12 06:20', 16.79],
  ['2020-08-12 06:30', 12.96],
  ['2020-08-12 06:40', 13.24],
  ['2020-08-12 06:50', 15.26],
  ['2020-08-12 07:00', 15.8],
  ['2020-08-12 07:10', 14.86],
  ['2020-08-12 07:20', 13.95],
  ['2020-08-12 07:30', 12.05],
  ['2020-08-12 07:40', 13.87],
  ['2020-08-12 07:50', 12.16],
  ['2020-08-12 08:00', 12.6],
  ['2020-08-12 08:10', 12.2],
  ['2020-08-12 08:20', 15.16],
  ['2020-08-12 08:30', 15.12],
  ['2020-08-12 08:40', 15.11],
  ['2020-08-12 08:50', 15.86],
  ['2020-08-12 09:00', 13.62],
  ['2020-08-12 09:10', 14.97],
  ['2020-08-12 09:20', 15.27],
  ['2020-08-12 09:30', 14.61],
  ['2020-08-12 09:40', 14.67],
  ['2020-08-12 09:50', 15.18],
  ['2020-08-12 10:00', 16.64],
  ['2020-08-12 10:10', 16.86],
  ['2020-08-12 10:20', 15.58],
  ['2020-08-12 10:30', 16.5],
  ['2020-08-12 10:40', 13.72],
  ['2020-08-12 10:50', 14.52],
  ['2020-08-12 11:00', 13.54],
  ['2020-08-12 11:10', 14.65],
  ['2020-08-12 11:20', 14.84],
  ['2020-08-12 11:30', 15.36],
  ['2020-08-12 11:40', 15.54],
  ['2020-08-12 11:50', 14.9],
  ['2020-08-12 12:00', 14.79],
  ['2020-08-12 12:10', 14.2],
  ['2020-08-12 12:20', 13.79],
  ['2020-08-12 12:30', 11.89],
  ['2020-08-12 12:40', 10.86],
  ['2020-08-12 12:50', 14.32],
  ['2020-08-12 13:00', 14.12],
  ['2020-08-12 13:10', 12.79],
  ['2020-08-12 13:20', 12.58],
  ['2020-08-12 13:30', 13.26],
  ['2020-08-12 13:40', 13.59],
  ['2020-08-12 13:50', 12.69],
  ['2020-08-12 14:00', 12.23],
  ['2020-08-12 14:10', 11.64],
  ['2020-08-12 14:20', 12.59],
  ['2020-08-12 14:30', 11.97],
  ['2020-08-12 14:40', 11.78],
  ['2020-08-12 14:50', 13.3],
  ['2020-08-12 15:00', 13.76],
  ['2020-08-12 15:10', 13.42],
  ['2020-08-12 15:20', 12.42],
  ['2020-08-12 15:30', 13.52],
  ['2020-08-12 15:40', 13.76],
  ['2020-08-12 15:50', 13.37],
  ['2020-08-12 16:00', 13.56],
  ['2020-08-12 16:10', 13.64],
  ['2020-08-12 16:20', 15.1],
  ['2020-08-12 16:30', 14.42],
  ['2020-08-12 16:40', 14.69],
  ['2020-08-12 16:50', 16.49],
  ['2020-08-12 17:00', 16.71],
  ['2020-08-12 17:10', 15.68],
  ['2020-08-12 17:20', 15.89],
  ['2020-08-12 17:30', 17.46],
  ['2020-08-12 17:40', 17.81],
  ['2020-08-12 17:50', 18.29],
  ['2020-08-12 18:00', 17.77],
  ['2020-08-12 18:10', 17.11],
  ['2020-08-12 18:20', 16.57],
  ['2020-08-12 18:30', 16.62],
  ['2020-08-12 18:40', 16.12],
  ['2020-08-12 18:50', 15.92],
  ['2020-08-12 19:00', 16.21],
  ['2020-08-12 19:10', 16.54],
  ['2020-08-12 19:20', 13.08],
  ['2020-08-12 19:30', 12.87],
  ['2020-08-12 19:40', 12.9],
  ['2020-08-12 19:50', 13.79],
  ['2020-08-12 20:00', 12.62],
  ['2020-08-12 20:10', 14.09],
  ['2020-08-12 20:20', 13.28],
  ['2020-08-12 20:30', 14.49],
  ['2020-08-12 20:40', 14.97],
  ['2020-08-12 20:50', 15.05],
  ['2020-08-12 21:00', 14.82],
  ['2020-08-12 21:10', 14.6],
  ['2020-08-12 21:20', 14.01],
  ['2020-08-12 21:30', 14.23],
  ['2020-08-12 21:40', 14.64],
  ['2020-08-12 21:50', 14.95],
  ['2020-08-12 22:00', 15.2],
  ['2020-08-12 22:10', 14.97],
  ['2020-08-12 22:20', 14.57],
  ['2020-08-12 22:30', 15.17],
  ['2020-08-12 22:40', 16.16],
  ['2020-08-12 22:50', 15.86],
  ['2020-08-12 23:00', 15.68],
  ['2020-08-12 23:10', 15.91],
  ['2020-08-12 23:20', 15.75],
  ['2020-08-12 23:30', 15.57],
  ['2020-08-12 23:40', 15.24],
  ['2020-08-12 23:50', 13.57],
  ['2020-08-13 00:00', 11.93],
  ['2020-08-13 00:10', 12.01],
  ['2020-08-13 00:20', 12.05],
  ['2020-08-13 00:30', 12.28],
  ['2020-08-13 00:40', 12.19],
  ['2020-08-13 00:50', 12.02],
  ['2020-08-13 01:00', 12.07],
  ['2020-08-13 01:10', 11.98],
  ['2020-08-13 01:20', 12.27],
  ['2020-08-13 01:30', 12.19],
  ['2020-08-13 01:40', 12.1],
  ['2020-08-13 01:50', 11.97],
  ['2020-08-13 02:00', 11.94],
  ['2020-08-13 02:10', 15.5],
  ['2020-08-13 02:20', 16.07],
  ['2020-08-13 02:30', 15.57],
  ['2020-08-13 02:40', 16.24],
  ['2020-08-13 02:50', 16.37],
  ['2020-08-13 03:00', 16.36],
  ['2020-08-13 03:10', 15.86],
  ['2020-08-13 03:20', 16.11],
  ['2020-08-13 03:30', 15.78],
  ['2020-08-13 03:40', 15.63],
  ['2020-08-13 03:50', 14.83],
  ['2020-08-13 04:00', 15.11],
  ['2020-08-13 04:10', 14.93],
  ['2020-08-13 04:20', 14.81],
  ['2020-08-13 04:30', 14.6],
  ['2020-08-13 04:40', 15.25],
  ['2020-08-13 04:50', 15.09],
  ['2020-08-13 05:00', 14.74],
  ['2020-08-13 05:10', 14.65],
  ['2020-08-13 05:20', 14.77],
  ['2020-08-13 05:30', 14.8],
  ['2020-08-13 05:40', 14.47],
  ['2020-08-13 05:50', 13.92],
  ['2020-08-13 06:00', 13.84],
  ['2020-08-13 06:10', 13.65],
  ['2020-08-13 06:20', 13.34],
  ['2020-08-13 06:30', 13.22],
  ['2020-08-13 06:40', 12.6],
  ['2020-08-13 06:50', 12.16],
  ['2020-08-13 07:00', 11.4],
  ['2020-08-13 07:10', 11.28],
  ['2020-08-13 07:20', 11.62],
  ['2020-08-13 07:30', 11.1],
  ['2020-08-13 07:40', 10.56],
  ['2020-08-13 07:50', 10.95],
  ['2020-08-13 08:00', 10.65],
  ['2020-08-13 08:10', 10.47],
  ['2020-08-13 08:20', 10.71],
  ['2020-08-13 08:30', 11.02],
  ['2020-08-13 08:40', 11.59],
  ['2020-08-13 08:50', 11.56],
  ['2020-08-13 09:00', 11.95],
  ['2020-08-13 09:10', 12.67],
  ['2020-08-13 09:20', 11.06],
  ['2020-08-13 09:30', 10.1],
  ['2020-08-13 09:40', 11.87],
  ['2020-08-13 09:50', 12.24],
  ['2020-08-13 10:00', 11.93],
  ['2020-08-13 10:10', 12.93],
  ['2020-08-13 10:20', 13.96],
  ['2020-08-13 10:30', 14.55],
  ['2020-08-13 10:40', 14.88],
  ['2020-08-13 10:50', 14.95],
  ['2020-08-13 11:00', 14.4],
  ['2020-08-13 11:10', 14.14],
  ['2020-08-13 11:20', 13.91],
  ['2020-08-13 11:30', 11.94],
  ['2020-08-13 11:40', 12.09],
  ['2020-08-13 11:50', 12.21],
  ['2020-08-13 12:00', 12.43],
  ['2020-08-13 12:10', 12.55],
  ['2020-08-13 12:20', 15.34],
  ['2020-08-13 12:30', 15.4],
  ['2020-08-13 12:40', 15.16],
  ['2020-08-13 12:50', 15.08],
  ['2020-08-13 13:00', 16.49],
  ['2020-08-13 13:10', 15.78],
  ['2020-08-13 13:20', 15.84],
  ['2020-08-13 13:30', 16.87],
  ['2020-08-13 13:40', 16.92],
  ['2020-08-13 13:50', 16.95],
  ['2020-08-13 14:00', 16.23],
  ['2020-08-13 14:10', 16.55],
  ['2020-08-13 14:20', 17.32],
  ['2020-08-13 14:30', 16.93],
  ['2020-08-13 14:40', 16.14],
  ['2020-08-13 14:50', 15.95],
  ['2020-08-13 15:00', 16.6],
  ['2020-08-13 15:10', 16.2],
  ['2020-08-13 15:20', 15.59],
  ['2020-08-13 15:30', 15.33],
  ['2020-08-13 15:40', 15.55],
  ['2020-08-13 15:50', 15.93],
  ['2020-08-13 16:00', 16.03],
  ['2020-08-13 16:10', 16.93],
  ['2020-08-13 16:20', 17.17],
  ['2020-08-13 16:30', 16.73],
  ['2020-08-13 16:40', 16.62],
  ['2020-08-13 16:50', 16.45],
  ['2020-08-13 17:00', 16.33],
  ['2020-08-13 17:10', 16.41],
  ['2020-08-13 17:20', 16.78],
  ['2020-08-13 17:30', 16.74],
  ['2020-08-13 17:40', 16.95],
  ['2020-08-13 17:50', 16.45],
  ['2020-08-13 18:00', 16.33],
  ['2020-08-13 18:10', 16.62],
  ['2020-08-13 18:20', 16.97],
  ['2020-08-13 18:30', 17.11],
  ['2020-08-13 18:40', 17.34],
  ['2020-08-13 18:50', 17.04],
  ['2020-08-13 19:00', 17.4],
  ['2020-08-13 19:10', 17.16],
  ['2020-08-13 19:20', 17.55],
  ['2020-08-13 19:30', 17.06],
  ['2020-08-13 19:40', 17.43],
  ['2020-08-13 19:50', 17.79],
  ['2020-08-13 20:00', 18.34],
  ['2020-08-13 20:10', 18.42],
  ['2020-08-13 20:20', 18.52],
  ['2020-08-13 20:30', 18.38],
  ['2020-08-13 20:40', 17.99],
  ['2020-08-13 20:50', 17.94],
  ['2020-08-13 21:00', 18.27],
  ['2020-08-13 21:10', 18.54],
  ['2020-08-13 21:20', 19.27],
  ['2020-08-13 21:30', 19],
  ['2020-08-13 21:40', 17.72],
  ['2020-08-13 21:50', 16.69],
  ['2020-08-13 22:00', 16.05],
  ['2020-08-13 22:10', 15.82],
  ['2020-08-13 22:20', 15.73],
  ['2020-08-13 22:30', 14.82],
  ['2020-08-13 22:40', 16.73],
  ['2020-08-13 22:50', 17.48],
  ['2020-08-13 23:00', 17.61],
  ['2020-08-13 23:10', 17.42],
  ['2020-08-13 23:20', 16.99],
  ['2020-08-13 23:30', 16.52],
  ['2020-08-13 23:40', 16],
  ['2020-08-13 23:50', 14.98],
  ['2020-08-14 00:00', 14.93],
  ['2020-08-14 00:10', 14.78],
  ['2020-08-14 00:20', 14.46],
  ['2020-08-14 00:30', 14.29],
  ['2020-08-14 00:40', 14.25],
  ['2020-08-14 00:50', 14.84],
  ['2020-08-14 01:00', 15.56],
  ['2020-08-14 01:10', 15.28],
  ['2020-08-14 01:20', 14.32],
  ['2020-08-14 01:30', 14.05],
  ['2020-08-14 01:40', 14.65],
  ['2020-08-14 01:50', 15.26],
  ['2020-08-14 02:00', 15.24],
  ['2020-08-14 02:10', 14.19],
  ['2020-08-14 02:20', 14.71],
  ['2020-08-14 02:30', 14.94],
  ['2020-08-14 02:40', 14.98],
  ['2020-08-14 02:50', 14.61],
  ['2020-08-14 03:00', 14.91],
  ['2020-08-14 03:10', 15.33],
  ['2020-08-14 03:20', 15.89],
  ['2020-08-14 03:30', 16.48],
  ['2020-08-14 03:40', 16.96],
  ['2020-08-14 03:50', 16.54],
  ['2020-08-14 04:00', 16.28],
  ['2020-08-14 04:10', 17.92],
  ['2020-08-14 04:20', 17.46],
  ['2020-08-14 04:30', 17.57],
  ['2020-08-14 04:40', 18.59],
  ['2020-08-14 04:50', 18.99],
  ['2020-08-14 05:00', 18.52],
  ['2020-08-14 05:10', 18.24],
  ['2020-08-14 05:20', 18.04],
  ['2020-08-14 05:30', 17.52],
  ['2020-08-14 05:40', 17.41],
  ['2020-08-14 05:50', 17.47],
  ['2020-08-14 06:00', 16.93],
  ['2020-08-14 06:10', 17.29],
  ['2020-08-14 06:20', 17.38],
  ['2020-08-14 06:30', 17.14],
  ['2020-08-14 06:40', 17.59],
  ['2020-08-14 06:50', 17.97],
  ['2020-08-14 07:00', 18.47],
  ['2020-08-14 07:10', 18.37],
  ['2020-08-14 07:20', 17.8],
  ['2020-08-14 07:30', 17.84],
  ['2020-08-14 07:40', 17.81],
  ['2020-08-14 07:50', 17.4],
  ['2020-08-14 08:00', 18.2],
  ['2020-08-14 08:10', 17.64],
  ['2020-08-14 08:20', 17.3],
  ['2020-08-14 08:30', 17.05],
  ['2020-08-14 08:40', 16.82],
  ['2020-08-14 08:50', 16.94],
  ['2020-08-14 09:00', 17.18],
  ['2020-08-14 09:10', 16.89],
  ['2020-08-14 09:20', 17.43],
  ['2020-08-14 09:30', 17.86],
  ['2020-08-14 09:40', 18.03],
  ['2020-08-14 09:50', 17.53],
  ['2020-08-14 10:00', 17.79],
  ['2020-08-14 10:10', 17.45],
  ['2020-08-14 10:20', 16.98],
  ['2020-08-14 10:30', 16.62],
  ['2020-08-14 10:40', 16.45],
  ['2020-08-14 10:50', 17.09],
  ['2020-08-14 11:00', 17.79],
  ['2020-08-14 11:10', 18.47],
  ['2020-08-14 11:20', 18.42],
  ['2020-08-14 11:30', 17.63],
  ['2020-08-14 11:40', 17.66],
  ['2020-08-14 11:50', 17.7],
  ['2020-08-14 12:00', 17.31],
  ['2020-08-14 12:10', 17.14],
  ['2020-08-14 12:20', 18.03],
  ['2020-08-14 12:30', 17.92],
  ['2020-08-14 12:40', 17.69],
  ['2020-08-14 12:50', 17.3],
  ['2020-08-14 13:00', 17.89],
  ['2020-08-14 13:10', 17.56],
  ['2020-08-14 13:20', 17.94],
  ['2020-08-14 13:30', 17.13],
  ['2020-08-14 13:40', 17.86],
  ['2020-08-14 13:50', 17.42],
  ['2020-08-14 14:00', 17.74],
  ['2020-08-14 14:10', 18.03],
  ['2020-08-14 14:20', 18.19],
  ['2020-08-14 14:30', 18.31],
  ['2020-08-14 14:40', 17.98],
  ['2020-08-14 14:50', 17.65],
  ['2020-08-14 15:00', 18.43],
  ['2020-08-14 15:10', 18.9],
  ['2020-08-14 15:20', 18.81],
  ['2020-08-14 15:30', 19.61],
  ['2020-08-14 15:40', 19.83],
  ['2020-08-14 15:50', 20.16],
  ['2020-08-14 16:00', 20.02],
  ['2020-08-14 16:10', 19.19],
  ['2020-08-14 16:20', 19.33],
  ['2020-08-14 16:30', 19.05],
  ['2020-08-14 16:40', 19.68],
  ['2020-08-14 16:50', 19.21],
  ['2020-08-14 17:00', 19.66],
  ['2020-08-14 17:10', 19.05],
  ['2020-08-14 17:20', 18.8],
  ['2020-08-14 17:30', 18.03],
  ['2020-08-14 17:40', 17.7],
  ['2020-08-14 17:50', 17.45],
  ['2020-08-14 18:00', 17.45],
  ['2020-08-14 18:10', 18.14],
  ['2020-08-14 18:20', 17.94],
  ['2020-08-14 18:30', 18.2],
  ['2020-08-14 18:40', 18.94],
  ['2020-08-14 18:50', 19.41],
  ['2020-08-14 19:00', 19.84],
  ['2020-08-14 19:10', 19.73],
  ['2020-08-14 19:20', 19.13],
  ['2020-08-14 19:30', 19.36],
  ['2020-08-14 19:40', 19.84],
  ['2020-08-14 19:50', 19.22],
  ['2020-08-14 20:00', 18.61],
  ['2020-08-14 20:10', 18.37],
  ['2020-08-14 20:20', 18.05],
  ['2020-08-14 20:30', 18.92],
  ['2020-08-14 20:40', 17.91],
  ['2020-08-14 20:50', 17.56],
  ['2020-08-14 21:00', 17.45],
  ['2020-08-14 21:10', 18.15],
  ['2020-08-14 21:20', 18.47],
  ['2020-08-14 21:30', 18.84],
  ['2020-08-14 21:40', 18.44],
  ['2020-08-14 21:50', 18.39],
  ['2020-08-14 22:00', 19.07],
  ['2020-08-14 22:10', 19.04],
  ['2020-08-14 22:20', 18.99],
  ['2020-08-14 22:30', 19.32],
  ['2020-08-14 22:40', 18.7],
  ['2020-08-14 22:50', 18.83],
  ['2020-08-14 23:00', 19.21],
  ['2020-08-14 23:10', 19.56],
  ['2020-08-14 23:20', 20.15],
  ['2020-08-14 23:30', 19.1],
  ['2020-08-14 23:40', 18.38],
  ['2020-08-14 23:50', 18.23],
  ['2020-08-15 00:00', 18.06],
  ['2020-08-15 00:10', 17.83],
  ['2020-08-15 00:20', 18.01],
  ['2020-08-15 00:30', 16.7],
  ['2020-08-15 00:40', 16.3],
  ['2020-08-15 00:50', 16.22],
  ['2020-08-15 01:00', 16.27],
  ['2020-08-15 01:10', 16.13],
  ['2020-08-15 01:20', 15.92],
  ['2020-08-15 01:30', 15.89],
  ['2020-08-15 01:40', 15.81],
  ['2020-08-15 01:50', 15.56],
  ['2020-08-15 02:00', 15.62],
  ['2020-08-15 02:10', 15.04],
  ['2020-08-15 02:20', 16.68],
  ['2020-08-15 02:30', 15.27],
  ['2020-08-15 02:40', 15.3],
  ['2020-08-15 02:50', 15.35],
  ['2020-08-15 03:00', 15.53],
  ['2020-08-15 03:10', 14.07],
  ['2020-08-15 03:20', 13.86],
  ['2020-08-15 03:30', 14.62],
  ['2020-08-15 03:40', 14.11],
  ['2020-08-15 03:50', 14.01],
  ['2020-08-15 04:00', 13.86],
  ['2020-08-15 04:10', 15.23],
  ['2020-08-15 04:20', 14.68],
  ['2020-08-15 04:30', 14.47],
  ['2020-08-15 04:40', 14.12],
  ['2020-08-15 04:50', 14.05],
  ['2020-08-15 05:00', 14.75],
  ['2020-08-15 05:10', 14.35],
  ['2020-08-15 05:20', 13.92],
  ['2020-08-15 05:30', 13.46],
  ['2020-08-15 05:40', 13.55],
  ['2020-08-15 05:50', 13.63],
  ['2020-08-15 06:00', 13.48],
  ['2020-08-15 06:10', 13.56],
  ['2020-08-15 06:20', 13.66],
  ['2020-08-15 06:30', 13.59],
  ['2020-08-15 06:40', 13.81],
  ['2020-08-15 06:50', 13.98],
  ['2020-08-15 07:00', 13.89],
  ['2020-08-15 07:10', 14.05],
  ['2020-08-15 07:20', 14.63],
  ['2020-08-15 07:30', 14.97],
  ['2020-08-15 07:40', 14.86],
  ['2020-08-15 07:50', 14.09],
  ['2020-08-15 08:00', 13.76],
  ['2020-08-15 08:10', 14.14],
  ['2020-08-15 08:20', 14.26],
  ['2020-08-15 08:30', 14.15],
  ['2020-08-15 08:40', 14.28],
  ['2020-08-15 08:50', 14.49],
  ['2020-08-15 09:00', 14.75],
  ['2020-08-15 09:10', 14.83],
  ['2020-08-15 09:20', 14.88],
  ['2020-08-15 09:30', 15.64],
  ['2020-08-15 09:40', 15.45],
  ['2020-08-15 09:50', 15.32],
  ['2020-08-15 10:00', 15.28],
  ['2020-08-15 10:10', 15],
  ['2020-08-15 10:20', 15.27],
  ['2020-08-15 10:30', 15.13],
  ['2020-08-15 10:40', 14.83],
  ['2020-08-15 10:50', 15.06],
  ['2020-08-15 11:00', 14.95],
  ['2020-08-15 11:10', 14.53],
  ['2020-08-15 11:20', 15.02],
  ['2020-08-15 11:30', 15.34],
  ['2020-08-15 11:40', 16],
  ['2020-08-15 11:50', 15.76],
  ['2020-08-15 12:00', 15.03],
  ['2020-08-15 12:10', 14.11],
  ['2020-08-15 12:20', 13.84],
  ['2020-08-15 12:30', 13.68],
  ['2020-08-15 12:40', 13.37],
  ['2020-08-15 12:50', 13.12],
  ['2020-08-15 13:00', 12.94],
  ['2020-08-15 13:10', 13.11],
  ['2020-08-15 13:20', 13.08],
  ['2020-08-15 13:30', 13.21],
  ['2020-08-15 13:40', 13.58],
  ['2020-08-15 13:50', 13.86],
  ['2020-08-15 14:00', 14.12],
  ['2020-08-15 14:10', 14.64],
  ['2020-08-15 14:20', 14.46],
  ['2020-08-15 14:30', 14.64],
  ['2020-08-15 14:40', 14.33],
  ['2020-08-15 14:50', 14.59],
  ['2020-08-15 15:00', 14.63],
  ['2020-08-15 15:10', 15.24],
  ['2020-08-15 15:20', 14.01],
  ['2020-08-15 15:30', 14.72],
  ['2020-08-15 15:40', 14.32],
  ['2020-08-15 15:50', 14.42],
  ['2020-08-15 16:00', 14.36],
  ['2020-08-15 16:10', 14.22],
  ['2020-08-15 16:20', 14.52],
  ['2020-08-15 16:30', 14.25],
  ['2020-08-15 16:40', 14.36],
  ['2020-08-15 16:50', 15.17],
  ['2020-08-15 17:00', 14.95],
  ['2020-08-15 17:10', 15],
  ['2020-08-15 17:20', 14.77],
  ['2020-08-15 17:30', 14.28],
  ['2020-08-15 17:40', 14.61],
  ['2020-08-15 17:50', 15.16],
  ['2020-08-15 18:00', 14.56],
  ['2020-08-15 18:10', 14.53],
  ['2020-08-15 18:20', 14.79],
  ['2020-08-15 18:30', 15.06],
  ['2020-08-15 18:40', 15.8],
  ['2020-08-15 18:50', 16.04],
  ['2020-08-15 19:00', 15.62],
  ['2020-08-15 19:10', 15.8],
  ['2020-08-15 19:20', 15.67],
  ['2020-08-15 19:30', 16],
  ['2020-08-15 19:40', 15.54],
  ['2020-08-15 19:50', 15.52],
  ['2020-08-15 20:00', 14.87],
  ['2020-08-15 20:10', 14.26],
  ['2020-08-15 20:20', 14.12],
  ['2020-08-15 20:30', 14.49],
  ['2020-08-15 20:40', 15.11],
  ['2020-08-15 20:50', 15.31],
  ['2020-08-15 21:00', 15.44],
  ['2020-08-15 21:10', 15.58],
  ['2020-08-15 21:20', 15.57],
  ['2020-08-15 21:30', 15.17],
  ['2020-08-15 21:40', 15.21],
  ['2020-08-15 21:50', 15.81],
  ['2020-08-15 22:00', 15.84],
  ['2020-08-15 22:10', 16.28],
  ['2020-08-15 22:20', 16.5],
  ['2020-08-15 22:30', 16.14],
  ['2020-08-15 22:40', 15.98],
  ['2020-08-15 22:50', 16.06],
  ['2020-08-15 23:00', 15.59],
  ['2020-08-15 23:10', 15.12],
  ['2020-08-15 23:20', 15.2],
  ['2020-08-15 23:30', 15.16],
  ['2020-08-15 23:40', 14.98],
  ['2020-08-15 23:50', 14.97],
];

const WIND3 = [
  ['2020-08-16 00:00', 14.76],
  ['2020-08-16 00:10', 14.57],
  ['2020-08-16 00:20', 14.53],
  ['2020-08-16 00:30', 14.31],
  ['2020-08-16 00:40', 14.42],
  ['2020-08-16 00:50', 14.67],
  ['2020-08-16 01:00', 14.32],
  ['2020-08-16 01:10', 13.85],
  ['2020-08-16 01:20', 13.61],
  ['2020-08-16 01:30', 13.71],
  ['2020-08-16 01:40', 13.69],
  ['2020-08-16 01:50', 13.32],
  ['2020-08-16 02:00', 13.49],
  ['2020-08-16 02:10', 13.2],
  ['2020-08-16 02:20', 13.02],
  ['2020-08-16 02:30', 12.85],
  ['2020-08-16 02:40', 13.57],
  ['2020-08-16 02:50', 12.87],
  ['2020-08-16 03:00', 12.56],
  ['2020-08-16 03:10', 12.61],
  ['2020-08-16 03:20', 12.36],
  ['2020-08-16 03:30', 12.26],
  ['2020-08-16 03:40', 12.07],
  ['2020-08-16 03:50', 11.95],
  ['2020-08-16 04:00', 12.06],
  ['2020-08-16 04:10', 11.76],
  ['2020-08-16 04:20', 11.28],
  ['2020-08-16 04:30', 11.12],
  ['2020-08-16 04:40', 10.62],
  ['2020-08-16 04:50', 10.24],
  ['2020-08-16 05:00', 10.94],
  ['2020-08-16 05:10', 10.38],
  ['2020-08-16 05:20', 10.29],
  ['2020-08-16 05:30', 10.64],
  ['2020-08-16 05:40', 10.5],
  ['2020-08-16 05:50', 10.92],
  ['2020-08-16 06:00', 10.59],
  ['2020-08-16 06:10', 10.27],
  ['2020-08-16 06:20', 10.36],
  ['2020-08-16 06:30', 9.92],
  ['2020-08-16 06:40', 9.51],
  ['2020-08-16 06:50', 9.68],
  ['2020-08-16 07:00', 9.53],
  ['2020-08-16 07:10', 9.18],
  ['2020-08-16 07:20', 8.68],
  ['2020-08-16 07:30', 8.69],
  ['2020-08-16 07:40', 9.17],
  ['2020-08-16 07:50', 9.39],
  ['2020-08-16 08:00', 9.35],
  ['2020-08-16 08:10', 9.2],
  ['2020-08-16 08:20', 9.21],
  ['2020-08-16 08:30', 9.23],
  ['2020-08-16 08:40', 9.29],
  ['2020-08-16 08:50', 9.01],
  ['2020-08-16 09:00', 9.88],
  ['2020-08-16 09:10', 10.53],
  ['2020-08-16 09:20', 10.43],
  ['2020-08-16 09:30', 10.04],
  ['2020-08-16 09:40', 10.52],
  ['2020-08-16 09:50', 10.49],
  ['2020-08-16 10:00', 10.22],
  ['2020-08-16 10:10', 10.82],
  ['2020-08-16 10:20', 10.16],
  ['2020-08-16 10:30', 10.35],
  ['2020-08-16 10:40', 10.75],
  ['2020-08-16 10:50', 11.22],
  ['2020-08-16 11:00', 11.17],
  ['2020-08-16 11:10', 10.65],
  ['2020-08-16 11:20', 10.66],
  ['2020-08-16 11:30', 10.86],
  ['2020-08-16 11:40', 11.06],
  ['2020-08-16 11:50', 11.11],
  ['2020-08-16 12:00', 11.41],
  ['2020-08-16 12:10', 11.38],
  ['2020-08-16 12:20', 10.96],
  ['2020-08-16 12:30', 10.98],
  ['2020-08-16 12:40', 10.85],
  ['2020-08-16 12:50', 10.53],
  ['2020-08-16 13:00', 10.82],
  ['2020-08-16 13:10', 10.45],
  ['2020-08-16 13:20', 10.49],
  ['2020-08-16 13:30', 10.99],
  ['2020-08-16 13:40', 10.57],
  ['2020-08-16 13:50', 10.95],
  ['2020-08-16 14:00', 11.83],
  ['2020-08-16 14:10', 11.57],
  ['2020-08-16 14:20', 11.06],
  ['2020-08-16 14:30', 10.88],
  ['2020-08-16 14:40', 12.3],
  ['2020-08-16 14:50', 13.53],
  ['2020-08-16 15:00', 13.37],
  ['2020-08-16 15:10', 13.73],
  ['2020-08-16 15:20', 13.64],
  ['2020-08-16 15:30', 13.89],
  ['2020-08-16 15:40', 13.7],
  ['2020-08-16 15:50', 14.33],
  ['2020-08-16 16:00', 14.62],
  ['2020-08-16 16:10', 14.46],
  ['2020-08-16 16:20', 14.51],
  ['2020-08-16 16:30', 13.39],
  ['2020-08-16 16:40', 13.04],
  ['2020-08-16 16:50', 13.16],
  ['2020-08-16 17:00', 13.87],
  ['2020-08-16 17:10', 13.85],
  ['2020-08-16 17:20', 13.17],
  ['2020-08-16 17:30', 12.87],
  ['2020-08-16 17:40', 13.18],
  ['2020-08-16 17:50', 14.15],
  ['2020-08-16 18:00', 14.22],
  ['2020-08-16 18:10', 13.93],
  ['2020-08-16 18:20', 14.11],
  ['2020-08-16 18:30', 14.5],
  ['2020-08-16 18:40', 13.63],
  ['2020-08-16 18:50', 13.15],
  ['2020-08-16 19:00', 13.62],
  ['2020-08-16 19:10', 13.89],
  ['2020-08-16 19:20', 14.03],
  ['2020-08-16 19:30', 14.61],
  ['2020-08-16 19:40', 14.74],
  ['2020-08-16 19:50', 14.5],
  ['2020-08-16 20:00', 14.36],
  ['2020-08-16 20:10', 14.86],
  ['2020-08-16 20:20', 15.55],
  ['2020-08-16 20:30', 15.61],
  ['2020-08-16 20:40', 15.84],
  ['2020-08-16 20:50', 15.56],
  ['2020-08-16 21:00', 15.38],
  ['2020-08-16 21:10', 15.3],
  ['2020-08-16 21:20', 15.39],
  ['2020-08-16 21:30', 15.49],
  ['2020-08-16 21:40', 15.07],
  ['2020-08-16 21:50', 15.1],
  ['2020-08-16 22:00', 15.04],
  ['2020-08-16 22:10', 14.51],
  ['2020-08-16 22:20', 14.26],
  ['2020-08-16 22:30', 13.96],
  ['2020-08-16 22:40', 13.66],
  ['2020-08-16 22:50', 13.27],
  ['2020-08-16 23:00', 13.01],
  ['2020-08-16 23:10', 12.56],
  ['2020-08-16 23:20', 12.63],
  ['2020-08-16 23:30', 12.3],
  ['2020-08-16 23:40', 11.84],
  ['2020-08-16 23:50', 11.6],
  ['2020-08-17 00:00', 11.39],
  ['2020-08-17 00:10', 11.15],
  ['2020-08-17 00:20', 11.2],
  ['2020-08-17 00:30', 11.12],
  ['2020-08-17 00:40', 10.08],
  ['2020-08-17 00:50', 9.98],
  ['2020-08-17 01:00', 9.91],
  ['2020-08-17 01:10', 9.15],
  ['2020-08-17 01:20', 8.97],
  ['2020-08-17 01:30', 9.15],
  ['2020-08-17 01:40', 9.48],
  ['2020-08-17 01:50', 9.22],
  ['2020-08-17 02:00', 8.42],
  ['2020-08-17 02:10', 8.24],
  ['2020-08-17 02:20', 7.82],
  ['2020-08-17 02:30', 7.87],
  ['2020-08-17 02:40', 7.81],
  ['2020-08-17 02:50', 7.35],
  ['2020-08-17 03:00', 7.21],
  ['2020-08-17 03:10', 6.84],
  ['2020-08-17 03:20', 6.7],
  ['2020-08-17 03:30', 6.77],
  ['2020-08-17 03:40', 6.76],
  ['2020-08-17 03:50', 6.77],
  ['2020-08-17 04:00', 6.59],
  ['2020-08-17 04:10', 6.53],
  ['2020-08-17 04:20', 6.21],
  ['2020-08-17 04:30', 5.89],
  ['2020-08-17 04:40', 5.61],
  ['2020-08-17 04:50', 6.14],
  ['2020-08-17 05:00', 6.23],
  ['2020-08-17 05:10', 5.49],
  ['2020-08-17 05:20', 4.85],
  ['2020-08-17 05:30', 4.64],
  ['2020-08-17 05:40', 5.17],
  ['2020-08-17 05:50', 5.18],
  ['2020-08-17 06:00', 5.16],
  ['2020-08-17 06:10', 5.1],
  ['2020-08-17 06:20', 4.88],
  ['2020-08-17 06:30', 4.38],
  ['2020-08-17 06:40', 4.02],
  ['2020-08-17 06:50', 4.4],
  ['2020-08-17 07:00', 4.35],
  ['2020-08-17 07:10', 4.05],
  ['2020-08-17 07:20', 3.93],
  ['2020-08-17 07:30', 3.5],
  ['2020-08-17 07:40', 3.31],
  ['2020-08-17 07:50', 3.24],
  ['2020-08-17 08:00', 2.83],
  ['2020-08-17 08:10', 2.93],
  ['2020-08-17 08:20', 2.85],
  ['2020-08-17 08:30', 2.25],
  ['2020-08-17 08:40', 2.27],
  ['2020-08-17 08:50', 2.05],
  ['2020-08-17 09:00', 2.06],
  ['2020-08-17 09:10', 2.57],
  ['2020-08-17 09:20', 2.79],
  ['2020-08-17 09:30', 2.97],
  ['2020-08-17 09:40', 2.95],
  ['2020-08-17 09:50', 3.2],
  ['2020-08-17 10:00', 2.85],
  ['2020-08-17 10:10', 3.14],
  ['2020-08-17 10:20', 3.14],
  ['2020-08-17 10:30', 3.01],
  ['2020-08-17 10:40', 3.63],
  ['2020-08-17 10:50', 3.48],
  ['2020-08-17 11:00', 3.44],
  ['2020-08-17 11:10', 2.98],
  ['2020-08-17 11:20', 3.42],
  ['2020-08-17 11:30', 3.11],
  ['2020-08-17 11:40', 2.21],
  ['2020-08-17 11:50', 1.86],
  ['2020-08-17 12:00', 1.37],
  ['2020-08-17 12:10', 1.15],
  ['2020-08-17 12:20', 1.04],
  ['2020-08-17 12:30', 1.73],
  ['2020-08-17 12:40', 1.28],
  ['2020-08-17 12:50', 1.69],
  ['2020-08-17 13:00', 1.45],
  ['2020-08-17 13:10', 1.08],
  ['2020-08-17 13:20', 0.76],
  ['2020-08-17 13:30', 0.81],
  ['2020-08-17 13:40', 0.54],
  ['2020-08-17 13:50', 0.64],
  ['2020-08-17 14:00', 0.66],
  ['2020-08-17 14:10', 0.96],
  ['2020-08-17 14:20', 1.05],
  ['2020-08-17 14:30', 1.51],
  ['2020-08-17 14:40', 1.95],
  ['2020-08-17 14:50', 2.65],
  ['2020-08-17 15:00', 2.81],
  ['2020-08-17 15:10', 2.95],
  ['2020-08-17 15:20', 3.24],
  ['2020-08-17 15:30', 3.38],
  ['2020-08-17 15:40', 3.13],
  ['2020-08-17 15:50', 3.2],
  ['2020-08-17 16:00', 3.5],
  ['2020-08-17 16:10', 3.63],
  ['2020-08-17 16:20', 3.65],
  ['2020-08-17 16:30', 3.65],
  ['2020-08-17 16:40', 3.48],
  ['2020-08-17 16:50', 3.04],
  ['2020-08-17 17:00', 3.06],
  ['2020-08-17 17:10', 3.04],
  ['2020-08-17 17:20', 3.08],
  ['2020-08-17 17:30', 3.11],
  ['2020-08-17 17:40', 2.93],
  ['2020-08-17 17:50', 3.05],
  ['2020-08-17 18:00', 3.07],
  ['2020-08-17 18:10', 3.26],
  ['2020-08-17 18:20', 2.87],
  ['2020-08-17 18:30', 2.66],
  ['2020-08-17 18:40', 3.1],
  ['2020-08-17 18:50', 3.15],
  ['2020-08-17 19:00', 3.19],
  ['2020-08-17 19:10', 3.35],
  ['2020-08-17 19:20', 3.29],
  ['2020-08-17 19:30', 2.84],
  ['2020-08-17 19:40', 2.52],
  ['2020-08-17 19:50', 2.45],
  ['2020-08-17 20:00', 2.7],
  ['2020-08-17 20:10', 2.79],
  ['2020-08-17 20:20', 2.45],
  ['2020-08-17 20:30', 2.42],
  ['2020-08-17 20:40', 2.52],
  ['2020-08-17 20:50', 2.8],
  ['2020-08-17 21:00', 2.64],
  ['2020-08-17 21:10', 2.47],
  ['2020-08-17 21:20', 2.37],
  ['2020-08-17 21:30', 2.8],
  ['2020-08-17 21:40', 2.87],
  ['2020-08-17 21:50', 2.68],
  ['2020-08-17 22:00', 2.64],
  ['2020-08-17 22:10', 3.02],
  ['2020-08-17 22:20', 3.31],
  ['2020-08-17 22:30', 3.16],
  ['2020-08-17 22:40', 3.5],
  ['2020-08-17 22:50', 3.45],
  ['2020-08-17 23:00', 3.27],
  ['2020-08-17 23:10', 2.89],
  ['2020-08-17 23:20', 3.05],
  ['2020-08-17 23:30', 3.47],
  ['2020-08-17 23:40', 3.83],
  ['2020-08-17 23:50', 3.8],
  ['2020-08-18 00:00', 3.63],
  ['2020-08-18 00:10', 3.6],
  ['2020-08-18 00:20', 3.7],
  ['2020-08-18 00:30', 3.5],
  ['2020-08-18 00:40', 3.25],
  ['2020-08-18 00:50', 3.67],
  ['2020-08-18 01:00', 3.99],
  ['2020-08-18 01:10', 4.05],
  ['2020-08-18 01:20', 4.02],
  ['2020-08-18 01:30', 4.15],
  ['2020-08-18 01:40', 4.14],
  ['2020-08-18 01:50', 4.19],
  ['2020-08-18 02:00', 4.41],
  ['2020-08-18 02:10', 4.4],
  ['2020-08-18 02:20', 3.96],
  ['2020-08-18 02:30', 4.03],
  ['2020-08-18 02:40', 4.32],
  ['2020-08-18 02:50', 4.31],
  ['2020-08-18 03:00', 4.21],
  ['2020-08-18 03:10', 3.98],
  ['2020-08-18 03:20', 3.54],
  ['2020-08-18 03:30', 3.12],
  ['2020-08-18 03:40', 3.07],
  ['2020-08-18 03:50', 2.96],
  ['2020-08-18 04:00', 2.94],
  ['2020-08-18 04:10', 3.24],
  ['2020-08-18 04:20', 3.52],
  ['2020-08-18 04:30', 3.73],
  ['2020-08-18 04:40', 3.76],
  ['2020-08-18 04:50', 3.75],
  ['2020-08-18 05:00', 3.46],
  ['2020-08-18 05:10', 3.04],
  ['2020-08-18 05:20', 2.91],
  ['2020-08-18 05:30', 3.09],
  ['2020-08-18 05:40', 3.3],
  ['2020-08-18 05:50', 3.6],
  ['2020-08-18 06:00', 3.69],
  ['2020-08-18 06:10', 3.69],
  ['2020-08-18 06:20', 3.4],
  ['2020-08-18 06:30', 2.87],
  ['2020-08-18 06:40', 2.3],
  ['2020-08-18 06:50', 1.25],
  ['2020-08-18 07:00', 2.94],
  ['2020-08-18 07:10', 3.24],
  ['2020-08-18 07:20', 3.02],
  ['2020-08-18 07:30', 3.14],
  ['2020-08-18 07:40', 3.05],
  ['2020-08-18 07:50', 2.86],
  ['2020-08-18 08:00', 3.11],
  ['2020-08-18 08:10', 3.29],
  ['2020-08-18 08:20', 3.69],
  ['2020-08-18 08:30', 3.74],
  ['2020-08-18 08:40', 3.68],
  ['2020-08-18 08:50', 3.98],
  ['2020-08-18 09:00', 4.44],
  ['2020-08-18 09:10', 4.48],
  ['2020-08-18 09:20', 4.65],
  ['2020-08-18 09:30', 4.99],
  ['2020-08-18 09:40', 4.86],
  ['2020-08-18 09:50', 5.11],
  ['2020-08-18 10:00', 5.39],
  ['2020-08-18 10:10', 5.39],
  ['2020-08-18 10:20', 5.44],
  ['2020-08-18 10:30', 5.62],
  ['2020-08-18 10:40', 5.41],
  ['2020-08-18 10:50', 5.39],
  ['2020-08-18 11:00', 5.26],
  ['2020-08-18 11:10', 5.49],
  ['2020-08-18 11:20', 5.51],
  ['2020-08-18 11:30', 5.71],
  ['2020-08-18 11:40', 5.61],
  ['2020-08-18 11:50', 5.49],
  ['2020-08-18 12:00', 5.63],
  ['2020-08-18 12:10', 5.72],
  ['2020-08-18 12:20', 5.72],
  ['2020-08-18 12:30', 5.68],
  ['2020-08-18 12:40', 5.58],
  ['2020-08-18 12:50', 5.48],
  ['2020-08-18 13:00', 5.54],
  ['2020-08-18 13:10', 5.62],
  ['2020-08-18 13:20', 5.87],
  ['2020-08-18 13:30', 6.24],
  ['2020-08-18 13:40', 6.33],
  ['2020-08-18 13:50', 6.44],
  ['2020-08-18 14:00', 6.11],
  ['2020-08-18 14:10', 6.05],
  ['2020-08-18 14:20', 5.84],
  ['2020-08-18 14:30', 5.91],
  ['2020-08-18 14:40', 5.5],
  ['2020-08-18 14:50', 5.14],
  ['2020-08-18 15:00', 5.11],
  ['2020-08-18 15:10', 4.96],
  ['2020-08-18 15:20', 4.55],
  ['2020-08-18 15:30', 4.32],
  ['2020-08-18 15:40', 4.09],
  ['2020-08-18 15:50', 3.87],
  ['2020-08-18 16:00', 3.81],
  ['2020-08-18 16:10', 3.79],
  ['2020-08-18 16:20', 3.87],
  ['2020-08-18 16:30', 3.78],
  ['2020-08-18 16:40', 3.96],
  ['2020-08-18 16:50', 4.18],
  ['2020-08-18 17:00', 4.24],
  ['2020-08-18 17:10', 4.35],
  ['2020-08-18 17:20', 4.35],
  ['2020-08-18 17:30', 4.4],
  ['2020-08-18 17:40', 4.26],
  ['2020-08-18 17:50', 4.35],
  ['2020-08-18 18:00', 4.53],
  ['2020-08-18 18:10', 4.43],
  ['2020-08-18 18:20', 4.4],
  ['2020-08-18 18:30', 4.55],
  ['2020-08-18 18:40', 4.7],
  ['2020-08-18 18:50', 4.68],
  ['2020-08-18 19:00', 4.7],
  ['2020-08-18 19:10', 4.7],
  ['2020-08-18 19:20', 4.58],
  ['2020-08-18 19:30', 4.74],
  ['2020-08-18 19:40', 4.97],
  ['2020-08-18 19:50', 5.23],
  ['2020-08-18 20:00', 5.23],
  ['2020-08-18 20:10', 5.33],
  ['2020-08-18 20:20', 5.34],
  ['2020-08-18 20:30', 5.2],
  ['2020-08-18 20:40', 5.32],
  ['2020-08-18 20:50', 5.4],
  ['2020-08-18 21:00', 5.37],
  ['2020-08-18 21:10', 5.41],
  ['2020-08-18 21:20', 5.34],
  ['2020-08-18 21:30', 5.55],
  ['2020-08-18 21:40', 5.5],
  ['2020-08-18 21:50', 5.65],
  ['2020-08-18 22:00', 5.53],
  ['2020-08-18 22:10', 5.29],
  ['2020-08-18 22:20', 5.05],
  ['2020-08-18 22:30', 5.37],
  ['2020-08-18 22:40', 5.55],
  ['2020-08-18 22:50', 5.51],
  ['2020-08-18 23:00', 5.61],
  ['2020-08-18 23:10', 5.62],
  ['2020-08-18 23:20', 5.74],
  ['2020-08-18 23:30', 5.5],
  ['2020-08-18 23:40', 5.4],
  ['2020-08-18 23:50', 5.31],
  ['2020-08-19 00:00', 5.43],
  ['2020-08-19 00:10', 5.52],
  ['2020-08-19 00:20', 5.71],
  ['2020-08-19 00:30', 5.62],
  ['2020-08-19 00:40', 5.52],
  ['2020-08-19 00:50', 5.25],
  ['2020-08-19 01:00', 5.26],
  ['2020-08-19 01:10', 5.27],
  ['2020-08-19 01:20', 5.5],
  ['2020-08-19 01:30', 5.23],
  ['2020-08-19 01:40', 5.18],
  ['2020-08-19 01:50', 4.88],
  ['2020-08-19 02:00', 4.89],
  ['2020-08-19 02:10', 4.7],
  ['2020-08-19 02:20', 4.73],
  ['2020-08-19 02:30', 4.76],
  ['2020-08-19 02:40', 4.99],
  ['2020-08-19 02:50', 4.5],
  ['2020-08-19 03:00', 4.28],
  ['2020-08-19 03:10', 4.06],
  ['2020-08-19 03:20', 3.87],
  ['2020-08-19 03:30', 4.04],
  ['2020-08-19 03:40', 4.07],
  ['2020-08-19 03:50', 4.16],
  ['2020-08-19 04:00', 4.29],
  ['2020-08-19 04:10', 4.43],
  ['2020-08-19 04:20', 4.66],
  ['2020-08-19 04:30', 4.92],
  ['2020-08-19 04:40', 4.98],
  ['2020-08-19 04:50', 4.75],
  ['2020-08-19 05:00', 4.69],
  ['2020-08-19 05:10', 4.71],
  ['2020-08-19 05:20', 4.72],
  ['2020-08-19 05:30', 4.8],
  ['2020-08-19 05:40', 4.53],
  ['2020-08-19 05:50', 4.17],
  ['2020-08-19 06:00', 4.01],
  ['2020-08-19 06:10', 4.27],
  ['2020-08-19 06:20', 4.41],
  ['2020-08-19 06:30', 4.01],
  ['2020-08-19 06:40', 3.58],
  ['2020-08-19 06:50', 3.03],
  ['2020-08-19 07:00', 2.76],
  ['2020-08-19 07:10', 3.14],
  ['2020-08-19 07:20', 3.11],
  ['2020-08-19 07:30', 2.42],
  ['2020-08-19 07:40', 2.23],
  ['2020-08-19 07:50', 1.81],
  ['2020-08-19 08:00', 2.25],
  ['2020-08-19 08:10', 2.1],
  ['2020-08-19 08:20', 2.7],
  ['2020-08-19 08:30', 3.1],
  ['2020-08-19 08:40', 3.36],
  ['2020-08-19 08:50', 3.69],
  ['2020-08-19 09:00', 4.04],
  ['2020-08-19 09:10', 4.2],
  ['2020-08-19 09:20', 4.49],
  ['2020-08-19 09:30', 4.88],
  ['2020-08-19 09:40', 4.75],
  ['2020-08-19 09:50', 4.54],
  ['2020-08-19 10:00', 4.67],
  ['2020-08-19 10:10', 4.74],
  ['2020-08-19 10:20', 4.71],
  ['2020-08-19 10:30', 5.08],
  ['2020-08-19 10:40', 5.49],
  ['2020-08-19 10:50', 5.62],
  ['2020-08-19 11:00', 5.52],
  ['2020-08-19 11:10', 5.57],
  ['2020-08-19 11:20', 5.83],
  ['2020-08-19 11:30', 5.93],
  ['2020-08-19 11:40', 6.28],
  ['2020-08-19 11:50', 6.31],
  ['2020-08-19 12:00', 6.23],
  ['2020-08-19 12:10', 6.62],
  ['2020-08-19 12:20', 7.08],
  ['2020-08-19 12:30', 7.28],
  ['2020-08-19 12:40', 7.19],
  ['2020-08-19 12:50', 7.21],
  ['2020-08-19 13:00', 7.45],
  ['2020-08-19 13:10', 7.43],
  ['2020-08-19 13:20', 7.48],
  ['2020-08-19 13:30', 7.3],
  ['2020-08-19 13:40', 7.23],
  ['2020-08-19 13:50', 7.31],
  ['2020-08-19 14:00', 7.16],
  ['2020-08-19 14:10', 7.09],
  ['2020-08-19 14:20', 7.07],
  ['2020-08-19 14:30', 7.46],
  ['2020-08-19 14:40', 7.43],
  ['2020-08-19 14:50', 7.39],
  ['2020-08-19 15:00', 7.52],
  ['2020-08-19 15:10', 7.79],
  ['2020-08-19 15:20', 8.03],
  ['2020-08-19 15:30', 7.77],
  ['2020-08-19 15:40', 7.61],
  ['2020-08-19 15:50', 7.8],
  ['2020-08-19 16:00', 8.1],
  ['2020-08-19 16:10', 8.11],
  ['2020-08-19 16:20', 8.05],
  ['2020-08-19 16:30', 8.36],
  ['2020-08-19 16:40', 8.08],
  ['2020-08-19 16:50', 8.04],
  ['2020-08-19 17:00', 8.16],
  ['2020-08-19 17:10', 8.05],
  ['2020-08-19 17:20', 7.96],
  ['2020-08-19 17:30', 7.92],
  ['2020-08-19 17:40', 8.2],
  ['2020-08-19 17:50', 8.29],
  ['2020-08-19 18:00', 8.13],
  ['2020-08-19 18:10', 8.1],
  ['2020-08-19 18:20', 7.92],
  ['2020-08-19 18:30', 7.94],
  ['2020-08-19 18:40', 7.78],
  ['2020-08-19 18:50', 7.74],
  ['2020-08-19 19:00', 8.63],
  ['2020-08-19 19:10', 8.92],
  ['2020-08-19 19:20', 9.3],
  ['2020-08-19 19:30', 9.48],
  ['2020-08-19 19:40', 9.62],
  ['2020-08-19 19:50', 9.53],
  ['2020-08-19 20:00', 9.01],
  ['2020-08-19 20:10', 9.27],
  ['2020-08-19 20:20', 9.61],
  ['2020-08-19 20:30', 9.53],
  ['2020-08-19 20:40', 9.12],
  ['2020-08-19 20:50', 8.98],
  ['2020-08-19 21:00', 8.68],
  ['2020-08-19 21:10', 8.87],
  ['2020-08-19 21:20', 8.89],
  ['2020-08-19 21:30', 9.05],
  ['2020-08-19 21:40', 8.81],
  ['2020-08-19 21:50', 8.97],
  ['2020-08-19 22:00', 8.74],
  ['2020-08-19 22:10', 8.52],
  ['2020-08-19 22:20', 8.29],
  ['2020-08-19 22:30', 8.17],
  ['2020-08-19 22:40', 8.61],
  ['2020-08-19 22:50', 8.25],
  ['2020-08-19 23:00', 8.22],
  ['2020-08-19 23:10', 7.74],
  ['2020-08-19 23:20', 7.82],
  ['2020-08-19 23:30', 7.93],
  ['2020-08-19 23:40', 7.96],
  ['2020-08-19 23:50', 7.56],
  ['2020-08-20 00:00', 7.58],
  ['2020-08-20 00:10', 6.43],
  ['2020-08-20 00:20', 5.37],
  ['2020-08-20 00:30', 4.88],
  ['2020-08-20 00:40', 4.17],
  ['2020-08-20 00:50', 4.43],
  ['2020-08-20 01:00', 4.39],
  ['2020-08-20 01:10', 3.93],
  ['2020-08-20 01:20', 3.76],
  ['2020-08-20 01:30', 4.22],
  ['2020-08-20 01:40', 3.74],
  ['2020-08-20 01:50', 3.4],
  ['2020-08-20 02:00', 3.38],
  ['2020-08-20 02:10', 3.16],
  ['2020-08-20 02:20', 3.41],
  ['2020-08-20 02:30', 2.88],
  ['2020-08-20 02:40', 2.7],
  ['2020-08-20 02:50', 3.19],
  ['2020-08-20 03:00', 3.25],
  ['2020-08-20 03:10', 3.39],
  ['2020-08-20 03:20', 3.53],
  ['2020-08-20 03:30', 3.23],
  ['2020-08-20 03:40', 2.76],
  ['2020-08-20 03:50', 2.93],
  ['2020-08-20 04:00', 2.75],
  ['2020-08-20 04:10', 3.31],
  ['2020-08-20 04:20', 3.23],
  ['2020-08-20 04:30', 3.14],
  ['2020-08-20 04:40', 3.2],
  ['2020-08-20 04:50', 3.42],
  ['2020-08-20 05:00', 2.73],
  ['2020-08-20 05:10', 2.9],
  ['2020-08-20 05:20', 3.49],
  ['2020-08-20 05:30', 4.05],
  ['2020-08-20 05:40', 4.46],
  ['2020-08-20 05:50', 4.62],
  ['2020-08-20 06:00', 4.79],
  ['2020-08-20 06:10', 4.42],
  ['2020-08-20 06:20', 4.5],
  ['2020-08-20 06:30', 4.17],
  ['2020-08-20 06:40', 4.81],
  ['2020-08-20 06:50', 4.56],
  ['2020-08-20 07:00', 4.2],
  ['2020-08-20 07:10', 4.29],
  ['2020-08-20 07:20', 3.86],
  ['2020-08-20 07:30', 4.6],
  ['2020-08-20 07:40', 4.97],
  ['2020-08-20 07:50', 4.73],
  ['2020-08-20 08:00', 4.01],
  ['2020-08-20 08:10', 3.95],
  ['2020-08-20 08:20', 4.37],
  ['2020-08-20 08:30', 4.2],
  ['2020-08-20 08:40', 4.3],
  ['2020-08-20 08:50', 4.2],
  ['2020-08-20 09:00', 3.85],
  ['2020-08-20 09:10', 4.06],
  ['2020-08-20 09:20', 4.22],
  ['2020-08-20 09:30', 3.88],
  ['2020-08-20 09:40', 3.93],
  ['2020-08-20 09:50', 3.78],
  ['2020-08-20 10:00', 3.89],
  ['2020-08-20 10:10', 3.48],
  ['2020-08-20 10:20', 3.4],
  ['2020-08-20 10:30', 3.26],
  ['2020-08-20 10:40', 3.12],
  ['2020-08-20 10:50', 2.91],
  ['2020-08-20 11:00', 3.04],
  ['2020-08-20 11:10', 2.94],
  ['2020-08-20 11:20', 2.9],
  ['2020-08-20 11:30', 2.76],
  ['2020-08-20 11:40', 2.65],
  ['2020-08-20 11:50', 2.5],
  ['2020-08-20 12:00', 2.19],
  ['2020-08-20 12:10', 2.15],
  ['2020-08-20 12:20', 2.07],
  ['2020-08-20 12:30', 1.97],
  ['2020-08-20 12:40', 1.47],
  ['2020-08-20 12:50', 1.13],
  ['2020-08-20 13:00', 1.23],
  ['2020-08-20 13:10', 1.13],
  ['2020-08-20 13:20', 1.13],
  ['2020-08-20 13:30', 1.72],
  ['2020-08-20 13:40', 2.37],
  ['2020-08-20 13:50', 3.72],
  ['2020-08-20 14:00', 3.59],
  ['2020-08-20 14:10', 3.59],
  ['2020-08-20 14:20', 3.7],
  ['2020-08-20 14:30', 3.36],
  ['2020-08-20 14:40', 3.75],
  ['2020-08-20 14:50', 3.38],
  ['2020-08-20 15:00', 3.38],
  ['2020-08-20 15:10', 3.6],
  ['2020-08-20 15:20', 3.52],
  ['2020-08-20 15:30', 3.36],
  ['2020-08-20 15:40', 3.64],
  ['2020-08-20 15:50', 3.77],
  ['2020-08-20 16:00', 3.27],
  ['2020-08-20 16:10', 2.92],
  ['2020-08-20 16:20', 3.18],
  ['2020-08-20 16:30', 3.35],
  ['2020-08-20 16:40', 2.94],
  ['2020-08-20 16:50', 3.41],
  ['2020-08-20 17:00', 3.59],
  ['2020-08-20 17:10', 3.48],
  ['2020-08-20 17:20', 3.89],
  ['2020-08-20 17:30', 3.86],
  ['2020-08-20 17:40', 3.96],
  ['2020-08-20 17:50', 4.12],
  ['2020-08-20 18:00', 4.49],
  ['2020-08-20 18:10', 4.11],
  ['2020-08-20 18:20', 3.76],
  ['2020-08-20 18:30', 4.59],
  ['2020-08-20 18:40', 4.32],
  ['2020-08-20 18:50', 4.21],
  ['2020-08-20 19:00', 4.18],
  ['2020-08-20 19:10', 4.25],
  ['2020-08-20 19:20', 4.84],
  ['2020-08-20 19:30', 4.84],
  ['2020-08-20 19:40', 4.8],
  ['2020-08-20 19:50', 4.94],
  ['2020-08-20 20:00', 5.58],
  ['2020-08-20 20:10', 5.96],
  ['2020-08-20 20:20', 5.9],
  ['2020-08-20 20:30', 6.01],
  ['2020-08-20 20:40', 5.69],
  ['2020-08-20 20:50', 6.41],
  ['2020-08-20 21:00', 6.37],
  ['2020-08-20 21:10', 6.22],
  ['2020-08-20 21:20', 6.33],
  ['2020-08-20 21:30', 6.16],
  ['2020-08-20 21:40', 6.14],
  ['2020-08-20 21:50', 6.17],
  ['2020-08-20 22:00', 6.08],
  ['2020-08-20 22:10', 6.39],
  ['2020-08-20 22:20', 6.49],
  ['2020-08-20 22:30', 6.56],
  ['2020-08-20 22:40', 6.46],
  ['2020-08-20 22:50', 6.42],
  ['2020-08-20 23:00', 6.15],
  ['2020-08-20 23:10', 6.26],
  ['2020-08-20 23:20', 6.14],
  ['2020-08-20 23:30', 6.32],
  ['2020-08-20 23:40', 6.13],
  ['2020-08-20 23:50', 6.47],
  ['2020-08-21 00:00', 6.11],
  ['2020-08-21 00:10', 6.41],
  ['2020-08-21 00:20', 6.8],
  ['2020-08-21 00:30', 7.08],
  ['2020-08-21 00:40', 7.17],
  ['2020-08-21 00:50', 6.88],
  ['2020-08-21 01:00', 6.91],
  ['2020-08-21 01:10', 7.22],
  ['2020-08-21 01:20', 7.19],
  ['2020-08-21 01:30', 7.18],
  ['2020-08-21 01:40', 6.61],
  ['2020-08-21 01:50', 7.57],
  ['2020-08-21 02:00', 7.57],
  ['2020-08-21 02:10', 7.43],
  ['2020-08-21 02:20', 7.12],
  ['2020-08-21 02:30', 7.26],
  ['2020-08-21 02:40', 7.09],
  ['2020-08-21 02:50', 6.98],
  ['2020-08-21 03:00', 6.75],
  ['2020-08-21 03:10', 6.61],
  ['2020-08-21 03:20', 6.31],
  ['2020-08-21 03:30', 6.02],
  ['2020-08-21 03:40', 6.02],
  ['2020-08-21 03:50', 6.06],
  ['2020-08-21 04:00', 6.56],
  ['2020-08-21 04:10', 6.5],
  ['2020-08-21 04:20', 6.18],
  ['2020-08-21 04:30', 6.24],
  ['2020-08-21 04:40', 5.64],
  ['2020-08-21 04:50', 5.34],
  ['2020-08-21 05:00', 5.72],
  ['2020-08-21 05:10', 5.88],
  ['2020-08-21 05:20', 5.73],
  ['2020-08-21 05:30', 5.8],
  ['2020-08-21 05:40', 4.87],
  ['2020-08-21 05:50', 3.67],
  ['2020-08-21 06:00', 5.23],
  ['2020-08-21 06:10', 5.7],
  ['2020-08-21 06:20', 4.87],
  ['2020-08-21 06:30', 3.8],
  ['2020-08-21 06:40', 4.13],
  ['2020-08-21 06:50', 4.8],
  ['2020-08-21 07:00', 5.02],
  ['2020-08-21 07:10', 3.96],
  ['2020-08-21 07:20', 3.94],
  ['2020-08-21 07:30', 3.3],
  ['2020-08-21 07:40', 2.7],
  ['2020-08-21 07:50', 3.79],
  ['2020-08-21 08:00', 4.03],
  ['2020-08-21 08:10', 3.67],
  ['2020-08-21 08:20', 3.03],
  ['2020-08-21 08:30', 2.95],
  ['2020-08-21 08:40', 3.86],
  ['2020-08-21 08:50', 4.03],
  ['2020-08-21 09:00', 3.97],
  ['2020-08-21 09:10', 3.8],
  ['2020-08-21 09:20', 3.35],
  ['2020-08-21 09:30', 3.62],
  ['2020-08-21 09:40', 3.61],
  ['2020-08-21 09:50', 3.74],
  ['2020-08-21 10:00', 3.96],
  ['2020-08-21 10:10', 3.69],
  ['2020-08-21 10:20', 4.12],
  ['2020-08-21 10:30', 4.19],
  ['2020-08-21 10:40', 3.97],
  ['2020-08-21 10:50', 3.98],
  ['2020-08-21 11:00', 3.96],
  ['2020-08-21 11:10', 3.94],
  ['2020-08-21 11:20', 3.9],
  ['2020-08-21 11:30', 3.75],
  ['2020-08-21 11:40', 3.9],
  ['2020-08-21 11:50', 3.9],
  ['2020-08-21 12:00', 4.16],
  ['2020-08-21 12:10', 4.62],
  ['2020-08-21 12:20', 4.63],
  ['2020-08-21 12:30', 4.78],
  ['2020-08-21 12:40', 5.11],
  ['2020-08-21 12:50', 5.54],
  ['2020-08-21 13:00', 5.39],
  ['2020-08-21 13:10', 5.22],
  ['2020-08-21 13:20', 4.92],
  ['2020-08-21 13:30', 5.34],
  ['2020-08-21 13:40', 5.07],
  ['2020-08-21 13:50', 5.12],
  ['2020-08-21 14:00', 5.32],
  ['2020-08-21 14:10', 5.52],
  ['2020-08-21 14:20', 5.56],
  ['2020-08-21 14:30', 5.66],
  ['2020-08-21 14:40', 5.59],
  ['2020-08-21 14:50', 5.76],
  ['2020-08-21 15:00', 5.58],
  ['2020-08-21 15:10', 5.69],
  ['2020-08-21 15:20', 6.37],
  ['2020-08-21 15:30', 6.33],
  ['2020-08-21 15:40', 6.49],
  ['2020-08-21 15:50', 6.52],
  ['2020-08-21 16:00', 6.86],
  ['2020-08-21 16:10', 7.12],
  ['2020-08-21 16:20', 7.12],
  ['2020-08-21 16:30', 7.41],
  ['2020-08-21 16:40', 7.69],
  ['2020-08-21 16:50', 7.7],
  ['2020-08-21 17:00', 7.79],
  ['2020-08-21 17:10', 7.79],
  ['2020-08-21 17:20', 7.87],
  ['2020-08-21 17:30', 7.84],
  ['2020-08-21 17:40', 7.4],
  ['2020-08-21 17:50', 7.41],
  ['2020-08-21 18:00', 7.51],
  ['2020-08-21 18:10', 7.42],
  ['2020-08-21 18:20', 7.72],
  ['2020-08-21 18:30', 7.77],
  ['2020-08-21 18:40', 7.78],
  ['2020-08-21 18:50', 7.57],
  ['2020-08-21 19:00', 7.55],
  ['2020-08-21 19:10', 7.72],
  ['2020-08-21 19:20', 7.51],
  ['2020-08-21 19:30', 7.5],
  ['2020-08-21 19:40', 7.48],
  ['2020-08-21 19:50', 8.09],
  ['2020-08-21 20:00', 8.03],
  ['2020-08-21 20:10', 8.6],
  ['2020-08-21 20:20', 8.91],
  ['2020-08-21 20:30', 9.12],
  ['2020-08-21 20:40', 9.15],
  ['2020-08-21 20:50', 9.22],
  ['2020-08-21 21:00', 9.11],
  ['2020-08-21 21:10', 9.24],
  ['2020-08-21 21:20', 9.08],
  ['2020-08-21 21:30', 8.44],
  ['2020-08-21 21:40', 8.13],
  ['2020-08-21 21:50', 8.1],
  ['2020-08-21 22:00', 8.01],
  ['2020-08-21 22:10', 8.01],
  ['2020-08-21 22:20', 8.43],
  ['2020-08-21 22:30', 8.31],
  ['2020-08-21 22:40', 8.09],
  ['2020-08-21 22:50', 8.43],
  ['2020-08-21 23:00', 7.77],
  ['2020-08-21 23:10', 8.17],
  ['2020-08-21 23:20', 7.28],
  ['2020-08-21 23:30', 7.71],
  ['2020-08-21 23:40', 7.54],
  ['2020-08-21 23:50', 8.05],
  ['2020-08-22 00:00', 8.05],
  ['2020-08-22 00:10', 8.17],
  ['2020-08-22 00:20', 7.88],
  ['2020-08-22 00:30', 7.68],
  ['2020-08-22 00:40', 8.15],
  ['2020-08-22 00:50', 8.28],
  ['2020-08-22 01:00', 7.7],
  ['2020-08-22 01:10', 7.65],
  ['2020-08-22 01:20', 7.84],
  ['2020-08-22 01:30', 7.61],
  ['2020-08-22 01:40', 7.77],
  ['2020-08-22 01:50', 7.54],
  ['2020-08-22 02:00', 7.84],
  ['2020-08-22 02:10', 7.8],
  ['2020-08-22 02:20', 7.69],
  ['2020-08-22 02:30', 7.65],
  ['2020-08-22 02:40', 8.02],
  ['2020-08-22 02:50', 8.08],
  ['2020-08-22 03:00', 7.73],
  ['2020-08-22 03:10', 7.46],
  ['2020-08-22 03:20', 7.61],
  ['2020-08-22 03:30', 7.6],
  ['2020-08-22 03:40', 7.68],
  ['2020-08-22 03:50', 7.75],
  ['2020-08-22 04:00', 8.05],
  ['2020-08-22 04:10', 8.4],
  ['2020-08-22 04:20', 8.42],
  ['2020-08-22 04:30', 7.69],
  ['2020-08-22 04:40', 8.53],
  ['2020-08-22 04:50', 9.33],
  ['2020-08-22 05:00', 8.38],
  ['2020-08-22 05:10', 8.51],
  ['2020-08-22 05:20', 8.24],
  ['2020-08-22 05:30', 8.49],
  ['2020-08-22 05:40', 7.9],
  ['2020-08-22 05:50', 7.76],
  ['2020-08-22 06:00', 8.26],
  ['2020-08-22 06:10', 7.79],
  ['2020-08-22 06:20', 7.72],
  ['2020-08-22 06:30', 7.2],
  ['2020-08-22 06:40', 6.85],
  ['2020-08-22 06:50', 6.85],
  ['2020-08-22 07:00', 6.89],
  ['2020-08-22 07:10', 6.6],
  ['2020-08-22 07:20', 6.45],
  ['2020-08-22 07:30', 6.18],
  ['2020-08-22 07:40', 6.04],
  ['2020-08-22 07:50', 5.96],
  ['2020-08-22 08:00', 6.19],
  ['2020-08-22 08:10', 5.76],
  ['2020-08-22 08:20', 5.54],
  ['2020-08-22 08:30', 5.52],
  ['2020-08-22 08:40', 5.09],
  ['2020-08-22 08:50', 4.98],
  ['2020-08-22 09:00', 5.27],
  ['2020-08-22 09:10', 5.74],
  ['2020-08-22 09:20', 5.35],
  ['2020-08-22 09:30', 5.69],
  ['2020-08-22 09:40', 5.94],
  ['2020-08-22 09:50', 6.34],
  ['2020-08-22 10:00', 6.32],
  ['2020-08-22 10:10', 6.9],
  ['2020-08-22 10:20', 6.68],
  ['2020-08-22 10:30', 6.55],
  ['2020-08-22 10:40', 6.51],
  ['2020-08-22 10:50', 5.94],
  ['2020-08-22 11:00', 6.26],
  ['2020-08-22 11:10', 6.18],
  ['2020-08-22 11:20', 6.52],
  ['2020-08-22 11:30', 6.77],
  ['2020-08-22 11:40', 6.64],
  ['2020-08-22 11:50', 6.54],
  ['2020-08-22 12:00', 6.39],
  ['2020-08-22 12:10', 6.05],
  ['2020-08-22 12:20', 5.95],
  ['2020-08-22 12:30', 5.66],
  ['2020-08-22 12:40', 5.97],
  ['2020-08-22 12:50', 5.63],
  ['2020-08-22 13:00', 5.82],
  ['2020-08-22 13:10', 5.79],
  ['2020-08-22 13:20', 5.96],
  ['2020-08-22 13:30', 5.78],
  ['2020-08-22 13:40', 6.12],
  ['2020-08-22 13:50', 5.91],
  ['2020-08-22 14:00', 5.72],
  ['2020-08-22 14:10', 5.57],
  ['2020-08-22 14:20', 5.55],
  ['2020-08-22 14:30', 5.59],
  ['2020-08-22 14:40', 5.72],
  ['2020-08-22 14:50', 5.83],
  ['2020-08-22 15:00', 6.04],
  ['2020-08-22 15:10', 5.86],
  ['2020-08-22 15:20', 5.65],
  ['2020-08-22 15:30', 5.67],
  ['2020-08-22 15:40', 5.55],
  ['2020-08-22 15:50', 5.69],
  ['2020-08-22 16:00', 5.81],
  ['2020-08-22 16:10', 5.72],
  ['2020-08-22 16:20', 6.17],
  ['2020-08-22 16:30', 6.04],
  ['2020-08-22 16:40', 6.29],
  ['2020-08-22 16:50', 6.59],
  ['2020-08-22 17:00', 6.92],
  ['2020-08-22 17:10', 6.25],
  ['2020-08-22 17:20', 6.14],
  ['2020-08-22 17:30', 5.92],
  ['2020-08-22 17:40', 5.63],
  ['2020-08-22 17:50', 5.97],
  ['2020-08-22 18:00', 5.73],
  ['2020-08-22 18:10', 5.16],
  ['2020-08-22 18:20', 4.88],
  ['2020-08-22 18:30', 5.25],
  ['2020-08-22 18:40', 5.07],
  ['2020-08-22 18:50', 5.15],
  ['2020-08-22 19:00', 5.36],
  ['2020-08-22 19:10', 5.44],
  ['2020-08-22 19:20', 5.36],
  ['2020-08-22 19:30', 5.64],
  ['2020-08-22 19:40', 5.42],
  ['2020-08-22 19:50', 5.23],
  ['2020-08-22 20:00', 5.36],
  ['2020-08-22 20:10', 5.58],
  ['2020-08-22 20:20', 5.7],
  ['2020-08-22 20:30', 5.63],
  ['2020-08-22 20:40', 6.19],
  ['2020-08-22 20:50', 6.4],
  ['2020-08-22 21:00', 5.99],
  ['2020-08-22 21:10', 5.76],
  ['2020-08-22 21:20', 5.81],
  ['2020-08-22 21:30', 5.5],
  ['2020-08-22 21:40', 6.26],
  ['2020-08-22 21:50', 6.74],
  ['2020-08-22 22:00', 6.66],
  ['2020-08-22 22:10', 6.21],
  ['2020-08-22 22:20', 6.61],
  ['2020-08-22 22:30', 7.03],
  ['2020-08-22 22:40', 6.88],
  ['2020-08-22 22:50', 6.15],
  ['2020-08-22 23:00', 6.14],
  ['2020-08-22 23:10', 6.11],
  ['2020-08-22 23:20', 5.58],
  ['2020-08-22 23:30', 5.26],
  ['2020-08-22 23:40', 4.14],
  ['2020-08-22 23:50', 3.61],
];

const WIND4 = [
  ['2020-08-23 00:00', 3.38],
  ['2020-08-23 00:10', 3.5],
  ['2020-08-23 00:20', 3.22],
  ['2020-08-23 00:30', 2.77],
  ['2020-08-23 00:40', 2.73],
  ['2020-08-23 00:50', 2.62],
  ['2020-08-23 01:00', 3.23],
  ['2020-08-23 01:10', 2.96],
  ['2020-08-23 01:20', 2.9],
  ['2020-08-23 01:30', 2.71],
  ['2020-08-23 01:40', 3.12],
  ['2020-08-23 01:50', 3.1],
  ['2020-08-23 02:00', 3.03],
  ['2020-08-23 02:10', 3.34],
  ['2020-08-23 02:20', 3.65],
  ['2020-08-23 02:30', 3.62],
  ['2020-08-23 02:40', 3.85],
  ['2020-08-23 02:50', 3.84],
  ['2020-08-23 03:00', 3.32],
  ['2020-08-23 03:10', 3.77],
  ['2020-08-23 03:20', 3.73],
  ['2020-08-23 03:30', 4.15],
  ['2020-08-23 03:40', 4.59],
  ['2020-08-23 03:50', 4.79],
  ['2020-08-23 04:00', 4.68],
  ['2020-08-23 04:10', 5.5],
  ['2020-08-23 04:20', 5.06],
  ['2020-08-23 04:30', 4.82],
  ['2020-08-23 04:40', 4.44],
  ['2020-08-23 04:50', 4.31],
  ['2020-08-23 05:00', 4.83],
  ['2020-08-23 05:10', 5.09],
  ['2020-08-23 05:20', 5.89],
  ['2020-08-23 05:30', 6.71],
  ['2020-08-23 05:40', 6.84],
  ['2020-08-23 05:50', 6.46],
  ['2020-08-23 06:00', 6.41],
  ['2020-08-23 06:10', 6.18],
  ['2020-08-23 06:20', 5.27],
  ['2020-08-23 06:30', 5.24],
  ['2020-08-23 06:40', 5.86],
  ['2020-08-23 06:50', 5.88],
  ['2020-08-23 07:00', 5.66],
  ['2020-08-23 07:10', 5.94],
  ['2020-08-23 07:20', 6.38],
  ['2020-08-23 07:30', 7.74],
  ['2020-08-23 07:40', 7.29],
  ['2020-08-23 07:50', 7.93],
  ['2020-08-23 08:00', 7.4],
  ['2020-08-23 08:10', 7.42],
  ['2020-08-23 08:20', 8.65],
  ['2020-08-23 08:30', 8.07],
  ['2020-08-23 08:40', 7.49],
  ['2020-08-23 08:50', 7.54],
  ['2020-08-23 09:00', 7.32],
  ['2020-08-23 09:10', 7.67],
  ['2020-08-23 09:20', 7.57],
  ['2020-08-23 09:30', 6.76],
  ['2020-08-23 09:40', 6.2],
  ['2020-08-23 09:50', 5.64],
  ['2020-08-23 10:00', 5.02],
  ['2020-08-23 10:10', 4.99],
  ['2020-08-23 10:20', 5.2],
  ['2020-08-23 10:30', 5.01],
  ['2020-08-23 10:40', 4.4],
  ['2020-08-23 10:50', 4.17],
  ['2020-08-23 11:00', 4.17],
  ['2020-08-23 11:10', 4.22],
  ['2020-08-23 11:20', 3.87],
  ['2020-08-23 11:30', 3.21],
  ['2020-08-23 11:40', 2.91],
  ['2020-08-23 11:50', 2.34],
  ['2020-08-23 12:00', 1.91],
  ['2020-08-23 12:10', 2.19],
  ['2020-08-23 12:20', 2.09],
  ['2020-08-23 12:30', 2.28],
  ['2020-08-23 12:40', 1.99],
  ['2020-08-23 12:50', 2.45],
  ['2020-08-23 13:00', 2.82],
  ['2020-08-23 13:10', 1.89],
  ['2020-08-23 13:20', 1.68],
  ['2020-08-23 13:30', 1.57],
  ['2020-08-23 13:40', 2.16],
  ['2020-08-23 13:50', 2.24],
  ['2020-08-23 14:00', 2.45],
  ['2020-08-23 14:10', 3.15],
  ['2020-08-23 14:20', 3.36],
  ['2020-08-23 14:30', 2.73],
  ['2020-08-23 14:40', 2.8],
  ['2020-08-23 14:50', 2.83],
  ['2020-08-23 15:00', 2.21],
  ['2020-08-23 15:10', 2.12],
  ['2020-08-23 15:20', 1.98],
  ['2020-08-23 15:30', 2.05],
  ['2020-08-23 15:40', 2.13],
  ['2020-08-23 15:50', 1.66],
  ['2020-08-23 16:00', 1.95],
  ['2020-08-23 16:10', 1.87],
  ['2020-08-23 16:20', 1.97],
  ['2020-08-23 16:30', 1.45],
  ['2020-08-23 16:40', 1.33],
  ['2020-08-23 16:50', 1.04],
  ['2020-08-23 17:00', 0.64],
  ['2020-08-23 17:10', 0.8],
  ['2020-08-23 17:20', 0.79],
  ['2020-08-23 17:30', 1.33],
  ['2020-08-23 17:40', 1.27],
  ['2020-08-23 17:50', 1.73],
  ['2020-08-23 18:00', 1.71],
  ['2020-08-23 18:10', 1.82],
  ['2020-08-23 18:20', 2.33],
  ['2020-08-23 18:30', 2.34],
  ['2020-08-23 18:40', 2.27],
  ['2020-08-23 18:50', 2.68],
  ['2020-08-23 19:00', 2.36],
  ['2020-08-23 19:10', 2.54],
  ['2020-08-23 19:20', 2.14],
  ['2020-08-23 19:30', 1.98],
  ['2020-08-23 19:40', 1.87],
  ['2020-08-23 19:50', 2.08],
  ['2020-08-23 20:00', 2.37],
  ['2020-08-23 20:10', 2.49],
  ['2020-08-23 20:20', 2.67],
  ['2020-08-23 20:30', 3.14],
  ['2020-08-23 20:40', 3.11],
  ['2020-08-23 20:50', 2.91],
  ['2020-08-23 21:00', 2.65],
  ['2020-08-23 21:10', 2.35],
  ['2020-08-23 21:20', 2.46],
  ['2020-08-23 21:30', 2.58],
  ['2020-08-23 21:40', 2.82],
  ['2020-08-23 21:50', 2.77],
  ['2020-08-23 22:00', 2.62],
  ['2020-08-23 22:10', 2.67],
  ['2020-08-23 22:20', 2.51],
  ['2020-08-23 22:30', 2.64],
  ['2020-08-23 22:40', 2.99],
  ['2020-08-23 22:50', 3.13],
  ['2020-08-23 23:00', 2.64],
  ['2020-08-23 23:10', 2.44],
  ['2020-08-23 23:20', 2.26],
  ['2020-08-23 23:30', 2.73],
  ['2020-08-23 23:40', 2.88],
  ['2020-08-23 23:50', 2.72],
  ['2020-08-24 00:00', 2.93],
  ['2020-08-24 00:10', 2.36],
  ['2020-08-24 00:20', 2.36],
  ['2020-08-24 00:30', 2.76],
  ['2020-08-24 00:40', 2.74],
  ['2020-08-24 00:50', 2.95],
  ['2020-08-24 01:00', 3.09],
  ['2020-08-24 01:10', 2.86],
  ['2020-08-24 01:20', 2.84],
  ['2020-08-24 01:30', 2.21],
  ['2020-08-24 01:40', 2.3],
  ['2020-08-24 01:50', 2.55],
  ['2020-08-24 02:00', 2.59],
  ['2020-08-24 02:10', 2.52],
  ['2020-08-24 02:20', 2.88],
  ['2020-08-24 02:30', 3.05],
  ['2020-08-24 02:40', 2.95],
  ['2020-08-24 02:50', 2.83],
  ['2020-08-24 03:00', 2.57],
  ['2020-08-24 03:10', 2.36],
  ['2020-08-24 03:20', 2.36],
  ['2020-08-24 03:30', 2.63],
  ['2020-08-24 03:40', 2.73],
  ['2020-08-24 03:50', 2.62],
  ['2020-08-24 04:00', 2.59],
  ['2020-08-24 04:10', 2.5],
  ['2020-08-24 04:20', 2.04],
  ['2020-08-24 04:30', 2.22],
  ['2020-08-24 04:40', 1.94],
  ['2020-08-24 04:50', 2.15],
  ['2020-08-24 05:00', 2.05],
  ['2020-08-24 05:10', 2.26],
  ['2020-08-24 05:20', 2.48],
  ['2020-08-24 05:30', 2.45],
  ['2020-08-24 05:40', 2.54],
  ['2020-08-24 05:50', 3.03],
  ['2020-08-24 06:00', 2.91],
  ['2020-08-24 06:10', 2.88],
  ['2020-08-24 06:20', 3.34],
  ['2020-08-24 06:30', 3.49],
  ['2020-08-24 06:40', 3.8],
  ['2020-08-24 06:50', 3.79],
  ['2020-08-24 07:00', 4.16],
  ['2020-08-24 07:10', 4.66],
  ['2020-08-24 07:20', 5.22],
  ['2020-08-24 07:30', 4.68],
  ['2020-08-24 07:40', 4.88],
  ['2020-08-24 07:50', 5.3],
  ['2020-08-24 08:00', 5.09],
  ['2020-08-24 08:10', 4.84],
  ['2020-08-24 08:20', 4.19],
  ['2020-08-24 08:30', 3.64],
  ['2020-08-24 08:40', 3.7],
  ['2020-08-24 08:50', 3.73],
  ['2020-08-24 09:00', 3.33],
  ['2020-08-24 09:10', 3.06],
  ['2020-08-24 09:20', 2.96],
  ['2020-08-24 09:30', 3.1],
  ['2020-08-24 09:40', 3.23],
  ['2020-08-24 09:50', 3.13],
  ['2020-08-24 10:00', 2.96],
  ['2020-08-24 10:10', 3.48],
  ['2020-08-24 10:20', 3.3],
  ['2020-08-24 10:30', 3.78],
  ['2020-08-24 10:40', 3.9],
  ['2020-08-24 10:50', 3.79],
  ['2020-08-24 11:00', 3.78],
  ['2020-08-24 11:10', 3.71],
  ['2020-08-24 11:20', 3.44],
  ['2020-08-24 11:30', 3.42],
  ['2020-08-24 11:40', 3.62],
  ['2020-08-24 11:50', 3.83],
  ['2020-08-24 12:00', 3.35],
  ['2020-08-24 12:10', 3.14],
  ['2020-08-24 12:20', 2.89],
  ['2020-08-24 12:30', 2.42],
  ['2020-08-24 12:40', 2.42],
  ['2020-08-24 12:50', 2.94],
  ['2020-08-24 13:00', 3.1],
  ['2020-08-24 13:10', 3.1],
  ['2020-08-24 13:20', 3.14],
  ['2020-08-24 13:30', 3.4],
  ['2020-08-24 13:40', 3.22],
  ['2020-08-24 13:50', 3.31],
  ['2020-08-24 14:00', 3.27],
  ['2020-08-24 14:10', 3.28],
  ['2020-08-24 14:20', 3.54],
  ['2020-08-24 14:30', 3.72],
  ['2020-08-24 14:40', 3.03],
  ['2020-08-24 14:50', 2.75],
  ['2020-08-24 15:00', 3.16],
  ['2020-08-24 15:10', 2.94],
  ['2020-08-24 15:20', 2.76],
  ['2020-08-24 15:30', 3.02],
  ['2020-08-24 15:40', 3.34],
  ['2020-08-24 15:50', 3.48],
  ['2020-08-24 16:00', 3.29],
  ['2020-08-24 16:10', 3.16],
  ['2020-08-24 16:20', 3.54],
  ['2020-08-24 16:30', 3.69],
  ['2020-08-24 16:40', 4.09],
  ['2020-08-24 16:50', 4.54],
  ['2020-08-24 17:00', 4.79],
  ['2020-08-24 17:10', 4.89],
  ['2020-08-24 17:20', 5.15],
  ['2020-08-24 17:30', 5.08],
  ['2020-08-24 17:40', 4.9],
  ['2020-08-24 17:50', 4.8],
  ['2020-08-24 18:00', 4.95],
  ['2020-08-24 18:10', 5.19],
  ['2020-08-24 18:20', 5.54],
  ['2020-08-24 18:30', 5.56],
  ['2020-08-24 18:40', 5.43],
  ['2020-08-24 18:50', 5.45],
  ['2020-08-24 19:00', 5.53],
  ['2020-08-24 19:10', 5.67],
  ['2020-08-24 19:20', 5.31],
  ['2020-08-24 19:30', 5.15],
  ['2020-08-24 19:40', 5.4],
  ['2020-08-24 19:50', 5.36],
  ['2020-08-24 20:00', 5.25],
  ['2020-08-24 20:10', 5.54],
  ['2020-08-24 20:20', 5.97],
  ['2020-08-24 20:30', 6.23],
  ['2020-08-24 20:40', 6.53],
  ['2020-08-24 20:50', 6.17],
  ['2020-08-24 21:00', 6.2],
  ['2020-08-24 21:10', 6.73],
  ['2020-08-24 21:20', 7.03],
  ['2020-08-24 21:30', 6.87],
  ['2020-08-24 21:40', 6.87],
  ['2020-08-24 21:50', 6.92],
  ['2020-08-24 22:00', 6.83],
  ['2020-08-24 22:10', 6.96],
  ['2020-08-24 22:20', 7.3],
  ['2020-08-24 22:30', 7.41],
  ['2020-08-24 22:40', 6.82],
  ['2020-08-24 22:50', 6.48],
  ['2020-08-24 23:00', 6.23],
  ['2020-08-24 23:10', 6.41],
  ['2020-08-24 23:20', 5.94],
  ['2020-08-24 23:30', 5.95],
  ['2020-08-24 23:40', 5.47],
  ['2020-08-24 23:50', 5.25],
  ['2020-08-25 00:00', 4.75],
  ['2020-08-25 00:10', 4.09],
  ['2020-08-25 00:20', 4.47],
  ['2020-08-25 00:30', 5.14],
  ['2020-08-25 00:40', 4.87],
  ['2020-08-25 00:50', 4.95],
  ['2020-08-25 01:00', 4.98],
  ['2020-08-25 01:10', 4.4],
  ['2020-08-25 01:20', 4.42],
  ['2020-08-25 01:30', 4.34],
  ['2020-08-25 01:40', 4.09],
  ['2020-08-25 01:50', 4.54],
  ['2020-08-25 02:00', 4.66],
  ['2020-08-25 02:10', 4.99],
  ['2020-08-25 02:20', 4.71],
  ['2020-08-25 02:30', 4.25],
  ['2020-08-25 02:40', 3.83],
  ['2020-08-25 02:50', 4.17],
  ['2020-08-25 03:00', 3.99],
  ['2020-08-25 03:10', 4],
  ['2020-08-25 03:20', 3.84],
  ['2020-08-25 03:30', 3.81],
  ['2020-08-25 03:40', 3.76],
  ['2020-08-25 03:50', 3.9],
  ['2020-08-25 04:00', 3.67],
  ['2020-08-25 04:10', 3.8],
  ['2020-08-25 04:20', 3.54],
  ['2020-08-25 04:30', 3.09],
  ['2020-08-25 04:40', 3.31],
  ['2020-08-25 04:50', 3.09],
  ['2020-08-25 05:00', 3.22],
  ['2020-08-25 05:10', 3.44],
  ['2020-08-25 05:20', 4.08],
  ['2020-08-25 05:30', 4.34],
  ['2020-08-25 05:40', 4.5],
  ['2020-08-25 05:50', 4.74],
  ['2020-08-25 06:00', 4.27],
  ['2020-08-25 06:10', 4.36],
  ['2020-08-25 06:20', 4.74],
  ['2020-08-25 06:30', 5.54],
  ['2020-08-25 06:40', 5.9],
  ['2020-08-25 06:50', 6.43],
  ['2020-08-25 07:00', 6.5],
  ['2020-08-25 07:10', 6.54],
  ['2020-08-25 07:20', 6.56],
  ['2020-08-25 07:30', 6.78],
  ['2020-08-25 07:40', 7.05],
  ['2020-08-25 07:50', 7.5],
  ['2020-08-25 08:00', 7.55],
  ['2020-08-25 08:10', 6.75],
  ['2020-08-25 08:20', 6.19],
  ['2020-08-25 08:30', 5.87],
  ['2020-08-25 08:40', 6.18],
  ['2020-08-25 08:50', 6.1],
  ['2020-08-25 09:00', 6.42],
  ['2020-08-25 09:10', 6.4],
  ['2020-08-25 09:20', 5.27],
  ['2020-08-25 09:30', 5.05],
  ['2020-08-25 09:40', 5.94],
  ['2020-08-25 09:50', 6.52],
  ['2020-08-25 10:00', 6.36],
  ['2020-08-25 10:10', 5.92],
  ['2020-08-25 10:20', 6.44],
  ['2020-08-25 10:30', 6.84],
  ['2020-08-25 10:40', 6.06],
  ['2020-08-25 10:50', 5.75],
  ['2020-08-25 11:00', 5.6],
  ['2020-08-25 11:10', 5.43],
  ['2020-08-25 11:20', 4.77],
  ['2020-08-25 11:30', 5.37],
  ['2020-08-25 11:40', 5.36],
  ['2020-08-25 11:50', 5.04],
  ['2020-08-25 12:00', 5.31],
  ['2020-08-25 12:10', 4.8],
  ['2020-08-25 12:20', 4.29],
  ['2020-08-25 12:30', 3.92],
  ['2020-08-25 12:40', 3.46],
  ['2020-08-25 12:50', 3.22],
  ['2020-08-25 13:00', 3.56],
  ['2020-08-25 13:10', 3.53],
  ['2020-08-25 13:20', 4.19],
  ['2020-08-25 13:30', 4.28],
  ['2020-08-25 13:40', 4.19],
  ['2020-08-25 13:50', 4.18],
  ['2020-08-25 14:00', 3.44],
  ['2020-08-25 14:10', 3.66],
  ['2020-08-25 14:20', 2.82],
  ['2020-08-25 14:30', 2.93],
  ['2020-08-25 14:40', 2.56],
  ['2020-08-25 14:50', 2.48],
  ['2020-08-25 15:00', 2.26],
  ['2020-08-25 15:10', 2.24],
  ['2020-08-25 15:20', 1.54],
  ['2020-08-25 15:30', 2.28],
  ['2020-08-25 15:40', 2.7],
  ['2020-08-25 15:50', 2.59],
  ['2020-08-25 16:00', 2.5],
  ['2020-08-25 16:10', 2.74],
  ['2020-08-25 16:20', 2.75],
  ['2020-08-25 16:30', 2.51],
  ['2020-08-25 16:40', 2.28],
  ['2020-08-25 16:50', 2.57],
  ['2020-08-25 17:00', 2.95],
  ['2020-08-25 17:10', 3.38],
  ['2020-08-25 17:20', 3.39],
  ['2020-08-25 17:30', 3.13],
  ['2020-08-25 17:40', 2.94],
  ['2020-08-25 17:50', 3.33],
  ['2020-08-25 18:00', 3.66],
  ['2020-08-25 18:10', 4.29],
  ['2020-08-25 18:20', 4.3],
  ['2020-08-25 18:30', 4.87],
  ['2020-08-25 18:40', 5.16],
  ['2020-08-25 18:50', 5.32],
  ['2020-08-25 19:00', 5.58],
  ['2020-08-25 19:10', 5.43],
  ['2020-08-25 19:20', 5.43],
  ['2020-08-25 19:30', 6.26],
  ['2020-08-25 19:40', 6.89],
  ['2020-08-25 19:50', 7.69],
  ['2020-08-25 20:00', 7.92],
  ['2020-08-25 20:10', 8.82],
  ['2020-08-25 20:20', 9.35],
  ['2020-08-25 20:30', 10.72],
  ['2020-08-25 20:40', 10.99],
  ['2020-08-25 20:50', 11.24],
  ['2020-08-25 21:00', 11.41],
  ['2020-08-25 21:10', 12.05],
  ['2020-08-25 21:20', 12.57],
  ['2020-08-25 21:30', 11.66],
  ['2020-08-25 21:40', 12.07],
  ['2020-08-25 21:50', 11.63],
  ['2020-08-25 22:00', 11.98],
  ['2020-08-25 22:10', 12.18],
  ['2020-08-25 22:20', 12.1],
  ['2020-08-25 22:30', 12.04],
  ['2020-08-25 22:40', 12.37],
  ['2020-08-25 22:50', 12.63],
  ['2020-08-25 23:00', 12.79],
  ['2020-08-25 23:10', 12.02],
  ['2020-08-25 23:20', 11.78],
  ['2020-08-25 23:30', 11.8],
  ['2020-08-25 23:40', 11.17],
  ['2020-08-25 23:50', 9.88],
  ['2020-08-26 00:00', 9.49],
  ['2020-08-26 00:10', 8.65],
  ['2020-08-26 00:20', 8.01],
  ['2020-08-26 00:30', 8.05],
  ['2020-08-26 00:40', 7.71],
  ['2020-08-26 00:50', 7.94],
  ['2020-08-26 01:00', 7.89],
  ['2020-08-26 01:10', 7.74],
  ['2020-08-26 01:20', 7.25],
  ['2020-08-26 01:30', 8.26],
  ['2020-08-26 01:40', 8.5],
  ['2020-08-26 01:50', 8.18],
  ['2020-08-26 02:00', 8.36],
  ['2020-08-26 02:10', 8.81],
  ['2020-08-26 02:20', 8.7],
  ['2020-08-26 02:30', 8.57],
  ['2020-08-26 02:40', 9.18],
  ['2020-08-26 02:50', 9.13],
  ['2020-08-26 03:00', 8.95],
  ['2020-08-26 03:10', 9.74],
  ['2020-08-26 03:20', 10.25],
  ['2020-08-26 03:30', 9.93],
  ['2020-08-26 03:40', 10.41],
  ['2020-08-26 03:50', 10.91],
  ['2020-08-26 04:00', 11.72],
  ['2020-08-26 04:10', 11.83],
  ['2020-08-26 04:20', 11.34],
  ['2020-08-26 04:30', 11.52],
  ['2020-08-26 04:40', 10.92],
  ['2020-08-26 04:50', 10.79],
  ['2020-08-26 05:00', 10.52],
  ['2020-08-26 05:10', 11.45],
  ['2020-08-26 05:20', 11.85],
  ['2020-08-26 05:30', 13.24],
  ['2020-08-26 05:40', 12.58],
  ['2020-08-26 05:50', 11.82],
  ['2020-08-26 06:00', 11.87],
  ['2020-08-26 06:10', 11.72],
  ['2020-08-26 06:20', 12.24],
  ['2020-08-26 06:30', 10.86],
  ['2020-08-26 06:40', 10.3],
  ['2020-08-26 06:50', 10.56],
  ['2020-08-26 07:00', 10.65],
  ['2020-08-26 07:10', 10.35],
  ['2020-08-26 07:20', 11.29],
  ['2020-08-26 07:30', 11.76],
  ['2020-08-26 07:40', 11.23],
  ['2020-08-26 07:50', 12.26],
  ['2020-08-26 08:00', 11.85],
  ['2020-08-26 08:10', 11.67],
  ['2020-08-26 08:20', 12.93],
  ['2020-08-26 08:30', 12.12],
  ['2020-08-26 08:40', 12.57],
  ['2020-08-26 08:50', 13.05],
  ['2020-08-26 09:00', 12.51],
  ['2020-08-26 09:10', 12.11],
  ['2020-08-26 09:20', 11.58],
  ['2020-08-26 09:30', 11.88],
  ['2020-08-26 09:40', 12.22],
  ['2020-08-26 09:50', 11.65],
  ['2020-08-26 10:00', 12.63],
  ['2020-08-26 10:10', 12.6],
  ['2020-08-26 10:20', 11.95],
  ['2020-08-26 10:30', 12.05],
  ['2020-08-26 10:40', 10.77],
  ['2020-08-26 10:50', 11.09],
  ['2020-08-26 11:00', 10.84],
  ['2020-08-26 11:10', 11.44],
  ['2020-08-26 11:20', 11.13],
  ['2020-08-26 11:30', 12.16],
  ['2020-08-26 11:40', 11.71],
  ['2020-08-26 11:50', 11.46],
  ['2020-08-26 12:00', 11.69],
  ['2020-08-26 12:10', 12.82],
  ['2020-08-26 12:20', 12.62],
  ['2020-08-26 12:30', 14.35],
  ['2020-08-26 12:40', 15.34],
  ['2020-08-26 12:50', 16.09],
  ['2020-08-26 13:00', 16.69],
  ['2020-08-26 13:10', 16.06],
  ['2020-08-26 13:20', 13.53],
  ['2020-08-26 13:30', 12.41],
  ['2020-08-26 13:40', 13.66],
  ['2020-08-26 13:50', 13.71],
  ['2020-08-26 14:00', 13.79],
  ['2020-08-26 14:10', 14.26],
  ['2020-08-26 14:20', 14.36],
  ['2020-08-26 14:30', 14.41],
  ['2020-08-26 14:40', 13.8],
  ['2020-08-26 14:50', 14.06],
  ['2020-08-26 15:00', 13.89],
  ['2020-08-26 15:10', 14.88],
  ['2020-08-26 15:20', 15.2],
  ['2020-08-26 15:30', 16.52],
  ['2020-08-26 15:40', 17.22],
  ['2020-08-26 15:50', 17.24],
  ['2020-08-26 16:00', 17.15],
  ['2020-08-26 16:10', 14.32],
  ['2020-08-26 16:20', 14.69],
  ['2020-08-26 16:30', 15.27],
  ['2020-08-26 16:40', 16.5],
  ['2020-08-26 16:50', 15.72],
  ['2020-08-26 17:00', 14.8],
  ['2020-08-26 17:10', 14.86],
  ['2020-08-26 17:20', 14.57],
  ['2020-08-26 17:30', 14.02],
  ['2020-08-26 17:40', 14.73],
  ['2020-08-26 17:50', 15.85],
  ['2020-08-26 18:00', 15.05],
  ['2020-08-26 18:10', 14.05],
  ['2020-08-26 18:20', 14.06],
  ['2020-08-26 18:30', 13.72],
  ['2020-08-26 18:40', 13.55],
  ['2020-08-26 18:50', 12.51],
  ['2020-08-26 19:00', 12.46],
  ['2020-08-26 19:10', 13.5],
  ['2020-08-26 19:20', 13.16],
  ['2020-08-26 19:30', 12.88],
  ['2020-08-26 19:40', 12.6],
  ['2020-08-26 19:50', 12.34],
  ['2020-08-26 20:00', 12.42],
  ['2020-08-26 20:10', 11.02],
  ['2020-08-26 20:20', 11.94],
  ['2020-08-26 20:30', 12.68],
  ['2020-08-26 20:40', 13.67],
  ['2020-08-26 20:50', 14.25],
  ['2020-08-26 21:00', 14.2],
  ['2020-08-26 21:10', 14.86],
  ['2020-08-26 21:20', 14.43],
  ['2020-08-26 21:30', 14.58],
  ['2020-08-26 21:40', 14.24],
  ['2020-08-26 21:50', 14.02],
  ['2020-08-26 22:00', 13.66],
  ['2020-08-26 22:10', 13.82],
  ['2020-08-26 22:20', 13.69],
  ['2020-08-26 22:30', 13.16],
  ['2020-08-26 22:40', 12.91],
  ['2020-08-26 22:50', 13.55],
  ['2020-08-26 23:00', 15.58],
  ['2020-08-26 23:10', 15.29],
  ['2020-08-26 23:20', 16.03],
  ['2020-08-26 23:30', 15.06],
  ['2020-08-26 23:40', 15.17],
  ['2020-08-26 23:50', 15.69],
  ['2020-08-27 00:00', 15.46],
  ['2020-08-27 00:10', 15.73],
  ['2020-08-27 00:20', 14.73],
  ['2020-08-27 00:30', 13.93],
  ['2020-08-27 00:40', 14.4],
  ['2020-08-27 00:50', 14.32],
  ['2020-08-27 01:00', 14.89],
  ['2020-08-27 01:10', 14.54],
  ['2020-08-27 01:20', 15.35],
  ['2020-08-27 01:30', 16.64],
  ['2020-08-27 01:40', 16.48],
  ['2020-08-27 01:50', 16.43],
  ['2020-08-27 02:00', 15.94],
  ['2020-08-27 02:10', 16.33],
  ['2020-08-27 02:20', 15.36],
  ['2020-08-27 02:30', 15.72],
  ['2020-08-27 02:40', 14.7],
  ['2020-08-27 02:50', 14.14],
  ['2020-08-27 03:00', 13.97],
  ['2020-08-27 03:10', 14.08],
  ['2020-08-27 03:20', 13.48],
  ['2020-08-27 03:30', 13.21],
  ['2020-08-27 03:40', 12.55],
  ['2020-08-27 03:50', 12.19],
  ['2020-08-27 04:00', 12.79],
  ['2020-08-27 04:10', 13.18],
  ['2020-08-27 04:20', 13.44],
  ['2020-08-27 04:30', 13.52],
  ['2020-08-27 04:40', 12.96],
  ['2020-08-27 04:50', 12.46],
  ['2020-08-27 05:00', 12.01],
  ['2020-08-27 05:10', 11.72],
  ['2020-08-27 05:20', 12.68],
  ['2020-08-27 05:30', 13.13],
  ['2020-08-27 05:40', 12.68],
  ['2020-08-27 05:50', 13.84],
  ['2020-08-27 06:00', 14.03],
  ['2020-08-27 06:10', 14.65],
  ['2020-08-27 06:20', 13.86],
  ['2020-08-27 06:30', 13.19],
  ['2020-08-27 06:40', 13.5],
  ['2020-08-27 06:50', 13.24],
  ['2020-08-27 07:00', 13.12],
  ['2020-08-27 07:10', 14.39],
  ['2020-08-27 07:20', 14.33],
  ['2020-08-27 07:30', 14.33],
  ['2020-08-27 07:40', 14.45],
  ['2020-08-27 07:50', 14.38],
  ['2020-08-27 08:00', 14.47],
  ['2020-08-27 08:10', 14.47],
  ['2020-08-27 08:20', 14.22],
  ['2020-08-27 08:30', 14.64],
  ['2020-08-27 08:40', 14.1],
  ['2020-08-27 08:50', 14.76],
  ['2020-08-27 09:00', 14.1],
  ['2020-08-27 09:10', 13.59],
  ['2020-08-27 09:20', 13.2],
  ['2020-08-27 09:30', 12.42],
  ['2020-08-27 09:40', 12.93],
  ['2020-08-27 09:50', 14.57],
  ['2020-08-27 10:00', 14.03],
  ['2020-08-27 10:10', 13.54],
  ['2020-08-27 10:20', 13.14],
  ['2020-08-27 10:30', 13.31],
  ['2020-08-27 10:40', 13.16],
  ['2020-08-27 10:50', 13.23],
  ['2020-08-27 11:00', 13.6],
  ['2020-08-27 11:10', 13.32],
  ['2020-08-27 11:20', 13.41],
  ['2020-08-27 11:30', 13.91],
  ['2020-08-27 11:40', 12.91],
  ['2020-08-27 11:50', 12.36],
  ['2020-08-27 12:00', 12.73],
  ['2020-08-27 12:10', 12.85],
  ['2020-08-27 12:20', 13.41],
  ['2020-08-27 12:30', 13.63],
  ['2020-08-27 12:40', 12.94],
  ['2020-08-27 12:50', 13.5],
  ['2020-08-27 13:00', 13.34],
  ['2020-08-27 13:10', 12.68],
  ['2020-08-27 13:20', 11.65],
  ['2020-08-27 13:30', 10.13],
  ['2020-08-27 13:40', 9.82],
  ['2020-08-27 13:50', 11.26],
  ['2020-08-27 14:00', 11.45],
  ['2020-08-27 14:10', 11.59],
  ['2020-08-27 14:20', 11.32],
  ['2020-08-27 14:30', 11.21],
  ['2020-08-27 14:40', 11.4],
  ['2020-08-27 14:50', 11.43],
  ['2020-08-27 15:00', 12.13],
  ['2020-08-27 15:10', 11.55],
  ['2020-08-27 15:20', 11.43],
  ['2020-08-27 15:30', 11.27],
  ['2020-08-27 15:40', 11.51],
  ['2020-08-27 15:50', 11.07],
  ['2020-08-27 16:00', 10.4],
  ['2020-08-27 16:10', 11.59],
  ['2020-08-27 16:20', 10.92],
  ['2020-08-27 16:30', 10.47],
  ['2020-08-27 16:40', 9.67],
  ['2020-08-27 16:50', 9.6],
  ['2020-08-27 17:00', 10.44],
  ['2020-08-27 17:10', 10.14],
  ['2020-08-27 17:20', 9.35],
  ['2020-08-27 17:30', 9.12],
  ['2020-08-27 17:40', 9.08],
  ['2020-08-27 17:50', 9.48],
  ['2020-08-27 18:00', 9.63],
  ['2020-08-27 18:10', 9.61],
  ['2020-08-27 18:20', 9.53],
  ['2020-08-27 18:30', 9.5],
  ['2020-08-27 18:40', 9.03],
  ['2020-08-27 18:50', 9.12],
  ['2020-08-27 19:00', 9.8],
  ['2020-08-27 19:10', 10.02],
  ['2020-08-27 19:20', 9.74],
  ['2020-08-27 19:30', 9.74],
  ['2020-08-27 19:40', 9.41],
  ['2020-08-27 19:50', 9.69],
  ['2020-08-27 20:00', 9.78],
  ['2020-08-27 20:10', 10.6],
  ['2020-08-27 20:20', 10.6],
  ['2020-08-27 20:30', 10.02],
  ['2020-08-27 20:40', 9.92],
  ['2020-08-27 20:50', 9.1],
  ['2020-08-27 21:00', 9.37],
  ['2020-08-27 21:10', 8.61],
  ['2020-08-27 21:20', 8.63],
  ['2020-08-27 21:30', 9.26],
  ['2020-08-27 21:40', 8.82],
  ['2020-08-27 21:50', 7.91],
  ['2020-08-27 22:00', 7.3],
  ['2020-08-27 22:10', 7.13],
  ['2020-08-27 22:20', 7.44],
  ['2020-08-27 22:30', 7.1],
  ['2020-08-27 22:40', 6.62],
  ['2020-08-27 22:50', 6.67],
  ['2020-08-27 23:00', 6.42],
  ['2020-08-27 23:10', 6.21],
  ['2020-08-27 23:20', 6.01],
  ['2020-08-27 23:30', 6.09],
  ['2020-08-27 23:40', 5.88],
  ['2020-08-27 23:50', 5.92],
  ['2020-08-28 00:00', 5.88],
  ['2020-08-28 00:10', 5.82],
  ['2020-08-28 00:20', 6.04],
  ['2020-08-28 00:30', 5.8],
  ['2020-08-28 00:40', 5.98],
  ['2020-08-28 00:50', 6.28],
  ['2020-08-28 01:00', 5.98],
  ['2020-08-28 01:10', 6.33],
  ['2020-08-28 01:20', 7.24],
  ['2020-08-28 01:30', 7.31],
  ['2020-08-28 01:40', 7.33],
  ['2020-08-28 01:50', 6.91],
  ['2020-08-28 02:00', 7.4],
  ['2020-08-28 02:10', 7.85],
  ['2020-08-28 02:20', 7.61],
  ['2020-08-28 02:30', 7.47],
  ['2020-08-28 02:40', 7.24],
  ['2020-08-28 02:50', 6.5],
  ['2020-08-28 03:00', 6.54],
  ['2020-08-28 03:10', 6.26],
  ['2020-08-28 03:20', 6.03],
  ['2020-08-28 03:30', 6.03],
  ['2020-08-28 03:40', 6.08],
  ['2020-08-28 03:50', 6.15],
  ['2020-08-28 04:00', 5.48],
  ['2020-08-28 04:10', 5.88],
  ['2020-08-28 04:20', 6.11],
  ['2020-08-28 04:30', 6.13],
  ['2020-08-28 04:40', 5.43],
  ['2020-08-28 04:50', 5.4],
  ['2020-08-28 05:00', 5.78],
  ['2020-08-28 05:10', 5.9],
  ['2020-08-28 05:20', 6.25],
  ['2020-08-28 05:30', 6.51],
  ['2020-08-28 05:40', 6.42],
  ['2020-08-28 05:50', 6.27],
  ['2020-08-28 06:00', 6.29],
  ['2020-08-28 06:10', 6.38],
  ['2020-08-28 06:20', 6.91],
  ['2020-08-28 06:30', 6.84],
  ['2020-08-28 06:40', 6.91],
  ['2020-08-28 06:50', 6.74],
  ['2020-08-28 07:00', 6.15],
  ['2020-08-28 07:10', 6.19],
  ['2020-08-28 07:20', 5.8],
  ['2020-08-28 07:30', 5.9],
  ['2020-08-28 07:40', 6.38],
  ['2020-08-28 07:50', 7.07],
  ['2020-08-28 08:00', 7.31],
  ['2020-08-28 08:10', 7.01],
  ['2020-08-28 08:20', 7.75],
  ['2020-08-28 08:30', 7.84],
  ['2020-08-28 08:40', 8.24],
  ['2020-08-28 08:50', 7.66],
  ['2020-08-28 09:00', 7.58],
  ['2020-08-28 09:10', 7.07],
  ['2020-08-28 09:20', 6.86],
  ['2020-08-28 09:30', 7.08],
  ['2020-08-28 09:40', 6.86],
  ['2020-08-28 09:50', 6.83],
  ['2020-08-28 10:00', 6.88],
  ['2020-08-28 10:10', 6.79],
  ['2020-08-28 10:20', 7.14],
  ['2020-08-28 10:30', 6.61],
  ['2020-08-28 10:40', 6.53],
  ['2020-08-28 10:50', 7.29],
  ['2020-08-28 11:00', 7.51],
  ['2020-08-28 11:10', 7.66],
  ['2020-08-28 11:20', 7.34],
  ['2020-08-28 11:30', 6.33],
  ['2020-08-28 11:40', 6.26],
  ['2020-08-28 11:50', 6.99],
  ['2020-08-28 12:00', 6.8],
  ['2020-08-28 12:10', 6.92],
  ['2020-08-28 12:20', 7.36],
  ['2020-08-28 12:30', 7.14],
  ['2020-08-28 12:40', 7.25],
  ['2020-08-28 12:50', 7.85],
  ['2020-08-28 13:00', 7.86],
  ['2020-08-28 13:10', 8.28],
  ['2020-08-28 13:20', 8.13],
  ['2020-08-28 13:30', 8.42],
  ['2020-08-28 13:40', 8.29],
  ['2020-08-28 13:50', 8.28],
  ['2020-08-28 14:00', 9.1],
  ['2020-08-28 14:10', 9.03],
  ['2020-08-28 14:20', 8.48],
  ['2020-08-28 14:30', 8.83],
  ['2020-08-28 14:40', 8.5],
  ['2020-08-28 14:50', 8.12],
  ['2020-08-28 15:00', 8.32],
  ['2020-08-28 15:10', 8.62],
  ['2020-08-28 15:20', 9.14],
  ['2020-08-28 15:30', 8.92],
  ['2020-08-28 15:40', 8.61],
  ['2020-08-28 15:50', 8.79],
  ['2020-08-28 16:00', 8.88],
  ['2020-08-28 16:10', 8.11],
  ['2020-08-28 16:20', 7.55],
  ['2020-08-28 16:30', 6.83],
  ['2020-08-28 16:40', 7.4],
  ['2020-08-28 16:50', 7.89],
  ['2020-08-28 17:00', 7.83],
  ['2020-08-28 17:10', 7.66],
  ['2020-08-28 17:20', 7.57],
  ['2020-08-28 17:30', 8.01],
  ['2020-08-28 17:40', 7.9],
  ['2020-08-28 17:50', 8.21],
  ['2020-08-28 18:00', 8.73],
  ['2020-08-28 18:10', 8.54],
  ['2020-08-28 18:20', 8.68],
  ['2020-08-28 18:30', 8.63],
  ['2020-08-28 18:40', 8.6],
  ['2020-08-28 18:50', 8.46],
  ['2020-08-28 19:00', 9.07],
  ['2020-08-28 19:10', 9.23],
  ['2020-08-28 19:20', 9.78],
  ['2020-08-28 19:30', 10.92],
  ['2020-08-28 19:40', 11.14],
  ['2020-08-28 19:50', 11.56],
  ['2020-08-28 20:00', 10.86],
  ['2020-08-28 20:10', 10.73],
  ['2020-08-28 20:20', 11.14],
  ['2020-08-28 20:30', 11.33],
  ['2020-08-28 20:40', 11.23],
  ['2020-08-28 20:50', 10.93],
  ['2020-08-28 21:00', 10.47],
  ['2020-08-28 21:10', 10.22],
  ['2020-08-28 21:20', 9.66],
  ['2020-08-28 21:30', 9.5],
  ['2020-08-28 21:40', 9.25],
  ['2020-08-28 21:50', 9.42],
  ['2020-08-28 22:00', 9.37],
  ['2020-08-28 22:10', 9.5],
  ['2020-08-28 22:20', 8.97],
  ['2020-08-28 22:30', 9.16],
  ['2020-08-28 22:40', 9.25],
  ['2020-08-28 22:50', 9.34],
  ['2020-08-28 23:00', 10.01],
  ['2020-08-28 23:10', 10.15],
  ['2020-08-28 23:20', 10.21],
  ['2020-08-28 23:30', 10.14],
  ['2020-08-28 23:40', 9.42],
  ['2020-08-28 23:50', 9.38],
  ['2020-08-29 00:00', 9.46],
  ['2020-08-29 00:10', 9.42],
  ['2020-08-29 00:20', 9.68],
  ['2020-08-29 00:30', 9.45],
  ['2020-08-29 00:40', 9.92],
  ['2020-08-29 00:50', 8.98],
  ['2020-08-29 01:00', 8.27],
  ['2020-08-29 01:10', 8.55],
  ['2020-08-29 01:20', 8.3],
  ['2020-08-29 01:30', 8.26],
  ['2020-08-29 01:40', 7.82],
  ['2020-08-29 01:50', 8.61],
  ['2020-08-29 02:00', 8.13],
  ['2020-08-29 02:10', 8.63],
  ['2020-08-29 02:20', 11.04],
  ['2020-08-29 02:30', 12.13],
  ['2020-08-29 02:40', 11.2],
  ['2020-08-29 02:50', 10.18],
  ['2020-08-29 03:00', 10.32],
  ['2020-08-29 03:10', 9.41],
  ['2020-08-29 03:20', 9.59],
  ['2020-08-29 03:30', 9.14],
  ['2020-08-29 03:40', 9.45],
  ['2020-08-29 03:50', 8.82],
  ['2020-08-29 04:00', 8.25],
  ['2020-08-29 04:10', 8.04],
  ['2020-08-29 04:20', 7.97],
  ['2020-08-29 04:30', 8.35],
  ['2020-08-29 04:40', 8.5],
  ['2020-08-29 04:50', 9.4],
  ['2020-08-29 05:00', 9.75],
  ['2020-08-29 05:10', 9.54],
  ['2020-08-29 05:20', 9.31],
  ['2020-08-29 05:30', 8.85],
  ['2020-08-29 05:40', 8.26],
  ['2020-08-29 05:50', 8.43],
  ['2020-08-29 06:00', 8.47],
  ['2020-08-29 06:10', 8.3],
  ['2020-08-29 06:20', 7.72],
  ['2020-08-29 06:30', 7.61],
  ['2020-08-29 06:40', 7.2],
  ['2020-08-29 06:50', 6.69],
  ['2020-08-29 07:00', 6.52],
  ['2020-08-29 07:10', 6.31],
  ['2020-08-29 07:20', 6.53],
  ['2020-08-29 07:30', 6.33],
  ['2020-08-29 07:40', 6.36],
  ['2020-08-29 07:50', 6.24],
  ['2020-08-29 08:00', 6.28],
  ['2020-08-29 08:10', 6.43],
  ['2020-08-29 08:20', 6.32],
  ['2020-08-29 08:30', 6.52],
  ['2020-08-29 08:40', 6.25],
  ['2020-08-29 08:50', 6.29],
  ['2020-08-29 09:00', 6.29],
  ['2020-08-29 09:10', 6.42],
  ['2020-08-29 09:20', 5.89],
  ['2020-08-29 09:30', 6.62],
  ['2020-08-29 09:40', 7.15],
  ['2020-08-29 09:50', 7],
  ['2020-08-29 10:00', 7.02],
  ['2020-08-29 10:10', 6.47],
  ['2020-08-29 10:20', 7.14],
  ['2020-08-29 10:30', 6.67],
  ['2020-08-29 10:40', 6.37],
  ['2020-08-29 10:50', 6.5],
  ['2020-08-29 11:00', 6.65],
  ['2020-08-29 11:10', 6.42],
  ['2020-08-29 11:20', 6.77],
  ['2020-08-29 11:30', 6.48],
  ['2020-08-29 11:40', 6.31],
  ['2020-08-29 11:50', 6.25],
  ['2020-08-29 12:00', 6.1],
  ['2020-08-29 12:10', 5.86],
  ['2020-08-29 12:20', 6.22],
  ['2020-08-29 12:30', 5.84],
  ['2020-08-29 12:40', 5.92],
  ['2020-08-29 12:50', 5.9],
  ['2020-08-29 13:00', 5.93],
  ['2020-08-29 13:10', 5.98],
  ['2020-08-29 13:20', 5.96],
  ['2020-08-29 13:30', 6.29],
  ['2020-08-29 13:40', 6.07],
  ['2020-08-29 13:50', 6.04],
  ['2020-08-29 14:00', 6.28],
  ['2020-08-29 14:10', 6.22],
  ['2020-08-29 14:20', 6.28],
  ['2020-08-29 14:30', 6.44],
  ['2020-08-29 14:40', 6.24],
  ['2020-08-29 14:50', 6.46],
  ['2020-08-29 15:00', 6.54],
  ['2020-08-29 15:10', 6.44],
  ['2020-08-29 15:20', 6.03],
  ['2020-08-29 15:30', 6.4],
  ['2020-08-29 15:40', 6.34],
  ['2020-08-29 15:50', 6.33],
  ['2020-08-29 16:00', 6.03],
  ['2020-08-29 16:10', 6.14],
  ['2020-08-29 16:20', 5.96],
  ['2020-08-29 16:30', 6.13],
  ['2020-08-29 16:40', 6.16],
  ['2020-08-29 16:50', 6.58],
  ['2020-08-29 17:00', 6.21],
  ['2020-08-29 17:10', 5.73],
  ['2020-08-29 17:20', 5.67],
  ['2020-08-29 17:30', 5.63],
  ['2020-08-29 17:40', 5.6],
  ['2020-08-29 17:50', 5.54],
  ['2020-08-29 18:00', 5.63],
  ['2020-08-29 18:10', 5.73],
  ['2020-08-29 18:20', 5.92],
  ['2020-08-29 18:30', 6.11],
  ['2020-08-29 18:40', 6.13],
  ['2020-08-29 18:50', 6.2],
  ['2020-08-29 19:00', 5.7],
  ['2020-08-29 19:10', 5.83],
  ['2020-08-29 19:20', 5.67],
  ['2020-08-29 19:30', 5.61],
  ['2020-08-29 19:40', 5.72],
  ['2020-08-29 19:50', 5.86],
  ['2020-08-29 20:00', 5.93],
  ['2020-08-29 20:10', 6.02],
  ['2020-08-29 20:20', 6.01],
  ['2020-08-29 20:30', 6.06],
  ['2020-08-29 20:40', 6.14],
  ['2020-08-29 20:50', 6.49],
  ['2020-08-29 21:00', 6.4],
  ['2020-08-29 21:10', 6.3],
  ['2020-08-29 21:20', 6.33],
  ['2020-08-29 21:30', 6.43],
  ['2020-08-29 21:40', 6.89],
  ['2020-08-29 21:50', 6.32],
  ['2020-08-29 22:00', 6.41],
  ['2020-08-29 22:10', 6.74],
  ['2020-08-29 22:20', 7.01],
  ['2020-08-29 22:30', 6.64],
  ['2020-08-29 22:40', 6.39],
  ['2020-08-29 22:50', 6.31],
  ['2020-08-29 23:00', 6.28],
  ['2020-08-29 23:10', 6.39],
  ['2020-08-29 23:20', 6.14],
  ['2020-08-29 23:30', 6.72],
  ['2020-08-29 23:40', 6.96],
  ['2020-08-29 23:50', 6.93],
];

const WIND5 = [
  ['2020-08-30 00:00', 6.72],
  ['2020-08-30 00:10', 6.59],
  ['2020-08-30 00:20', 6.36],
  ['2020-08-30 00:30', 6.16],
  ['2020-08-30 00:40', 6.1],
  ['2020-08-30 00:50', 5.94],
  ['2020-08-30 01:00', 6.42],
  ['2020-08-30 01:10', 6.47],
  ['2020-08-30 01:20', 6.71],
  ['2020-08-30 01:30', 6.59],
  ['2020-08-30 01:40', 6.62],
  ['2020-08-30 01:50', 6.27],
  ['2020-08-30 02:00', 5.8],
  ['2020-08-30 02:10', 5.67],
  ['2020-08-30 02:20', 6.21],
  ['2020-08-30 02:30', 6.22],
  ['2020-08-30 02:40', 6.04],
  ['2020-08-30 02:50', 5.95],
  ['2020-08-30 03:00', 5.72],
  ['2020-08-30 03:10', 5.74],
  ['2020-08-30 03:20', 5.99],
  ['2020-08-30 03:30', 5.72],
  ['2020-08-30 03:40', 5.61],
  ['2020-08-30 03:50', 5.63],
  ['2020-08-30 04:00', 5.7],
  ['2020-08-30 04:10', 5.68],
  ['2020-08-30 04:20', 5.43],
  ['2020-08-30 04:30', 5.24],
  ['2020-08-30 04:40', 5.3],
  ['2020-08-30 04:50', 5.16],
  ['2020-08-30 05:00', 4.84],
  ['2020-08-30 05:10', 4.88],
  ['2020-08-30 05:20', 4.78],
  ['2020-08-30 05:30', 4.73],
  ['2020-08-30 05:40', 4.69],
  ['2020-08-30 05:50', 4.94],
  ['2020-08-30 06:00', 5.05],
  ['2020-08-30 06:10', 4.77],
  ['2020-08-30 06:20', 4.66],
  ['2020-08-30 06:30', 5.03],
  ['2020-08-30 06:40', 5.05],
  ['2020-08-30 06:50', 4.85],
  ['2020-08-30 07:00', 5.27],
  ['2020-08-30 07:10', 5.39],
  ['2020-08-30 07:20', 4.82],
  ['2020-08-30 07:30', 4.49],
  ['2020-08-30 07:40', 4.26],
  ['2020-08-30 07:50', 4.14],
  ['2020-08-30 08:00', 4.06],
  ['2020-08-30 08:10', 4.18],
  ['2020-08-30 08:20', 3.78],
  ['2020-08-30 08:30', 3.64],
  ['2020-08-30 08:40', 3.24],
  ['2020-08-30 08:50', 2.83],
  ['2020-08-30 09:00', 2.5],
  ['2020-08-30 09:10', 2.47],
  ['2020-08-30 09:20', 2.76],
  ['2020-08-30 09:30', 2.74],
  ['2020-08-30 09:40', 2.74],
  ['2020-08-30 09:50', 2.59],
  ['2020-08-30 10:00', 2.37],
  ['2020-08-30 10:10', 2.33],
  ['2020-08-30 10:20', 1.92],
  ['2020-08-30 10:30', 1.83],
  ['2020-08-30 10:40', 2.12],
  ['2020-08-30 10:50', 1.93],
  ['2020-08-30 11:00', 1.32],
  ['2020-08-30 11:10', 1.28],
  ['2020-08-30 11:20', 1.22],
  ['2020-08-30 11:30', 1.1],
  ['2020-08-30 11:40', 0.69],
  ['2020-08-30 11:50', 0.68],
  ['2020-08-30 12:00', 0.64],
  ['2020-08-30 12:10', 0.57],
  ['2020-08-30 12:20', 0.52],
  ['2020-08-30 12:30', 0.52],
  ['2020-08-30 12:40', 0.62],
  ['2020-08-30 12:50', 0.76],
  ['2020-08-30 13:00', 0.76],
  ['2020-08-30 13:10', 1.65],
  ['2020-08-30 13:20', 2.04],
  ['2020-08-30 13:30', 2.04],
  ['2020-08-30 13:40', 1.73],
  ['2020-08-30 13:50', 2.19],
  ['2020-08-30 14:00', 2.84],
  ['2020-08-30 14:10', 2.85],
  ['2020-08-30 14:20', 3.04],
  ['2020-08-30 14:30', 3.41],
  ['2020-08-30 14:40', 3.46],
  ['2020-08-30 14:50', 2.84],
  ['2020-08-30 15:00', 2.87],
  ['2020-08-30 15:10', 2.89],
  ['2020-08-30 15:20', 3.8],
  ['2020-08-30 15:30', 3.95],
  ['2020-08-30 15:40', 4.34],
  ['2020-08-30 15:50', 5.43],
  ['2020-08-30 16:00', 5.44],
  ['2020-08-30 16:10', 5.58],
  ['2020-08-30 16:20', 5.66],
  ['2020-08-30 16:30', 6.22],
  ['2020-08-30 16:40', 6.82],
  ['2020-08-30 16:50', 7.69],
  ['2020-08-30 17:00', 8.32],
  ['2020-08-30 17:10', 9.82],
  ['2020-08-30 17:20', 9.93],
  ['2020-08-30 17:30', 9.37],
  ['2020-08-30 17:40', 9.34],
  ['2020-08-30 17:50', 8.72],
  ['2020-08-30 18:00', 8.75],
  ['2020-08-30 18:10', 8.66],
  ['2020-08-30 18:20', 9.06],
  ['2020-08-30 18:30', 9.53],
  ['2020-08-30 18:40', 10],
  ['2020-08-30 18:50', 10.25],
  ['2020-08-30 19:00', 10.22],
  ['2020-08-30 19:10', 11.02],
  ['2020-08-30 19:20', 11.31],
  ['2020-08-30 19:30', 11.98],
  ['2020-08-30 19:40', 11.79],
  ['2020-08-30 19:50', 11.98],
  ['2020-08-30 20:00', 11.6],
  ['2020-08-30 20:10', 11.38],
  ['2020-08-30 20:20', 10.68],
  ['2020-08-30 20:30', 10.81],
  ['2020-08-30 20:40', 10.57],
  ['2020-08-30 20:50', 10.82],
  ['2020-08-30 21:00', 11.26],
  ['2020-08-30 21:10', 11.08],
  ['2020-08-30 21:20', 11.35],
  ['2020-08-30 21:30', 11.74],
  ['2020-08-30 21:40', 11.21],
  ['2020-08-30 21:50', 10.37],
  ['2020-08-30 22:00', 11.26],
  ['2020-08-30 22:10', 10.82],
  ['2020-08-30 22:20', 11.68],
  ['2020-08-30 22:30', 11.75],
  ['2020-08-30 22:40', 11.54],
  ['2020-08-30 22:50', 11.03],
  ['2020-08-30 23:00', 11.27],
  ['2020-08-30 23:10', 10.49],
  ['2020-08-30 23:20', 10.58],
  ['2020-08-30 23:30', 10.29],
  ['2020-08-30 23:40', 10.49],
  ['2020-08-30 23:50', 10.48],
  ['2020-08-31 00:00', 10.37],
  ['2020-08-31 00:10', 9.73],
  ['2020-08-31 00:20', 10],
  ['2020-08-31 00:30', 11.13],
  ['2020-08-31 00:40', 10.2],
  ['2020-08-31 00:50', 10.31],
  ['2020-08-31 01:00', 10.01],
  ['2020-08-31 01:10', 10.18],
  ['2020-08-31 01:20', 10.64],
  ['2020-08-31 01:30', 10.58],
  ['2020-08-31 01:40', 10.51],
  ['2020-08-31 01:50', 10.3],
  ['2020-08-31 02:00', 9.69],
  ['2020-08-31 02:10', 10],
  ['2020-08-31 02:20', 10.38],
  ['2020-08-31 02:30', 9.77],
  ['2020-08-31 02:40', 9.68],
  ['2020-08-31 02:50', 9.8],
  ['2020-08-31 03:00', 9.76],
  ['2020-08-31 03:10', 9.88],
  ['2020-08-31 03:20', 9.81],
  ['2020-08-31 03:30', 9.46],
  ['2020-08-31 03:40', 9.4],
  ['2020-08-31 03:50', 9.65],
  ['2020-08-31 04:00', 9.44],
  ['2020-08-31 04:10', 9.15],
  ['2020-08-31 04:20', 9.02],
  ['2020-08-31 04:30', 8.95],
  ['2020-08-31 04:40', 9.39],
  ['2020-08-31 04:50', 8.8],
  ['2020-08-31 05:00', 8.04],
  ['2020-08-31 05:10', 7.95],
  ['2020-08-31 05:20', 7.88],
  ['2020-08-31 05:30', 8.19],
  ['2020-08-31 05:40', 7.6],
  ['2020-08-31 05:50', 7.15],
  ['2020-08-31 06:00', 6.51],
  ['2020-08-31 06:10', 6.89],
  ['2020-08-31 06:20', 7.22],
  ['2020-08-31 06:30', 7.08],
  ['2020-08-31 06:40', 7.05],
  ['2020-08-31 06:50', 6.88],
  ['2020-08-31 07:00', 6.44],
  ['2020-08-31 07:10', 4.64],
  ['2020-08-31 07:20', 8.38],
  ['2020-08-31 07:30', 10.85],
  ['2020-08-31 07:40', 13.35],
  ['2020-08-31 07:50', 14.14],
  ['2020-08-31 08:00', 14.4],
  ['2020-08-31 08:10', 14.88],
  ['2020-08-31 08:20', 14.63],
  ['2020-08-31 08:30', 15.33],
  ['2020-08-31 08:40', 15.52],
  ['2020-08-31 08:50', 14.92],
  ['2020-08-31 09:00', 15.35],
  ['2020-08-31 09:10', 14.54],
  ['2020-08-31 09:20', 14.6],
  ['2020-08-31 09:30', 14.55],
  ['2020-08-31 09:40', 15.04],
  ['2020-08-31 09:50', 14.62],
  ['2020-08-31 10:00', 14.31],
  ['2020-08-31 10:10', 14.48],
  ['2020-08-31 10:20', 13.87],
  ['2020-08-31 10:30', 13.98],
  ['2020-08-31 10:40', 13.78],
  ['2020-08-31 10:50', 13.5],
  ['2020-08-31 11:00', 12.96],
  ['2020-08-31 11:10', 12.96],
  ['2020-08-31 11:20', 13.29],
  ['2020-08-31 11:30', 13],
  ['2020-08-31 11:40', 13.24],
  ['2020-08-31 11:50', 13.53],
  ['2020-08-31 12:00', 13.67],
  ['2020-08-31 12:10', 13.61],
  ['2020-08-31 12:20', 13.42],
  ['2020-08-31 12:30', 13.29],
  ['2020-08-31 12:40', 13.47],
  ['2020-08-31 12:50', 13.21],
  ['2020-08-31 13:00', 13.77],
  ['2020-08-31 13:10', 14.04],
  ['2020-08-31 13:20', 13.33],
  ['2020-08-31 13:30', 13.63],
  ['2020-08-31 13:40', 13.18],
  ['2020-08-31 13:50', 12.57],
  ['2020-08-31 14:00', 12.98],
  ['2020-08-31 14:10', 12.9],
  ['2020-08-31 14:20', 13.08],
  ['2020-08-31 14:30', 13.39],
  ['2020-08-31 14:40', 13.68],
  ['2020-08-31 14:50', 14.14],
  ['2020-08-31 15:00', 13.69],
  ['2020-08-31 15:10', 13.72],
  ['2020-08-31 15:20', 13.63],
  ['2020-08-31 15:30', 14.32],
  ['2020-08-31 15:40', 14.18],
  ['2020-08-31 15:50', 13.75],
  ['2020-08-31 16:00', 13.2],
  ['2020-08-31 16:10', 13.46],
  ['2020-08-31 16:20', 13.6],
  ['2020-08-31 16:30', 13.46],
  ['2020-08-31 16:40', 12.96],
  ['2020-08-31 16:50', 12.98],
  ['2020-08-31 17:00', 12.88],
  ['2020-08-31 17:10', 12.69],
  ['2020-08-31 17:20', 13.13],
  ['2020-08-31 17:30', 12.81],
  ['2020-08-31 17:40', 12.42],
  ['2020-08-31 17:50', 12.37],
  ['2020-08-31 18:00', 12.53],
  ['2020-08-31 18:10', 12.44],
  ['2020-08-31 18:20', 11.77],
  ['2020-08-31 18:30', 11.96],
  ['2020-08-31 18:40', 11.7],
  ['2020-08-31 18:50', 11.68],
  ['2020-08-31 19:00', 11.13],
  ['2020-08-31 19:10', 10.64],
  ['2020-08-31 19:20', 11.01],
  ['2020-08-31 19:30', 11.46],
  ['2020-08-31 19:40', 11.03],
  ['2020-08-31 19:50', 11.27],
  ['2020-08-31 20:00', 10.83],
  ['2020-08-31 20:10', 11.24],
  ['2020-08-31 20:20', 11.11],
  ['2020-08-31 20:30', 11.18],
  ['2020-08-31 20:40', 11],
  ['2020-08-31 20:50', 10.64],
  ['2020-08-31 21:00', 11.14],
  ['2020-08-31 21:10', 11.71],
  ['2020-08-31 21:20', 10.96],
  ['2020-08-31 21:30', 11.54],
  ['2020-08-31 21:40', 10.8],
  ['2020-08-31 21:50', 10.9],
  ['2020-08-31 22:00', 10.8],
  ['2020-08-31 22:10', 10.18],
  ['2020-08-31 22:20', 10.05],
  ['2020-08-31 22:30', 9.96],
  ['2020-08-31 22:40', 10.16],
  ['2020-08-31 22:50', 9.6],
  ['2020-08-31 23:00', 9.78],
  ['2020-08-31 23:10', 9.72],
  ['2020-08-31 23:20', 9.24],
  ['2020-08-31 23:30', 8.69],
  ['2020-08-31 23:40', 9.25],
  ['2020-08-31 23:50', 9.22],
  ['2020-09-01 00:00', 9.58],
  ['2020-09-01 00:10', 9.56],
  ['2020-09-01 00:20', 9.19],
  ['2020-09-01 00:30', 9.42],
  ['2020-09-01 00:40', 9.61],
  ['2020-09-01 00:50', 9.05],
  ['2020-09-01 01:00', 8.81],
  ['2020-09-01 01:10', 8.19],
  ['2020-09-01 01:20', 8.43],
  ['2020-09-01 01:30', 8.8],
  ['2020-09-01 01:40', 9.19],
  ['2020-09-01 01:50', 9.01],
  ['2020-09-01 02:00', 9.14],
  ['2020-09-01 02:10', 8.59],
  ['2020-09-01 02:20', 8.72],
  ['2020-09-01 02:30', 8.78],
  ['2020-09-01 02:40', 9.35],
  ['2020-09-01 02:50', 9.54],
  ['2020-09-01 03:00', 9.69],
  ['2020-09-01 03:10', 9.58],
  ['2020-09-01 03:20', 9.51],
  ['2020-09-01 03:30', 10.21],
  ['2020-09-01 03:40', 10.62],
  ['2020-09-01 03:50', 10.6],
  ['2020-09-01 04:00', 10.05],
  ['2020-09-01 04:10', 9.55],
  ['2020-09-01 04:20', 9.83],
  ['2020-09-01 04:30', 10.32],
  ['2020-09-01 04:40', 9.83],
  ['2020-09-01 04:50', 9.48],
  ['2020-09-01 05:00', 9.32],
  ['2020-09-01 05:10', 9.25],
  ['2020-09-01 05:20', 8.9],
  ['2020-09-01 05:30', 9.03],
  ['2020-09-01 05:40', 9.15],
  ['2020-09-01 05:50', 9.02],
  ['2020-09-01 06:00', 9.03],
  ['2020-09-01 06:10', 8.66],
  ['2020-09-01 06:20', 9.11],
  ['2020-09-01 06:30', 9.44],
  ['2020-09-01 06:40', 9.54],
  ['2020-09-01 06:50', 9.56],
  ['2020-09-01 07:00', 9.66],
  ['2020-09-01 07:10', 9.45],
  ['2020-09-01 07:20', 9.17],
  ['2020-09-01 07:30', 9.11],
  ['2020-09-01 07:40', 8.96],
  ['2020-09-01 07:50', 8.53],
  ['2020-09-01 08:00', 8.85],
  ['2020-09-01 08:10', 8.78],
  ['2020-09-01 08:20', 8.85],
  ['2020-09-01 08:30', 8.73],
  ['2020-09-01 08:40', 8.74],
  ['2020-09-01 08:50', 8.62],
  ['2020-09-01 09:00', 8.6],
  ['2020-09-01 09:10', 9],
  ['2020-09-01 09:20', 8.88],
  ['2020-09-01 09:30', 9.24],
  ['2020-09-01 09:40', 9.15],
  ['2020-09-01 09:50', 9.76],
  ['2020-09-01 10:00', 9.62],
  ['2020-09-01 10:10', 9.88],
  ['2020-09-01 10:20', 9.92],
  ['2020-09-01 10:30', 9.22],
  ['2020-09-01 10:40', 9.44],
  ['2020-09-01 10:50', 9.38],
  ['2020-09-01 11:00', 8.61],
  ['2020-09-01 11:10', 9.04],
  ['2020-09-01 11:20', 8.92],
  ['2020-09-01 11:30', 9.44],
  ['2020-09-01 11:40', 9.03],
  ['2020-09-01 11:50', 9.23],
  ['2020-09-01 12:00', 8.89],
  ['2020-09-01 12:10', 9.1],
  ['2020-09-01 12:20', 8.66],
  ['2020-09-01 12:30', 8.15],
  ['2020-09-01 12:40', 7.76],
  ['2020-09-01 12:50', 7.48],
  ['2020-09-01 13:00', 7.77],
  ['2020-09-01 13:10', 7.54],
  ['2020-09-01 13:20', 7.36],
  ['2020-09-01 13:30', 7.19],
  ['2020-09-01 13:40', 7.2],
  ['2020-09-01 13:50', 7.79],
  ['2020-09-01 14:00', 7.73],
  ['2020-09-01 14:10', 7.94],
  ['2020-09-01 14:20', 8.23],
  ['2020-09-01 14:30', 8.22],
  ['2020-09-01 14:40', 8.15],
  ['2020-09-01 14:50', 7.99],
  ['2020-09-01 15:00', 8.09],
  ['2020-09-01 15:10', 8.01],
  ['2020-09-01 15:20', 7.54],
  ['2020-09-01 15:30', 7.45],
  ['2020-09-01 15:40', 7.66],
  ['2020-09-01 15:50', 7.82],
  ['2020-09-01 16:00', 8.3],
  ['2020-09-01 16:10', 8.54],
  ['2020-09-01 16:20', 9.01],
  ['2020-09-01 16:30', 8.5],
  ['2020-09-01 16:40', 7.99],
  ['2020-09-01 16:50', 7.63],
  ['2020-09-01 17:00', 7.73],
  ['2020-09-01 17:10', 7.9],
  ['2020-09-01 17:20', 8.14],
  ['2020-09-01 17:30', 8],
  ['2020-09-01 17:40', 8.02],
  ['2020-09-01 17:50', 8.4],
  ['2020-09-01 18:00', 8.53],
  ['2020-09-01 18:10', 8.71],
  ['2020-09-01 18:20', 8.66],
  ['2020-09-01 18:30', 8.4],
  ['2020-09-01 18:40', 8.56],
  ['2020-09-01 18:50', 8.59],
  ['2020-09-01 19:00', 8.73],
  ['2020-09-01 19:10', 8.9],
  ['2020-09-01 19:20', 8.65],
  ['2020-09-01 19:30', 8.9],
  ['2020-09-01 19:40', 8.67],
  ['2020-09-01 19:50', 8.58],
  ['2020-09-01 20:00', 8.33],
  ['2020-09-01 20:10', 8.22],
  ['2020-09-01 20:20', 7.83],
  ['2020-09-01 20:30', 7.84],
  ['2020-09-01 20:40', 7.77],
  ['2020-09-01 20:50', 7.78],
  ['2020-09-01 21:00', 8.35],
  ['2020-09-01 21:10', 7.72],
  ['2020-09-01 21:20', 7.78],
  ['2020-09-01 21:30', 7.75],
  ['2020-09-01 21:40', 7.6],
  ['2020-09-01 21:50', 8.06],
  ['2020-09-01 22:00', 8.07],
  ['2020-09-01 22:10', 8.16],
  ['2020-09-01 22:20', 8.37],
  ['2020-09-01 22:30', 7.79],
  ['2020-09-01 22:40', 7.79],
  ['2020-09-01 22:50', 7.82],
  ['2020-09-01 23:00', 7.75],
  ['2020-09-01 23:10', 7.89],
  ['2020-09-01 23:20', 7.75],
  ['2020-09-01 23:30', 7.92],
  ['2020-09-01 23:40', 8.29],
  ['2020-09-01 23:50', 8.35],
  ['2020-09-02 00:00', 8.91],
  ['2020-09-02 00:10', 9.09],
  ['2020-09-02 00:20', 9.05],
  ['2020-09-02 00:30', 9.03],
  ['2020-09-02 00:40', 8.69],
  ['2020-09-02 00:50', 9],
  ['2020-09-02 01:00', 9.57],
  ['2020-09-02 01:10', 9.07],
  ['2020-09-02 01:20', 9.09],
  ['2020-09-02 01:30', 8.97],
  ['2020-09-02 01:40', 8.61],
  ['2020-09-02 01:50', 9.01],
  ['2020-09-02 02:00', 8.91],
  ['2020-09-02 02:10', 9.03],
  ['2020-09-02 02:20', 9.18],
  ['2020-09-02 02:30', 8.69],
  ['2020-09-02 02:40', 8.8],
  ['2020-09-02 02:50', 9.29],
  ['2020-09-02 03:00', 9.5],
  ['2020-09-02 03:10', 9.72],
  ['2020-09-02 03:20', 9.68],
  ['2020-09-02 03:30', 9.98],
  ['2020-09-02 03:40', 10.3],
  ['2020-09-02 03:50', 10.12],
  ['2020-09-02 04:00', 9.38],
  ['2020-09-02 04:10', 9.45],
  ['2020-09-02 04:20', 9.3],
  ['2020-09-02 04:30', 9.14],
  ['2020-09-02 04:40', 8.98],
  ['2020-09-02 04:50', 9.19],
  ['2020-09-02 05:00', 9.46],
  ['2020-09-02 05:10', 9.27],
  ['2020-09-02 05:20', 9.55],
  ['2020-09-02 05:30', 9.57],
  ['2020-09-02 05:40', 9.63],
  ['2020-09-02 05:50', 9.57],
  ['2020-09-02 06:00', 8.96],
  ['2020-09-02 06:10', 9.24],
  ['2020-09-02 06:20', 9.29],
  ['2020-09-02 06:30', 9.39],
  ['2020-09-02 06:40', 10.35],
  ['2020-09-02 06:50', 10.32],
  ['2020-09-02 07:00', 10.09],
  ['2020-09-02 07:10', 9.72],
  ['2020-09-02 07:20', 8.98],
  ['2020-09-02 07:30', 8.39],
  ['2020-09-02 07:40', 7.88],
  ['2020-09-02 07:50', 7.26],
  ['2020-09-02 08:00', 7.35],
  ['2020-09-02 08:10', 7.03],
  ['2020-09-02 08:20', 6.41],
  ['2020-09-02 08:30', 6.31],
  ['2020-09-02 08:40', 6.01],
  ['2020-09-02 08:50', 6.39],
  ['2020-09-02 09:00', 8.59],
  ['2020-09-02 09:10', 8.75],
  ['2020-09-02 09:20', 8.73],
  ['2020-09-02 09:30', 9.17],
  ['2020-09-02 09:40', 9.36],
  ['2020-09-02 09:50', 9.23],
  ['2020-09-02 10:00', 8.94],
  ['2020-09-02 10:10', 9.18],
  ['2020-09-02 10:20', 9.28],
  ['2020-09-02 10:30', 9.53],
  ['2020-09-02 10:40', 9.65],
  ['2020-09-02 10:50', 9.89],
  ['2020-09-02 11:00', 10.03],
  ['2020-09-02 11:10', 10.4],
  ['2020-09-02 11:20', 10.47],
  ['2020-09-02 11:30', 10.91],
  ['2020-09-02 11:40', 11.03],
  ['2020-09-02 11:50', 10.83],
  ['2020-09-02 12:00', 11.6],
  ['2020-09-02 12:10', 11.44],
  ['2020-09-02 12:20', 11.65],
  ['2020-09-02 12:30', 11.75],
  ['2020-09-02 12:40', 11.63],
  ['2020-09-02 12:50', 11.55],
  ['2020-09-02 13:00', 11.75],
  ['2020-09-02 13:10', 11.82],
  ['2020-09-02 13:20', 11.76],
  ['2020-09-02 13:30', 11.92],
  ['2020-09-02 13:40', 11.94],
  ['2020-09-02 13:50', 12.29],
  ['2020-09-02 14:00', 12.3],
  ['2020-09-02 14:10', 12.34],
  ['2020-09-02 14:20', 12.79],
  ['2020-09-02 14:30', 12.9],
  ['2020-09-02 14:40', 12.82],
  ['2020-09-02 14:50', 13.3],
  ['2020-09-02 15:00', 13.48],
  ['2020-09-02 15:10', 13.75],
  ['2020-09-02 15:20', 13.61],
  ['2020-09-02 15:30', 14.01],
  ['2020-09-02 15:40', 14.12],
  ['2020-09-02 15:50', 14.14],
  ['2020-09-02 16:00', 14.35],
  ['2020-09-02 16:10', 14.45],
  ['2020-09-02 16:20', 14.42],
  ['2020-09-02 16:30', 14.21],
  ['2020-09-02 16:40', 14.31],
  ['2020-09-02 16:50', 14.36],
  ['2020-09-02 17:00', 14.48],
  ['2020-09-02 17:10', 14.53],
  ['2020-09-02 17:20', 14.64],
  ['2020-09-02 17:30', 14.54],
  ['2020-09-02 17:40', 14.64],
  ['2020-09-02 17:50', 15.04],
  ['2020-09-02 18:00', 14.92],
  ['2020-09-02 18:10', 15.27],
  ['2020-09-02 18:20', 15.2],
  ['2020-09-02 18:30', 15.64],
  ['2020-09-02 18:40', 15.66],
  ['2020-09-02 18:50', 15.96],
  ['2020-09-02 19:00', 16.3],
  ['2020-09-02 19:10', 16.68],
  ['2020-09-02 19:20', 17.15],
  ['2020-09-02 19:30', 17.33],
  ['2020-09-02 19:40', 17.61],
  ['2020-09-02 19:50', 17.34],
  ['2020-09-02 20:00', 18.36],
  ['2020-09-02 20:10', 19.18],
  ['2020-09-02 20:20', 19.28],
  ['2020-09-02 20:30', 20.12],
  ['2020-09-02 20:40', 20.12],
  ['2020-09-02 20:50', 20.15],
  ['2020-09-02 21:00', 20.79],
  ['2020-09-02 21:10', 21.46],
  ['2020-09-02 21:20', 21.65],
  ['2020-09-02 21:30', 21.75],
  ['2020-09-02 21:40', 22.08],
  ['2020-09-02 21:50', 22.54],
  ['2020-09-02 22:00', 22.95],
  ['2020-09-02 22:10', 23.08],
  ['2020-09-02 22:20', 23.94],
  ['2020-09-02 22:30', 24.76],
  ['2020-09-02 22:40', 24.88],
  ['2020-09-02 22:50', 25.38],
  ['2020-09-02 23:00', 25.76],
  ['2020-09-02 23:10', 25.96],
  ['2020-09-02 23:20', 26],
  ['2020-09-02 23:30', 26.25],
  ['2020-09-02 23:40', 25.89],
  ['2020-09-02 23:50', 25.69],
  ['2020-09-03 00:00', 25.55],
  ['2020-09-03 00:10', 25.7],
  ['2020-09-03 00:20', 26.83],
  ['2020-09-03 00:30', 27.59],
  ['2020-09-03 00:40', 27.96],
  ['2020-09-03 00:50', 28.43],
  ['2020-09-03 01:00', 28.54],
  ['2020-09-03 01:10', 29.09],
  ['2020-09-03 01:20', 29.82],
  ['2020-09-03 01:30', 29.85],
  ['2020-09-03 01:40', 31.11],
  ['2020-09-03 01:50', 32.39],
  ['2020-09-03 02:00', 34.09],
  ['2020-09-03 02:10', 35.15],
  ['2020-09-03 02:20', 35.93],
  ['2020-09-03 02:30', 36.55],
  ['2020-09-03 02:40', 36.9],
  ['2020-09-03 02:50', 37.81],
  ['2020-09-03 03:00', 38.51],
  ['2020-09-03 03:10', 39.74],
  ['2020-09-03 03:20', 39.66],
  ['2020-09-03 03:30', 39.48],
  ['2020-09-03 03:40', 39.36],
  ['2020-09-03 03:50', 39.31],
  ['2020-09-03 04:00', 39.29],
  ['2020-09-03 04:10', 38.83],
  ['2020-09-03 04:20', 38.25],
  ['2020-09-03 04:30', 37.55],
  ['2020-09-03 04:40', 36.83],
  ['2020-09-03 04:50', 36.18],
  ['2020-09-03 05:00', 35.69],
  ['2020-09-03 05:10', 35.28],
  ['2020-09-03 05:20', 34.13],
  ['2020-09-03 05:30', 33.59],
  ['2020-09-03 05:40', 32.49],
  ['2020-09-03 05:50', 31.49],
  ['2020-09-03 06:00', 30.6],
  ['2020-09-03 06:10', 29.53],
  ['2020-09-03 06:20', 28.87],
  ['2020-09-03 06:30', 28.74],
  ['2020-09-03 06:40', 28.72],
  ['2020-09-03 06:50', 27.86],
  ['2020-09-03 07:00', 27.52],
  ['2020-09-03 07:10', 27.31],
  ['2020-09-03 07:20', 26.85],
  ['2020-09-03 07:30', 27.08],
  ['2020-09-03 07:40', 26.92],
  ['2020-09-03 07:50', 26.27],
  ['2020-09-03 08:00', 27.2],
  ['2020-09-03 08:10', 26.54],
  ['2020-09-03 08:20', 26.91],
  ['2020-09-03 08:30', 26.44],
  ['2020-09-03 08:40', 26.73],
  ['2020-09-03 08:50', 26.59],
  ['2020-09-03 09:00', 25.87],
  ['2020-09-03 09:10', 25.34],
  ['2020-09-03 09:20', 25.03],
  ['2020-09-03 09:30', 25.08],
  ['2020-09-03 09:40', 24.83],
  ['2020-09-03 09:50', 23.48],
  ['2020-09-03 10:00', 24.13],
  ['2020-09-03 10:10', 23.55],
  ['2020-09-03 10:20', 23.39],
  ['2020-09-03 10:30', 22.94],
  ['2020-09-03 10:40', 22.25],
  ['2020-09-03 10:50', 21.75],
  ['2020-09-03 11:00', 21.48],
  ['2020-09-03 11:10', 21.46],
  ['2020-09-03 11:20', 21.24],
  ['2020-09-03 11:30', 20.82],
  ['2020-09-03 11:40', 20.28],
  ['2020-09-03 11:50', 20.05],
  ['2020-09-03 12:00', 19.41],
  ['2020-09-03 12:10', 18.98],
  ['2020-09-03 12:20', 19.2],
  ['2020-09-03 12:30', 19.71],
  ['2020-09-03 12:40', 19.34],
  ['2020-09-03 12:50', 19.38],
  ['2020-09-03 13:00', 19.83],
  ['2020-09-03 13:10', 18.79],
  ['2020-09-03 13:20', 19.22],
  ['2020-09-03 13:30', 19.42],
  ['2020-09-03 13:40', 19.22],
  ['2020-09-03 13:50', 18.96],
  ['2020-09-03 14:00', 19.15],
  ['2020-09-03 14:10', 18.89],
  ['2020-09-03 14:20', 18.4],
  ['2020-09-03 14:30', 19.15],
  ['2020-09-03 14:40', 18.37],
  ['2020-09-03 14:50', 18.46],
  ['2020-09-03 15:00', 18.42],
  ['2020-09-03 15:10', 18.43],
  ['2020-09-03 15:20', 18.15],
  ['2020-09-03 15:30', 18],
  ['2020-09-03 15:40', 17.84],
  ['2020-09-03 15:50', 17.62],
  ['2020-09-03 16:00', 17.74],
  ['2020-09-03 16:10', 16.76],
  ['2020-09-03 16:20', 17.25],
  ['2020-09-03 16:30', 17.73],
  ['2020-09-03 16:40', 16.42],
  ['2020-09-03 16:50', 17.46],
  ['2020-09-03 17:00', 16.62],
  ['2020-09-03 17:10', 17.1],
  ['2020-09-03 17:20', 16.39],
  ['2020-09-03 17:30', 17.24],
  ['2020-09-03 17:40', 17.45],
  ['2020-09-03 17:50', 16.95],
  ['2020-09-03 18:00', 16.66],
  ['2020-09-03 18:10', 16.9],
  ['2020-09-03 18:20', 16.37],
  ['2020-09-03 18:30', 16.56],
  ['2020-09-03 18:40', 16.58],
  ['2020-09-03 18:50', 15.5],
  ['2020-09-03 19:00', 15.22],
  ['2020-09-03 19:10', 15.47],
  ['2020-09-03 19:20', 14.92],
  ['2020-09-03 19:30', 15.3],
  ['2020-09-03 19:40', 15.19],
  ['2020-09-03 19:50', 14.62],
  ['2020-09-03 20:00', 14.83],
  ['2020-09-03 20:10', 13.87],
  ['2020-09-03 20:20', 13.88],
  ['2020-09-03 20:30', 13.93],
  ['2020-09-03 20:40', 13.4],
  ['2020-09-03 20:50', 13.17],
  ['2020-09-03 21:00', 13.34],
  ['2020-09-03 21:10', 12.99],
  ['2020-09-03 21:20', 13.04],
  ['2020-09-03 21:30', 12.73],
  ['2020-09-03 21:40', 12.42],
  ['2020-09-03 21:50', 12.4],
  ['2020-09-03 22:00', 12.19],
  ['2020-09-03 22:10', 12.27],
  ['2020-09-03 22:20', 12.38],
  ['2020-09-03 22:30', 11.8],
  ['2020-09-03 22:40', 11.81],
  ['2020-09-03 22:50', 12.06],
  ['2020-09-03 23:00', 11.94],
  ['2020-09-03 23:10', 12.06],
  ['2020-09-03 23:20', 12.23],
  ['2020-09-03 23:30', 12.45],
  ['2020-09-03 23:40', 12.12],
  ['2020-09-03 23:50', 11.7],
  ['2020-09-04 00:00', 12.26],
  ['2020-09-04 00:10', 11.77],
  ['2020-09-04 00:20', 11.88],
  ['2020-09-04 00:30', 11.96],
  ['2020-09-04 00:40', 11.59],
  ['2020-09-04 00:50', 11.61],
  ['2020-09-04 01:00', 11.77],
  ['2020-09-04 01:10', 11.36],
  ['2020-09-04 01:20', 11.39],
  ['2020-09-04 01:30', 11.46],
  ['2020-09-04 01:40', 10.81],
  ['2020-09-04 01:50', 11.27],
  ['2020-09-04 02:00', 11.88],
  ['2020-09-04 02:10', 11.74],
  ['2020-09-04 02:20', 11.85],
  ['2020-09-04 02:30', 11.98],
  ['2020-09-04 02:40', 11.66],
  ['2020-09-04 02:50', 11.16],
  ['2020-09-04 03:00', 10.61],
  ['2020-09-04 03:10', 9.84],
  ['2020-09-04 03:20', 9.33],
  ['2020-09-04 03:30', 9.02],
  ['2020-09-04 03:40', 10.13],
  ['2020-09-04 03:50', 10.39],
  ['2020-09-04 04:00', 10.05],
  ['2020-09-04 04:10', 9.37],
  ['2020-09-04 04:20', 9.05],
  ['2020-09-04 04:30', 9.17],
  ['2020-09-04 04:40', 9.01],
  ['2020-09-04 04:50', 9.09],
  ['2020-09-04 05:00', 9.36],
  ['2020-09-04 05:10', 8.53],
  ['2020-09-04 05:20', 8.56],
  ['2020-09-04 05:30', 8.14],
  ['2020-09-04 05:40', 8.05],
  ['2020-09-04 05:50', 8.07],
  ['2020-09-04 06:00', 7.73],
  ['2020-09-04 06:10', 7.68],
  ['2020-09-04 06:20', 7.88],
  ['2020-09-04 06:30', 7.68],
  ['2020-09-04 06:40', 7.6],
  ['2020-09-04 06:50', 7.54],
  ['2020-09-04 07:00', 7.58],
  ['2020-09-04 07:10', 7.61],
  ['2020-09-04 07:20', 7.42],
  ['2020-09-04 07:30', 7.7],
  ['2020-09-04 07:40', 7.38],
  ['2020-09-04 07:50', 7.88],
  ['2020-09-04 08:00', 7.47],
  ['2020-09-04 08:10', 7.16],
  ['2020-09-04 08:20', 7.02],
  ['2020-09-04 08:30', 6.94],
  ['2020-09-04 08:40', 6.97],
  ['2020-09-04 08:50', 7.18],
  ['2020-09-04 09:00', 6.78],
  ['2020-09-04 09:10', 6.47],
  ['2020-09-04 09:20', 6.38],
  ['2020-09-04 09:30', 6.48],
  ['2020-09-04 09:40', 5.83],
  ['2020-09-04 09:50', 6.05],
  ['2020-09-04 10:00', 5.94],
  ['2020-09-04 10:10', 5.7],
  ['2020-09-04 10:20', 5.43],
  ['2020-09-04 10:30', 5.35],
  ['2020-09-04 10:40', 5.54],
  ['2020-09-04 10:50', 5.49],
  ['2020-09-04 11:00', 5.38],
  ['2020-09-04 11:10', 5.19],
  ['2020-09-04 11:20', 5.62],
  ['2020-09-04 11:30', 5.01],
  ['2020-09-04 11:40', 5.3],
  ['2020-09-04 11:50', 5.19],
  ['2020-09-04 12:00', 4.66],
  ['2020-09-04 12:10', 4.67],
  ['2020-09-04 12:20', 4.64],
  ['2020-09-04 12:30', 4.51],
  ['2020-09-04 12:40', 4.24],
  ['2020-09-04 12:50', 4.43],
  ['2020-09-04 13:00', 4.12],
  ['2020-09-04 13:10', 4.38],
  ['2020-09-04 13:20', 4.31],
  ['2020-09-04 13:30', 3.8],
  ['2020-09-04 13:40', 4.3],
  ['2020-09-04 13:50', 4.2],
  ['2020-09-04 14:00', 4.1],
  ['2020-09-04 14:10', 4.08],
  ['2020-09-04 14:20', 4.12],
  ['2020-09-04 14:30', 4.38],
  ['2020-09-04 14:40', 3.97],
  ['2020-09-04 14:50', 4.07],
  ['2020-09-04 15:00', 3.9],
  ['2020-09-04 15:10', 4.12],
  ['2020-09-04 15:20', 4.11],
  ['2020-09-04 15:30', 4.24],
  ['2020-09-04 15:40', 4.12],
  ['2020-09-04 15:50', 3.78],
  ['2020-09-04 16:00', 3.64],
  ['2020-09-04 16:10', 3.96],
  ['2020-09-04 16:20', 3.6],
  ['2020-09-04 16:30', 3.37],
  ['2020-09-04 16:40', 3.58],
  ['2020-09-04 16:50', 3.79],
  ['2020-09-04 17:00', 3.08],
  ['2020-09-04 17:10', 2.71],
  ['2020-09-04 17:20', 4.15],
  ['2020-09-04 17:30', 3.7],
  ['2020-09-04 17:40', 3.25],
  ['2020-09-04 17:50', 3.81],
  ['2020-09-04 18:00', 3.9],
  ['2020-09-04 18:10', 3.53],
  ['2020-09-04 18:20', 3.55],
  ['2020-09-04 18:30', 3.63],
  ['2020-09-04 18:40', 3.25],
  ['2020-09-04 18:50', 2.85],
  ['2020-09-04 19:00', 2.92],
  ['2020-09-04 19:10', 2.6],
  ['2020-09-04 19:20', 2.6],
  ['2020-09-04 19:30', 2.62],
  ['2020-09-04 19:40', 2.56],
  ['2020-09-04 19:50', 2.63],
  ['2020-09-04 20:00', 2.36],
  ['2020-09-04 20:10', 2.15],
  ['2020-09-04 20:20', 1.73],
  ['2020-09-04 20:30', 1.75],
  ['2020-09-04 20:40', 1.48],
  ['2020-09-04 20:50', 1.33],
  ['2020-09-04 21:00', 1.49],
  ['2020-09-04 21:10', 1.25],
  ['2020-09-04 21:20', 0.82],
  ['2020-09-04 21:30', 0.72],
  ['2020-09-04 21:40', 0.66],
  ['2020-09-04 21:50', 0.62],
  ['2020-09-04 22:00', 0.38],
  ['2020-09-04 22:10', 0.39],
  ['2020-09-04 22:20', 0.39],
  ['2020-09-04 22:30', 0.52],
  ['2020-09-04 22:40', 0.73],
  ['2020-09-04 22:50', 1.11],
  ['2020-09-04 23:00', 1.77],
  ['2020-09-04 23:10', 1.76],
  ['2020-09-04 23:20', 1.56],
  ['2020-09-04 23:30', 1.53],
  ['2020-09-04 23:40', 2.22],
  ['2020-09-04 23:50', 2.66],
  ['2020-09-05 00:00', 2.72],
  ['2020-09-05 00:10', 2.08],
  ['2020-09-05 00:20', 2.21],
  ['2020-09-05 00:30', 2.14],
  ['2020-09-05 00:40', 2.42],
  ['2020-09-05 00:50', 2.86],
  ['2020-09-05 01:00', 3.24],
  ['2020-09-05 01:10', 3.67],
  ['2020-09-05 01:20', 3.29],
  ['2020-09-05 01:30', 3.36],
  ['2020-09-05 01:40', 3.51],
  ['2020-09-05 01:50', 3.69],
  ['2020-09-05 02:00', 3.69],
  ['2020-09-05 02:10', 3.9],
  ['2020-09-05 02:20', 3.92],
  ['2020-09-05 02:30', 3.26],
  ['2020-09-05 02:40', 2.76],
  ['2020-09-05 02:50', 3.59],
  ['2020-09-05 03:00', 4.07],
  ['2020-09-05 03:10', 3.9],
  ['2020-09-05 03:20', 4.11],
  ['2020-09-05 03:30', 4.17],
  ['2020-09-05 03:40', 4.37],
  ['2020-09-05 03:50', 4.42],
  ['2020-09-05 04:00', 4.75],
  ['2020-09-05 04:10', 4.99],
  ['2020-09-05 04:20', 5.11],
  ['2020-09-05 04:30', 5.32],
  ['2020-09-05 04:40', 5.16],
  ['2020-09-05 04:50', 6.21],
  ['2020-09-05 05:00', 6.42],
  ['2020-09-05 05:10', 6.78],
  ['2020-09-05 05:20', 6.99],
  ['2020-09-05 05:30', 6.26],
  ['2020-09-05 05:40', 5.89],
  ['2020-09-05 05:50', 5.57],
  ['2020-09-05 06:00', 5.21],
  ['2020-09-05 06:10', 5.67],
  ['2020-09-05 06:20', 5.49],
  ['2020-09-05 06:30', 5.27],
  ['2020-09-05 06:40', 5],
  ['2020-09-05 06:50', 5.13],
  ['2020-09-05 07:00', 5.62],
  ['2020-09-05 07:10', 6.4],
  ['2020-09-05 07:20', 6.11],
  ['2020-09-05 07:30', 6.4],
  ['2020-09-05 07:40', 5.92],
  ['2020-09-05 07:50', 5.34],
  ['2020-09-05 08:00', 4.97],
  ['2020-09-05 08:10', 5.69],
  ['2020-09-05 08:20', 5.58],
  ['2020-09-05 08:30', 5.05],
  ['2020-09-05 08:40', 4.87],
  ['2020-09-05 08:50', 6.05],
  ['2020-09-05 09:00', 3.96],
  ['2020-09-05 09:10', 3.72],
  ['2020-09-05 09:20', 4.46],
  ['2020-09-05 09:30', 4.15],
  ['2020-09-05 09:40', 3.82],
  ['2020-09-05 09:50', 3.58],
  ['2020-09-05 10:00', 3.1],
  ['2020-09-05 10:10', 3.34],
  ['2020-09-05 10:20', 2.71],
  ['2020-09-05 10:30', 3.04],
  ['2020-09-05 10:40', 3.94],
  ['2020-09-05 10:50', 3.36],
  ['2020-09-05 11:00', 4.63],
  ['2020-09-05 11:10', 5.51],
  ['2020-09-05 11:20', 5.45],
  ['2020-09-05 11:30', 5.65],
  ['2020-09-05 11:40', 5.66],
  ['2020-09-05 11:50', 8.18],
  ['2020-09-05 12:00', 7.63],
  ['2020-09-05 12:10', 7.13],
  ['2020-09-05 12:20', 6.74],
  ['2020-09-05 12:30', 7.05],
  ['2020-09-05 12:40', 7.4],
  ['2020-09-05 12:50', 7.59],
  ['2020-09-05 13:00', 7.49],
  ['2020-09-05 13:10', 7.57],
  ['2020-09-05 13:20', 7.61],
  ['2020-09-05 13:30', 7.92],
  ['2020-09-05 13:40', 7.92],
  ['2020-09-05 13:50', 8.12],
  ['2020-09-05 14:00', 8.24],
  ['2020-09-05 14:10', 8.19],
  ['2020-09-05 14:20', 8.44],
  ['2020-09-05 14:30', 8.54],
  ['2020-09-05 14:40', 8.63],
  ['2020-09-05 14:50', 8.38],
  ['2020-09-05 15:00', 8.66],
  ['2020-09-05 15:10', 8.91],
  ['2020-09-05 15:20', 9.21],
  ['2020-09-05 15:30', 9.51],
  ['2020-09-05 15:40', 9.58],
  ['2020-09-05 15:50', 9.75],
  ['2020-09-05 16:00', 10.15],
  ['2020-09-05 16:10', 10.08],
  ['2020-09-05 16:20', 11.11],
  ['2020-09-05 16:30', 10.85],
  ['2020-09-05 16:40', 11.58],
  ['2020-09-05 16:50', 11.87],
  ['2020-09-05 17:00', 12.48],
  ['2020-09-05 17:10', 12.34],
  ['2020-09-05 17:20', 12.57],
  ['2020-09-05 17:30', 12.46],
  ['2020-09-05 17:40', 12.08],
  ['2020-09-05 17:50', 12.67],
  ['2020-09-05 18:00', 12.86],
  ['2020-09-05 18:10', 12.84],
  ['2020-09-05 18:20', 12.45],
  ['2020-09-05 18:30', 12.73],
  ['2020-09-05 18:40', 12.79],
  ['2020-09-05 18:50', 12.18],
  ['2020-09-05 19:00', 12.02],
  ['2020-09-05 19:10', 12.37],
  ['2020-09-05 19:20', 12.02],
  ['2020-09-05 19:30', 12.2],
  ['2020-09-05 19:40', 12.36],
  ['2020-09-05 19:50', 12.42],
  ['2020-09-05 20:00', 11.69],
  ['2020-09-05 20:10', 11.84],
  ['2020-09-05 20:20', 11.86],
  ['2020-09-05 20:30', 11.57],
  ['2020-09-05 20:40', 11.5],
  ['2020-09-05 20:50', 11.8],
  ['2020-09-05 21:00', 11.5],
  ['2020-09-05 21:10', 11.17],
  ['2020-09-05 21:20', 10.84],
  ['2020-09-05 21:30', 11.47],
  ['2020-09-05 21:40', 11.23],
  ['2020-09-05 21:50', 11.63],
  ['2020-09-05 22:00', 11.64],
  ['2020-09-05 22:10', 12.28],
  ['2020-09-05 22:20', 11.74],
  ['2020-09-05 22:30', 12.26],
  ['2020-09-05 22:40', 12.33],
  ['2020-09-05 22:50', 12.32],
  ['2020-09-05 23:00', 12.43],
  ['2020-09-05 23:10', 12.54],
  ['2020-09-05 23:20', 12.32],
  ['2020-09-05 23:30', 12.52],
  ['2020-09-05 23:40', 12.41],
  ['2020-09-05 23:50', 12.02],
];

export { WIND1, WIND2, WIND3, WIND4, WIND5 };
