import * as echarts from 'echarts';

const GAP = 1000 * 60 * 10;
const COUNT = 75;
const BASE_TIME = 1596207600000;

const timestamp = (d: Date): string => {
  function pad(n) {
    return n < 10 ? '0' + n : n;
  }
  return (
    d.getFullYear() +
    '-' +
    pad(d.getMonth() + 1) +
    '-' +
    pad(d.getDate()) +
    ' ' +
    pad(d.getHours()) +
    ':' +
    pad(d.getMinutes()) +
    ':' +
    pad(d.getSeconds())
  );
};

const initChartOption = (wind_option, power_option, wind_data, power_data) => {
  for (let idx = 0; idx < COUNT; idx++) {
    const date = new Date(BASE_TIME + GAP * idx);

    wind_option.series[0].data.push({
      name: date.toString(),
      value: [timestamp(date), wind_data[idx]],
    });

    power_option.series[0].data.push({
      name: date.toString(),
      value: [timestamp(date), power_data[idx] / 1000],
    });
  }
};

const getWindChartOption = () => {
  return {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const param = params[0];
        const date = new Date(param.name);
        return timestamp(date) + ' : ' + param.value[1];
      },
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
      splitLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 8,
      },
      axisTick: {
        show: true,
        inside: true,
        alignWithLabel: true,
      },
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '5%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 8,
      },
      axisTick: {
        show: true,
        inside: true,
        alignWithLabel: true,
      },
    },
    series: [
      {
        name: '풍속(m/s)',
        type: 'line',
        showSymbol: false,
        data: [],
        zlevel: 9,
        z: 9,
      },
    ],
  };
};
const getPowerChartOption = () => {
  return {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const param = params[0];
        const date = new Date(param.name);
        return timestamp(date) + ' : ' + param.value[1];
      },
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
      splitLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 8,
      },
      axisTick: {
        show: true,
        inside: true,
        alignWithLabel: true,
      },
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '5%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 8,
      },
      axisTick: {
        show: true,
        inside: true,
        alignWithLabel: true,
      },
    },
    series: [
      {
        name: '발전량(wh)',
        type: 'line',
        showSymbol: false,
        data: [],
        zlevel: 9,
        z: 9,
      },
    ],
  };
};

const initChart = (chart, domWind, domPower): void => {
  chart.wind.chart = echarts.init(domWind);
  chart.wind.chart.setOption(chart.wind.option);
  chart.power.chart = echarts.init(domPower);
  chart.power.chart.setOption(chart.power.option);
};

export {
  COUNT,
  GAP,
  BASE_TIME,
  timestamp,
  initChartOption,
  initChart,
  getWindChartOption,
  getPowerChartOption,
};
