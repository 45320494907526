
import Vue from 'vue';
import axios from 'axios';
import SellerClient from '@/view/vcm/SellerPage.vue';
import BuyerClient from '@/view/vcm/BuyerPage.vue';
import OrderBook from '@/components/vcm/TheOrderBook.vue';
import { CM_WS_URL } from '@/js/serverProtocol';
import {
  INIT_WEB,
  MSG_USER,
  MSG_ORDERBOOK,
  MSG_TRX_LOG,
  MSG_BANK,
  MSG_BANK_TRX,
  MSG_CAMPAIGN,
  MSG_CAMPAIGN_LOG,
} from '@/js/types/cmWsProtocol';
import io from 'socket.io-client';
import {
  UserInfo,
  Orderbook,
  OrderbookTrx,
  BankInfo,
  MsgBankTrx,
  Campaign,
} from '@/js/types';

export default Vue.extend({
  components: { SellerClient, BuyerClient, OrderBook },
  data() {
    return {
      socket: null,
      sellerHeader: {
        userId: 'test_account 01',
        typeImg: require('@/assets/images/vcm/header/sellerTitle.png'),
        decoImg: require('@/assets/images/vcm/header/titleDecoBar.png'),
        summary: {
          userType: 'Sell',
        },
      },
      buyerHeader: {
        userId: 'test_account 02',
        typeImg: require('@/assets/images/vcm/header/buyerTitle.png'),
        decoImg: require('@/assets/images/vcm/header/titleDecoBar2.png'),
        summary: {
          userType: 'Buy',
        },
      },
      sellerNav: {
        navTitle: 'Credit list',
      },
      BuyerNav: {
        navTitle: 'Emissions trade',
      },
      sellerUserInfo: undefined,
      buyerUserInfo: undefined,
      offsetTitle: {
        type: 1,
        titleBg: require('@/assets/images/vcm/orderbook/offset-title.png'),
      },
      creditTitle: {
        type: 0,
        titleBg: require('@/assets/images/vcm/orderbook/credit-title.png'),
      },
      orderBookOffset: undefined,
      orderBookCredit: undefined,
      trxLog: [],
      bank: {
        fund: '-',
        ecocredit: '-',
      },
      bankLog: [],
      campaign: {
        ecocredit: '-',
        offset: '-',
      },
      campaignLog: [],
    };
  },
  created() {
    this.socket = io(CM_WS_URL, {
      withCredentials: false,
    });
  },
  mounted() {
    this.socket.on('connect', () => {
      this.socket.emit(INIT_WEB);

      this.socket.on(MSG_USER, (msg: UserInfo) => {
        if (msg.uid == 1) {
          this.sellerUserInfo = msg;
          this.$store.commit('sellerInfoComplete', true);
        } else if (msg.uid == 2) {
          this.buyerUserInfo = msg;
          this.$store.commit('buyerInfoComplete', true);
        }
      });

      this.socket.on(MSG_ORDERBOOK, (msg: Orderbook) => {
        this.orderBookOffset = msg.offset;
        this.orderBookCredit = msg.credit;
        this.$refs.sellerClient.printAssetList();
        this.$refs.sellerClient.printTransactionList();
        this.$refs.buyerClient.printOrderList();
        this.$refs.buyerClient.printTransactionList();
        this.$store.commit('orderbookComplete', true);
      });

      this.socket.on(MSG_TRX_LOG, (msg: OrderbookTrx) => {
        const trxs = {
          id: msg.id,
          buyer: msg.buyer,
          buy: msg.buy,
          seller: msg.seller,
          sell: msg.sell,
          issued: this.$moment.unix(msg.issued).format('DD.MM.YYYY'),
          volume: msg.volume / 1000000,
          exp: this.$moment.unix(msg.exp).format('DD.MM.YYYY'),
          fill: msg.fill / 1000000,
          price: msg.price,
        };

        if (this.trxLog.length > 4) {
          this.trxLog.shift();
        }
        this.trxLog.push(trxs);
      });

      this.socket.on(MSG_BANK, (msg: BankInfo) => {
        this.bank.fund = this.changeNumberFormat(msg.fund);
        this.bank.ecocredit = this.changeNumberFormat(msg.ecocredit);
      });

      this.socket.on(MSG_BANK_TRX, (msg: MsgBankTrx) => {
        const trxs = {
          uid: msg.uid,
          type: msg.type,
          requested: this.$moment.unix(msg.requested).format('DD.MM.YYYY'),
          amount: msg.amount,
        };

        if (this.bankLog.length > 3) {
          this.bankLog.shift();
        }
        this.bankLog.push(trxs);
      });

      this.socket.on(MSG_CAMPAIGN, (msg: Campaign) => {
        this.campaign.ecocredit = this.changeNumberFormat(msg.ecocredit);
        this.campaign.offset = this.changeNumberFormat(msg.offset / 1000000);
      });

      this.socket.on(MSG_CAMPAIGN_LOG, (msg) => {
        for (let i = msg.length - 1; i >= 0; i--) {
          const camp = {
            id: msg[i].id,
            name: msg[i].name,
            campaign: msg[i].campaign,
            issued: this.$moment.unix(msg[i].issued).format('DD.MM.YYYY'),
            site: msg[i].site,
            ec: msg[i].ec,
            carbon: msg[i].carbon,
          };

          if (this.campaignLog.length > 3) {
            this.campaignLog.shift();
          }
          this.campaignLog.push(camp);
        }
      });
    });
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  methods: {
    changeNumberFormat(value: number): string {
      return value
        .toString()
        .replace(
          /(\..*)$|(\d)(?=(\d{3})+(?!\d))/g,
          (digit, fract) => fract || digit + ','
        );
    },
    onClickResetBtn() {
      const options = { method: 'GET', url: `${CM_WS_URL}dump` };

      axios
        .request(options)
        .then((response) => {
          if (response) {
            location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
