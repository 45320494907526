
import Vue from 'vue';
import TheLogo from '@/components/plant/TheLogo.vue';
import TheNav from '@/components/plant/TheNav.vue';
import { WIND, POWER, DATE } from '@/js/plant/viewdetails/data';
import ECharts from 'vue-echarts';

export default Vue.extend({
  name: 'ViewDetails',
  components: { TheLogo, TheNav, ECharts },
  data() {
    return {
      profileID: 'plant3',
      tBody: {
        isPlant1Active: false,
        isPlant2Active: false,
        isPlant3Active: false,
      },
      week: 1,
      detailInfoSelectedDate: DATE[0],
      windOption: {
        title: {
          text: 'detail information wind chart',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
          },
        },
        xAxis: {
          type: 'time',
          axisLabel: {
            fontSize: 8,
            formatter: function (value) {
              var week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
              var d = new Date(value);
              return week[d.getDay()];
            },
          },
        },
        grid: { top: 10, right: 2, bottom: 15, left: 20 },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            name: 'Wind speed(m/s)',
            type: 'line',
            showSymbol: false,
            data: WIND[0],
            zlevel: 9,
            z: 9,
          },
        ],
      },
      powerOption: {
        title: {
          text: 'detail information power chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          axisLabel: {
            fontSize: 8,
          },
        },
        grid: { top: 10, right: 2, bottom: 15, left: 20 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: POWER[0],
            type: 'bar',
            universalTransition: {
              enabled: true,
              divideShape: 'clone',
            },
            zlevel: 9,
            z: 9,
          },
        ],
      },
    };
  },
  mounted() {
    this.onClickPlBodyRow(this.profileID);
  },
  methods: {
    onClickPlBodyRow(value: string) {
      if (value === 'plant1') {
        this.tBody.isPlant1Active = true;
        this.tBody.isPlant2Active = false;
        this.tBody.isPlant3Active = false;
      }

      if (value === 'plant2') {
        this.tBody.isPlant1Active = false;
        this.tBody.isPlant2Active = true;
        this.tBody.isPlant3Active = false;
      }

      if (value === 'plant3') {
        this.tBody.isPlant1Active = false;
        this.tBody.isPlant2Active = false;
        this.tBody.isPlant3Active = true;
      }
    },
    getSelectedClass(week: number) {
      return this.week === week ? `selected` : `unSelected`;
    },
    setSelectedWeek(week: number) {
      switch (week) {
        case 1:
          this.setChartOption(week);
          break;
        case 2:
          this.setChartOption(week);
          break;
        case 3:
          this.setChartOption(week);
          break;
        case 4:
          this.setChartOption(week);
          break;
        case 5:
          this.setChartOption(week);
          break;
      }
      return (this.week = week);
    },
    setChartOption(week: number) {
      this.windOption.series[0].data = WIND[week - 1];
      this.powerOption.series[0].data = POWER[week - 1];
      this.detailInfoSelectedDate = DATE[week - 1];
    },
  },
});
