
import Vue from 'vue';

export default Vue.extend({
  props: {
    headerValue: Object,
    userInfoValue: {
      type: Object,
      default: () => {
        return {
          uid: '-',
          asset: {
            credit: '-',
            offset: '-',
          },
          orderState: {
            sell: {
              volume: '-',
              number: '-',
            },
            buy: {
              volume: '-',
              number: '-',
            },
          },
          balance: {
            ecocredit: '-',
            currency: '-',
          },
        };
      },
    },
  },
  data() {
    return {
      userInfoData: this.userInfoValue,
    };
  },
  computed: {
    OrderVolume() {
      if (this.userInfoValue.uid == 1) {
        return `${this.numberFormat(
          this.userInfoValue.orderState.sell.volume / 1000000
        )}`;
      } else if (this.userInfoValue.uid == 2) {
        return `${this.numberFormat(
          this.userInfoValue.orderState.buy.volume / 1000000
        )}`;
      } else {
        return `-`;
      }
    },
    OrderNumber() {
      if (this.userInfoValue.uid == 1) {
        return `${this.userInfoValue.orderState.sell.number}`;
      } else if (this.userInfoValue.uid == 2) {
        return `${this.userInfoValue.orderState.buy.number}`;
      } else {
        return `-`;
      }
    },
  },
  methods: {
    numberFormat(value: number) {
      return value.toLocaleString('ko-KR');
    },
  },
});
