const WIND1 = [
  7.71, 4.84, 4.94, 6.74, 9.18, 6.7, 6.29, 7.56, 5.69, 6.7, 11.38, 12.69, 14.54,
  13.83, 13.22, 12.47, 12.63, 14.14, 13.67, 15.16, 14.47, 12.27, 9.15, 10.04,
  13.74, 13.13, 14.63, 12.97, 12.29, 14.31, 14.17, 13.42, 12.85, 13.91, 14.63,
  14.48, 12.34, 12.84, 16.1, 15.88, 15.59, 14.71, 14.87, 15.66, 16.63, 15.45,
  14.14, 12.87, 11.53, 12.85, 13.56, 13.9, 13.62, 11, 10.65, 10.45, 10.92, 9.68,
  9.49, 9.25, 8.91, 9.13, 11.05, 12.92, 15.77, 15.69, 15.14, 14.07, 14.5, 14.49,
  14.97, 13.84, 13.14, 14.11, 14.61, 11.46, 10.72, 13.59, 13.06, 13.45, 14.95,
  15.96, 15.45, 7.83, 7.07, 9.21, 12.92, 12.4, 12.66, 12.77, 13.29, 14.18,
  14.36, 14.69, 15.7, 15.24, 15.89, 15.74, 16.88, 15.91, 16.13, 15.02, 11.44,
  10.02, 9.01, 8, 9.96, 11.17, 11.34, 9.67, 11.5, 10.61, 10.07, 7.01, 11.14,
  12.53, 11.18, 12.35, 12.04, 10.14, 11.72, 13.36, 12.11, 12.02, 12.41, 10.63,
  8.71, 8.17, 7.48, 9.73, 10.59, 11.82, 12.67, 13.59, 14.41, 14.66, 14.54,
  14.73, 14.52, 14.2, 14.9, 15.62, 15.37, 15.61, 16.56, 17.28, 17.48, 16.51,
  16.23, 16.2, 16.74, 17.49, 18.15, 17.44, 17.82, 17.16, 16.38, 16.13, 17.38,
  17.57, 16.65, 16.43, 17.28, 17.29, 17.13, 16.72, 16.23, 16.02, 15.65, 15.68,
  15.43, 15.52, 16.64, 16.75, 17.03, 17.56, 17.93, 18.17, 17.64, 18.22, 17.54,
  16.71, 16.73, 15.81, 15.63, 15.68, 15.15, 15.12, 14.77, 14.68, 14.99, 14.74,
  15.39, 15.69, 15.47, 15.88, 15.73, 14.93, 14.16, 13.66, 13.57, 13.04, 13.45,
  14.67, 14.28, 14.05, 14.23, 12.77, 12.64, 12.68, 9.61, 12.81, 13.01, 13.22,
  14.28, 13.72, 13.86, 15.32, 16.72, 15.64, 14.61, 14.05, 14.51, 14.13, 14.99,
  14.46, 14.14, 12.99, 11.93, 12.78, 12.33, 11.72, 11.73, 12.44, 13.18, 14.34,
  14.17, 15.52, 15.71, 16.75, 17.16, 16.45, 15.74, 14.89, 16.5, 16.23, 14.77,
  14.32, 14.38, 15.28, 16.36, 15.85, 15.57, 15.27, 16.7, 15.79, 15.79, 15.59,
  14.57, 14.01, 13.56, 14.01, 16.07, 15.16, 15.73, 15.78, 17.16, 17.26, 15.9,
  15.1, 15.8, 14.62, 15.6, 15.79, 15.76, 16.37, 16.83, 15.65, 13.87, 15.98,
  15.77, 15.94, 16.09, 16.21, 16.84, 17.76, 18.07, 15.56,
];
const POWER1 = [
  1.6352e5, 35406, 38871, 1.0508e5, 2.7789e5, 1.0332e5, 85239, 1.5359e5, 62685,
  1.0332e5, 4.6406e5, 5.0009e5, 5.04e5, 5.04e5, 5.04e5, 4.9732e5, 4.9934e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.948e5, 2.7515e5, 3.5658e5, 5.04e5, 5.04e5,
  5.04e5, 5.0362e5, 4.9505e5, 5.04e5, 5.04e5, 5.04e5, 5.0211e5, 5.04e5, 5.04e5,
  5.04e5, 4.9568e5, 5.0198e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.0236e5, 4.7067e5, 5.0211e5, 5.04e5, 5.04e5, 5.04e5,
  4.473e5, 4.1423e5, 3.9532e5, 4.3974e5, 3.2357e5, 3.0621e5, 2.8429e5, 2.5436e5,
  2.7333e5, 4.4951e5, 5.0299e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.6759e5, 4.2084e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 1.7145e5, 1.2118e5, 2.8063e5, 5.0299e5,
  4.9644e5, 4.9972e5, 5.011e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.667e5, 3.5469e5, 2.6236e5,
  1.827e5, 3.4915e5, 4.548e5, 4.6229e5, 3.2265e5, 4.6935e5, 4.1045e5, 3.5942e5,
  1.1721e5, 4.5347e5, 4.9808e5, 4.5524e5, 4.9581e5, 4.919e5, 3.6603e5, 4.7905e5,
  5.04e5, 4.9279e5, 4.9165e5, 4.9657e5, 4.1234e5, 2.3861e5, 1.9609e5, 1.483e5,
  3.2814e5, 4.0856e5, 4.8346e5, 4.9984e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.011e5, 4.9946e5,
  4.9997e5, 3.1717e5, 5.0161e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.0387e5, 4.8831e5, 5.0123e5, 4.9556e5, 4.7905e5, 4.7949e5, 4.9694e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
];

export { WIND1, POWER1 };
