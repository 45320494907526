export const daily_emission_results = [
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 3532.2099999999996,
        CH4: 108.35000000000002,
        NMHC: 931.81,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8535638, CH4: 12154, NMHC: 10770 },
      Venting: { CO2: 0, CH4: 614640.0000000001, NMHC: 1552360.0000000002 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 83840.40000000001, CH4: 1890, NMHC: 5266.8 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8243430, CH4: 12059, NMHC: 10627 },
      Venting: { CO2: 0, CH4: 475200.00000000006, NMHC: 2044800 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 2113.02, CH4: 98.28, NMHC: 786.24 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7693828, CH4: 10957, NMHC: 9709 },
      Venting: { CO2: 0, CH4: 472500.00000000006, NMHC: 1984499.9999999998 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 203770, CH4: 5453.000000000001, NMHC: 13612 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8611814, CH4: 11790, NMHC: 10534 },
      Venting: { CO2: 0, CH4: 774900, NMHC: 3325099.9999999995 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 97414.56000000001,
        CH4: 2196,
        NMHC: 6119.5199999999995,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7693828, CH4: 10957, NMHC: 9709 },
      Venting: { CO2: 0, CH4: 494100.00000000006, NMHC: 2433900 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 2967.8599999999997,
        CH4: 138.04000000000002,
        NMHC: 1104.3200000000002,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8399056, CH4: 12061, NMHC: 10669 },
      Venting: { CO2: 0, CH4: 602910, NMHC: 2848090 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 157711.39999999997, CH4: 3469.4, NMHC: 9363.2 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7462026, CH4: 11319, NMHC: 9903 },
      Venting: { CO2: 0, CH4: 580800, NMHC: 3599199.9999999995 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 167920.56, CH4: 4332.96, NMHC: 8151.840000000001 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7793959, CH4: 11597, NMHC: 10185 },
      Venting: { CO2: 0, CH4: 428399.99999999994, NMHC: 3243600 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: null, CH4: null, NMHC: null },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7226950, CH4: 10951, NMHC: 9583 },
      Venting: { CO2: 0, CH4: 509600.00000000006, NMHC: 2822400 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 3833.7599999999993,
        CH4: 117.60000000000001,
        NMHC: 1011.36,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8186298, CH4: 12332, NMHC: 10804 },
      Venting: { CO2: 0, CH4: 517440, NMHC: 1834560.0000000002 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 126426.00000000001, CH4: 2850, NMHC: 7942 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7206269, CH4: 10677, NMHC: 9385 },
      Venting: { CO2: 0, CH4: 500000, NMHC: 3300000.0000000005 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 118325.40000000001,
        CH4: 2706.4000000000005,
        NMHC: 7920.2,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7600245, CH4: 11777, NMHC: 10261 },
      Venting: { CO2: 0, CH4: 696500, NMHC: 3283500.0000000005 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 211722, CH4: 5665.8, NMHC: 14143.199999999999 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8964428, CH4: 12342, NMHC: 11014 },
      Venting: { CO2: 0, CH4: 894600.0000000001, NMHC: 3365399.9999999995 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 2448, CH4: 81.6, NMHC: 713.9999999999999 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7790685, CH4: 10867, NMHC: 9671 },
      Venting: { CO2: 0, CH4: 709920, NMHC: 1330080.0000000002 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 74705.4, CH4: 1643.4, NMHC: 4435.200000000001 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7892453, CH4: 11872, NMHC: 10404 },
      Venting: { CO2: 0, CH4: 561600, NMHC: 1418400 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 2779.2, CH4: 92.64, NMHC: 810.5999999999999 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8278244, CH4: 11147, NMHC: 9995 },
      Venting: { CO2: 0, CH4: 463200.00000000006, NMHC: 1852799.9999999998 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 112624.04999999999,
        CH4: 2477.5499999999997,
        NMHC: 6686.400000000001,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7168181, CH4: 10859, NMHC: 9503 },
      Venting: { CO2: 0, CH4: 549240.0000000001, NMHC: 2435760 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 65541.90000000001,
        CH4: 1477.5,
        NMHC: 4117.299999999999,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7517521, CH4: 10681, NMHC: 9469 },
      Venting: { CO2: 0, CH4: 620549.9999999999, NMHC: 1349450.0000000002 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 122487.6, CH4: 2801.6000000000004, NMHC: 8198.8 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7966992, CH4: 11143, NMHC: 9911 },
      Venting: { CO2: 0, CH4: 498519.99999999994, NMHC: 3621480 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 132115.17, CH4: 2978.25, NMHC: 8299.39 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7674784, CH4: 11048, NMHC: 9768 },
      Venting: { CO2: 0, CH4: 643720, NMHC: 3327279.9999999995 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 6132.0599999999995, CH4: 188.1, NMHC: 1617.66 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7949585, CH4: 11599, NMHC: 10227 },
      Venting: { CO2: 0, CH4: 665279.9999999999, NMHC: 3096720.0000000005 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 93621.78, CH4: 2110.5, NMHC: 5881.259999999999 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 9042241, CH4: 12343, NMHC: 11035 },
      Venting: { CO2: 0, CH4: 506519.99999999994, NMHC: 2307480 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 183890, CH4: 4921, NMHC: 12283.999999999998 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7382576, CH4: 10953, NMHC: 9625 },
      Venting: { CO2: 0, CH4: 666000, NMHC: 3034000.0000000005 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 3276.3, CH4: 100.5, NMHC: 864.3 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7773278, CH4: 11323, NMHC: 9987 },
      Venting: { CO2: 0, CH4: 633150, NMHC: 1376850.0000000002 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 120104.7, CH4: 2707.5, NMHC: 7544.9 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8165617, CH4: 12058, NMHC: 10606 },
      Venting: { CO2: 0, CH4: 480700, NMHC: 3129299.9999999995 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 81608.85, CH4: 1866.6000000000001, NMHC: 5462.55 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7930541, CH4: 11690, NMHC: 10286 },
      Venting: { CO2: 0, CH4: 512399.99999999994, NMHC: 2232600 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: {
        CO2: 147509.60000000003,
        CH4: 3947.4400000000005,
        NMHC: 9853.759999999998,
      },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7130093, CH4: 11041, NMHC: 9621 },
      Venting: { CO2: 0, CH4: 606320, NMHC: 2361680 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 3202.64, CH4: 148.96, NMHC: 1191.68 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7870135, CH4: 11233, NMHC: 9949 },
      Venting: { CO2: 0, CH4: 764400, NMHC: 2959600 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 79149.33000000002, CH4: 1784.25, NMHC: 4972.11 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 8262474, CH4: 11968, NMHC: 10568 },
      Venting: { CO2: 0, CH4: 713700, NMHC: 1665300 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
  {
    exploration: {
      Combustion: { CO2: 3319330, CH4: 2780, NMHC: 2820 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    drilling_and_development: {
      Combustion: { CO2: 84239.64, CH4: 1899, NMHC: 5291.879999999999 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    production_and_extraction: {
      Combustion: { CO2: 7933815, CH4: 12420, NMHC: 10800 },
      Venting: { CO2: 0, CH4: 422000.00000000006, NMHC: 2110000 },
      Flaring: { CO2: 0, CH4: 137747.837388042, NMHC: 166118.51556989137 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
    crude_oil_storage: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 4.059492650482208, NMHC: 9.13385846358497 },
    },
    crude_oil_transport: {
      Combustion: { CO2: 0, CH4: 0, NMHC: 0 },
      Venting: { CO2: 0, CH4: 0, NMHC: 0 },
      Flaring: { CO2: 0, CH4: 0, NMHC: 0 },
      Fugitives: { CO2: 0, CH4: 0, NMHC: 0 },
    },
  },
];
