
import Vue from 'vue';

export default Vue.extend({
  props: {
    titleValue: Object,
    orderbookValue: {
      type: Object,
      default: () => {
        return {
          sell: [
            {
              exp: 1,
              price: 1,
              volume: 1,
            },
          ],
          buy: [
            {
              exp: 1,
              price: 1,
              volume: 1,
            },
          ],
          trx24h: {
            carbon: 1,
            ecocredit: 1,
          },
        };
      },
    },
  },
  data() {
    return {
      type: this.titleValue.type,
      loading: true,
      orderbookSellList: [],
      orderbookBuyList: [],
    };
  },
  mounted() {
    if (this.$store.state.orderbookComplete) {
      this.orderbookSellList = this.orderbookValue.sell;
      this.orderbookBuyList = this.orderbookValue.buy;
      this.isLoading();
    }
  },
  watch: {
    checkInfoComplete() {
      this.orderbookSellList = this.orderbookValue.sell;
      this.orderbookBuyList = this.orderbookValue.buy;
      this.isLoading();
    },
  },
  computed: {
    titleBox() {
      if (this.type == 1) {
        return 'background: #113184';
      } else {
        return 'background: #84113A';
      }
    },
    loadingStyle(): string {
      return this.loading ? `display: block` : `display: none`;
    },
    checkInfoComplete() {
      return this.$store.getters.getOrderbookComplete;
    },
    sellOrderPadding() {
      return (
        4 -
        (this.orderbookValue.sell.length < 4
          ? this.orderbookValue.sell.length
          : 4)
      );
    },
    buyOrderPadding() {
      return (
        4 -
        (this.orderbookValue.buy.length < 4
          ? this.orderbookValue.buy.length
          : 4)
      );
    },
  },
  methods: {
    isLoading(): void {
      this.loading = false;
    },
    changeDateFormat(value: number): number {
      const today = new Date().getTime();
      const currentTime = Math.floor(this.$moment(today).utc() / 1000);

      let exp = Math.ceil((value - currentTime) / 86400);

      if (exp <= 0) {
        return 0;
      } else {
        return exp;
      }
    },
    changeNumberFormat(value: number): string {
      if (value != null || value != undefined) {
        return value.toLocaleString('ko-KR');
      } else {
        return;
      }
    },
  },
});
