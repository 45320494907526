import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from '../view/LoginPage.vue';
import SelectPage from '../view/SelectPage.vue';
import NotFoundPage from '../view/NotFoundPage.vue';
import RE100OverviewPage from '../view/re100/OverviewPage.vue';
import RE100ViewdetailsPage from '../view/re100/ViewdetailsPage.vue';
import RE100StatisticsPage from '../view/re100/StatisticsPage.vue';
import RE100PpaPage from '../view/re100/PpaPage.vue';
import PlantOverviewPage from '../view/plant/OverviewPage.vue';
import PlantViewdetailsPage from '../view/plant/ViewdetailsPage.vue';
import PlantStatisticsPage from '../view/plant/StatisticsPage.vue';
import PlantReportbyPeriodPage from '../view/plant/ReportbyPeriodPage.vue';
import DashBoardPage from '../view/ghg/DashBoardPage.vue';
import CarbonMarketPage from '../view/vcm/CMSimulatorPage.vue';

Vue.use(VueRouter);

const requireAuth = () => (from, to, next) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const accessExp =
    sessionStorage.getItem('accessTokenExpired') === null
      ? 0
      : sessionStorage.getItem('accessTokenExpired');
  const unixTime = Math.floor(new Date().getTime() / 1000);
  const validTime = Number(accessExp) - unixTime;

  if (accessToken && validTime > 60) {
    return next();
  } else if (!(accessToken && validTime > 60)) {
    sessionStorage.clear();
    return next({ name: 'loginpage' });
  }
};

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: LoginPage,
      name: 'loginpage',
    },
    {
      path: '/apps',
      component: SelectPage,
      name: 'selectpage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/re100/overview',
      component: RE100OverviewPage,
      name: 're100overviewpage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/re100/viewdetails',
      component: RE100ViewdetailsPage,
      name: 're100viewdetailspage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/re100/statistics',
      component: RE100StatisticsPage,
      name: 're100statisticspage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/re100/ppa',
      component: RE100PpaPage,
      name: 're100ppapage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/plant/overview',
      component: PlantOverviewPage,
      name: 'plantoverviewpage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/plant/viewdetails',
      component: PlantViewdetailsPage,
      name: 'plantviewdetailspage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/plant/statistics',
      component: PlantStatisticsPage,
      name: 'plantstatisticspage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/plant/reportbyperiod',
      component: PlantReportbyPeriodPage,
      name: 'plantreportbyperiodpage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/ghg/dashboard',
      component: DashBoardPage,
      name: 'ghgdashboardpage',
      beforeEnter: requireAuth(),
    },
    {
      path: '/apps/carbonmarket',
      component: CarbonMarketPage,
      name: 'carbonmarketsimulatorpage',
      beforeEnter: requireAuth(),
    },
    { path: '*', component: NotFoundPage, name: 'notfoundpage' },
  ],
});
