
import Vue from 'vue';
import TheHeader from '@/components/ghg/TheHeader.vue';
import { daily_emission_results } from '@/js/ghg/daily_emission_results';
import ECharts from 'vue-echarts';

export default Vue.extend({
  components: { TheHeader, ECharts },
  data() {
    return {
      co2Summary: 0,
      ch4Summary: 0,
      nmhcSummary: 0,
      dailyData: daily_emission_results,
      processCo2option: {
        title: {
          text: 'co2',
          show: false,
        },
        color: [
          '#538EEA',
          '#F11F15',
          '#BD23FC',
          '#034BBF',
          '#FF73AE',
          '#000000',
        ],
        grid: { top: 20, right: 20, bottom: 24, left: 80 },
        xAxis: {
          type: 'category',
          data: [
            '2022-06-01',
            '2022-06-02',
            '2022-06-03',
            '2022-06-04',
            '2022-06-05',
            '2022-06-06',
            '2022-06-07',
            '2022-06-08',
            '2022-06-09',
            '2022-06-10',
            '2022-06-11',
            '2022-06-12',
            '2022-06-13',
            '2022-06-14',
            '2022-06-15',
            '2022-06-16',
            '2022-06-17',
            '2022-06-18',
            '2022-06-19',
            '2022-06-20',
            '2022-06-21',
            '2022-06-22',
            '2022-06-23',
            '2022-06-24',
            '2022-06-25',
            '2022-06-26',
            '2022-06-27',
            '2022-06-28',
            '2022-06-29',
            '2022-06-30',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Exploration',
            type: 'line',
            data: [],
          },
          {
            name: 'Drilling and development',
            type: 'line',
            data: [],
          },
          {
            name: 'Production and extraction',
            type: 'line',
            data: [],
          },
          {
            name: 'Crude oil storage',
            type: 'line',
            data: [],
          },
          {
            name: 'Crude oil transport',
            type: 'line',
            data: [],
          },
          {
            name: 'Total',
            type: 'line',
            data: [],
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      },
      processCh4option: {
        title: {
          text: 'ch4',
          show: false,
        },
        color: [
          '#538EEA',
          '#F11F15',
          '#BD23FC',
          '#034BBF',
          '#FF73AE',
          '#000000',
        ],
        grid: { top: 20, right: 20, bottom: 24, left: 75 },
        xAxis: {
          type: 'category',
          data: [
            '2022-06-01',
            '2022-06-02',
            '2022-06-03',
            '2022-06-04',
            '2022-06-05',
            '2022-06-06',
            '2022-06-07',
            '2022-06-08',
            '2022-06-09',
            '2022-06-10',
            '2022-06-11',
            '2022-06-12',
            '2022-06-13',
            '2022-06-14',
            '2022-06-15',
            '2022-06-16',
            '2022-06-17',
            '2022-06-18',
            '2022-06-19',
            '2022-06-20',
            '2022-06-21',
            '2022-06-22',
            '2022-06-23',
            '2022-06-24',
            '2022-06-25',
            '2022-06-26',
            '2022-06-27',
            '2022-06-28',
            '2022-06-29',
            '2022-06-30',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Exploration',
            type: 'line',
            data: [],
          },
          {
            name: 'Drilling and development',
            type: 'line',
            data: [],
          },
          {
            name: 'Production and extraction',
            type: 'line',
            data: [],
          },
          {
            name: 'Crude oil storage',
            type: 'line',
            data: [],
          },
          {
            name: 'Crude oil transport',
            type: 'line',
            data: [],
          },
          {
            name: 'Total',
            type: 'line',
            data: [],
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      },
      processNmhcoption: {
        title: {
          text: 'nmhc',
          show: false,
        },
        color: [
          '#538EEA',
          '#F11F15',
          '#BD23FC',
          '#034BBF',
          '#FF73AE',
          '#000000',
        ],
        grid: { top: 20, right: 20, bottom: 24, left: 75 },
        xAxis: {
          type: 'category',
          data: [
            '2022-06-01',
            '2022-06-02',
            '2022-06-03',
            '2022-06-04',
            '2022-06-05',
            '2022-06-06',
            '2022-06-07',
            '2022-06-08',
            '2022-06-09',
            '2022-06-10',
            '2022-06-11',
            '2022-06-12',
            '2022-06-13',
            '2022-06-14',
            '2022-06-15',
            '2022-06-16',
            '2022-06-17',
            '2022-06-18',
            '2022-06-19',
            '2022-06-20',
            '2022-06-21',
            '2022-06-22',
            '2022-06-23',
            '2022-06-24',
            '2022-06-25',
            '2022-06-26',
            '2022-06-27',
            '2022-06-28',
            '2022-06-29',
            '2022-06-30',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Exploration',
            type: 'line',
            data: [],
          },
          {
            name: 'Drilling and development',
            type: 'line',
            data: [],
          },
          {
            name: 'Production and extraction',
            type: 'line',
            data: [],
          },
          {
            name: 'Crude oil storage',
            type: 'line',
            data: [],
          },
          {
            name: 'Crude oil transport',
            type: 'line',
            data: [],
          },
          {
            name: 'Total',
            type: 'line',
            data: [],
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      },
      activityCo2option: {
        title: {
          text: 'co2',
          show: false,
        },
        color: ['#DE1F1A', '#3031EC', '#920FF0', '#0992C9', '#000000'],
        grid: { top: 20, right: 20, bottom: 24, left: 80 },
        xAxis: {
          type: 'category',
          data: [
            '2022-06-01',
            '2022-06-02',
            '2022-06-03',
            '2022-06-04',
            '2022-06-05',
            '2022-06-06',
            '2022-06-07',
            '2022-06-08',
            '2022-06-09',
            '2022-06-10',
            '2022-06-11',
            '2022-06-12',
            '2022-06-13',
            '2022-06-14',
            '2022-06-15',
            '2022-06-16',
            '2022-06-17',
            '2022-06-18',
            '2022-06-19',
            '2022-06-20',
            '2022-06-21',
            '2022-06-22',
            '2022-06-23',
            '2022-06-24',
            '2022-06-25',
            '2022-06-26',
            '2022-06-27',
            '2022-06-28',
            '2022-06-29',
            '2022-06-30',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Combustion',
            type: 'line',
            data: [],
          },
          {
            name: 'Venting',
            type: 'line',
            data: [],
          },
          {
            name: 'Flaring',
            type: 'line',
            data: [],
          },
          {
            name: 'Fugitives',
            type: 'line',
            data: [],
          },
          {
            name: 'Total',
            type: 'line',
            data: [],
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      },
      activityCh4option: {
        title: {
          text: 'ch4',
          show: false,
        },
        color: ['#DE1F1A', '#3031EC', '#920FF0', '#0992C9', '#000000'],
        grid: { top: 20, right: 20, bottom: 24, left: 75 },
        xAxis: {
          type: 'category',
          data: [
            '2022-06-01',
            '2022-06-02',
            '2022-06-03',
            '2022-06-04',
            '2022-06-05',
            '2022-06-06',
            '2022-06-07',
            '2022-06-08',
            '2022-06-09',
            '2022-06-10',
            '2022-06-11',
            '2022-06-12',
            '2022-06-13',
            '2022-06-14',
            '2022-06-15',
            '2022-06-16',
            '2022-06-17',
            '2022-06-18',
            '2022-06-19',
            '2022-06-20',
            '2022-06-21',
            '2022-06-22',
            '2022-06-23',
            '2022-06-24',
            '2022-06-25',
            '2022-06-26',
            '2022-06-27',
            '2022-06-28',
            '2022-06-29',
            '2022-06-30',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Combustion',
            type: 'line',
            data: [],
          },
          {
            name: 'Venting',
            type: 'line',
            data: [],
          },
          {
            name: 'Flaring',
            type: 'line',
            data: [],
          },
          {
            name: 'Fugitives',
            type: 'line',
            data: [],
          },
          {
            name: 'Total',
            type: 'line',
            data: [],
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      },
      activityNmhcoption: {
        title: {
          text: 'nmhc',
          show: false,
        },
        color: ['#DE1F1A', '#3031EC', '#920FF0', '#0992C9', '#000000'],
        grid: { top: 20, right: 20, bottom: 24, left: 75 },
        xAxis: {
          type: 'category',
          data: [
            '2022-06-01',
            '2022-06-02',
            '2022-06-03',
            '2022-06-04',
            '2022-06-05',
            '2022-06-06',
            '2022-06-07',
            '2022-06-08',
            '2022-06-09',
            '2022-06-10',
            '2022-06-11',
            '2022-06-12',
            '2022-06-13',
            '2022-06-14',
            '2022-06-15',
            '2022-06-16',
            '2022-06-17',
            '2022-06-18',
            '2022-06-19',
            '2022-06-20',
            '2022-06-21',
            '2022-06-22',
            '2022-06-23',
            '2022-06-24',
            '2022-06-25',
            '2022-06-26',
            '2022-06-27',
            '2022-06-28',
            '2022-06-29',
            '2022-06-30',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Combustion',
            type: 'line',
            data: [],
          },
          {
            name: 'Venting',
            type: 'line',
            data: [],
          },
          {
            name: 'Flaring',
            type: 'line',
            data: [],
          },
          {
            name: 'Fugitives',
            type: 'line',
            data: [],
          },
          {
            name: 'Total',
            type: 'line',
            data: [],
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      },
      proccessModalCo2: false,
      proccessModalCh4: false,
      proccessModalNmhc: false,
      activityModalCo2: false,
      activityModalCh4: false,
      activityModalNmhc: false,
      modalDate: '',
      processCo2Modal: {
        total: {
          value: {
            exploration: 0,
            drilling: 0,
            production: 0,
            storage: 0,
            transport: 0,
          },
          percent: {
            exploration: 0,
            drilling: 0,
            production: 0,
            storage: 0,
            transport: 0,
          },
        },
        detail: {
          exploration: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          drilling: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          production: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          storage: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          transport: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
        },
      },
      processCh4Modal: {
        total: {
          value: {
            exploration: 0,
            drilling: 0,
            production: 0,
            storage: 0,
            transport: 0,
          },
          percent: {
            exploration: 0,
            drilling: 0,
            production: 0,
            storage: 0,
            transport: 0,
          },
        },
        detail: {
          exploration: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          drilling: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          production: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          storage: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          transport: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
        },
      },
      processNmhcModal: {
        total: {
          value: {
            exploration: 0,
            drilling: 0,
            production: 0,
            storage: 0,
            transport: 0,
          },
          percent: {
            exploration: 0,
            drilling: 0,
            production: 0,
            storage: 0,
            transport: 0,
          },
        },
        detail: {
          exploration: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          drilling: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          production: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          storage: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
          transport: {
            value: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
              total: 0,
            },
            percent: {
              combustion: 0,
              venting: 0,
              flaring: 0,
              fugitives: 0,
            },
          },
        },
      },
      activityCo2Modal: {
        total: {
          value: {
            combustion: 0,
            venting: 0,
            flaring: 0,
            fugitives: 0,
          },
          percent: {
            combustion: 0,
            venting: 0,
            flaring: 0,
            fugitives: 0,
          },
        },
        detail: {
          combustion: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          venting: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          flaring: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          fugitives: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
        },
      },
      activityCh4Modal: {
        total: {
          value: {
            combustion: 0,
            venting: 0,
            flaring: 0,
            fugitives: 0,
          },
          percent: {
            combustion: 0,
            venting: 0,
            flaring: 0,
            fugitives: 0,
          },
        },
        detail: {
          combustion: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          venting: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          flaring: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          fugitives: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
        },
      },
      activityNmhcModal: {
        total: {
          value: {
            combustion: 0,
            venting: 0,
            flaring: 0,
            fugitives: 0,
          },
          percent: {
            combustion: 0,
            venting: 0,
            flaring: 0,
            fugitives: 0,
          },
        },
        detail: {
          combustion: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          venting: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          flaring: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
          fugitives: {
            value: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
              total: 0,
            },
            percent: {
              exploration: 0,
              drilling: 0,
              production: 0,
              storage: 0,
              transport: 0,
            },
          },
        },
      },
      proccessPieModalCo2Option: {
        color: ['#538EEA', '#F11F15', '#BD23FC', '#034BBF', '#FF73AE'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: '#fff',
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [
              { value: 0, name: 'Exploration' },
              { value: 0, name: 'Drilling and development' },
              { value: 0, name: 'Production and extraction' },
              { value: 0, name: 'Crude oil storage' },
              { value: 0, name: 'Crude oil transport' },
            ],
          },
        ],
      },
      processBarModalCo2OptionExp: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCo2OptionDril: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCo2OptionProd: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCo2OptionStor: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCo2OptionTrans: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      proccessPieModalCh4Option: {
        color: ['#538EEA', '#F11F15', '#BD23FC', '#034BBF', '#FF73AE'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: '#fff',
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [
              { value: 0, name: 'Exploration' },
              { value: 0, name: 'Drilling and development' },
              { value: 0, name: 'Production and extraction' },
              { value: 0, name: 'Crude oil storage' },
              { value: 0, name: 'Crude oil transport' },
            ],
          },
        ],
      },
      processBarModalCh4OptionExp: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCh4OptionDril: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCh4OptionProd: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCh4OptionStor: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalCh4OptionTrans: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      proccessPieModalNmhcOption: {
        color: ['#538EEA', '#F11F15', '#BD23FC', '#034BBF', '#FF73AE'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: '#fff',
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [
              { value: 0, name: 'Exploration' },
              { value: 0, name: 'Drilling and development' },
              { value: 0, name: 'Production and extraction' },
              { value: 0, name: 'Crude oil storage' },
              { value: 0, name: 'Crude oil transport' },
            ],
          },
        ],
      },
      processBarModalNmhcOptionExp: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalNmhcOptionDril: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalNmhcOptionProd: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalNmhcOptionStor: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      processBarModalNmhcOptionTrans: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: ['Combustion', 'Venting', 'Flaring', 'Fugitives'],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#DE1F1A' },
              },
              {
                value: 0,
                itemStyle: { color: '#3031EC' },
              },
              {
                value: 0,
                itemStyle: { color: '#920FF0' },
              },
              {
                value: 0,
                itemStyle: { color: '#0992C9' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityPieModalCo2Option: {
        color: ['#DE1F1A', '#3031EC', '#920FF0', '#0992C9'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: '#fff',
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [
              { value: 0, name: 'Combustion' },
              { value: 0, name: 'Venting' },
              { value: 0, name: 'Flaring' },
              { value: 0, name: 'Fugitives' },
            ],
          },
        ],
      },
      activityBarModalCo2OptionCom: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalCo2OptionVen: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalCo2OptionFla: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalCo2OptionFugi: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityPieModalCh4Option: {
        color: ['#DE1F1A', '#3031EC', '#920FF0', '#0992C9'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: '#fff',
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [
              { value: 0, name: 'Combustion' },
              { value: 0, name: 'Venting' },
              { value: 0, name: 'Flaring' },
              { value: 0, name: 'Fugitives' },
            ],
          },
        ],
      },
      activityBarModalCh4OptionCom: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalCh4OptionVen: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalCh4OptionFla: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalCh4OptionFugi: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityPieModalNmhcOption: {
        color: ['#DE1F1A', '#3031EC', '#920FF0', '#0992C9'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: '#fff',
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [
              { value: 0, name: 'Combustion' },
              { value: 0, name: 'Venting' },
              { value: 0, name: 'Flaring' },
              { value: 0, name: 'Fugitives' },
            ],
          },
        ],
      },
      activityBarModalNmhcOptionCom: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalNmhcOptionVen: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalNmhcOptionFla: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
      activityBarModalNmhcOptionFugi: {
        grid: { top: 10, right: 5, bottom: 5, left: 5 },
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          type: 'category',
          data: [
            'Exploration',
            'Drilling & Development',
            'Production & Extraction',
            'Curde oil storge',
            'Crude oil transport',
          ],
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: { color: '#538EEA' },
              },
              {
                value: 0,
                itemStyle: { color: '#F11F15' },
              },
              {
                value: 0,
                itemStyle: { color: '#BD23FC' },
              },
              {
                value: 0,
                itemStyle: { color: '#034BBF' },
              },
              {
                value: 0,
                itemStyle: { color: '#FF73AE' },
              },
            ],
            type: 'bar',
            barWidth: '15%',
          },
        ],
      },
    };
  },
  methods: {
    closeModalBtn() {
      this.proccessModalCo2 = false;
      this.proccessModalCh4 = false;
      this.proccessModalNmhc = false;
      this.activityModalCo2 = false;
      this.activityModalCh4 = false;
      this.activityModalNmhc = false;
    },
    openProcessDetailModalCo2(params) {
      this.proccessModalCo2 = true;
      this.modalDate = params.name;
      const dateI = this.processCo2option.xAxis.data.findIndex(
        (date) => date === params.name
      );

      const currentIndex = this.dailyData[dateI];

      // total data value
      const explorationCO2Sum = Math.round(
        currentIndex.exploration.Combustion.CO2 +
          currentIndex.exploration.Venting.CO2 +
          currentIndex.exploration.Flaring.CO2 +
          currentIndex.exploration.Fugitives.CO2
      );
      const drillingCO2Sum = Math.round(
        currentIndex.drilling_and_development.Combustion.CO2 +
          currentIndex.drilling_and_development.Venting.CO2 +
          currentIndex.drilling_and_development.Flaring.CO2 +
          currentIndex.drilling_and_development.Fugitives.CO2
      );
      const productionCO2Sum = Math.round(
        currentIndex.production_and_extraction.Combustion.CO2 +
          currentIndex.production_and_extraction.Venting.CO2 +
          currentIndex.production_and_extraction.Flaring.CO2 +
          currentIndex.production_and_extraction.Fugitives.CO2
      );
      const storageCO2Sum = Math.round(
        currentIndex.crude_oil_storage.Combustion.CO2 +
          currentIndex.crude_oil_storage.Venting.CO2 +
          currentIndex.crude_oil_storage.Flaring.CO2 +
          currentIndex.crude_oil_storage.Fugitives.CO2
      );
      const transportCO2Sum = Math.round(
        currentIndex.crude_oil_transport.Combustion.CO2 +
          currentIndex.crude_oil_transport.Venting.CO2 +
          currentIndex.crude_oil_transport.Flaring.CO2 +
          currentIndex.crude_oil_transport.Fugitives.CO2
      );

      this.processCo2Modal.total.value.exploration =
        explorationCO2Sum.toLocaleString('ko-KR');
      this.processCo2Modal.total.value.drilling =
        drillingCO2Sum.toLocaleString('ko-KR');
      this.processCo2Modal.total.value.production =
        productionCO2Sum.toLocaleString('ko-KR');
      this.processCo2Modal.total.value.storage =
        storageCO2Sum.toLocaleString('ko-KR');
      this.processCo2Modal.total.value.transport =
        transportCO2Sum.toLocaleString('ko-KR');

      // total data percent
      const dailyProcessSum =
        explorationCO2Sum +
        drillingCO2Sum +
        productionCO2Sum +
        storageCO2Sum +
        transportCO2Sum;

      if (dailyProcessSum > 0) {
        this.processCo2Modal.total.percent.exploration = (
          (explorationCO2Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCo2Modal.total.percent.drilling = (
          (drillingCO2Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCo2Modal.total.percent.production = (
          (productionCO2Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCo2Modal.total.percent.storage = (
          (storageCO2Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCo2Modal.total.percent.transport = (
          (transportCO2Sum / dailyProcessSum) *
          100
        ).toFixed(2);
      } else {
        this.processCo2Modal.total.percent.exploration = 0;
        this.processCo2Modal.total.percent.drilling = 0;
        this.processCo2Modal.total.percent.production = 0;
        this.processCo2Modal.total.percent.storage = 0;
        this.processCo2Modal.total.percent.transport = 0;
      }

      // total pie chart
      this.proccessPieModalCo2Option.series[0].data[0].value =
        explorationCO2Sum;
      this.proccessPieModalCo2Option.series[0].data[1].value = drillingCO2Sum;
      this.proccessPieModalCo2Option.series[0].data[2].value = productionCO2Sum;
      this.proccessPieModalCo2Option.series[0].data[3].value = storageCO2Sum;
      this.proccessPieModalCo2Option.series[0].data[4].value = transportCO2Sum;

      // detail data value =====================================================================================================================
      const explorationCO2totalvalue = Math.round(
        currentIndex.exploration.Combustion.CO2 +
          currentIndex.exploration.Venting.CO2 +
          currentIndex.exploration.Flaring.CO2 +
          currentIndex.exploration.Fugitives.CO2
      );
      const explorationCO2combustionvalue = Math.round(
        currentIndex.exploration.Combustion.CO2
      );
      const explorationCO2ventingvalue = Math.round(
        currentIndex.exploration.Venting.CO2
      );
      const explorationCO2flaringvalue = Math.round(
        currentIndex.exploration.Flaring.CO2
      );
      const explorationCO2fugitivesvalue = Math.round(
        currentIndex.exploration.Fugitives.CO2
      );
      this.processCo2Modal.detail.exploration.value.total =
        explorationCO2totalvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.exploration.value.combustion =
        explorationCO2combustionvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.exploration.value.venting =
        explorationCO2ventingvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.exploration.value.flaring =
        explorationCO2flaringvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.exploration.value.fugitives =
        explorationCO2fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (explorationCO2totalvalue > 0) {
        this.processCo2Modal.detail.exploration.percent.combustion = Math.round(
          (explorationCO2combustionvalue / explorationCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.exploration.percent.venting = Math.round(
          (explorationCO2ventingvalue / explorationCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.exploration.percent.flaring = Math.round(
          (explorationCO2flaringvalue / explorationCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.exploration.percent.fugitives = Math.round(
          (explorationCO2fugitivesvalue / explorationCO2totalvalue) * 100
        );
      } else {
        this.processCo2Modal.detail.exploration.percent.combustion = 0;
        this.processCo2Modal.detail.exploration.percent.venting = 0;
        this.processCo2Modal.detail.exploration.percent.flaring = 0;
        this.processCo2Modal.detail.exploration.percent.fugitives = 0;
      }

      this.processBarModalCo2OptionExp.series[0].data[0].value =
        explorationCO2combustionvalue;
      this.processBarModalCo2OptionExp.series[0].data[1].value =
        explorationCO2ventingvalue;
      this.processBarModalCo2OptionExp.series[0].data[2].value =
        explorationCO2flaringvalue;
      this.processBarModalCo2OptionExp.series[0].data[3].value =
        explorationCO2fugitivesvalue;

      // detail data value =====================================================================================================================
      const drillingCO2totalvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.CO2 +
          currentIndex.drilling_and_development.Venting.CO2 +
          currentIndex.drilling_and_development.Flaring.CO2 +
          currentIndex.drilling_and_development.Fugitives.CO2
      );
      const drillingCO2combustionvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.CO2
      );
      const drillingCO2ventingvalue = Math.round(
        currentIndex.drilling_and_development.Venting.CO2
      );
      const drillingCO2flaringvalue = Math.round(
        currentIndex.drilling_and_development.Flaring.CO2
      );
      const drillingCO2fugitivesvalue = Math.round(
        currentIndex.drilling_and_development.Fugitives.CO2
      );
      this.processCo2Modal.detail.drilling.value.total =
        drillingCO2totalvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.drilling.value.combustion =
        drillingCO2combustionvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.drilling.value.venting =
        drillingCO2ventingvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.drilling.value.flaring =
        drillingCO2flaringvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.drilling.value.fugitives =
        drillingCO2fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (drillingCO2totalvalue > 0) {
        this.processCo2Modal.detail.drilling.percent.combustion = Math.round(
          (drillingCO2combustionvalue / drillingCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.drilling.percent.venting = Math.round(
          (drillingCO2ventingvalue / drillingCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.drilling.percent.flaring = Math.round(
          (drillingCO2flaringvalue / drillingCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.drilling.percent.fugitives = Math.round(
          (drillingCO2fugitivesvalue / drillingCO2totalvalue) * 100
        );
      } else {
        this.processCo2Modal.detail.drilling.percent.combustion = 0;
        this.processCo2Modal.detail.drilling.percent.venting = 0;
        this.processCo2Modal.detail.drilling.percent.flaring = 0;
        this.processCo2Modal.detail.drilling.percent.fugitives = 0;
      }

      this.processBarModalCo2OptionDril.series[0].data[0].value =
        drillingCO2combustionvalue;
      this.processBarModalCo2OptionDril.series[0].data[1].value =
        drillingCO2ventingvalue;
      this.processBarModalCo2OptionDril.series[0].data[2].value =
        drillingCO2flaringvalue;
      this.processBarModalCo2OptionDril.series[0].data[3].value =
        drillingCO2fugitivesvalue;

      // detail data value =====================================================================================================================
      const productionCO2totalvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.CO2 +
          currentIndex.production_and_extraction.Venting.CO2 +
          currentIndex.production_and_extraction.Flaring.CO2 +
          currentIndex.production_and_extraction.Fugitives.CO2
      );
      const productionCO2combustionvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.CO2
      );
      const productionCO2ventingvalue = Math.round(
        currentIndex.production_and_extraction.Venting.CO2
      );
      const productionCO2flaringvalue = Math.round(
        currentIndex.production_and_extraction.Flaring.CO2
      );
      const productionCO2fugitivesvalue = Math.round(
        currentIndex.production_and_extraction.Fugitives.CO2
      );

      this.processCo2Modal.detail.production.value.total =
        productionCO2totalvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.production.value.combustion =
        productionCO2combustionvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.production.value.venting =
        productionCO2ventingvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.production.value.flaring =
        productionCO2flaringvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.production.value.fugitives =
        productionCO2fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (productionCO2totalvalue > 0) {
        this.processCo2Modal.detail.production.percent.combustion = Math.round(
          (productionCO2combustionvalue / productionCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.production.percent.venting = Math.round(
          (productionCO2ventingvalue / productionCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.production.percent.flaring = Math.round(
          (productionCO2flaringvalue / productionCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.production.percent.fugitives = Math.round(
          (productionCO2fugitivesvalue / productionCO2totalvalue) * 100
        );
      } else {
        this.processCo2Modal.detail.production.percent.combustion = 0;
        this.processCo2Modal.detail.production.percent.venting = 0;
        this.processCo2Modal.detail.production.percent.flaring = 0;
        this.processCo2Modal.detail.production.percent.fugitives = 0;
      }

      this.processBarModalCo2OptionProd.series[0].data[0].value =
        productionCO2combustionvalue;
      this.processBarModalCo2OptionProd.series[0].data[1].value =
        productionCO2ventingvalue;
      this.processBarModalCo2OptionProd.series[0].data[2].value =
        productionCO2flaringvalue;
      this.processBarModalCo2OptionProd.series[0].data[3].value =
        productionCO2fugitivesvalue;

      // detail data value =====================================================================================================================
      const storageCO2totalvalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.CO2 +
          currentIndex.crude_oil_storage.Venting.CO2 +
          currentIndex.crude_oil_storage.Flaring.CO2 +
          currentIndex.crude_oil_storage.Fugitives.CO2
      );
      const storageCO2combustionvalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.CO2
      );
      const storageCO2ventingvalue = Math.round(
        currentIndex.crude_oil_storage.Venting.CO2
      );
      const storageCO2flaringvalue = Math.round(
        currentIndex.crude_oil_storage.Flaring.CO2
      );
      const storageCO2fugitivesvalue = Math.round(
        currentIndex.crude_oil_storage.Fugitives.CO2
      );

      this.processCo2Modal.detail.storage.value.total =
        storageCO2totalvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.storage.value.combustion =
        storageCO2combustionvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.storage.value.venting =
        storageCO2ventingvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.storage.value.flaring =
        storageCO2flaringvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.storage.value.fugitives =
        storageCO2fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (storageCO2totalvalue > 0) {
        this.processCo2Modal.detail.storage.percent.combustion = Math.round(
          (storageCO2combustionvalue / storageCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.storage.percent.venting = Math.round(
          (storageCO2ventingvalue / storageCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.storage.percent.flaring = Math.round(
          (storageCO2flaringvalue / storageCO2totalvalue) * 100
        );

        this.processCo2Modal.detail.storage.percent.fugitives = Math.round(
          (storageCO2fugitivesvalue / storageCO2totalvalue) * 100
        );
      } else {
        this.processCo2Modal.detail.storage.percent.combustion = 0;
        this.processCo2Modal.detail.storage.percent.venting = 0;
        this.processCo2Modal.detail.storage.percent.flaring = 0;
        this.processCo2Modal.detail.storage.percent.fugitives = 0;
      }

      this.processBarModalCo2OptionStor.series[0].data[0].value =
        storageCO2combustionvalue;
      this.processBarModalCo2OptionStor.series[0].data[1].value =
        storageCO2ventingvalue;
      this.processBarModalCo2OptionStor.series[0].data[2].value =
        storageCO2flaringvalue;
      this.processBarModalCo2OptionStor.series[0].data[3].value =
        storageCO2fugitivesvalue;

      // detail data value =====================================================================================================================
      const transportCO2totalvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.CO2 +
          currentIndex.crude_oil_transport.Venting.CO2 +
          currentIndex.crude_oil_transport.Flaring.CO2 +
          currentIndex.crude_oil_transport.Fugitives.CO2
      );
      const transportCO2combustionvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.CO2
      );
      const transportCO2ventingvalue = Math.round(
        currentIndex.crude_oil_transport.Venting.CO2
      );
      const transportCO2flaringvalue = Math.round(
        currentIndex.crude_oil_transport.Flaring.CO2
      );
      const transportCO2fugitivesvalue = Math.round(
        currentIndex.crude_oil_transport.Fugitives.CO2
      );

      this.processCo2Modal.detail.transport.value.total =
        transportCO2totalvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.transport.value.combustion =
        transportCO2combustionvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.transport.value.venting =
        transportCO2ventingvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.transport.value.flaring =
        transportCO2flaringvalue.toLocaleString('ko-KR');
      this.processCo2Modal.detail.transport.value.fugitives =
        transportCO2fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (transportCO2totalvalue > 0) {
        this.processCo2Modal.detail.transport.percent.combustion =
          (transportCO2combustionvalue / transportCO2totalvalue) * 100;

        this.processCo2Modal.detail.transport.percent.venting =
          (transportCO2ventingvalue / transportCO2totalvalue) * 100;

        this.processCo2Modal.detail.transport.percent.flaring =
          (transportCO2flaringvalue / transportCO2totalvalue) * 100;

        this.processCo2Modal.detail.transport.percent.fugitives =
          (transportCO2fugitivesvalue / transportCO2totalvalue) * 100;
      } else {
        this.processCo2Modal.detail.transport.percent.combustion = 0;
        this.processCo2Modal.detail.transport.percent.venting = 0;
        this.processCo2Modal.detail.transport.percent.flaring = 0;
        this.processCo2Modal.detail.transport.percent.fugitives = 0;
      }

      this.processBarModalCo2OptionTrans.series[0].data[0].value =
        transportCO2combustionvalue;
      this.processBarModalCo2OptionTrans.series[0].data[1].value =
        transportCO2ventingvalue;
      this.processBarModalCo2OptionTrans.series[0].data[2].value =
        transportCO2flaringvalue;
      this.processBarModalCo2OptionTrans.series[0].data[3].value =
        transportCO2fugitivesvalue;
    },
    openProcessDetailModalCh4(params) {
      this.proccessModalCh4 = true;
      this.modalDate = params.name;
      const dateI = this.processCh4option.xAxis.data.findIndex(
        (date) => date === params.name
      );

      const currentIndex = this.dailyData[dateI];

      // total data value
      const explorationCH4Sum = Math.round(
        currentIndex.exploration.Combustion.CH4 +
          currentIndex.exploration.Venting.CH4 +
          currentIndex.exploration.Flaring.CH4 +
          currentIndex.exploration.Fugitives.CH4
      );
      const drillingCH4Sum = Math.round(
        currentIndex.drilling_and_development.Combustion.CH4 +
          currentIndex.drilling_and_development.Venting.CH4 +
          currentIndex.drilling_and_development.Flaring.CH4 +
          currentIndex.drilling_and_development.Fugitives.CH4
      );
      const productionCH4Sum = Math.round(
        currentIndex.production_and_extraction.Combustion.CH4 +
          currentIndex.production_and_extraction.Venting.CH4 +
          currentIndex.production_and_extraction.Flaring.CH4 +
          currentIndex.production_and_extraction.Fugitives.CH4
      );
      const storageCH4Sum = Math.round(
        currentIndex.crude_oil_storage.Combustion.CH4 +
          currentIndex.crude_oil_storage.Venting.CH4 +
          currentIndex.crude_oil_storage.Flaring.CH4 +
          currentIndex.crude_oil_storage.Fugitives.CH4
      );
      const transportCH4Sum = Math.round(
        currentIndex.crude_oil_transport.Combustion.CH4 +
          currentIndex.crude_oil_transport.Venting.CH4 +
          currentIndex.crude_oil_transport.Flaring.CH4 +
          currentIndex.crude_oil_transport.Fugitives.CH4
      );
      this.processCh4Modal.total.value.exploration =
        explorationCH4Sum.toLocaleString('ko-KR');
      this.processCh4Modal.total.value.drilling =
        drillingCH4Sum.toLocaleString('ko-KR');
      this.processCh4Modal.total.value.production =
        productionCH4Sum.toLocaleString('ko-KR');
      this.processCh4Modal.total.value.storage =
        storageCH4Sum.toLocaleString('ko-KR');
      this.processCh4Modal.total.value.transport =
        transportCH4Sum.toLocaleString('ko-KR');

      // total data percent
      const dailyProcessSum =
        explorationCH4Sum +
        drillingCH4Sum +
        productionCH4Sum +
        storageCH4Sum +
        transportCH4Sum;

      if (dailyProcessSum > 0) {
        this.processCh4Modal.total.percent.exploration = (
          (explorationCH4Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCh4Modal.total.percent.drilling = (
          (drillingCH4Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCh4Modal.total.percent.production = (
          (productionCH4Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCh4Modal.total.percent.storage = (
          (storageCH4Sum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processCh4Modal.total.percent.transport = (
          (transportCH4Sum / dailyProcessSum) *
          100
        ).toFixed(2);
      } else {
        this.processCh4Modal.total.percent.exploration = 0;
        this.processCh4Modal.total.percent.drilling = 0;
        this.processCh4Modal.total.percent.production = 0;
        this.processCh4Modal.total.percent.storage = 0;
        this.processCh4Modal.total.percent.transport = 0;
      }

      // total pie chart
      this.proccessPieModalCh4Option.series[0].data[0].value =
        explorationCH4Sum;
      this.proccessPieModalCh4Option.series[0].data[1].value = drillingCH4Sum;
      this.proccessPieModalCh4Option.series[0].data[2].value = productionCH4Sum;
      this.proccessPieModalCh4Option.series[0].data[3].value = storageCH4Sum;
      this.proccessPieModalCh4Option.series[0].data[4].value = transportCH4Sum;

      // detail data value =====================================================================================================================
      const explorationCH4totalvalue = Math.round(
        currentIndex.exploration.Combustion.CH4 +
          currentIndex.exploration.Venting.CH4 +
          currentIndex.exploration.Flaring.CH4 +
          currentIndex.exploration.Fugitives.CH4
      );
      const explorationCH4combustionvalue = Math.round(
        currentIndex.exploration.Combustion.CH4
      );
      const explorationCH4ventingvalue = Math.round(
        currentIndex.exploration.Venting.CH4
      );
      const explorationCH4flaringvalue = Math.round(
        currentIndex.exploration.Flaring.CH4
      );
      const explorationCH4fugitivesvalue = Math.round(
        currentIndex.exploration.Fugitives.CH4
      );

      this.processCh4Modal.detail.exploration.value.total =
        explorationCH4totalvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.exploration.value.combustion =
        explorationCH4combustionvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.exploration.value.venting =
        explorationCH4ventingvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.exploration.value.flaring =
        explorationCH4flaringvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.exploration.value.fugitives =
        explorationCH4fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (explorationCH4totalvalue > 0) {
        this.processCh4Modal.detail.exploration.percent.combustion = Math.round(
          (explorationCH4combustionvalue / explorationCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.exploration.percent.venting = Math.round(
          (explorationCH4ventingvalue / explorationCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.exploration.percent.flaring = Math.round(
          (explorationCH4flaringvalue / explorationCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.exploration.percent.fugitives = Math.round(
          (explorationCH4fugitivesvalue / explorationCH4totalvalue) * 100
        );
      } else {
        this.processCh4Modal.detail.exploration.percent.combustion = 0;
        this.processCh4Modal.detail.exploration.percent.venting = 0;
        this.processCh4Modal.detail.exploration.percent.flaring = 0;
        this.processCh4Modal.detail.exploration.percent.fugitives = 0;
      }

      this.processBarModalCh4OptionExp.series[0].data[0].value =
        explorationCH4combustionvalue;
      this.processBarModalCh4OptionExp.series[0].data[1].value =
        explorationCH4ventingvalue;
      this.processBarModalCh4OptionExp.series[0].data[2].value =
        explorationCH4flaringvalue;
      this.processBarModalCh4OptionExp.series[0].data[3].value =
        explorationCH4fugitivesvalue;

      // detail data value
      const drillingCH4totalvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.CH4 +
          currentIndex.drilling_and_development.Venting.CH4 +
          currentIndex.drilling_and_development.Flaring.CH4 +
          currentIndex.drilling_and_development.Fugitives.CH4
      );
      const drillingCH4combustionvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.CH4
      );
      const drillingCH4ventingvalue = Math.round(
        currentIndex.drilling_and_development.Venting.CH4
      );
      const drillingCH4flaringvalue = Math.round(
        currentIndex.drilling_and_development.Flaring.CH4
      );
      const drillingCH4fugitivesvalue = Math.round(
        currentIndex.drilling_and_development.Fugitives.CH4
      );

      this.processCh4Modal.detail.drilling.value.total =
        drillingCH4totalvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.drilling.value.combustion =
        drillingCH4combustionvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.drilling.value.venting =
        drillingCH4ventingvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.drilling.value.flaring =
        drillingCH4flaringvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.drilling.value.fugitives =
        drillingCH4fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (drillingCH4totalvalue > 0) {
        this.processCh4Modal.detail.drilling.percent.combustion = Math.round(
          (drillingCH4combustionvalue / drillingCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.drilling.percent.venting = Math.round(
          (drillingCH4ventingvalue / drillingCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.drilling.percent.flaring = Math.round(
          (drillingCH4flaringvalue / drillingCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.drilling.percent.fugitives = Math.round(
          (drillingCH4fugitivesvalue / drillingCH4totalvalue) * 100
        );
      } else {
        this.processCh4Modal.detail.drilling.percent.combustion = 0;
        this.processCh4Modal.detail.drilling.percent.venting = 0;
        this.processCh4Modal.detail.drilling.percent.flaring = 0;
        this.processCh4Modal.detail.drilling.percent.fugitives = 0;
      }

      this.processBarModalCh4OptionDril.series[0].data[0].value =
        drillingCH4combustionvalue;
      this.processBarModalCh4OptionDril.series[0].data[1].value =
        drillingCH4ventingvalue;
      this.processBarModalCh4OptionDril.series[0].data[2].value =
        drillingCH4flaringvalue;
      this.processBarModalCh4OptionDril.series[0].data[3].value =
        drillingCH4fugitivesvalue;

      // detail data value
      const productionCH4totalvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.CH4 +
          currentIndex.production_and_extraction.Venting.CH4 +
          currentIndex.production_and_extraction.Flaring.CH4 +
          currentIndex.production_and_extraction.Fugitives.CH4
      );
      const productionCH4combustionvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.CH4
      );
      const productionCH4ventingvalue = Math.round(
        currentIndex.production_and_extraction.Venting.CH4
      );
      const productionCH4flaringvalue = Math.round(
        currentIndex.production_and_extraction.Flaring.CH4
      );
      const productionCH4fugitivesvalue = Math.round(
        currentIndex.production_and_extraction.Fugitives.CH4
      );

      this.processCh4Modal.detail.production.value.total =
        productionCH4totalvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.production.value.combustion =
        productionCH4combustionvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.production.value.venting =
        productionCH4ventingvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.production.value.flaring =
        productionCH4flaringvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.production.value.fugitives =
        productionCH4fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (productionCH4totalvalue > 0) {
        this.processCh4Modal.detail.production.percent.combustion = Math.round(
          (productionCH4combustionvalue / productionCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.production.percent.venting = Math.round(
          (productionCH4ventingvalue / productionCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.production.percent.flaring = Math.round(
          (productionCH4flaringvalue / productionCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.production.percent.fugitives = Math.round(
          (productionCH4fugitivesvalue / productionCH4totalvalue) * 100
        );
      } else {
        this.processCh4Modal.detail.production.percent.combustion = 0;
        this.processCh4Modal.detail.production.percent.venting = 0;
        this.processCh4Modal.detail.production.percent.flaring = 0;
        this.processCh4Modal.detail.production.percent.fugitives = 0;
      }

      this.processBarModalCh4OptionProd.series[0].data[0].value =
        productionCH4combustionvalue;
      this.processBarModalCh4OptionProd.series[0].data[1].value =
        productionCH4ventingvalue;
      this.processBarModalCh4OptionProd.series[0].data[2].value =
        productionCH4flaringvalue;
      this.processBarModalCh4OptionProd.series[0].data[3].value =
        productionCH4fugitivesvalue;

      // detail data value
      const storageCH4totalvalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.CH4 +
          currentIndex.crude_oil_storage.Venting.CH4 +
          currentIndex.crude_oil_storage.Flaring.CH4 +
          currentIndex.crude_oil_storage.Fugitives.CH4
      );
      const storageCH4combustionvalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.CH4
      );
      const storageCH4ventingvalue = Math.round(
        currentIndex.crude_oil_storage.Venting.CH4
      );
      const storageCH4flaringvalue = Math.round(
        currentIndex.crude_oil_storage.Flaring.CH4
      );
      const storageCH4fugitivesvalue = Math.round(
        currentIndex.crude_oil_storage.Fugitives.CH4
      );

      this.processCh4Modal.detail.storage.value.total =
        storageCH4totalvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.storage.value.combustion =
        storageCH4combustionvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.storage.value.venting =
        storageCH4ventingvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.storage.value.flaring =
        storageCH4flaringvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.storage.value.fugitives =
        storageCH4fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (storageCH4totalvalue > 0) {
        this.processCh4Modal.detail.storage.percent.combustion = Math.round(
          (storageCH4combustionvalue / storageCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.storage.percent.venting = Math.round(
          (storageCH4ventingvalue / storageCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.storage.percent.flaring = Math.round(
          (storageCH4flaringvalue / storageCH4totalvalue) * 100
        );

        this.processCh4Modal.detail.storage.percent.fugitives = Math.round(
          (storageCH4fugitivesvalue / storageCH4totalvalue) * 100
        );
      } else {
        this.processCh4Modal.detail.storage.percent.combustion = 0;
        this.processCh4Modal.detail.storage.percent.venting = 0;
        this.processCh4Modal.detail.storage.percent.flaring = 0;
        this.processCh4Modal.detail.storage.percent.fugitives = 0;
      }

      this.processBarModalCh4OptionStor.series[0].data[0].value =
        storageCH4combustionvalue;
      this.processBarModalCh4OptionStor.series[0].data[1].value =
        storageCH4ventingvalue;
      this.processBarModalCh4OptionStor.series[0].data[2].value =
        storageCH4flaringvalue;
      this.processBarModalCh4OptionStor.series[0].data[3].value =
        storageCH4fugitivesvalue;

      // detail data value
      const transportCH4totalvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.CH4 +
          currentIndex.crude_oil_transport.Venting.CH4 +
          currentIndex.crude_oil_transport.Flaring.CH4 +
          currentIndex.crude_oil_transport.Fugitives.CH4
      );
      const transportCH4combustionvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.CH4
      );
      const transportCH4ventingvalue = Math.round(
        currentIndex.crude_oil_transport.Venting.CH4
      );
      const transportCH4flaringvalue = Math.round(
        currentIndex.crude_oil_transport.Flaring.CH4
      );
      const transportCH4fugitivesvalue = Math.round(
        currentIndex.crude_oil_transport.Fugitives.CH4
      );

      this.processCh4Modal.detail.transport.value.total =
        transportCH4totalvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.transport.value.combustion =
        transportCH4combustionvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.transport.value.venting =
        transportCH4ventingvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.transport.value.flaring =
        transportCH4flaringvalue.toLocaleString('ko-KR');
      this.processCh4Modal.detail.transport.value.fugitives =
        transportCH4fugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (transportCH4totalvalue > 0) {
        this.processCh4Modal.detail.transport.percent.combustion =
          (transportCH4combustionvalue / transportCH4totalvalue) * 100;

        this.processCh4Modal.detail.transport.percent.venting =
          (transportCH4ventingvalue / transportCH4totalvalue) * 100;

        this.processCh4Modal.detail.transport.percent.flaring =
          (transportCH4flaringvalue / transportCH4totalvalue) * 100;

        this.processCh4Modal.detail.transport.percent.fugitives =
          (transportCH4fugitivesvalue / transportCH4totalvalue) * 100;
      } else {
        this.processCh4Modal.detail.transport.percent.combustion = 0;
        this.processCh4Modal.detail.transport.percent.venting = 0;
        this.processCh4Modal.detail.transport.percent.flaring = 0;
        this.processCh4Modal.detail.transport.percent.fugitives = 0;
      }

      this.processBarModalCh4OptionTrans.series[0].data[0].value =
        transportCH4combustionvalue;
      this.processBarModalCh4OptionTrans.series[0].data[1].value =
        transportCH4ventingvalue;
      this.processBarModalCh4OptionTrans.series[0].data[2].value =
        transportCH4flaringvalue;
      this.processBarModalCh4OptionTrans.series[0].data[3].value =
        transportCH4fugitivesvalue;
    },
    openProcessDetailModalNmhc(params) {
      this.proccessModalNmhc = true;
      this.modalDate = params.name;
      const dateI = this.processNmhcoption.xAxis.data.findIndex(
        (date) => date === params.name
      );

      const currentIndex = this.dailyData[dateI];

      // total data value
      const explorationNMHCSum = Math.round(
        currentIndex.exploration.Combustion.NMHC +
          currentIndex.exploration.Venting.NMHC +
          currentIndex.exploration.Flaring.NMHC +
          currentIndex.exploration.Fugitives.NMHC
      );
      const drillingNMHCSum = Math.round(
        currentIndex.drilling_and_development.Combustion.NMHC +
          currentIndex.drilling_and_development.Venting.NMHC +
          currentIndex.drilling_and_development.Flaring.NMHC +
          currentIndex.drilling_and_development.Fugitives.NMHC
      );
      const productionNMHCSum = Math.round(
        currentIndex.production_and_extraction.Combustion.NMHC +
          currentIndex.production_and_extraction.Venting.NMHC +
          currentIndex.production_and_extraction.Flaring.NMHC +
          currentIndex.production_and_extraction.Fugitives.NMHC
      );
      const storageNMHCSum = Math.round(
        currentIndex.crude_oil_storage.Combustion.NMHC +
          currentIndex.crude_oil_storage.Venting.NMHC +
          currentIndex.crude_oil_storage.Flaring.NMHC +
          currentIndex.crude_oil_storage.Fugitives.NMHC
      );
      const transportNMHCSum = Math.round(
        currentIndex.crude_oil_transport.Combustion.NMHC +
          currentIndex.crude_oil_transport.Venting.NMHC +
          currentIndex.crude_oil_transport.Flaring.NMHC +
          currentIndex.crude_oil_transport.Fugitives.NMHC
      );

      this.processNmhcModal.total.value.exploration =
        explorationNMHCSum.toLocaleString('ko-KR');
      this.processNmhcModal.total.value.drilling =
        drillingNMHCSum.toLocaleString('ko-KR');
      this.processNmhcModal.total.value.production =
        productionNMHCSum.toLocaleString('ko-KR');
      this.processNmhcModal.total.value.storage =
        storageNMHCSum.toLocaleString('ko-KR');
      this.processNmhcModal.total.value.transport =
        transportNMHCSum.toLocaleString('ko-KR');

      // total data percent
      const dailyProcessSum =
        explorationNMHCSum +
        drillingNMHCSum +
        productionNMHCSum +
        storageNMHCSum +
        transportNMHCSum;

      if (dailyProcessSum > 0) {
        this.processNmhcModal.total.percent.exploration = (
          (explorationNMHCSum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processNmhcModal.total.percent.drilling = (
          (drillingNMHCSum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processNmhcModal.total.percent.production = (
          (productionNMHCSum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processNmhcModal.total.percent.storage = (
          (storageNMHCSum / dailyProcessSum) *
          100
        ).toFixed(2);

        this.processNmhcModal.total.percent.transport = (
          (transportNMHCSum / dailyProcessSum) *
          100
        ).toFixed(2);
      } else {
        this.processNmhcModal.total.percent.exploration = 0;
        this.processNmhcModal.total.percent.drilling = 0;
        this.processNmhcModal.total.percent.production = 0;
        this.processNmhcModal.total.percent.storage = 0;
        this.processNmhcModal.total.percent.transport = 0;
      }

      // total pie chart
      this.proccessPieModalNmhcOption.series[0].data[0].value =
        explorationNMHCSum;
      this.proccessPieModalNmhcOption.series[0].data[1].value = drillingNMHCSum;
      this.proccessPieModalNmhcOption.series[0].data[2].value =
        productionNMHCSum;
      this.proccessPieModalNmhcOption.series[0].data[3].value = storageNMHCSum;
      this.proccessPieModalNmhcOption.series[0].data[4].value =
        transportNMHCSum;

      // detail data value =====================================================================================================================
      const explorationNMHCtotalvalue = Math.round(
        currentIndex.exploration.Combustion.NMHC +
          currentIndex.exploration.Venting.NMHC +
          currentIndex.exploration.Flaring.NMHC +
          currentIndex.exploration.Fugitives.NMHC
      );
      const explorationNMHCcombustionvalue = Math.round(
        currentIndex.exploration.Combustion.NMHC
      );
      const explorationNMHCventingvalue = Math.round(
        currentIndex.exploration.Venting.NMHC
      );
      const explorationNMHCflaringvalue = Math.round(
        currentIndex.exploration.Flaring.NMHC
      );
      const explorationNMHCfugitivesvalue = Math.round(
        currentIndex.exploration.Fugitives.NMHC
      );

      this.processNmhcModal.detail.exploration.value.total =
        explorationNMHCtotalvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.exploration.value.combustion =
        explorationNMHCcombustionvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.exploration.value.venting =
        explorationNMHCventingvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.exploration.value.flaring =
        explorationNMHCflaringvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.exploration.value.fugitives =
        explorationNMHCfugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (explorationNMHCtotalvalue > 0) {
        this.processNmhcModal.detail.exploration.percent.combustion =
          Math.round(
            (explorationNMHCcombustionvalue / explorationNMHCtotalvalue) * 100
          );

        this.processNmhcModal.detail.exploration.percent.venting = Math.round(
          (explorationNMHCventingvalue / explorationNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.exploration.percent.flaring = Math.round(
          (explorationNMHCflaringvalue / explorationNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.exploration.percent.fugitives = Math.round(
          (explorationNMHCfugitivesvalue / explorationNMHCtotalvalue) * 100
        );
      } else {
        this.processNmhcModal.detail.exploration.percent.combustion = 0;
        this.processNmhcModal.detail.exploration.percent.venting = 0;
        this.processNmhcModal.detail.exploration.percent.flaring = 0;
        this.processNmhcModal.detail.exploration.percent.fugitives = 0;
      }

      this.processBarModalNmhcOptionExp.series[0].data[0].value =
        explorationNMHCcombustionvalue;
      this.processBarModalNmhcOptionExp.series[0].data[1].value =
        explorationNMHCventingvalue;
      this.processBarModalNmhcOptionExp.series[0].data[2].value =
        explorationNMHCflaringvalue;
      this.processBarModalNmhcOptionExp.series[0].data[3].value =
        explorationNMHCfugitivesvalue;

      // detail data value
      const drillingNMHCtotalvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.NMHC +
          currentIndex.drilling_and_development.Venting.NMHC +
          currentIndex.drilling_and_development.Flaring.NMHC +
          currentIndex.drilling_and_development.Fugitives.NMHC
      );
      const drillingNMHCcombustionvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.NMHC
      );
      const drillingNMHCventingvalue = Math.round(
        currentIndex.drilling_and_development.Venting.NMHC
      );
      const drillingNMHCflaringvalue = Math.round(
        currentIndex.drilling_and_development.Flaring.NMHC
      );
      const drillingNMHCfugitivesvalue = Math.round(
        currentIndex.drilling_and_development.Fugitives.NMHC
      );

      this.processNmhcModal.detail.drilling.value.total =
        drillingNMHCtotalvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.drilling.value.combustion =
        drillingNMHCcombustionvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.drilling.value.venting =
        drillingNMHCventingvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.drilling.value.flaring =
        drillingNMHCflaringvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.drilling.value.fugitives =
        drillingNMHCfugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (drillingNMHCtotalvalue > 0) {
        this.processNmhcModal.detail.drilling.percent.combustion = Math.round(
          (drillingNMHCcombustionvalue / drillingNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.drilling.percent.venting = Math.round(
          (drillingNMHCventingvalue / drillingNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.drilling.percent.flaring = Math.round(
          (drillingNMHCflaringvalue / drillingNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.drilling.percent.fugitives = Math.round(
          (drillingNMHCfugitivesvalue / drillingNMHCtotalvalue) * 100
        );
      } else {
        this.processNmhcModal.detail.drilling.percent.combustion = 0;
        this.processNmhcModal.detail.drilling.percent.venting = 0;
        this.processNmhcModal.detail.drilling.percent.flaring = 0;
        this.processNmhcModal.detail.drilling.percent.fugitives = 0;
      }

      this.processBarModalNmhcOptionDril.series[0].data[0].value =
        drillingNMHCcombustionvalue;
      this.processBarModalNmhcOptionDril.series[0].data[1].value =
        drillingNMHCventingvalue;
      this.processBarModalNmhcOptionDril.series[0].data[2].value =
        drillingNMHCflaringvalue;
      this.processBarModalNmhcOptionDril.series[0].data[3].value =
        drillingNMHCfugitivesvalue;

      // detail data value
      const productionNMHCtotalvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.NMHC +
          currentIndex.production_and_extraction.Venting.NMHC +
          currentIndex.production_and_extraction.Flaring.NMHC +
          currentIndex.production_and_extraction.Fugitives.NMHC
      );
      const productionNMHCcombustionvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.NMHC
      );
      const productionNMHCventingvalue = Math.round(
        currentIndex.production_and_extraction.Venting.NMHC
      );
      const productionNMHCflaringvalue = Math.round(
        currentIndex.production_and_extraction.Flaring.NMHC
      );
      const productionNMHCfugitivesvalue = Math.round(
        currentIndex.production_and_extraction.Fugitives.NMHC
      );

      this.processNmhcModal.detail.production.value.total =
        productionNMHCtotalvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.production.value.combustion =
        productionNMHCcombustionvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.production.value.venting =
        productionNMHCventingvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.production.value.flaring =
        productionNMHCflaringvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.production.value.fugitives =
        productionNMHCfugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (productionNMHCtotalvalue > 0) {
        this.processNmhcModal.detail.production.percent.combustion = Math.round(
          (productionNMHCcombustionvalue / productionNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.production.percent.venting = Math.round(
          (productionNMHCventingvalue / productionNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.production.percent.flaring = Math.round(
          (productionNMHCflaringvalue / productionNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.production.percent.fugitives = Math.round(
          (productionNMHCfugitivesvalue / productionNMHCtotalvalue) * 100
        );
      } else {
        this.processNmhcModal.detail.production.percent.combustion = 0;
        this.processNmhcModal.detail.production.percent.venting = 0;
        this.processNmhcModal.detail.production.percent.flaring = 0;
        this.processNmhcModal.detail.production.percent.fugitives = 0;
      }

      this.processBarModalNmhcOptionProd.series[0].data[0].value =
        productionNMHCcombustionvalue;
      this.processBarModalNmhcOptionProd.series[0].data[1].value =
        productionNMHCventingvalue;
      this.processBarModalNmhcOptionProd.series[0].data[2].value =
        productionNMHCflaringvalue;
      this.processBarModalNmhcOptionProd.series[0].data[3].value =
        productionNMHCfugitivesvalue;

      // detail data value
      const storageNMHCtotalvalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.NMHC +
          currentIndex.crude_oil_storage.Venting.NMHC +
          currentIndex.crude_oil_storage.Flaring.NMHC +
          currentIndex.crude_oil_storage.Fugitives.NMHC
      );
      const storageNMHCcombustionvalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.NMHC
      );
      const storageNMHCventingvalue = Math.round(
        currentIndex.crude_oil_storage.Venting.NMHC
      );
      const storageNMHCflaringvalue = Math.round(
        currentIndex.crude_oil_storage.Flaring.NMHC
      );
      const storageNMHCfugitivesvalue = Math.round(
        currentIndex.crude_oil_storage.Fugitives.NMHC
      );

      this.processNmhcModal.detail.storage.value.total =
        storageNMHCtotalvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.storage.value.combustion =
        storageNMHCcombustionvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.storage.value.venting =
        storageNMHCventingvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.storage.value.flaring =
        storageNMHCflaringvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.storage.value.fugitives =
        storageNMHCfugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (storageNMHCtotalvalue > 0) {
        this.processNmhcModal.detail.storage.percent.combustion = Math.round(
          (storageNMHCcombustionvalue / storageNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.storage.percent.venting = Math.round(
          (storageNMHCventingvalue / storageNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.storage.percent.flaring = Math.round(
          (storageNMHCflaringvalue / storageNMHCtotalvalue) * 100
        );

        this.processNmhcModal.detail.storage.percent.fugitives = Math.round(
          (storageNMHCfugitivesvalue / storageNMHCtotalvalue) * 100
        );
      } else {
        this.processNmhcModal.detail.storage.percent.combustion = 0;
        this.processNmhcModal.detail.storage.percent.venting = 0;
        this.processNmhcModal.detail.storage.percent.flaring = 0;
        this.processNmhcModal.detail.storage.percent.fugitives = 0;
      }

      this.processBarModalNmhcOptionStor.series[0].data[0].value =
        storageNMHCcombustionvalue;
      this.processBarModalNmhcOptionStor.series[0].data[1].value =
        storageNMHCventingvalue;
      this.processBarModalNmhcOptionStor.series[0].data[2].value =
        storageNMHCflaringvalue;
      this.processBarModalNmhcOptionStor.series[0].data[3].value =
        storageNMHCfugitivesvalue;

      // detail data value
      const transportNMHCtotalvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.NMHC +
          currentIndex.crude_oil_transport.Venting.NMHC +
          currentIndex.crude_oil_transport.Flaring.NMHC +
          currentIndex.crude_oil_transport.Fugitives.NMHC
      );
      const transportNMHCcombustionvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.NMHC
      );
      const transportNMHCventingvalue = Math.round(
        currentIndex.crude_oil_transport.Venting.NMHC
      );
      const transportNMHCflaringvalue = Math.round(
        currentIndex.crude_oil_transport.Flaring.NMHC
      );
      const transportNMHCfugitivesvalue = Math.round(
        currentIndex.crude_oil_transport.Fugitives.NMHC
      );

      this.processNmhcModal.detail.transport.value.total =
        transportNMHCtotalvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.transport.value.combustion =
        transportNMHCcombustionvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.transport.value.venting =
        transportNMHCventingvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.transport.value.flaring =
        transportNMHCflaringvalue.toLocaleString('ko-KR');
      this.processNmhcModal.detail.transport.value.fugitives =
        transportNMHCfugitivesvalue.toLocaleString('ko-KR');

      // detail data percent
      if (transportNMHCtotalvalue > 0) {
        this.processNmhcModal.detail.transport.percent.combustion =
          (transportNMHCcombustionvalue / transportNMHCtotalvalue) * 100;

        this.processNmhcModal.detail.transport.percent.venting =
          (transportNMHCventingvalue / transportNMHCtotalvalue) * 100;

        this.processNmhcModal.detail.transport.percent.flaring =
          (transportNMHCflaringvalue / transportNMHCtotalvalue) * 100;

        this.processNmhcModal.detail.transport.percent.fugitives =
          (transportNMHCfugitivesvalue / transportNMHCtotalvalue) * 100;
      } else {
        this.processNmhcModal.detail.transport.percent.combustion = 0;
        this.processNmhcModal.detail.transport.percent.venting = 0;
        this.processNmhcModal.detail.transport.percent.flaring = 0;
        this.processNmhcModal.detail.transport.percent.fugitives = 0;
      }

      this.processBarModalNmhcOptionTrans.series[0].data[0].value =
        transportNMHCcombustionvalue;
      this.processBarModalNmhcOptionTrans.series[0].data[1].value =
        transportNMHCventingvalue;
      this.processBarModalNmhcOptionTrans.series[0].data[2].value =
        transportNMHCflaringvalue;
      this.processBarModalNmhcOptionTrans.series[0].data[3].value =
        transportNMHCfugitivesvalue;
    },
    openActivityDetailModalCo2(params) {
      this.activityModalCo2 = true;
      this.modalDate = params.name;
      const dateI = this.activityCo2option.xAxis.data.findIndex(
        (date) => date === params.name
      );

      const currentIndex = this.dailyData[dateI];

      // total data value
      const combustionCO2Sum = Math.round(
        currentIndex.exploration.Combustion.CO2 +
          currentIndex.drilling_and_development.Combustion.CO2 +
          currentIndex.production_and_extraction.Combustion.CO2 +
          currentIndex.crude_oil_storage.Combustion.CO2 +
          currentIndex.crude_oil_transport.Combustion.CO2
      );
      const ventingCO2Sum = Math.round(
        currentIndex.exploration.Venting.CO2 +
          currentIndex.drilling_and_development.Venting.CO2 +
          currentIndex.production_and_extraction.Venting.CO2 +
          currentIndex.crude_oil_storage.Venting.CO2 +
          currentIndex.crude_oil_transport.Venting.CO2
      );
      const flaringCO2Sum = Math.round(
        currentIndex.exploration.Flaring.CO2 +
          currentIndex.drilling_and_development.Flaring.CO2 +
          currentIndex.production_and_extraction.Flaring.CO2 +
          currentIndex.crude_oil_storage.Flaring.CO2 +
          currentIndex.crude_oil_transport.Flaring.CO2
      );
      const fugitivesCO2Sum = Math.round(
        currentIndex.exploration.Fugitives.CO2 +
          currentIndex.drilling_and_development.Fugitives.CO2 +
          currentIndex.production_and_extraction.Fugitives.CO2 +
          currentIndex.crude_oil_storage.Fugitives.CO2 +
          currentIndex.crude_oil_transport.Fugitives.CO2
      );

      this.activityCo2Modal.total.value.combustion =
        combustionCO2Sum.toLocaleString('ko-KR');
      this.activityCo2Modal.total.value.venting =
        ventingCO2Sum.toLocaleString('ko-KR');
      this.activityCo2Modal.total.value.flaring =
        flaringCO2Sum.toLocaleString('ko-KR');
      this.activityCo2Modal.total.value.fugitives =
        fugitivesCO2Sum.toLocaleString('ko-KR');

      // total data percent
      const dailyActivitySum =
        combustionCO2Sum + ventingCO2Sum + flaringCO2Sum + fugitivesCO2Sum;

      if (dailyActivitySum > 0) {
        this.activityCo2Modal.total.percent.combustion = (
          (combustionCO2Sum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityCo2Modal.total.percent.venting = (
          (ventingCO2Sum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityCo2Modal.total.percent.flaring = (
          (flaringCO2Sum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityCo2Modal.total.percent.fugitives = (
          (fugitivesCO2Sum / dailyActivitySum) *
          100
        ).toFixed(2);
      } else {
        this.activityCo2Modal.total.percent.combustion = 0;
        this.activityCo2Modal.total.percent.venting = 0;
        this.activityCo2Modal.total.percent.flaring = 0;
        this.activityCo2Modal.total.percent.fugitives = 0;
      }

      // total pie chart
      this.activityPieModalCo2Option.series[0].data[0].value = combustionCO2Sum;
      this.activityPieModalCo2Option.series[0].data[1].value = ventingCO2Sum;
      this.activityPieModalCo2Option.series[0].data[2].value = flaringCO2Sum;
      this.activityPieModalCo2Option.series[0].data[3].value = fugitivesCO2Sum;

      // detail data value =====================================================================================================================
      const combustionCO2totalvalue = Math.round(
        currentIndex.exploration.Combustion.CO2 +
          currentIndex.drilling_and_development.Combustion.CO2 +
          currentIndex.production_and_extraction.Combustion.CO2 +
          currentIndex.crude_oil_storage.Combustion.CO2 +
          currentIndex.crude_oil_transport.Combustion.CO2
      );
      const combustionCO2explorationvalue = Math.round(
        currentIndex.exploration.Combustion.CO2
      );
      const combustionCO2drillingvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.CO2
      );
      const combustionCO2productionvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.CO2
      );
      const combustionCO2storagevalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.CO2
      );
      const combustionCO2transportvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.CO2
      );

      this.activityCo2Modal.detail.combustion.value.total =
        combustionCO2totalvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.combustion.value.exploration =
        combustionCO2explorationvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.combustion.value.drilling =
        combustionCO2drillingvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.combustion.value.production =
        combustionCO2productionvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.combustion.value.storage =
        combustionCO2storagevalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.combustion.value.transport =
        combustionCO2transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (combustionCO2totalvalue > 0) {
        this.activityCo2Modal.detail.combustion.percent.exploration =
          Math.round(
            (combustionCO2explorationvalue / combustionCO2totalvalue) * 100
          );

        this.activityCo2Modal.detail.combustion.percent.drilling = Math.round(
          (combustionCO2drillingvalue / combustionCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.combustion.percent.production = Math.round(
          (combustionCO2productionvalue / combustionCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.combustion.percent.storage = Math.round(
          (combustionCO2storagevalue / combustionCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.combustion.percent.transport = Math.round(
          (combustionCO2transportvalue / combustionCO2totalvalue) * 100
        );
      } else {
        this.activityCo2Modal.detail.combustion.percent.exploration = 0;
        this.activityCo2Modal.detail.combustion.percent.drilling = 0;
        this.activityCo2Modal.detail.combustion.percent.production = 0;
        this.activityCo2Modal.detail.combustion.percent.storage = 0;
        this.activityCo2Modal.detail.combustion.percent.transport = 0;
      }

      this.activityBarModalCo2OptionCom.series[0].data[0].value =
        combustionCO2explorationvalue;
      this.activityBarModalCo2OptionCom.series[0].data[1].value =
        combustionCO2drillingvalue;
      this.activityBarModalCo2OptionCom.series[0].data[2].value =
        combustionCO2productionvalue;
      this.activityBarModalCo2OptionCom.series[0].data[3].value =
        combustionCO2storagevalue;
      this.activityBarModalCo2OptionCom.series[0].data[4].value =
        combustionCO2transportvalue;

      // detail data value =====================================================================================================================
      const ventingCO2totalvalue = Math.round(
        currentIndex.exploration.Venting.CO2 +
          currentIndex.drilling_and_development.Venting.CO2 +
          currentIndex.production_and_extraction.Venting.CO2 +
          currentIndex.crude_oil_storage.Venting.CO2 +
          currentIndex.crude_oil_transport.Venting.CO2
      );
      const ventingCO2explorationvalue = Math.round(
        currentIndex.exploration.Venting.CO2
      );
      const ventingCO2drillingvalue = Math.round(
        currentIndex.drilling_and_development.Venting.CO2
      );
      const ventingCO2productionvalue = Math.round(
        currentIndex.production_and_extraction.Venting.CO2
      );
      const ventingCO2storagevalue = Math.round(
        currentIndex.crude_oil_storage.Venting.CO2
      );
      const ventingCO2transportvalue = Math.round(
        currentIndex.crude_oil_transport.Venting.CO2
      );

      this.activityCo2Modal.detail.venting.value.total =
        ventingCO2totalvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.venting.value.exploration =
        ventingCO2explorationvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.venting.value.drilling =
        ventingCO2drillingvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.venting.value.production =
        ventingCO2productionvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.venting.value.storage =
        ventingCO2storagevalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.venting.value.transport =
        ventingCO2transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (ventingCO2totalvalue > 0) {
        this.activityCo2Modal.detail.venting.percent.exploration = Math.round(
          (ventingCO2explorationvalue / ventingCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.venting.percent.drilling = Math.round(
          (ventingCO2drillingvalue / ventingCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.venting.percent.production = Math.round(
          (ventingCO2productionvalue / ventingCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.venting.percent.storage = Math.round(
          (ventingCO2storagevalue / ventingCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.venting.percent.transport = Math.round(
          (ventingCO2transportvalue / ventingCO2totalvalue) * 100
        );
      } else {
        this.activityCo2Modal.detail.venting.percent.exploration = 0;
        this.activityCo2Modal.detail.venting.percent.drilling = 0;
        this.activityCo2Modal.detail.venting.percent.production = 0;
        this.activityCo2Modal.detail.venting.percent.storage = 0;
        this.activityCo2Modal.detail.venting.percent.transport = 0;
      }

      this.activityBarModalCo2OptionVen.series[0].data[0].value =
        ventingCO2explorationvalue;
      this.activityBarModalCo2OptionVen.series[0].data[1].value =
        ventingCO2drillingvalue;
      this.activityBarModalCo2OptionVen.series[0].data[2].value =
        ventingCO2productionvalue;
      this.activityBarModalCo2OptionVen.series[0].data[3].value =
        ventingCO2storagevalue;
      this.activityBarModalCo2OptionVen.series[0].data[4].value =
        ventingCO2transportvalue;

      // detail data value =====================================================================================================================
      const flaringCO2totalvalue = Math.round(
        currentIndex.exploration.Flaring.CO2 +
          currentIndex.drilling_and_development.Flaring.CO2 +
          currentIndex.production_and_extraction.Flaring.CO2 +
          currentIndex.crude_oil_storage.Flaring.CO2 +
          currentIndex.crude_oil_transport.Flaring.CO2
      );
      const flaringCO2explorationvalue = Math.round(
        currentIndex.exploration.Flaring.CO2
      );
      const flaringCO2drillingvalue = Math.round(
        currentIndex.drilling_and_development.Flaring.CO2
      );
      const flaringCO2productionvalue = Math.round(
        currentIndex.production_and_extraction.Flaring.CO2
      );
      const flaringCO2storagevalue = Math.round(
        currentIndex.crude_oil_storage.Flaring.CO2
      );
      const flaringCO2transportvalue = Math.round(
        currentIndex.crude_oil_transport.Flaring.CO2
      );

      this.activityCo2Modal.detail.flaring.value.total =
        flaringCO2totalvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.flaring.value.exploration =
        flaringCO2explorationvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.flaring.value.drilling =
        flaringCO2drillingvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.flaring.value.production =
        flaringCO2productionvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.flaring.value.storage =
        flaringCO2storagevalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.flaring.value.transport =
        flaringCO2transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (flaringCO2totalvalue > 0) {
        this.activityCo2Modal.detail.flaring.percent.exploration = Math.round(
          (flaringCO2explorationvalue / flaringCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.flaring.percent.drilling = Math.round(
          (flaringCO2drillingvalue / flaringCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.flaring.percent.production = Math.round(
          (flaringCO2productionvalue / flaringCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.flaring.percent.storage = Math.round(
          (flaringCO2storagevalue / flaringCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.flaring.percent.transport = Math.round(
          (flaringCO2transportvalue / flaringCO2totalvalue) * 100
        );
      } else {
        this.activityCo2Modal.detail.flaring.percent.exploration = 0;
        this.activityCo2Modal.detail.flaring.percent.drilling = 0;
        this.activityCo2Modal.detail.flaring.percent.production = 0;
        this.activityCo2Modal.detail.flaring.percent.storage = 0;
        this.activityCo2Modal.detail.flaring.percent.transport = 0;
      }

      this.activityBarModalCo2OptionFla.series[0].data[0].value =
        flaringCO2explorationvalue;
      this.activityBarModalCo2OptionFla.series[0].data[1].value =
        flaringCO2drillingvalue;
      this.activityBarModalCo2OptionFla.series[0].data[2].value =
        flaringCO2productionvalue;
      this.activityBarModalCo2OptionFla.series[0].data[3].value =
        flaringCO2storagevalue;
      this.activityBarModalCo2OptionFla.series[0].data[4].value =
        flaringCO2transportvalue;

      // detail data value =====================================================================================================================
      const fugitivesCO2totalvalue = Math.round(
        currentIndex.exploration.Fugitives.CO2 +
          currentIndex.drilling_and_development.Fugitives.CO2 +
          currentIndex.production_and_extraction.Fugitives.CO2 +
          currentIndex.crude_oil_storage.Fugitives.CO2 +
          currentIndex.crude_oil_transport.Fugitives.CO2
      );
      const fugitivesCO2explorationvalue = Math.round(
        currentIndex.exploration.Fugitives.CO2
      );
      const fugitivesCO2drillingvalue = Math.round(
        currentIndex.drilling_and_development.Fugitives.CO2
      );
      const fugitivesCO2productionvalue = Math.round(
        currentIndex.production_and_extraction.Fugitives.CO2
      );
      const fugitivesCO2storagevalue = Math.round(
        currentIndex.crude_oil_storage.Fugitives.CO2
      );
      const fugitivesCO2transportvalue = Math.round(
        currentIndex.crude_oil_transport.Fugitives.CO2
      );

      this.activityCo2Modal.detail.fugitives.value.total =
        fugitivesCO2totalvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.fugitives.value.exploration =
        fugitivesCO2explorationvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.fugitives.value.drilling =
        fugitivesCO2drillingvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.fugitives.value.production =
        fugitivesCO2productionvalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.fugitives.value.storage =
        fugitivesCO2storagevalue.toLocaleString('ko-KR');
      this.activityCo2Modal.detail.fugitives.value.transport =
        fugitivesCO2transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (fugitivesCO2totalvalue > 0) {
        this.activityCo2Modal.detail.combustion.percent.exploration =
          Math.round(
            (fugitivesCO2explorationvalue / fugitivesCO2totalvalue) * 100
          );

        this.activityCo2Modal.detail.fugitives.percent.drilling = Math.round(
          (fugitivesCO2drillingvalue / fugitivesCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.fugitives.percent.production = Math.round(
          (fugitivesCO2productionvalue / fugitivesCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.fugitives.percent.storage = Math.round(
          (fugitivesCO2storagevalue / fugitivesCO2totalvalue) * 100
        );

        this.activityCo2Modal.detail.fugitives.percent.transport = Math.round(
          (fugitivesCO2transportvalue / fugitivesCO2totalvalue) * 100
        );
      } else {
        this.activityCo2Modal.detail.fugitives.percent.exploration = 0;
        this.activityCo2Modal.detail.fugitives.percent.drilling = 0;
        this.activityCo2Modal.detail.fugitives.percent.production = 0;
        this.activityCo2Modal.detail.fugitives.percent.storage = 0;
        this.activityCo2Modal.detail.fugitives.percent.transport = 0;
      }

      this.activityBarModalCo2OptionFugi.series[0].data[0].value =
        fugitivesCO2explorationvalue;
      this.activityBarModalCo2OptionFugi.series[0].data[1].value =
        fugitivesCO2drillingvalue;
      this.activityBarModalCo2OptionFugi.series[0].data[2].value =
        fugitivesCO2productionvalue;
      this.activityBarModalCo2OptionFugi.series[0].data[3].value =
        fugitivesCO2storagevalue;
      this.activityBarModalCo2OptionFugi.series[0].data[4].value =
        fugitivesCO2transportvalue;
    },
    openActivityDetailModalCh4(params) {
      this.activityModalCh4 = true;
      this.modalDate = params.name;
      const dateI = this.activityCh4option.xAxis.data.findIndex(
        (date) => date === params.name
      );

      const currentIndex = this.dailyData[dateI];

      // total data value
      const combustionCH4Sum = Math.round(
        currentIndex.exploration.Combustion.CH4 +
          currentIndex.drilling_and_development.Combustion.CH4 +
          currentIndex.production_and_extraction.Combustion.CH4 +
          currentIndex.crude_oil_storage.Combustion.CH4 +
          currentIndex.crude_oil_transport.Combustion.CH4
      );
      const ventingCH4Sum = Math.round(
        currentIndex.exploration.Venting.CH4 +
          currentIndex.drilling_and_development.Venting.CH4 +
          currentIndex.production_and_extraction.Venting.CH4 +
          currentIndex.crude_oil_storage.Venting.CH4 +
          currentIndex.crude_oil_transport.Venting.CH4
      );
      const flaringCH4Sum = Math.round(
        currentIndex.exploration.Flaring.CH4 +
          currentIndex.drilling_and_development.Flaring.CH4 +
          currentIndex.production_and_extraction.Flaring.CH4 +
          currentIndex.crude_oil_storage.Flaring.CH4 +
          currentIndex.crude_oil_transport.Flaring.CH4
      );
      const fugitivesCH4Sum = Math.round(
        currentIndex.exploration.Fugitives.CH4 +
          currentIndex.drilling_and_development.Fugitives.CH4 +
          currentIndex.production_and_extraction.Fugitives.CH4 +
          currentIndex.crude_oil_storage.Fugitives.CH4 +
          currentIndex.crude_oil_transport.Fugitives.CH4
      );

      this.activityCh4Modal.total.value.combustion =
        combustionCH4Sum.toLocaleString('ko-KR');
      this.activityCh4Modal.total.value.venting =
        ventingCH4Sum.toLocaleString('ko-KR');
      this.activityCh4Modal.total.value.flaring =
        flaringCH4Sum.toLocaleString('ko-KR');
      this.activityCh4Modal.total.value.fugitives =
        fugitivesCH4Sum.toLocaleString('ko-KR');

      // total data percent
      const dailyActivitySum =
        combustionCH4Sum + ventingCH4Sum + flaringCH4Sum + fugitivesCH4Sum;

      if (dailyActivitySum > 0) {
        this.activityCh4Modal.total.percent.combustion = (
          (combustionCH4Sum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityCh4Modal.total.percent.venting = (
          (ventingCH4Sum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityCh4Modal.total.percent.flaring = (
          (flaringCH4Sum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityCh4Modal.total.percent.fugitives = (
          (fugitivesCH4Sum / dailyActivitySum) *
          100
        ).toFixed(2);
      } else {
        this.activityCh4Modal.total.percent.combustion = 0;
        this.activityCh4Modal.total.percent.venting = 0;
        this.activityCh4Modal.total.percent.flaring = 0;
        this.activityCh4Modal.total.percent.fugitives = 0;
      }

      // total pie chart
      this.activityPieModalCh4Option.series[0].data[0].value = combustionCH4Sum;
      this.activityPieModalCh4Option.series[0].data[1].value = ventingCH4Sum;
      this.activityPieModalCh4Option.series[0].data[2].value = flaringCH4Sum;
      this.activityPieModalCh4Option.series[0].data[3].value = fugitivesCH4Sum;

      // detail data value =====================================================================================================================
      const combustionCH4totalvalue = Math.round(
        currentIndex.exploration.Combustion.CH4 +
          currentIndex.drilling_and_development.Combustion.CH4 +
          currentIndex.production_and_extraction.Combustion.CH4 +
          currentIndex.crude_oil_storage.Combustion.CH4 +
          currentIndex.crude_oil_transport.Combustion.CH4
      );
      const combustionCH4explorationvalue = Math.round(
        currentIndex.exploration.Combustion.CH4
      );
      const combustionCH4drillingvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.CH4
      );
      const combustionCH4productionvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.CH4
      );
      const combustionCH4storagevalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.CH4
      );
      const combustionCH4transportvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.CH4
      );

      this.activityCh4Modal.detail.combustion.value.total =
        combustionCH4totalvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.combustion.value.exploration =
        combustionCH4explorationvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.combustion.value.drilling =
        combustionCH4drillingvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.combustion.value.production =
        combustionCH4productionvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.combustion.value.storage =
        combustionCH4storagevalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.combustion.value.transport =
        combustionCH4transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (combustionCH4totalvalue > 0) {
        this.activityCh4Modal.detail.combustion.percent.exploration =
          Math.round(
            (combustionCH4explorationvalue / combustionCH4totalvalue) * 100
          );

        this.activityCh4Modal.detail.combustion.percent.drilling = Math.round(
          (combustionCH4drillingvalue / combustionCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.combustion.percent.production = Math.round(
          (combustionCH4productionvalue / combustionCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.combustion.percent.storage = Math.round(
          (combustionCH4storagevalue / combustionCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.combustion.percent.transport = Math.round(
          (combustionCH4transportvalue / combustionCH4totalvalue) * 100
        );
      } else {
        this.activityCh4Modal.detail.combustion.percent.exploration = 0;
        this.activityCh4Modal.detail.combustion.percent.drilling = 0;
        this.activityCh4Modal.detail.combustion.percent.production = 0;
        this.activityCh4Modal.detail.combustion.percent.storage = 0;
        this.activityCh4Modal.detail.combustion.percent.transport = 0;
      }

      this.activityBarModalCh4OptionCom.series[0].data[0].value =
        combustionCH4explorationvalue;
      this.activityBarModalCh4OptionCom.series[0].data[1].value =
        combustionCH4drillingvalue;
      this.activityBarModalCh4OptionCom.series[0].data[2].value =
        combustionCH4productionvalue;
      this.activityBarModalCh4OptionCom.series[0].data[3].value =
        combustionCH4storagevalue;
      this.activityBarModalCh4OptionCom.series[0].data[4].value =
        combustionCH4transportvalue;

      // detail data value =====================================================================================================================
      const ventingCH4totalvalue = Math.round(
        currentIndex.exploration.Venting.CH4 +
          currentIndex.drilling_and_development.Venting.CH4 +
          currentIndex.production_and_extraction.Venting.CH4 +
          currentIndex.crude_oil_storage.Venting.CH4 +
          currentIndex.crude_oil_transport.Venting.CH4
      );
      const ventingCH4explorationvalue = Math.round(
        currentIndex.exploration.Venting.CH4
      );
      const ventingCH4drillingvalue = Math.round(
        currentIndex.drilling_and_development.Venting.CH4
      );
      const ventingCH4productionvalue = Math.round(
        currentIndex.production_and_extraction.Venting.CH4
      );
      const ventingCH4storagevalue = Math.round(
        currentIndex.crude_oil_storage.Venting.CH4
      );
      const ventingCH4transportvalue = Math.round(
        currentIndex.crude_oil_transport.Venting.CH4
      );

      this.activityCh4Modal.detail.venting.value.total =
        ventingCH4totalvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.venting.value.exploration =
        ventingCH4explorationvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.venting.value.drilling =
        ventingCH4drillingvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.venting.value.production =
        ventingCH4productionvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.venting.value.storage =
        ventingCH4storagevalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.venting.value.transport =
        ventingCH4transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (ventingCH4totalvalue > 0) {
        this.activityCh4Modal.detail.venting.percent.exploration = Math.round(
          (ventingCH4explorationvalue / ventingCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.venting.percent.drilling = Math.round(
          (ventingCH4drillingvalue / ventingCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.venting.percent.production = Math.round(
          (ventingCH4productionvalue / ventingCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.venting.percent.storage = Math.round(
          (ventingCH4storagevalue / ventingCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.venting.percent.transport = Math.round(
          (ventingCH4transportvalue / ventingCH4totalvalue) * 100
        );
      } else {
        this.activityCh4Modal.detail.venting.percent.exploration = 0;
        this.activityCh4Modal.detail.venting.percent.drilling = 0;
        this.activityCh4Modal.detail.venting.percent.production = 0;
        this.activityCh4Modal.detail.venting.percent.storage = 0;
        this.activityCh4Modal.detail.venting.percent.transport = 0;
      }

      this.activityBarModalCh4OptionVen.series[0].data[0].value =
        ventingCH4explorationvalue;
      this.activityBarModalCh4OptionVen.series[0].data[1].value =
        ventingCH4drillingvalue;
      this.activityBarModalCh4OptionVen.series[0].data[2].value =
        ventingCH4productionvalue;
      this.activityBarModalCh4OptionVen.series[0].data[3].value =
        ventingCH4storagevalue;
      this.activityBarModalCh4OptionVen.series[0].data[4].value =
        ventingCH4transportvalue;

      // detail data value =====================================================================================================================
      const flaringCH4totalvalue = Math.round(
        currentIndex.exploration.Flaring.CH4 +
          currentIndex.drilling_and_development.Flaring.CH4 +
          currentIndex.production_and_extraction.Flaring.CH4 +
          currentIndex.crude_oil_storage.Flaring.CH4 +
          currentIndex.crude_oil_transport.Flaring.CH4
      );
      const flaringCH4explorationvalue = Math.round(
        currentIndex.exploration.Flaring.CH4
      );
      const flaringCH4drillingvalue = Math.round(
        currentIndex.drilling_and_development.Flaring.CH4
      );
      const flaringCH4productionvalue = Math.round(
        currentIndex.production_and_extraction.Flaring.CH4
      );
      const flaringCH4storagevalue = Math.round(
        currentIndex.crude_oil_storage.Flaring.CH4
      );
      const flaringCH4transportvalue = Math.round(
        currentIndex.crude_oil_transport.Flaring.CH4
      );

      this.activityCh4Modal.detail.flaring.value.total =
        flaringCH4totalvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.flaring.value.exploration =
        flaringCH4explorationvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.flaring.value.drilling =
        flaringCH4drillingvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.flaring.value.production =
        flaringCH4productionvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.flaring.value.storage =
        flaringCH4storagevalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.flaring.value.transport =
        flaringCH4transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (flaringCH4totalvalue > 0) {
        this.activityCh4Modal.detail.flaring.percent.exploration = Math.round(
          (flaringCH4explorationvalue / flaringCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.flaring.percent.drilling = Math.round(
          (flaringCH4drillingvalue / flaringCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.flaring.percent.production = Math.round(
          (flaringCH4productionvalue / flaringCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.flaring.percent.storage = Math.round(
          (flaringCH4storagevalue / flaringCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.flaring.percent.transport = Math.round(
          (flaringCH4transportvalue / flaringCH4totalvalue) * 100
        );
      } else {
        this.activityCh4Modal.detail.flaring.percent.exploration = 0;
        this.activityCh4Modal.detail.flaring.percent.drilling = 0;
        this.activityCh4Modal.detail.flaring.percent.production = 0;
        this.activityCh4Modal.detail.flaring.percent.storage = 0;
        this.activityCh4Modal.detail.flaring.percent.transport = 0;
      }

      this.activityBarModalCh4OptionFla.series[0].data[0].value =
        flaringCH4explorationvalue;
      this.activityBarModalCh4OptionFla.series[0].data[1].value =
        flaringCH4drillingvalue;
      this.activityBarModalCh4OptionFla.series[0].data[2].value =
        flaringCH4productionvalue;
      this.activityBarModalCh4OptionFla.series[0].data[3].value =
        flaringCH4storagevalue;
      this.activityBarModalCh4OptionFla.series[0].data[4].value =
        flaringCH4transportvalue;

      // detail data value =====================================================================================================================
      const fugitivesCH4totalvalue = Math.round(
        currentIndex.exploration.Fugitives.CH4 +
          currentIndex.drilling_and_development.Fugitives.CH4 +
          currentIndex.production_and_extraction.Fugitives.CH4 +
          currentIndex.crude_oil_storage.Fugitives.CH4 +
          currentIndex.crude_oil_transport.Fugitives.CH4
      );
      const fugitivesCH4explorationvalue = Math.round(
        currentIndex.exploration.Fugitives.CH4
      );
      const fugitivesCH4drillingvalue = Math.round(
        currentIndex.drilling_and_development.Fugitives.CH4
      );
      const fugitivesCH4productionvalue = Math.round(
        currentIndex.production_and_extraction.Fugitives.CH4
      );
      const fugitivesCH4storagevalue = Math.round(
        currentIndex.crude_oil_storage.Fugitives.CH4
      );
      const fugitivesCH4transportvalue = Math.round(
        currentIndex.crude_oil_transport.Fugitives.CH4
      );

      this.activityCh4Modal.detail.fugitives.value.total =
        fugitivesCH4totalvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.fugitives.value.exploration =
        fugitivesCH4explorationvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.fugitives.value.drilling =
        fugitivesCH4drillingvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.fugitives.value.production =
        fugitivesCH4productionvalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.fugitives.value.storage =
        fugitivesCH4storagevalue.toLocaleString('ko-KR');
      this.activityCh4Modal.detail.fugitives.value.transport =
        fugitivesCH4transportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (fugitivesCH4totalvalue > 0) {
        this.activityCh4Modal.detail.fugitives.percent.exploration = Math.round(
          (fugitivesCH4explorationvalue / fugitivesCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.fugitives.percent.drilling = Math.round(
          (fugitivesCH4drillingvalue / fugitivesCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.fugitives.percent.production = Math.round(
          (fugitivesCH4productionvalue / fugitivesCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.fugitives.percent.storage = Math.round(
          (fugitivesCH4storagevalue / fugitivesCH4totalvalue) * 100
        );

        this.activityCh4Modal.detail.fugitives.percent.transport = Math.round(
          (fugitivesCH4transportvalue / fugitivesCH4totalvalue) * 100
        );
      } else {
        this.activityCh4Modal.detail.fugitives.percent.exploration = 0;
        this.activityCh4Modal.detail.fugitives.percent.drilling = 0;
        this.activityCh4Modal.detail.fugitives.percent.production = 0;
        this.activityCh4Modal.detail.fugitives.percent.storage = 0;
        this.activityCh4Modal.detail.fugitives.percent.transport = 0;
      }

      this.activityBarModalCh4OptionFugi.series[0].data[0].value =
        fugitivesCH4explorationvalue;
      this.activityBarModalCh4OptionFugi.series[0].data[1].value =
        fugitivesCH4drillingvalue;
      this.activityBarModalCh4OptionFugi.series[0].data[2].value =
        fugitivesCH4productionvalue;
      this.activityBarModalCh4OptionFugi.series[0].data[3].value =
        fugitivesCH4storagevalue;
      this.activityBarModalCh4OptionFugi.series[0].data[4].value =
        fugitivesCH4transportvalue;
    },
    openActivityDetailModalNmhc(params) {
      this.activityModalNmhc = true;
      this.modalDate = params.name;
      const dateI = this.activityNmhcoption.xAxis.data.findIndex(
        (date) => date === params.name
      );

      const currentIndex = this.dailyData[dateI];

      // total data value
      const combustionNMHCSum = Math.round(
        currentIndex.exploration.Combustion.NMHC +
          currentIndex.drilling_and_development.Combustion.NMHC +
          currentIndex.production_and_extraction.Combustion.NMHC +
          currentIndex.crude_oil_storage.Combustion.NMHC +
          currentIndex.crude_oil_transport.Combustion.NMHC
      );
      const ventingNMHCSum = Math.round(
        currentIndex.exploration.Venting.NMHC +
          currentIndex.drilling_and_development.Venting.NMHC +
          currentIndex.production_and_extraction.Venting.NMHC +
          currentIndex.crude_oil_storage.Venting.NMHC +
          currentIndex.crude_oil_transport.Venting.NMHC
      );
      const flaringNMHCSum = Math.round(
        currentIndex.exploration.Flaring.NMHC +
          currentIndex.drilling_and_development.Flaring.NMHC +
          currentIndex.production_and_extraction.Flaring.NMHC +
          currentIndex.crude_oil_storage.Flaring.NMHC +
          currentIndex.crude_oil_transport.Flaring.NMHC
      );
      const fugitivesNMHCSum = Math.round(
        currentIndex.exploration.Fugitives.NMHC +
          currentIndex.drilling_and_development.Fugitives.NMHC +
          currentIndex.production_and_extraction.Fugitives.NMHC +
          currentIndex.crude_oil_storage.Fugitives.NMHC +
          currentIndex.crude_oil_transport.Fugitives.NMHC
      );

      this.activityNmhcModal.total.value.combustion =
        combustionNMHCSum.toLocaleString('ko-KR');
      this.activityNmhcModal.total.value.venting =
        ventingNMHCSum.toLocaleString('ko-KR');
      this.activityNmhcModal.total.value.flaring =
        flaringNMHCSum.toLocaleString('ko-KR');
      this.activityNmhcModal.total.value.fugitives =
        fugitivesNMHCSum.toLocaleString('ko-KR');

      // total data percent
      const dailyActivitySum =
        combustionNMHCSum + ventingNMHCSum + flaringNMHCSum + fugitivesNMHCSum;

      if (dailyActivitySum > 0) {
        this.activityNmhcModal.total.percent.combustion = (
          (combustionNMHCSum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityNmhcModal.total.percent.venting = (
          (ventingNMHCSum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityNmhcModal.total.percent.flaring = (
          (flaringNMHCSum / dailyActivitySum) *
          100
        ).toFixed(2);

        this.activityNmhcModal.total.percent.fugitives = (
          (fugitivesNMHCSum / dailyActivitySum) *
          100
        ).toFixed(2);
      } else {
        this.activityNmhcModal.total.percent.combustion = 0;
        this.activityNmhcModal.total.percent.venting = 0;
        this.activityNmhcModal.total.percent.flaring = 0;
        this.activityNmhcModal.total.percent.fugitives = 0;
      }

      // total pie chart
      this.activityPieModalNmhcOption.series[0].data[0].value =
        combustionNMHCSum;
      this.activityPieModalNmhcOption.series[0].data[1].value = ventingNMHCSum;
      this.activityPieModalNmhcOption.series[0].data[2].value = flaringNMHCSum;
      this.activityPieModalNmhcOption.series[0].data[3].value =
        fugitivesNMHCSum;

      // detail data value =====================================================================================================================
      const combustionNMHCtotalvalue = Math.round(
        currentIndex.exploration.Combustion.NMHC +
          currentIndex.drilling_and_development.Combustion.NMHC +
          currentIndex.production_and_extraction.Combustion.NMHC +
          currentIndex.crude_oil_storage.Combustion.NMHC +
          currentIndex.crude_oil_transport.Combustion.NMHC
      );
      const combustionNMHCexplorationvalue = Math.round(
        currentIndex.exploration.Combustion.NMHC
      );
      const combustionNMHCdrillingvalue = Math.round(
        currentIndex.drilling_and_development.Combustion.NMHC
      );
      const combustionNMHCproductionvalue = Math.round(
        currentIndex.production_and_extraction.Combustion.NMHC
      );
      const combustionNMHCstoragevalue = Math.round(
        currentIndex.crude_oil_storage.Combustion.NMHC
      );
      const combustionNMHCtransportvalue = Math.round(
        currentIndex.crude_oil_transport.Combustion.NMHC
      );

      this.activityNmhcModal.detail.combustion.value.total =
        combustionNMHCtotalvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.combustion.value.exploration =
        combustionNMHCexplorationvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.combustion.value.drilling =
        combustionNMHCdrillingvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.combustion.value.production =
        combustionNMHCproductionvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.combustion.value.storage =
        combustionNMHCstoragevalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.combustion.value.transport =
        combustionNMHCtransportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (combustionNMHCtotalvalue > 0) {
        this.activityNmhcModal.detail.combustion.percent.exploration =
          Math.round(
            (combustionNMHCexplorationvalue / combustionNMHCtotalvalue) * 100
          );

        this.activityNmhcModal.detail.combustion.percent.drilling = Math.round(
          (combustionNMHCdrillingvalue / combustionNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.combustion.percent.production =
          Math.round(
            (combustionNMHCproductionvalue / combustionNMHCtotalvalue) * 100
          );

        this.activityNmhcModal.detail.combustion.percent.storage = Math.round(
          (combustionNMHCstoragevalue / combustionNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.combustion.percent.transport = Math.round(
          (combustionNMHCtransportvalue / combustionNMHCtotalvalue) * 100
        );
      } else {
        this.activityNmhcModal.detail.combustion.percent.exploration = 0;
        this.activityNmhcModal.detail.combustion.percent.drilling = 0;
        this.activityNmhcModal.detail.combustion.percent.production = 0;
        this.activityNmhcModal.detail.combustion.percent.storage = 0;
        this.activityNmhcModal.detail.combustion.percent.transport = 0;
      }

      this.activityBarModalNmhcOptionCom.series[0].data[0].value =
        combustionNMHCexplorationvalue;
      this.activityBarModalNmhcOptionCom.series[0].data[1].value =
        combustionNMHCdrillingvalue;
      this.activityBarModalNmhcOptionCom.series[0].data[2].value =
        combustionNMHCproductionvalue;
      this.activityBarModalNmhcOptionCom.series[0].data[3].value =
        combustionNMHCstoragevalue;
      this.activityBarModalNmhcOptionCom.series[0].data[4].value =
        combustionNMHCtransportvalue;

      // detail data value =====================================================================================================================
      const ventingNMHCtotalvalue = Math.round(
        currentIndex.exploration.Venting.NMHC +
          currentIndex.drilling_and_development.Venting.NMHC +
          currentIndex.production_and_extraction.Venting.NMHC +
          currentIndex.crude_oil_storage.Venting.NMHC +
          currentIndex.crude_oil_transport.Venting.NMHC
      );
      const ventingNMHCexplorationvalue = Math.round(
        currentIndex.exploration.Venting.NMHC
      );
      const ventingNMHCdrillingvalue = Math.round(
        currentIndex.drilling_and_development.Venting.NMHC
      );
      const ventingNMHCproductionvalue = Math.round(
        currentIndex.production_and_extraction.Venting.NMHC
      );
      const ventingNMHCstoragevalue = Math.round(
        currentIndex.crude_oil_storage.Venting.NMHC
      );
      const ventingNMHCtransportvalue = Math.round(
        currentIndex.crude_oil_transport.Venting.NMHC
      );

      this.activityNmhcModal.detail.venting.value.total =
        ventingNMHCtotalvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.venting.value.exploration =
        ventingNMHCexplorationvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.venting.value.drilling =
        ventingNMHCdrillingvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.venting.value.production =
        ventingNMHCproductionvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.venting.value.storage =
        ventingNMHCstoragevalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.venting.value.transport =
        ventingNMHCtransportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (ventingNMHCtotalvalue > 0) {
        this.activityNmhcModal.detail.venting.percent.exploration = Math.round(
          (ventingNMHCexplorationvalue / ventingNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.venting.percent.drilling = Math.round(
          (ventingNMHCdrillingvalue / ventingNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.venting.percent.production = Math.round(
          (ventingNMHCproductionvalue / ventingNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.venting.percent.storage = Math.round(
          (ventingNMHCstoragevalue / ventingNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.venting.percent.transport = Math.round(
          (ventingNMHCtransportvalue / ventingNMHCtotalvalue) * 100
        );
      } else {
        this.activityNmhcModal.detail.venting.percent.exploration = 0;
        this.activityNmhcModal.detail.venting.percent.drilling = 0;
        this.activityNmhcModal.detail.venting.percent.production = 0;
        this.activityNmhcModal.detail.venting.percent.storage = 0;
        this.activityNmhcModal.detail.venting.percent.transport = 0;
      }

      this.activityBarModalNmhcOptionVen.series[0].data[0].value =
        ventingNMHCexplorationvalue;
      this.activityBarModalNmhcOptionVen.series[0].data[1].value =
        ventingNMHCdrillingvalue;
      this.activityBarModalNmhcOptionVen.series[0].data[2].value =
        ventingNMHCproductionvalue;
      this.activityBarModalNmhcOptionVen.series[0].data[3].value =
        ventingNMHCstoragevalue;
      this.activityBarModalNmhcOptionVen.series[0].data[4].value =
        ventingNMHCtransportvalue;

      // detail data value =====================================================================================================================
      const flaringNMHCtotalvalue = Math.round(
        currentIndex.exploration.Flaring.NMHC +
          currentIndex.drilling_and_development.Flaring.NMHC +
          currentIndex.production_and_extraction.Flaring.NMHC +
          currentIndex.crude_oil_storage.Flaring.NMHC +
          currentIndex.crude_oil_transport.Flaring.NMHC
      );
      const flaringNMHCexplorationvalue = Math.round(
        currentIndex.exploration.Flaring.NMHC
      );
      const flaringNMHCdrillingvalue = Math.round(
        currentIndex.drilling_and_development.Flaring.NMHC
      );
      const flaringNMHCproductionvalue = Math.round(
        currentIndex.production_and_extraction.Flaring.NMHC
      );
      const flaringNMHCstoragevalue = Math.round(
        currentIndex.crude_oil_storage.Flaring.NMHC
      );
      const flaringNMHCtransportvalue = Math.round(
        currentIndex.crude_oil_transport.Flaring.NMHC
      );

      this.activityNmhcModal.detail.flaring.value.total =
        flaringNMHCtotalvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.flaring.value.exploration =
        flaringNMHCexplorationvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.flaring.value.drilling =
        flaringNMHCdrillingvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.flaring.value.production =
        flaringNMHCproductionvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.flaring.value.storage =
        flaringNMHCstoragevalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.flaring.value.transport =
        flaringNMHCtransportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (flaringNMHCtotalvalue > 0) {
        this.activityNmhcModal.detail.flaring.percent.exploration = Math.round(
          (flaringNMHCexplorationvalue / flaringNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.flaring.percent.drilling = Math.round(
          (flaringNMHCdrillingvalue / flaringNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.flaring.percent.production = Math.round(
          (flaringNMHCproductionvalue / flaringNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.flaring.percent.storage = Math.round(
          (flaringNMHCstoragevalue / flaringNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.flaring.percent.transport = Math.round(
          (flaringNMHCtransportvalue / flaringNMHCtotalvalue) * 100
        );
      } else {
        this.activityNmhcModal.detail.flaring.percent.exploration = 0;
        this.activityNmhcModal.detail.flaring.percent.drilling = 0;
        this.activityNmhcModal.detail.flaring.percent.production = 0;
        this.activityNmhcModal.detail.flaring.percent.storage = 0;
        this.activityNmhcModal.detail.flaring.percent.transport = 0;
      }

      this.activityBarModalNmhcOptionFla.series[0].data[0].value =
        flaringNMHCexplorationvalue;
      this.activityBarModalNmhcOptionFla.series[0].data[1].value =
        flaringNMHCdrillingvalue;
      this.activityBarModalNmhcOptionFla.series[0].data[2].value =
        flaringNMHCproductionvalue;
      this.activityBarModalNmhcOptionFla.series[0].data[3].value =
        flaringNMHCstoragevalue;
      this.activityBarModalNmhcOptionFla.series[0].data[4].value =
        flaringNMHCtransportvalue;

      // detail data value =====================================================================================================================
      const fugitivesNMHCtotalvalue = Math.round(
        currentIndex.exploration.Fugitives.NMHC +
          currentIndex.drilling_and_development.Fugitives.NMHC +
          currentIndex.production_and_extraction.Fugitives.NMHC +
          currentIndex.crude_oil_storage.Fugitives.NMHC +
          currentIndex.crude_oil_transport.Fugitives.NMHC
      );
      const fugitivesNMHCexplorationvalue = Math.round(
        currentIndex.exploration.Fugitives.NMHC
      );
      const fugitivesNMHCdrillingvalue = Math.round(
        currentIndex.drilling_and_development.Fugitives.NMHC
      );
      const fugitivesNMHCproductionvalue = Math.round(
        currentIndex.production_and_extraction.Fugitives.NMHC
      );
      const fugitivesNMHCstoragevalue = Math.round(
        currentIndex.crude_oil_storage.Fugitives.NMHC
      );
      const fugitivesNMHCtransportvalue = Math.round(
        currentIndex.crude_oil_transport.Fugitives.NMHC
      );

      this.activityNmhcModal.detail.fugitives.value.total =
        fugitivesNMHCtotalvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.fugitives.value.exploration =
        fugitivesNMHCexplorationvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.fugitives.value.drilling =
        fugitivesNMHCdrillingvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.fugitives.value.production =
        fugitivesNMHCproductionvalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.fugitives.value.storage =
        fugitivesNMHCstoragevalue.toLocaleString('ko-KR');
      this.activityNmhcModal.detail.fugitives.value.transport =
        fugitivesNMHCtransportvalue.toLocaleString('ko-KR');

      // detail data percent
      if (fugitivesNMHCtotalvalue > 0) {
        this.activityNmhcModal.detail.fugitives.percent.exploration =
          Math.round(
            (fugitivesNMHCexplorationvalue / fugitivesNMHCtotalvalue) * 100
          );

        this.activityNmhcModal.detail.fugitives.percent.drilling = Math.round(
          (fugitivesNMHCdrillingvalue / fugitivesNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.fugitives.percent.production = Math.round(
          (fugitivesNMHCproductionvalue / fugitivesNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.fugitives.percent.storage = Math.round(
          (fugitivesNMHCstoragevalue / fugitivesNMHCtotalvalue) * 100
        );

        this.activityNmhcModal.detail.fugitives.percent.transport = Math.round(
          (fugitivesNMHCtransportvalue / fugitivesNMHCtotalvalue) * 100
        );
      } else {
        this.activityNmhcModal.detail.fugitives.percent.exploration = 0;
        this.activityNmhcModal.detail.fugitives.percent.drilling = 0;
        this.activityNmhcModal.detail.fugitives.percent.production = 0;
        this.activityNmhcModal.detail.fugitives.percent.storage = 0;
        this.activityNmhcModal.detail.fugitives.percent.transport = 0;
      }

      this.activityBarModalNmhcOptionFugi.series[0].data[0].value =
        fugitivesNMHCexplorationvalue;
      this.activityBarModalNmhcOptionFugi.series[0].data[1].value =
        fugitivesNMHCdrillingvalue;
      this.activityBarModalNmhcOptionFugi.series[0].data[2].value =
        fugitivesNMHCproductionvalue;
      this.activityBarModalNmhcOptionFugi.series[0].data[3].value =
        fugitivesNMHCstoragevalue;
      this.activityBarModalNmhcOptionFugi.series[0].data[4].value =
        fugitivesNMHCtransportvalue;
    },
  },
  created() {
    const p1_co2 = [],
      p2_co2 = [],
      p3_co2 = [],
      p4_co2 = [],
      p5_co2 = [],
      pTotal_co2 = [],
      p1_ch4 = [],
      p2_ch4 = [],
      p3_ch4 = [],
      p4_ch4 = [],
      p5_ch4 = [],
      pTotal_ch4 = [],
      p1_nmhc = [],
      p2_nmhc = [],
      p3_nmhc = [],
      p4_nmhc = [],
      p5_nmhc = [],
      pTotal_nmhc = [];

    const a1_co2 = [],
      a2_co2 = [],
      a3_co2 = [],
      a4_co2 = [],
      aTotal_co2 = [],
      a1_ch4 = [],
      a2_ch4 = [],
      a3_ch4 = [],
      a4_ch4 = [],
      aTotal_ch4 = [],
      a1_nmhc = [],
      a2_nmhc = [],
      a3_nmhc = [],
      a4_nmhc = [],
      aTotal_nmhc = [];

    for (let i = 0; i < this.dailyData.length; i++) {
      const exploration = this.dailyData[i].exploration;
      const drilling = this.dailyData[i].drilling_and_development;
      const extraction = this.dailyData[i].production_and_extraction;
      const storage = this.dailyData[i].crude_oil_storage;
      const transport = this.dailyData[i].crude_oil_transport;

      // Process_Co2
      p1_co2.push(
        exploration.Combustion.CO2 +
          exploration.Venting.CO2 +
          exploration.Flaring.CO2 +
          exploration.Fugitives.CO2
      );

      p2_co2.push(
        drilling.Combustion.CO2 +
          drilling.Venting.CO2 +
          drilling.Flaring.CO2 +
          drilling.Fugitives.CO2
      );

      p3_co2.push(
        extraction.Combustion.CO2 +
          extraction.Venting.CO2 +
          extraction.Flaring.CO2 +
          extraction.Fugitives.CO2
      );

      p4_co2.push(
        storage.Combustion.CO2 +
          storage.Venting.CO2 +
          storage.Flaring.CO2 +
          storage.Fugitives.CO2
      );

      p5_co2.push(
        transport.Combustion.CO2 +
          transport.Venting.CO2 +
          transport.Flaring.CO2 +
          transport.Fugitives.CO2
      );

      pTotal_co2.push(
        exploration.Combustion.CO2 +
          exploration.Venting.CO2 +
          exploration.Flaring.CO2 +
          exploration.Fugitives.CO2 +
          drilling.Combustion.CO2 +
          drilling.Venting.CO2 +
          drilling.Flaring.CO2 +
          drilling.Fugitives.CO2 +
          extraction.Combustion.CO2 +
          extraction.Venting.CO2 +
          extraction.Flaring.CO2 +
          extraction.Fugitives.CO2 +
          storage.Combustion.CO2 +
          storage.Venting.CO2 +
          storage.Flaring.CO2 +
          storage.Fugitives.CO2 +
          transport.Combustion.CO2 +
          transport.Venting.CO2 +
          transport.Flaring.CO2 +
          transport.Fugitives.CO2
      );

      // Process_CH4
      p1_ch4.push(
        exploration.Combustion.CH4 +
          exploration.Venting.CH4 +
          exploration.Flaring.CH4 +
          exploration.Fugitives.CH4
      );

      p2_ch4.push(
        drilling.Combustion.CH4 +
          drilling.Venting.CH4 +
          drilling.Flaring.CH4 +
          drilling.Fugitives.CH4
      );

      p3_ch4.push(
        extraction.Combustion.CH4 +
          extraction.Venting.CH4 +
          extraction.Flaring.CH4 +
          extraction.Fugitives.CH4
      );

      p4_ch4.push(
        storage.Combustion.CH4 +
          storage.Venting.CH4 +
          storage.Flaring.CH4 +
          storage.Fugitives.CH4
      );

      p5_ch4.push(
        transport.Combustion.CH4 +
          transport.Venting.CH4 +
          transport.Flaring.CH4 +
          transport.Fugitives.CH4
      );

      pTotal_ch4.push(
        exploration.Combustion.CH4 +
          exploration.Venting.CH4 +
          exploration.Flaring.CH4 +
          exploration.Fugitives.CH4 +
          drilling.Combustion.CH4 +
          drilling.Venting.CH4 +
          drilling.Flaring.CH4 +
          drilling.Fugitives.CH4 +
          extraction.Combustion.CH4 +
          extraction.Venting.CH4 +
          extraction.Flaring.CH4 +
          extraction.Fugitives.CH4 +
          storage.Combustion.CH4 +
          storage.Venting.CH4 +
          storage.Flaring.CH4 +
          storage.Fugitives.CH4 +
          transport.Combustion.CH4 +
          transport.Venting.CH4 +
          transport.Flaring.CH4 +
          transport.Fugitives.CH4
      );

      // Process_NMHC
      p1_nmhc.push(
        exploration.Combustion.NMHC +
          exploration.Venting.NMHC +
          exploration.Flaring.NMHC +
          exploration.Fugitives.NMHC
      );

      p2_nmhc.push(
        drilling.Combustion.NMHC +
          drilling.Venting.NMHC +
          drilling.Flaring.NMHC +
          drilling.Fugitives.NMHC
      );

      p3_nmhc.push(
        extraction.Combustion.NMHC +
          extraction.Venting.NMHC +
          extraction.Flaring.NMHC +
          extraction.Fugitives.NMHC
      );

      p4_nmhc.push(
        storage.Combustion.NMHC +
          storage.Venting.NMHC +
          storage.Flaring.NMHC +
          storage.Fugitives.NMHC
      );

      p5_nmhc.push(
        transport.Combustion.NMHC +
          transport.Venting.NMHC +
          transport.Flaring.NMHC +
          transport.Fugitives.NMHC
      );

      pTotal_nmhc.push(
        exploration.Combustion.NMHC +
          exploration.Venting.NMHC +
          exploration.Flaring.NMHC +
          exploration.Fugitives.NMHC +
          drilling.Combustion.NMHC +
          drilling.Venting.NMHC +
          drilling.Flaring.NMHC +
          drilling.Fugitives.NMHC +
          extraction.Combustion.NMHC +
          extraction.Venting.NMHC +
          extraction.Flaring.NMHC +
          extraction.Fugitives.NMHC +
          storage.Combustion.NMHC +
          storage.Venting.NMHC +
          storage.Flaring.NMHC +
          storage.Fugitives.NMHC +
          transport.Combustion.NMHC +
          transport.Venting.NMHC +
          transport.Flaring.NMHC +
          transport.Fugitives.NMHC
      );

      // Activity_Co2
      a1_co2.push(
        exploration.Combustion.CO2 +
          drilling.Combustion.CO2 +
          extraction.Combustion.CO2 +
          storage.Combustion.CO2 +
          transport.Combustion.CO2
      );

      a2_co2.push(
        exploration.Venting.CO2 +
          drilling.Venting.CO2 +
          extraction.Venting.CO2 +
          storage.Venting.CO2 +
          transport.Venting.CO2
      );
      a3_co2.push(
        exploration.Flaring.CO2 +
          drilling.Flaring.CO2 +
          extraction.Flaring.CO2 +
          storage.Flaring.CO2 +
          transport.Flaring.CO2
      );
      a4_co2.push(
        exploration.Fugitives.CO2 +
          drilling.Fugitives.CO2 +
          extraction.Fugitives.CO2 +
          storage.Fugitives.CO2 +
          transport.Fugitives.CO2
      );
      aTotal_co2.push(
        exploration.Combustion.CO2 +
          drilling.Combustion.CO2 +
          extraction.Combustion.CO2 +
          storage.Combustion.CO2 +
          transport.Combustion.CO2 +
          exploration.Venting.CO2 +
          drilling.Venting.CO2 +
          extraction.Venting.CO2 +
          storage.Venting.CO2 +
          transport.Venting.CO2 +
          exploration.Flaring.CO2 +
          drilling.Flaring.CO2 +
          extraction.Flaring.CO2 +
          storage.Flaring.CO2 +
          transport.Flaring.CO2 +
          exploration.Fugitives.CO2 +
          drilling.Fugitives.CO2 +
          extraction.Fugitives.CO2 +
          storage.Fugitives.CO2 +
          transport.Fugitives.CO2
      );

      // Activity_CH4
      a1_ch4.push(
        exploration.Combustion.CH4 +
          drilling.Combustion.CH4 +
          extraction.Combustion.CH4 +
          storage.Combustion.CH4 +
          transport.Combustion.CH4
      );

      a2_ch4.push(
        exploration.Venting.CH4 +
          drilling.Venting.CH4 +
          extraction.Venting.CH4 +
          storage.Venting.CH4 +
          transport.Venting.CH4
      );
      a3_ch4.push(
        exploration.Flaring.CH4 +
          drilling.Flaring.CH4 +
          extraction.Flaring.CH4 +
          storage.Flaring.CH4 +
          transport.Flaring.CH4
      );
      a4_ch4.push(
        exploration.Fugitives.CH4 +
          drilling.Fugitives.CH4 +
          extraction.Fugitives.CH4 +
          storage.Fugitives.CH4 +
          transport.Fugitives.CH4
      );
      aTotal_ch4.push(
        exploration.Combustion.CH4 +
          drilling.Combustion.CH4 +
          extraction.Combustion.CH4 +
          storage.Combustion.CH4 +
          transport.Combustion.CH4 +
          exploration.Venting.CH4 +
          drilling.Venting.CH4 +
          extraction.Venting.CH4 +
          storage.Venting.CH4 +
          transport.Venting.CH4 +
          exploration.Flaring.CH4 +
          drilling.Flaring.CH4 +
          extraction.Flaring.CH4 +
          storage.Flaring.CH4 +
          transport.Flaring.CH4 +
          exploration.Fugitives.CH4 +
          drilling.Fugitives.CH4 +
          extraction.Fugitives.CH4 +
          storage.Fugitives.CH4 +
          transport.Fugitives.CH4
      );

      // Activity_NMHC
      a1_nmhc.push(
        exploration.Combustion.NMHC +
          drilling.Combustion.NMHC +
          extraction.Combustion.NMHC +
          storage.Combustion.NMHC +
          transport.Combustion.NMHC
      );

      a2_nmhc.push(
        exploration.Venting.NMHC +
          drilling.Venting.NMHC +
          extraction.Venting.NMHC +
          storage.Venting.NMHC +
          transport.Venting.NMHC
      );
      a3_nmhc.push(
        exploration.Flaring.NMHC +
          drilling.Flaring.NMHC +
          extraction.Flaring.NMHC +
          storage.Flaring.NMHC +
          transport.Flaring.NMHC
      );
      a4_nmhc.push(
        exploration.Fugitives.NMHC +
          drilling.Fugitives.NMHC +
          extraction.Fugitives.NMHC +
          storage.Fugitives.NMHC +
          transport.Fugitives.NMHC
      );
      aTotal_nmhc.push(
        exploration.Combustion.NMHC +
          drilling.Combustion.NMHC +
          extraction.Combustion.NMHC +
          storage.Combustion.NMHC +
          transport.Combustion.NMHC +
          exploration.Venting.NMHC +
          drilling.Venting.NMHC +
          extraction.Venting.NMHC +
          storage.Venting.NMHC +
          transport.Venting.NMHC +
          exploration.Flaring.NMHC +
          drilling.Flaring.NMHC +
          extraction.Flaring.NMHC +
          storage.Flaring.NMHC +
          transport.Flaring.NMHC +
          exploration.Fugitives.NMHC +
          drilling.Fugitives.NMHC +
          extraction.Fugitives.NMHC +
          storage.Fugitives.NMHC +
          transport.Fugitives.NMHC
      );
    }

    this.processCo2option.series[0].data = p1_co2;
    this.processCo2option.series[1].data = p2_co2;
    this.processCo2option.series[2].data = p3_co2;
    this.processCo2option.series[3].data = p4_co2;
    this.processCo2option.series[4].data = p5_co2;
    this.processCo2option.series[5].data = pTotal_co2;

    this.processCh4option.series[0].data = p1_ch4;
    this.processCh4option.series[1].data = p2_ch4;
    this.processCh4option.series[2].data = p3_ch4;
    this.processCh4option.series[3].data = p4_ch4;
    this.processCh4option.series[4].data = p5_ch4;
    this.processCh4option.series[5].data = pTotal_ch4;

    this.processNmhcoption.series[0].data = p1_nmhc;
    this.processNmhcoption.series[1].data = p2_nmhc;
    this.processNmhcoption.series[2].data = p3_nmhc;
    this.processNmhcoption.series[3].data = p4_nmhc;
    this.processNmhcoption.series[4].data = p5_nmhc;
    this.processNmhcoption.series[5].data = pTotal_nmhc;

    this.activityCo2option.series[0].data = a1_co2;
    this.activityCo2option.series[1].data = a2_co2;
    this.activityCo2option.series[2].data = a3_co2;
    this.activityCo2option.series[3].data = a4_co2;
    this.activityCo2option.series[4].data = aTotal_co2;

    this.activityCh4option.series[0].data = a1_ch4;
    this.activityCh4option.series[1].data = a2_ch4;
    this.activityCh4option.series[2].data = a3_ch4;
    this.activityCh4option.series[3].data = a4_ch4;
    this.activityCh4option.series[4].data = aTotal_ch4;

    this.activityNmhcoption.series[0].data = a1_nmhc;
    this.activityNmhcoption.series[1].data = a2_nmhc;
    this.activityNmhcoption.series[2].data = a3_nmhc;
    this.activityNmhcoption.series[3].data = a4_nmhc;
    this.activityNmhcoption.series[4].data = aTotal_nmhc;

    // summary 구하기
    const totalCo2 =
      pTotal_co2.reduce((a, b) => a + b) + aTotal_co2.reduce((a, b) => a + b);
    const totalCh4 =
      pTotal_ch4.reduce((a, b) => a + b) + aTotal_ch4.reduce((a, b) => a + b);
    const totalNmhc =
      pTotal_nmhc.reduce((a, b) => a + b) + aTotal_nmhc.reduce((a, b) => a + b);

    this.co2Summary = Math.round(totalCo2).toLocaleString('ko-KR');
    this.ch4Summary = Math.round(totalCh4).toLocaleString('ko-KR');
    this.nmhcSummary = Math.round(totalNmhc).toLocaleString('ko-KR');
  },
});
