
import Vue from 'vue';
import TheLogo from '@/components/re100/TheLogo.vue';
import TheNav from '@/components/re100/TheNav.vue';
import re100Service from '@/js/re100-event';
import { TRX_WS_URL } from '@/js/serverProtocol';
import { IDX_WIND, IDX_PV, IDX_BUILDING, IDX_FACTORY } from '@/js/sesumeter-id';
import io from 'socket.io-client';
import '@lottiefiles/lottie-player';

const CONTRACTOR = 'Hong, Gildong';
const EMPTY_STRING = '';

export default Vue.extend({
  name: 'PPA',
  components: { TheLogo, TheNav },
  data() {
    return {
      socket: null,
      trxs: [{ idx: '', data: '' }],
      sesumeterInfo: [],
      sesumeterChart: [],
      isPPAContract: false,
      contractName: '',
      certificateDate: '',
      windPowerData: 0,
      pvPowerData: 0,
      buildingPowerData: 0,
      factoryPowerData: 0,
      totalConsumption: 0,
      energyConsumption: 0,
      totalGeneData: 0,
      totalConsData: 0,
      player: null,
    };
  },
  created() {
    this.socket = io(TRX_WS_URL);
  },
  mounted() {
    re100Service.getSesuMeterInfoData().subscribe({
      next: (info) => {
        this.sesumeterInfo = info;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    re100Service.getSesuMeterChartData().subscribe({
      next: (data) => {
        this.sesumeterChart = data;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    this.socket.on('trx', (msg) => {
      const trxJsonData = JSON.parse(msg);
      const trxData = trxJsonData.trx;
      const msgData = trxJsonData.msg;

      const trxs = {
        idx: trxData,
        data: msgData,
      };

      if (this.trxs.length > 3) {
        this.trxs.shift();
      }
      this.trxs.push(trxs);
    });
    this.player = this.$refs.player;
    this.certificateDate = this.$moment().format('YYYY.MM.DD');
    this.getSesumeterChartData();
    this.getTotalConsumptionData();
    this.runPPAAnimation();
    if (this.isPPAContract) {
      this.getEnergyConsumptionData();
    }
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  watch: {
    $route() {
      this.totalConsumption = 0;
    },
    sesumeterChart() {
      this.getSesumeterChartData();
      this.getTotalConsumptionData();
      this.getEnergyConsumptionData();
    },
    isPPAContract() {
      this.energyConsumption = 0;
      this.getEnergyConsumptionData();
      this.runPPAAnimation();
    },
  },
  computed: {
    totalCons() {
      return Number(this.totalConsumption.toFixed(1)).toLocaleString('ko-KR');
    },
    energyCons() {
      return Number(this.energyConsumption.toFixed(1)).toLocaleString('ko-KR');
    },
  },
  methods: {
    getSesumeterChartData() {
      const windPower = [];
      const pvPower = [];
      const buildingPower = [];
      const factoryPower = [];

      for (let i = 0; i <= 24; i++) {
        const wp = this.sesumeterChart[IDX_WIND].chart[i].power;
        const pp = this.sesumeterChart[IDX_PV].chart[i].power;
        const bp = this.sesumeterChart[IDX_BUILDING].chart[i].power;
        const fp = this.sesumeterChart[IDX_FACTORY].chart[i].power;
        windPower.push(wp);
        pvPower.push(pp);
        buildingPower.push(bp);
        factoryPower.push(fp);
      }

      this.windPowerData = windPower.reduce((a, b) => a + b);
      this.pvPowerData = pvPower.reduce((a, b) => a + b);
      this.buildingPowerData = buildingPower.reduce((a, b) => a + b);
      this.factoryPowerData = factoryPower.reduce((a, b) => a + b);

      this.totalGeneData = this.windPowerData + this.pvPowerData;
      this.totalConsData = this.buildingPowerData + this.factoryPowerData;
    },
    getTotalConsumptionData() {
      if (this.sesumeterChart != null) {
        const totalCons = this.buildingPowerData + this.factoryPowerData;
        this.totalConsumption = this.totalConsumption + totalCons / 1000;
      }
    },
    getEnergyConsumptionData() {
      if (this.isPPAContract) {
        if (this.sesumeterChart != null) {
          const energyCons = this.buildingPowerData + this.factoryPowerData;
          this.energyConsumption = this.energyConsumption + energyCons / 1000;
        }
      }
    },
    onClickPPAContract() {
      if (!this.isPPAContract) {
        this.isPPAContract = true;
        this.contractName = CONTRACTOR;
      } else {
        this.isPPAContract = false;
        this.contractName = EMPTY_STRING;
      }
    },
    runPPAAnimation() {
      if (this.isPPAContract) {
        if (this.totalGeneData != null && this.totalConsData != null) {
          if (this.totalGeneData == this.totalConsData) {
            this.player.load(`${process.env.BASE_URL}animation/sameAnim.json`);
          }
          if (this.totalGeneData > this.totalConsData) {
            this.player.load(`${process.env.BASE_URL}animation/geneAnim.json`);
          }
          if (this.totalGeneData < this.totalConsData) {
            this.player.load(`${process.env.BASE_URL}animation/consAnim.json`);
          }
        }
      }
      if (!this.isPPAContract) {
        this.player.load(`${process.env.BASE_URL}animation/noPPAAnim.json`);
      }
    },
  },
});
