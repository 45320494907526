import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import { BootstrapVue } from 'bootstrap-vue';
import ECharts from 'vue-echarts';
import * as echarts from 'echarts';
import {} from 'echarts/charts';
import VueMoment from 'vue-moment';
import VCalendar from 'v-calendar';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.component('v-chart', ECharts);
echarts.use([]);
Vue.use(VueMoment);
Vue.use(VCalendar);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
