const INIT_WEB = 'INIT_WEB';
const MSG_USER = 'MSG_USER_INFO';
const MSG_ORDERBOOK = 'MSG_ORDERBOOK';
const MSG_BANK = 'MSG_BANK';
const MSG_BANK_TRX = 'MSG_BANK_TRX';
const MSG_TRX_LOG = 'MSG_TRX_LOG';
const MSG_CAMPAIGN = 'MSG_CAMPAIGN';
const MSG_CAMPAIGN_LOG = 'MSG_CAMPAIGN_LOG';

export {
  INIT_WEB,
  MSG_USER,
  MSG_ORDERBOOK,
  MSG_BANK,
  MSG_BANK_TRX,
  MSG_TRX_LOG,
  MSG_CAMPAIGN,
  MSG_CAMPAIGN_LOG,
};
