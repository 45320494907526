
import Vue from 'vue';
import { WEB_DEMO_URL } from '@/js/serverProtocol';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';

export default Vue.extend({
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    handleChange(event) {
      this[event.target.name] = event.target.value;
    },

    isValidEmail(email: string) {
      return email !== '';
    },

    isValidPwd(password: string) {
      return password !== '';
    },

    onClickLoginButton(e) {
      e.preventDefault();

      if (!this.isValidEmail(this.email)) {
        alert('아이디가 유효하지 않습니다.');
        return;
      }

      if (!this.isValidPwd(this.password)) {
        alert('비밀번호가 유효하지 않습니다.');
        return;
      }

      const options = {
        method: 'POST',
        url: `${WEB_DEMO_URL}authentication`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email: this.email,
          password: this.password,
          uuid: uuidv1(),
        },
      };
      axios
        .request(options)
        .then((response) => {
          sessionStorage.setItem('uid', response.data.uid);
          sessionStorage.setItem('accessToken', response.data.accessToken);
          sessionStorage.setItem('refreshToken', response.data.refreshToken);
          sessionStorage.setItem(
            'accessTokenExpired',
            response.data.accessTokenExpired
          );
          sessionStorage.setItem(
            'refreshTokenExpired',
            response.data.refreshTokenExpired
          );
          this.$router.push('/apps');
        })
        .catch((error) => {
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
  },
});
