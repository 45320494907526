
import Vue from 'vue';
import TheLogo from '@/components/re100/TheLogo.vue';
import TheNav from '@/components/re100/TheNav.vue';
import re100Service from '@/js/re100-event';
import { WEB_DEMO_URL } from '@/js/serverProtocol';
import { SESU_METER_ID, MeterIdx } from '@/js/sesumeter-id';
import axios from 'axios';
import ECharts from 'vue-echarts';

const getDInfoTableOption = (
  idx: MeterIdx,
  type: string,
  f: string,
  t: string
) => {
  return {
    method: 'GET',
    url: `${WEB_DEMO_URL}sesumeters/${SESU_METER_ID[idx]}/charts/${type}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    },
    params: {
      f: f,
      t: t,
    },
  };
};

export default Vue.extend({
  components: { TheLogo, TheNav, ECharts },
  data() {
    return {
      ownerId: 1,
      selected: 'daily',
      sesumeterInfo: [],
      sesumeterChart: [],
      statisticsTableData: [],
      statisticsTableDataInitial: '',
      statisticsTableSelectIndex: 0,
      statisticsTableSelectDate: '',
      statisticsTableSelectDateF: '',
      statisticsTableSelectDateT: '',
      statisticsChartOptionType: 'hourly',
      statisticsChartOption: {
        title: {
          text: 'Statistics Chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        grid: { top: 10, right: 5, bottom: 20, left: 40 },
        tooltip: {
          trigger: 'item',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            name: 'Generation',
            data: [],
            type: 'bar',
            color: '#B749CA',
            zlevel: 9,
            z: 9,
          },
          {
            name: 'Consumption',
            data: [],
            type: 'bar',
            color: '#46BED7',
            zlevel: 9,
            z: 9,
          },
          {
            name: 'Grid',
            data: [],
            type: 'bar',
            color: '#E66649',
            zlevel: 9,
            z: 9,
          },
        ],
      },
      dInfoTableWindElec: 0,
      dInfoTablePvElec: 0,
      dInfoTableBuildingElec: 0,
      dInfoTableFactoryElec: 0,
      dInfoTableGridElec: 0,
      dInfoTableWindProfit: 0,
      dInfoTablePvProfit: 0,
      dInfoTableBuildingProfit: 0,
      dInfoTableFactoryProfit: 0,
      dInfoTableGridProfit: 0,
    };
  },
  mounted() {
    re100Service.getSesuMeterInfoData().subscribe({
      next: (info) => {
        this.sesumeterInfo = info;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    re100Service.getSesuMeterChartData().subscribe({
      next: (data) => {
        this.sesumeterChart = data;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    this.getPeriodChartData();
  },
  watch: {
    selected() {
      this.statisticsTableSelectIndex = 0;
      this.getPeriodChartData();
    },
  },
  methods: {
    onClickSelectBox(event) {
      this.selected = event.target.value;
    },
    getPeriodChartData() {
      const options = {
        method: 'GET',
        url: `${WEB_DEMO_URL}organizations/${this.ownerId}/charts/${this.selected}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      };
      axios
        .request(options)
        .then((response) => {
          this.statisticsTableData = response.data.data;
          if (this.selected == 'monthly' || this.selected == 'daily') {
            this.statisticsTableDataInitial = this.statisticsTableData[0].date;
          }
          if (this.selected == 'weekly') {
            this.statisticsTableDataInitial = this.statisticsTableData[0].f;
          }
          this.onClickStatisticsTableSelectRow(
            0,
            this.statisticsTableDataInitial
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStatisticsTableRowClass(index) {
      return this.statisticsTableSelectIndex === index
        ? `selected`
        : `unSelected`;
    },
    onClickStatisticsTableSelectRow(index, date) {
      this.statisticsTableSelectDateF = date;

      if (this.selected == 'monthly') {
        const calcDate = this.$moment(date).add(1, 'M').subtract(1, 'd');
        this.statisticsTableSelectDateT = this.$moment(calcDate._d).format(
          'YYYY-MM-DD 23:59:59'
        );
        this.statisticsTableSelectDate = this.$moment(
          this.statisticsTableSelectDateF
        ).format('YYYY-MM');
      }
      if (this.selected == 'weekly') {
        const calcDate = this.$moment(date).add(1, 'w').subtract(1, 'd');
        this.statisticsTableSelectDateT = this.$moment(calcDate._d).format(
          'YYYY-MM-DD 23:59:59'
        );
        this.statisticsTableSelectDate = `${this.$moment(
          this.statisticsTableSelectDateF
        ).format('YYYY-MM-DD')} ~ ${this.$moment(
          this.statisticsTableSelectDateT
        ).format('YYYY-MM-DD')}`;
      }
      if (this.selected == 'daily') {
        this.statisticsTableSelectDateT = this.$moment(date).format(
          'YYYY-MM-DD 23:59:59'
        );
        this.statisticsTableSelectDate = this.$moment(
          this.statisticsTableSelectDateF
        ).format('YYYY-MM-DD');
      }
      this.getStatisticsChartData();
      this.getDInfoTableData();
      return (this.statisticsTableSelectIndex = index);
    },
    getStatisticsChartData() {
      if (this.selected == 'monthly' || this.selected == 'weekly') {
        this.statisticsChartOptionType = 'daily';
      } else if (this.selected == 'daily') {
        this.statisticsChartOptionType = 'hourly';
      }

      const option = {
        method: 'GET',
        url: `${WEB_DEMO_URL}organizations/${this.ownerId}/charts/${this.statisticsChartOptionType}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
        params: {
          f: this.statisticsTableSelectDateF,
          t: this.statisticsTableSelectDateT,
        },
      };
      axios
        .request(option)
        .then((response) => {
          const statisChartData = response.data.data;
          const statisChartXData = [];
          const statisChartYDataProd = [];
          const statisChartYDataCons = [];
          const statisChartYDataGrid = [];

          for (let i = statisChartData.length - 1; i >= 0; i--) {
            statisChartXData.push(statisChartData[i].date);
            statisChartYDataProd.push(statisChartData[i].production.toFixed(1));
            statisChartYDataCons.push(
              statisChartData[i].consumption.toFixed(1)
            );
            statisChartYDataGrid.push(statisChartData[i].grid.toFixed(1));
          }

          this.statisticsChartOption.xAxis.data = statisChartXData;
          this.statisticsChartOption.series[0].data = statisChartYDataProd;
          this.statisticsChartOption.series[1].data = statisChartYDataCons;
          this.statisticsChartOption.series[2].data = statisChartYDataGrid;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDInfoTableData() {
      const SYSTEM_MARGINAL_PRICE = 85.06;
      axios
        .request(
          getDInfoTableOption(
            0,
            this.selected,
            this.statisticsTableSelectDateF,
            this.statisticsTableSelectDateT
          )
        )
        .then((response) => {
          const whData = response.data.data[0].wh.toFixed(1);
          this.dInfoTableWindElec = Number(whData).toLocaleString('ko-KR');
          this.dInfoTableWindProfit = Number(
            Math.ceil(whData * SYSTEM_MARGINAL_PRICE)
          ).toLocaleString('ko-KR');
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .request(
          getDInfoTableOption(
            1,
            this.selected,
            this.statisticsTableSelectDateF,
            this.statisticsTableSelectDateT
          )
        )
        .then((response) => {
          const whData = response.data.data[0].wh.toFixed(1);
          this.dInfoTablePvElec = Number(whData).toLocaleString('ko-KR');
          this.dInfoTablePvProfit = Number(
            Math.ceil(whData * SYSTEM_MARGINAL_PRICE)
          ).toLocaleString('ko-KR');
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .request(
          getDInfoTableOption(
            2,
            this.selected,
            this.statisticsTableSelectDateF,
            this.statisticsTableSelectDateT
          )
        )
        .then((response) => {
          const whData = response.data.data[0].wh.toFixed(1);
          this.dInfoTableBuildingElec = Number(whData).toLocaleString('ko-KR');
          this.dInfoTableBuildingProfit = Number(
            Math.ceil(whData * (SYSTEM_MARGINAL_PRICE * -1))
          ).toLocaleString('ko-KR');
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .request(
          getDInfoTableOption(
            3,
            this.selected,
            this.statisticsTableSelectDateF,
            this.statisticsTableSelectDateT
          )
        )
        .then((response) => {
          const whData = response.data.data[0].wh.toFixed(1);
          this.dInfoTableFactoryElec = Number(whData).toLocaleString('ko-KR');
          this.dInfoTableFactoryProfit = Number(
            Math.ceil(whData * (SYSTEM_MARGINAL_PRICE * -1))
          ).toLocaleString('ko-KR');
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .request(
          getDInfoTableOption(
            4,
            this.selected,
            this.statisticsTableSelectDateF,
            this.statisticsTableSelectDateT
          )
        )
        .then((response) => {
          const whData = response.data.data[0].wh.toFixed(1);
          this.dInfoTableGridElec = Number(whData).toLocaleString('ko-KR');
          this.dInfoTableGridProfit = Number(
            Math.ceil(whData * SYSTEM_MARGINAL_PRICE)
          ).toLocaleString('ko-KR');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    statisticsTablePeriodDateFormat(date) {
      return this.$moment(date).format('YYYY. MM. DD');
    },
    statisticsTableDataFormat(data) {
      return Number(data.toFixed(1)).toLocaleString('ko-KR');
    },
    statisticsTableItemFormat(item) {
      if (item.length > 22) {
        return item.substr(0, 22) + '...';
      } else {
        return item;
      }
    },
  },
});
