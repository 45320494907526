
import Vue from 'vue';
import Header from '@/components/vcm/TheHeader.vue';
import Footer from '@/components/vcm/TheFooterMenu.vue';
import axios from 'axios';
import { CM_WS_URL } from '@/js/serverProtocol';
import { Order, Transaction, BankTransaction } from '@/js/types';

export default Vue.extend({
  components: { Header, Footer },
  props: ['headerValue', 'navValue', 'userInfoValue'],
  data() {
    return {
      menuName: 0,
      userInfoData: this.userInfoValue,
      loading: true,
      orderVolume: null,
      date: new Date(),
      assetTypeBuy: 1,
      orderPrice: null,
      orderList: [] as Order[],
      registrationModal: false,
      confirmModal: false,
      orderId: null,
      transactionList: [] as Transaction[],
      payment: null,
      paymentAuto: null,
      ecocredit: null,
      ecocreditAuto: null,
      fundPopup: false,
      ecocreditPopup: false,
      historyList: [] as BankTransaction[],
    };
  },
  mounted() {
    if (this.$store.state.buyerInfoComplete) {
      this.printOrderList();
      this.printTransactionList();
      this.printHistoryList();
    }
  },
  watch: {
    checkInfoComplete() {
      this.printOrderList();
      this.printTransactionList();
      this.printHistoryList();
    },
    payment() {
      return (this.paymentAuto = this.payment);
    },
    ecocredit() {
      return (this.ecocreditAuto = this.ecocredit);
    },
  },
  computed: {
    menu0Style(): string {
      return this.menuName === 0 ? `display: block` : `display: none`;
    },
    menu1Style(): string {
      return this.menuName === 1 ? `display: block` : `display: none`;
    },
    menu2Style(): string {
      return this.menuName === 2 ? `display: block` : `display: none`;
    },
    loadingStyle(): string {
      return this.loading ? `display: block` : `display: none`;
    },
    checkInfoComplete() {
      return this.$store.getters.getBuyerInfoComplete;
    },
    registrationModalStyle(): string {
      return this.registrationModal ? `display: block` : `display: none`;
    },
    confirmModalStyle(): string {
      return this.confirmModal ? `display: block` : `display: none`;
    },
    fundPopupStyle(): string {
      return this.fundPopup ? `display:block` : `display:none`;
    },
    ecocreditPopupStyle(): string {
      return this.ecocreditPopup ? `display:block` : `display:none`;
    },
  },
  methods: {
    currentMenu(data: number): void {
      this.menuName = data;
    },
    isLoading(): void {
      this.loading = false;
    },
    handleChange(event): void {
      this[event.target.name] = event.target.value;
    },
    // emissions trade menu
    printOrderList(): void {
      const options = {
        method: 'GET',
        url: `${CM_WS_URL}orders`,
        params: { uid: '2', type: 'b' },
      };

      axios
        .request(options)
        .then((response) => {
          this.changeOrderItem(response.data);
          this.isLoading();
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    requestOrderList(): void {
      if (!this.isValidVolume(Number(this.orderVolume))) {
        alert('Invalid Amount of issued');
        return;
      }

      if (!this.isValidPrice(Number(this.orderPrice))) {
        alert('Invalid Limit price');
        return;
      }

      // if (!this.isValidestPrice(Number(this.orderVolume * this.orderPrice))) {
      //   alert('Invalid Est. Price');
      //   return;
      // }

      const options = {
        method: 'POST',
        url: `${CM_WS_URL}orders`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          type: 'b',
          order: {
            uid: 2,
            type: this.assetTypeBuy,
            exp: Math.floor(
              this.$moment(this.date.getTime()).utc().endOf('day') / 1000
            ),
            price: this.orderPrice,
            volume: this.orderVolume * 1000000,
          },
        },
      };

      axios
        .request(options)
        .then((response) => {
          console.log(response.data);
          this.printOrderList();
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    changeOrderItem(data): void {
      let stateA = [];
      let stateB = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].state == 1) {
          stateA.push(data[i]);
          data.splice(i, 1);
          i--;
        } else if (data[i].state == -1) {
          stateB.push(data[i]);
          data.splice(i, 1);
          i--;
        }
      }

      Array.prototype.push.apply(data, stateB);
      Array.prototype.push.apply(data, stateA);

      this.orderList = data;
    },
    requestDeleteItem(index: number): void {
      const options = {
        method: 'DELETE',
        url: `${CM_WS_URL}orders`,
        params: { uid: '2', type: 'b', id: index },
      };

      axios
        .request(options)
        .then((response) => {
          console.log(response.data);
          this.printOrderList();
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    // emissions trade 버튼 클릭 메서드
    onClickRegistrationBtn(): void {
      this.registrationModal = true;
    },
    onClickRegistrationCancleBtn(): void {
      this.registrationModal = false;
    },
    onClickRegistrationRegisterBtn(): void {
      this.requestOrderList();
      this.orderVolume = null;
      this.orderPrice = null;
      this.date = new Date();
      this.registrationModal = false;
    },
    onClickConfirmBtn(index: number): void {
      this.confirmModal = true;
      this.orderId = index;
    },
    onClickConfirmCancleBtn(): void {
      this.confirmModal = false;
    },
    onClickConfirmDeleteBtn(): void {
      this.requestDeleteItem(this.orderId);
      this.confirmModal = false;
    },
    // transactions menu
    printTransactionList() {
      const options = {
        method: 'GET',
        url: `${CM_WS_URL}transactions`,
        params: { uid: '2', type: 'b' },
      };

      axios
        .request(options)
        .then((response) => {
          this.transactionList = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // banking menu
    onClickFundBtn(): void {
      const options = {
        method: 'POST',
        url: `${CM_WS_URL}funds`,
        headers: { 'Content-Type': 'application/json' },
        data: { uid: 2, amount: this.payment },
      };

      axios
        .request(options)
        .then((response) => {
          this.historyList.unshift(response.data);
          this.payment = null;
          this.fundPopup = true;
          setTimeout(() => (this.fundPopup = false), 3000);
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    onClickReturnCapitalBtn(): void {
      const options = {
        method: 'DELETE',
        url: `${CM_WS_URL}funds`,
        params: { uid: 2, amount: this.ecocredit },
      };

      axios
        .request(options)
        .then((response) => {
          this.historyList.unshift(response.data);
          this.ecocredit = null;
          this.ecocreditPopup = true;
          setTimeout(() => (this.ecocreditPopup = false), 3000);
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    printHistoryList(): void {
      const options = {
        method: 'GET',
        url: `${CM_WS_URL}funds`,
        params: { uid: 2 },
      };

      axios
        .request(options)
        .then((response) => {
          this.historyList = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    // 유효성 검사
    isValidVolume(volume: number): boolean {
      return volume !== null && volume > 0;
    },
    isValidPrice(price: number): boolean {
      return price !== null && price > 0;
    },
    isValidestPrice(estPrice: number): boolean {
      let userEC = this.userInfoValue.balance.ecocredit;
      let useEC = [];
      let total: number;
      if (this.orderList.length > 0) {
        for (let i = 0; i < this.orderList.length; i++) {
          useEC.push(
            (this.orderList[i].volume / 1000000) * this.orderList[i].price
          );
        }
        total = useEC.reduce((a, b) => a + b);
      } else {
        total = 0;
      }
      const possibleEC = userEC - total;
      return estPrice !== null && estPrice > 0 && estPrice <= possibleEC;
    },
    // 포맷 변경 메서드
    changeHistoryTypeFormat(value: number): string {
      if (value === 1) {
        return require('@/assets/images/vcm/user/issuance-ic.png');
      } else {
        return require('@/assets/images/vcm/user/return-ic.png');
      }
    },
    changeEcocreditFormat(typeValue: number, numberValue: number): string {
      if (typeValue === 1) {
        return `+ ${numberValue.toLocaleString('ko-KR')}`;
      } else {
        return `- ${numberValue.toLocaleString('ko-KR')}`;
      }
    },
    changeCurrencyFormat(typeValue: number, numberValue: number): string {
      if (typeValue === 1) {
        return `- ${numberValue.toLocaleString('ko-KR')}`;
      } else {
        return `+ ${numberValue.toLocaleString('ko-KR')}`;
      }
    },
    changeNumberFormat(value: number): string {
      return value.toLocaleString('ko-KR');
    },
    changeDateFormat(value: number): string {
      return this.$moment.unix(value).utc().format('DD.MM.YYYY');
    },
    changeDateTimeFormat(value: number): string {
      const today = new Date().getTime();
      const todayStart = Math.floor(
        this.$moment(today).utc().startOf('day') / 1000
      );
      const todayEnd = Math.floor(
        this.$moment(today).utc().endOf('day') / 1000
      );

      // 00시 기준 (utc)
      if (value > todayStart && value < todayEnd) {
        return this.$moment.unix(value).utc().format('hh:mm:ss');
      } else {
        return this.$moment.unix(value).utc().format('DD.MM.YYYY');
      }

      // 24시 기준
      // if (currentTime - value >= 86400) {
      //   return this.$moment.unix(value).format('DD.MM.YYYY');
      // } else if (currentTime - value < 86400) {
      //   return this.$moment.unix(value).format('hh:mm:ss');
      // }
    },
    changeAssetTypeFormat(value: number): string {
      if (value === 1) {
        return require('@/assets/images/vcm/user/credit-ic.png');
      } else {
        return require('@/assets/images/vcm/user/offset-ic.png');
      }
    },
  },
});
