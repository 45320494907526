
import Vue from 'vue';
import TheLogo from '@/components/plant/TheLogo.vue';
import TheNav from '@/components/plant/TheNav.vue';
import { WIND, POWER } from '@/js/plant/data';
import TRXS from '@/js/plant/data-trx';
import {
  COUNT,
  GAP,
  BASE_TIME,
  timestamp,
  initChartOption,
  initChart,
  getWindChartOption,
  getPowerChartOption,
} from '@/js/plant/chart-options';
import { interval } from 'rxjs';

const INTERVAL = 500;
const MAX_PLANT = 2;
const CAPACITY = 504;

export default Vue.extend({
  data() {
    return {
      guage1: 0,
      guage2: 0,
      guage3: 0,
      trxs: {
        idx: 0,
        data: [],
        subscriber: undefined,
      },
      idx: 0,
      subscriber: undefined,
      chart: [
        {
          wind: {
            chart: undefined,
            option: {},
          },
          power: {
            chart: undefined,
            option: {},
          },
        },
        {
          wind: {
            chart: undefined,
            option: {},
          },
          power: {
            chart: undefined,
            option: {},
          },
        },
        {
          wind: {
            chart: undefined,
            option: {},
          },
          power: {
            chart: undefined,
            option: {},
          },
        },
      ],
    };
  },
  created() {
    for (let plantIdx = 0; plantIdx <= MAX_PLANT; plantIdx++) {
      Object.assign(this.chart[plantIdx].wind.option, getWindChartOption());
      Object.assign(this.chart[plantIdx].power.option, getPowerChartOption());
      initChartOption(
        this.chart[plantIdx].wind.option,
        this.chart[plantIdx].power.option,
        WIND[plantIdx],
        POWER[plantIdx]
      );
    }
    this.idx = COUNT - 1;
  },
  mounted() {
    initChart(this.chart[0], this.$refs.c_wind_01, this.$refs.c_power_01);
    initChart(this.chart[1], this.$refs.c_wind_02, this.$refs.c_power_02);
    initChart(this.chart[2], this.$refs.c_wind_03, this.$refs.c_power_03);

    this.subscriber = this.startPolling();

    this.trxs.subscriber = interval(300).subscribe({
      next: () => {
        if (this.trxs.data.length > 6) {
          this.trxs.data.shift();
        }
        this.trxs.data.push(TRXS[this.trxs.idx]);
        this.trxs.idx++;
        if (this.trxs.idx >= TRXS.length) {
          this.trxs.idx = 0;
        }
      },
    });
  },
  destroyed() {
    if (!this.subscriber && !this.subscriber.closed) {
      this.subscriber.unsubscribe();
    }
    this.subscriber = undefined;

    if (!this.trxs.subscriber && !this.trxs.subscriber.closed) {
      this.trxs.subscriber.unsubscribe();
    }
    this.trxs.subscriber = undefined;
  },
  methods: {
    startPolling() {
      return interval(INTERVAL).subscribe({
        next: () => {
          this.idx++;
          if (this.idx >= WIND[0].length) {
            this.idx = 0;
          }
          const date = timestamp(new Date(BASE_TIME + GAP * this.idx));
          this.guage1 = POWER[0][this.idx] / 1000;
          this.guage2 = POWER[1][this.idx] / 1000;
          this.guage3 = POWER[2][this.idx] / 1000;

          for (let plantIdx = 0; plantIdx <= MAX_PLANT; plantIdx++) {
            this.chart[plantIdx].wind.option.series[0].data.shift();
            this.chart[plantIdx].wind.option.series[0].data.push({
              name: date,
              value: [date, WIND[plantIdx][this.idx]],
            });
            this.chart[plantIdx].wind.chart.setOption(
              this.chart[plantIdx].wind.option
            );

            this.chart[plantIdx].power.option.series[0].data.shift();
            this.chart[plantIdx].power.option.series[0].data.push({
              name: date,
              value: [date, POWER[plantIdx][this.idx] / 1000],
            });
            this.chart[plantIdx].power.chart.setOption(
              this.chart[plantIdx].power.option
            );
          }
        },
        error: (err) => console.error(err),
      });
    },
    onClickViewMoreBtn() {
      this.$router.push('/apps/plant/viewdetails');
    },
  },
  computed: {
    guage1Style() {
      let value = this.guage1;
      if (this.guage1 <= 0) {
        value = 0;
      } else if (this.guage1 > CAPACITY) {
        value = CAPACITY;
      }
      return `width: ${(value / CAPACITY) * 100}%`;
    },
    guage1Rate() {
      let value = this.guage1;
      if (this.guage1 <= 0) {
        value = 0;
      } else if (this.guage1 > CAPACITY) {
        value = CAPACITY;
      }
      return ((value / CAPACITY) * 100).toFixed(1);
    },
    guage2Style() {
      let value = this.guage2;
      if (this.guage2 <= 0) {
        value = 0;
      } else if (this.guage2 > CAPACITY) {
        value = CAPACITY;
      }
      return `width: ${(value / CAPACITY) * 100}%`;
    },
    guage2Rate() {
      let value = this.guage2;
      if (this.guage2 <= 0) {
        value = 0;
      } else if (this.guage2 > CAPACITY) {
        value = CAPACITY;
      }
      return ((value / CAPACITY) * 100).toFixed(1);
    },
    guage3Style() {
      let value = this.guage3;
      if (this.guage3 <= 0) {
        value = 0;
      } else if (this.guage3 > CAPACITY) {
        value = CAPACITY;
      }
      return `width: ${(value / CAPACITY) * 100}%`;
    },
    guage3Rate() {
      let value = this.guage3;
      if (this.guage3 <= 0) {
        value = 0;
      } else if (this.guage3 > CAPACITY) {
        value = CAPACITY;
      }
      return ((value / CAPACITY) * 100).toFixed(1);
    },
  },
  components: { TheLogo, TheNav },
});
