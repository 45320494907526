
import Vue from 'vue';
import TheLogo from '@/components/re100/TheLogo.vue';
import TheNav from '@/components/re100/TheNav.vue';
import { WEB_DEMO_URL } from '@/js/serverProtocol';
import axios from 'axios';
import re100Service from '@/js/re100-event';
import {
  IDX_WIND,
  IDX_PV,
  IDX_BUILDING,
  IDX_FACTORY,
  IDX_GRID,
  SESU_METER_ID,
  MeterIdx,
} from '@/js/sesumeter-id';
import ECharts from 'vue-echarts';

export default Vue.extend({
  components: { TheLogo, TheNav, ECharts },
  data() {
    return {
      sesumeterInfo: [],
      sesumeterChart: [],
      isLoadingStyle: {
        display: 'none',
      },
      tBody: {
        isWindActive: false,
        isPvActive: false,
        isbuildingActive: false,
        isFactoryActive: false,
      },
      profileID: this.$store.state.selectID,
      profileLo: '',
      mapWrap: {
        map: null,
        mapCenter: { lat: 35.555716, lng: 129.514728 },
      },
      selected: 'monthly',
      detailInfoTableData: [],
      detailInfoTableDataInitial: '',
      detailInfoTableSelectIndex: 0,
      detailInfoTableSelectDate: '',
      detailInfoTableSelectDateF: '',
      detailInfoTableSelectDateT: '',
      detailInfoChartOptionType: 'daily',
      detailInfoChartOption: {
        title: {
          text: 'detail information chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#B749CA',
        grid: { top: 10, right: 10, bottom: 20, left: 20 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'bar',
            zlevel: 9,
            z: 9,
          },
        ],
      },
      realtimeChartOption: {
        title: {
          text: 'realtime chart',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#B749CA',
        grid: { top: 10, right: 10, bottom: 20, left: 40 },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            showSymbol: false,
            zlevel: 9,
            z: 9,
          },
        ],
      },
    };
  },
  mounted() {
    re100Service.getSesuMeterInfoData().subscribe({
      next: (info) => {
        this.sesumeterInfo = info;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    re100Service.getSesuMeterChartData().subscribe({
      next: (data) => {
        this.sesumeterChart = data;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    this.initMap();
    this.setMarker(this.mapWrap.mapCenter);
    this.onClickPlBodyRow(this.$store.state.selectID);
    this.getPeriodChartData();
    this.getRealtimeChartData();
  },
  updated() {
    this.initMap();
    this.setMarker(this.mapWrap.mapCenter);
  },
  beforeDestroy() {
    this.$store.commit('selectID', 202002000003);
  },
  watch: {
    sesumeterChart() {
      this.getRealtimeChartData();
    },
    selected() {
      this.getPeriodChartData();
      this.detailInfoTableSelectIndex = 0;
    },
    profileID() {
      this.getPeriodChartData();
      this.detailInfoTableSelectIndex = 0;
    },
  },
  methods: {
    isLoadingB() {
      this.isLoadingStyle.display = 'block';
    },
    isLoadingN() {
      this.isLoadingStyle.display = 'none';
    },
    onClickPlBodyRow(value) {
      if (value == SESU_METER_ID[0]) {
        this.tBody.isWindActive = true;
        this.tBody.isPvActive = false;
        this.tBody.isbuildingActive = false;
        this.tBody.isFactoryActive = false;
        this.profileID = SESU_METER_ID[0];
        this.profileLo = this.sesumeterInfo[0].address;
        this.mapWrap.mapCenter.lat = this.sesumeterInfo[0].latitude;
        this.mapWrap.mapCenter.lng = this.sesumeterInfo[0].longitude;
      }

      if (value == SESU_METER_ID[1]) {
        this.tBody.isWindActive = false;
        this.tBody.isPvActive = true;
        this.tBody.isbuildingActive = false;
        this.tBody.isFactoryActive = false;
        this.profileID = SESU_METER_ID[1];
        this.profileLo = this.sesumeterInfo[1].address;
        this.mapWrap.mapCenter.lat = this.sesumeterInfo[1].latitude;
        this.mapWrap.mapCenter.lng = this.sesumeterInfo[1].longitude;
      }

      if (value == SESU_METER_ID[2]) {
        this.tBody.isWindActive = false;
        this.tBody.isPvActive = false;
        this.tBody.isbuildingActive = true;
        this.tBody.isFactoryActive = false;
        this.profileID = SESU_METER_ID[2];
        this.profileLo = this.sesumeterInfo[2].address;
        this.mapWrap.mapCenter.lat = this.sesumeterInfo[2].latitude;
        this.mapWrap.mapCenter.lng = this.sesumeterInfo[2].longitude;
      }

      if (value == SESU_METER_ID[3]) {
        this.tBody.isWindActive = false;
        this.tBody.isPvActive = false;
        this.tBody.isbuildingActive = false;
        this.tBody.isFactoryActive = true;
        this.profileID = SESU_METER_ID[3];
        this.profileLo = this.sesumeterInfo[3].address;
        this.mapWrap.mapCenter.lat = this.sesumeterInfo[3].latitude;
        this.mapWrap.mapCenter.lng = this.sesumeterInfo[3].longitude;
      }
    },
    initMap() {
      this.mapWrap.map = new google.maps.Map(this.$refs.mapBox, {
        center: this.mapWrap.mapCenter,
        zoom: 17,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: true,
        mapTypeControl: true,
        fuulscreenControl: true,
        zoomControl: true,
      });
    },
    setMarker(Points) {
      const markers = new google.maps.Marker({
        position: Points,
        map: this.mapWrap.map,
      });
    },
    onClickSelectBox(event) {
      this.selected = event.target.value;
    },
    getPeriodChartData() {
      this.isLoadingB();
      const option = {
        method: 'GET',
        url: `${WEB_DEMO_URL}sesumeters/${this.profileID}/charts/${this.selected}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      };
      axios
        .request(option)
        .then((response) => {
          this.detailInfoTableData = response.data.data.reverse();

          if (this.selected == 'monthly') {
            this.detailInfoTableDataInitial = this.detailInfoTableData[0].date;
          }
          if (this.selected == 'weekly') {
            this.detailInfoTableDataInitial = this.detailInfoTableData[0].f;
          }
          if (this.selected == 'daily') {
            this.detailInfoTableDataInitial = this.detailInfoTableData[0].date;
          }
          this.onClickDInfoTableSelectRow(0, this.detailInfoTableDataInitial);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDInfoTableRowClass(index) {
      return this.detailInfoTableSelectIndex === index
        ? `selected`
        : `unSelected`;
    },
    onClickDInfoTableSelectRow(index, date) {
      this.detailInfoTableSelectDateF = date;

      if (this.selected == 'monthly') {
        const calcDate = this.$moment(date).add(1, 'M').subtract(1, 'd');
        this.detailInfoTableSelectDateT = this.$moment(calcDate._d).format(
          'YYYY-MM-DD 23:59:59'
        );
        this.detailInfoTableSelectDate = this.$moment(
          this.detailInfoTableSelectDateF
        ).format('YYYY-MM');
      }

      if (this.selected == 'weekly') {
        const calcDate = this.$moment(date).add(1, 'w').subtract(1, 'd');
        this.detailInfoTableSelectDateT = this.$moment(calcDate._d).format(
          'YYYY-MM-DD 23:59:59'
        );
        this.detailInfoTableSelectDate = `${this.$moment(
          this.detailInfoTableSelectDateF
        ).format('YYYY-MM-DD')} ~ ${this.$moment(
          this.detailInfoTableSelectDateT
        ).format('YYYY-MM-DD')}`;
      }

      if (this.selected == 'daily') {
        this.detailInfoTableSelectDateT = this.$moment(date).format(
          'YYYY-MM-DD 23:59:59'
        );
        this.detailInfoTableSelectDate = this.$moment(
          this.detailInfoTableSelectDateF
        ).format('YYYY-MM-DD');
      }
      this.getDInfoChartData();
      return (this.detailInfoTableSelectIndex = index);
    },
    getDInfoChartData() {
      if (this.selected == 'monthly' || this.selected == 'weekly') {
        this.detailInfoChartOptionType = 'daily';
      } else if (this.selected == 'daily') {
        this.detailInfoChartOptionType = 'hourly';
      }

      const option = {
        method: 'GET',
        url: `${WEB_DEMO_URL}sesumeters/${this.$store.state.selectID}/charts/${this.detailInfoChartOptionType}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
        params: {
          f: this.detailInfoTableSelectDateF,
          t: this.detailInfoTableSelectDateT,
        },
      };
      axios
        .request(option)
        .then((response) => {
          const detailInfoChartData = response.data.data;
          const detailChartXData = [];
          const detailChartYData = [];

          for (let i = 0; i < detailInfoChartData.length; i++) {
            detailChartXData.push(detailInfoChartData[i].date);
            detailChartYData.push(
              (detailInfoChartData[i].wh / 1000).toFixed(1)
            );
          }

          if (
            this.profileID == SESU_METER_ID[0] ||
            this.profileID == SESU_METER_ID[1]
          ) {
            this.detailInfoChartOption.color = '#B749CA';
          } else if (
            this.profileID == SESU_METER_ID[2] ||
            this.profileID == SESU_METER_ID[3]
          ) {
            this.detailInfoChartOption.color = '#46BED7';
          }

          this.detailInfoChartOption.xAxis.data = detailChartXData;
          this.detailInfoChartOption.series[0].data = detailChartYData;
        })
        .catch((error) => {
          console.log(error);
        });
      this.isLoadingN();
    },
    getRealtimeChartData() {
      if (this.sesumeterChart != null) {
        const windPower = [];
        const windMeasured = [];
        const pvPower = [];
        const pvMeasured = [];
        const buildingPower = [];
        const buildingMeasured = [];
        const factoryPower = [];
        const factoryMeasured = [];
        const gridPower = [];
        const gridMeasured = [];

        for (let i = 24; i >= 0; i--) {
          const wp = this.sesumeterChart[IDX_WIND].chart[i].power;
          const wm = this.$moment(
            this.sesumeterChart[IDX_WIND].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const pp = this.sesumeterChart[IDX_PV].chart[i].power;
          const pm = this.$moment(
            this.sesumeterChart[IDX_PV].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const bp = this.sesumeterChart[IDX_BUILDING].chart[i].power;
          const bm = this.$moment(
            this.sesumeterChart[IDX_BUILDING].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const fp = this.sesumeterChart[IDX_FACTORY].chart[i].power;
          const fm = this.$moment(
            this.sesumeterChart[IDX_FACTORY].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const gp = this.sesumeterChart[IDX_GRID].chart[i].power;
          const gm = this.$moment(
            this.sesumeterChart[IDX_GRID].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          windPower.push(wp.toFixed(1));
          windMeasured.push(wm);
          pvPower.push(pp.toFixed(1));
          pvMeasured.push(pm);
          buildingPower.push(bp.toFixed(1));
          buildingMeasured.push(bm);
          factoryPower.push(fp.toFixed(1));
          factoryMeasured.push(fm);
          gridPower.push(gp.toFixed(1));
          gridMeasured.push(gm);
        }

        if (this.profileID == SESU_METER_ID[0]) {
          this.realtimeChartOption.xAxis.data = windMeasured;
          this.realtimeChartOption.series[0].data = windPower;
          this.realtimeChartOption.color = '#B749CA';
        }

        if (this.profileID == SESU_METER_ID[1]) {
          this.realtimeChartOption.xAxis.data = pvMeasured;
          this.realtimeChartOption.series[0].data = pvPower;
          this.realtimeChartOption.color = '#B749CA';
        }

        if (this.profileID == SESU_METER_ID[2]) {
          this.realtimeChartOption.xAxis.data = buildingMeasured;
          this.realtimeChartOption.series[0].data = buildingPower;
          this.realtimeChartOption.color = '#46BED7';
        }

        if (this.profileID == SESU_METER_ID[3]) {
          this.realtimeChartOption.xAxis.data = factoryMeasured;
          this.realtimeChartOption.series[0].data = factoryPower;
          this.realtimeChartOption.color = '#46BED7';
        }
      }
    },
    detailTablePeriodFormat(date) {
      return this.$moment(date).format('YYYY. MM. DD');
    },
    electricFormat(data) {
      return Number((data / 1000).toFixed(1)).toLocaleString('ko-KR');
    },
    chargeCalc(whData) {
      return Math.floor((whData / 1000.0) * 85.06).toLocaleString('ko-KR');
    },
  },
});
