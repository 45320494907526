import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    selectID: 202002000003,
    sellerInfoComplete: false,
    buyerInfoComplete: false,
    orderbookComplete: false,
  },
  mutations: {
    selectID(state, selectID) {
      state.selectID = selectID;
    },
    sellerInfoComplete(state, sellerInfoComplete) {
      state.sellerInfoComplete = sellerInfoComplete;
    },
    buyerInfoComplete(state, buyerInfoComplete) {
      state.buyerInfoComplete = buyerInfoComplete;
    },
    orderbookComplete(state, orderbookComplete) {
      state.orderbookComplete = orderbookComplete;
    },
  },
  getters: {
    getSellerInfoComplete(state) {
      return state.sellerInfoComplete;
    },
    getBuyerInfoComplete(state) {
      return state.buyerInfoComplete;
    },
    getOrderbookComplete(state) {
      return state.orderbookComplete;
    },
  },
});
