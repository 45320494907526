const TRXS = [
  [
    'ebca5817e13b7a1d176230e6c6b6c16072595951...',
    66855,
    202002000007,
    '01.02',
    7.620422467047146,
    219.73366504907608,
    1674.4633579065917,
    0.9908396023511886,
    59.93171482682228,
    1.3953861315888263,
  ],
  [
    '1dfd218cb56b0d228814e414b0c247e00bdd7f77...',
    66854,
    202002000006,
    '01.02',
    17.539482248998997,
    219.95675259828568,
    3857.9275577450953,
    0.9900558835268021,
    59.91768085360527,
    3.214939631454246,
  ],
  [
    '7a5b6ddd8ae1d3a7bbe22c6a29854c9ef7d2fa61...',
    66856,
    202002000004,
    '01.02',
    5.159100547281036,
    219.698067009449,
    1133.4444177450341,
    0.9953700017929077,
    59.92400602698326,
    0.9445370147875284,
  ],
  [
    '96e44f1fa30e7132dfd976976d9223c70e4d1594...',
    66860,
    202002000007,
    '01.02',
    7.723447558294361,
    219.0465093255043,
    1691.7942276029692,
    0.9972230494022369,
    59.994579046964645,
    1.4098285230024743,
  ],
  [
    '9e1bc3be5b82f0c1b343c649261200ebecf8eff4...',
    66858,
    202002000003,
    '01.02',
    5.942200233308454,
    219.4637589454651,
    1304.097599608493,
    0.993162897825241,
    59.936855190992354,
    1.0867479996737441,
  ],
  [
    '0fad11a4e199a09049504308f324251a2fc6628b...',
    66861,
    202002000004,
    '01.02',
    6.9887790034128585,
    219.5806058049202,
    1534.600327406102,
    0.9904443967342377,
    59.97678390145302,
    1.2788336061717516,
  ],
  [
    'ab1fb8fe0d4ce2e2c4e32a3e21df1cf267610335...',
    66859,
    202002000006,
    '01.02',
    16.198210409354708,
    219.45618319511414,
    3554.7974310283516,
    0.9981184977293014,
    59.92294777035713,
    2.962331192523626,
  ],
  [
    '0dfa6d3f21cf371c1040a70e6c3eff584990d3e0...',
    66857,
    202002000005,
    '01.02',
    -12.482391,
    220.0,
    -2746.125914,
    1.0,
    60.0,
    -2.288438,
  ],
  [
    'a34f92e0c1d6fb1504a2322844f850d2944439b5...',
    66864,
    202002000006,
    '01.02',
    16.083985411454552,
    219.36210572719574,
    3528.2169083421677,
    0.9918022871017456,
    59.962460768222805,
    2.9401807569518064,
  ],
  [
    'ce4a96156946750d5e365beaeb754ad4fe95171c...',
    66862,
    202002000005,
    '01.02',
    -10.944972,
    220.0,
    -2407.893732,
    1.0,
    60.0,
    -2.006578,
  ],
  [
    '7d181fea832d06111d8c618fb727458c9390d808...',
    66863,
    202002000003,
    '01.02',
    3.9723549873453643,
    219.71455186605453,
    872.7841958974734,
    0.9902593839168549,
    59.93242728114128,
    0.7273201632478946,
  ],
  [
    '90f533f603bc81fb9c2e7b49d73df4f5061904a5...',
    66867,
    202002000005,
    '01.02',
    -11.147926,
    220.0,
    -2452.543717,
    1.0,
    60.0,
    -2.043786,
  ],
  [
    '480cea33ceb96c560c31f47cedf7ea32124afa41...',
    66865,
    202002000007,
    '01.02',
    6.814466255623201,
    219.66520303487778,
    1496.9011136157937,
    0.9902002906799316,
    59.92269532680511,
    1.2474175946798283,
  ],
  [
    '94a99e830b8670d26248fde948d6563a90f1d341...',
    66866,
    202002000004,
    '01.02',
    7.731175349985906,
    219.86179745197296,
    1699.7901088642875,
    0.9994174635410309,
    59.97956643700599,
    1.4164917573869062,
  ],
  [
    'c933859efd215d1d6ce8ecf2f0bbdbe854c18a79...',
    66870,
    202002000007,
    '01.02',
    5.199800991813733,
    219.47461265325546,
    1141.2243085523326,
    0.9912347507476806,
    59.98037118315697,
    0.9510202571269438,
  ],
  [
    '4df52eec961bb2f72e08511219af2e5782affaa2...',
    66868,
    202002000003,
    '01.02',
    3.196442720543104,
    219.33189326524734,
    701.081833610637,
    0.9933383077383041,
    59.90253009200096,
    0.5842348613421976,
  ],
  [
    '4547f39a2b7ca95c16e27dd2d294c61c5a2e465e...',
    66869,
    202002000006,
    '01.02',
    17.29358543379663,
    219.42801761627197,
    3794.6971692156308,
    0.9945416778326035,
    59.95345214009285,
    3.1622476410130256,
  ],
  [
    '408f7628817ee7586892774af92ef5cc7795a2c6...',
    66872,
    202002000003,
    '01.02',
    4.208117912435933,
    219.86506760120392,
    925.2181292915635,
    0.9959915757179261,
    59.991132533550264,
    0.7710151077429697,
  ],
  [
    '3efa9bf11279692ca2f40725033815fdf4d1be22...',
    66873,
    202002000006,
    '01.02',
    19.09544283909552,
    219.35635912418365,
    4188.706817047958,
    0.9938519650697708,
    59.95867691040039,
    3.4905890142066314,
  ],
  [
    '940082d2f2cdf8f606c892b868ec26361d50555e...',
    66874,
    202002000007,
    '01.02',
    3.731102404710034,
    219.0711418390274,
    817.3768641181681,
    0.9941326946020126,
    59.916397619247434,
    0.6811473867651401,
  ],
  [
    'fa88e3151ef810474d95b6b2529a551cb3f51139...',
    66871,
    202002000005,
    '01.02',
    -11.522952,
    220.0,
    -2535.049535,
    1.0,
    60.0,
    -2.112541,
  ],
  [
    'e9a07ad4adac872c4810e20a8b96750ef27a9867...',
    66876,
    202002000003,
    '01.02',
    6.230532393699686,
    219.46193134784698,
    1367.3646724466573,
    0.9973278111219406,
    59.94483921527863,
    1.1394705603722144,
  ],
  [
    '071bc41b4ecde7cd479a9bdfef5a56ca9191c3f5...',
    66875,
    202002000005,
    '01.02',
    -10.82307,
    220.0,
    -2381.075443,
    1.0,
    60.0,
    -1.98423,
  ],
  [
    'c8a86981d63f54856be7b3c94df9b8829519e5fe...',
    66878,
    202002000007,
    '01.02',
    3.1962179888737157,
    219.23879325389862,
    700.7349748570762,
    0.9940913271903992,
    59.900895023345946,
    0.5839458123808968,
  ],
  [
    '12098c884486a6efed522a1f313552e92307b33c...',
    66877,
    202002000006,
    '01.02',
    20.31622498408846,
    219.7995022535324,
    4465.496139173423,
    0.9954374265670777,
    59.9410044491291,
    3.7212467826445192,
  ],
  [
    '39c05f68b82bf8ca2296ae183a76d632ea68112d...',
    66882,
    202002000007,
    '01.02',
    3.8913249650139985,
    219.58197861909866,
    854.4648352676687,
    0.9956669968366623,
    59.93689724206924,
    0.712054029389724,
  ],
  [
    '3dbf9787a4f206908ebb00b1d58e30fb1fd1d250...',
    66881,
    202002000006,
    '01.02',
    20.31385306793923,
    219.21641159057617,
    4453.129975131855,
    0.9976730191707611,
    59.941219264268874,
    3.7109416459432123,
  ],
  [
    '8e7118a3f83056ea9a45c105adb1ae32f8c54a92...',
    66879,
    202002000005,
    '01.02',
    -9.541256,
    220.0,
    -2099.076333,
    1.0,
    60.0,
    -1.74923,
  ],
  [
    'c90cc4a3b0684dec0000c3eeb922b562a4ec650f...',
    66880,
    202002000003,
    '01.02',
    7.648076885321609,
    219.2348672747612,
    1676.725120860652,
    0.9973424768447876,
    59.97037344574928,
    1.3972709340505431,
  ],
  [
    '250ae92b160f2b2677937a65b2e5d4a59913ea3b...',
    66883,
    202002000005,
    '01.02',
    -8.777634,
    220.0,
    -1931.079581,
    1.0,
    60.0,
    -1.609233,
  ],
  [
    'd877e69046fdcf81c9cf09d0d1347435cfe9edcb...',
    66885,
    202002000006,
    '01.02',
    18.961283009726472,
    219.3569865822792,
    4159.289902747369,
    0.9972736579179764,
    59.98016934394836,
    3.4660749189561404,
  ],
  [
    '45b3a9acdda5fb43cdc1bda34c41c4914a8c2e98...',
    66884,
    202002000003,
    '01.02',
    7.322944059941543,
    219.56397950649261,
    1607.8547395041967,
    0.9949316298961639,
    59.940475755929945,
    1.3398789495868304,
  ],
  [
    '9e177bf46094fc30b76e9d41a9ad238df3f86e2c...',
    66887,
    202002000005,
    '01.02',
    -9.304367,
    220.0,
    -2046.960703,
    1.0,
    60.0,
    -1.705801,
  ],
  [
    '01c443d666871b26cb8e1ae2d5ce0d52f9ae71f1...',
    66886,
    202002000007,
    '01.02',
    5.442827555307764,
    219.61299270391464,
    1195.3156481924696,
    0.9984196531772613,
    59.910824668407436,
    0.9960963734937246,
  ],
  [
    '7755fd7c0aa9eaa5c2c6d2c282f509969aa8c502...',
    66888,
    202002000003,
    '01.02',
    5.541129721583365,
    219.34063333272934,
    1215.3949025109055,
    0.9913532984256744,
    59.93488763570785,
    1.0128290854257544,
  ],
  [
    'e66d2adfc47b350d4848e164f8efe13550dd59e3...',
    66889,
    202002000006,
    '01.02',
    17.158549144022913,
    219.51171875,
    3766.5026138608114,
    0.9932660818099975,
    59.99072167873383,
    3.138752178217343,
  ],
  [
    'fa204fcb6186f51331be1d231f35c4fcb6a5ed30...',
    66890,
    202002000007,
    '01.02',
    7.368438526746306,
    219.78833258152008,
    1619.496817523003,
    0.9968809723854065,
    59.99196383357048,
    1.349580681269169,
  ],
  [
    '6a101e7deaeaafedac2d8aed407132734fd0888f...',
    66893,
    202002000006,
    '01.02',
    16.00964189605531,
    219.7899580001831,
    3518.7585199319683,
    0.9996675807237625,
    59.96604114770889,
    2.932298766609973,
  ],
  [
    '521c53d670ec1e5733021029a5feffffd9912246...',
    66892,
    202002000003,
    '01.02',
    3.6893593610553195,
    219.74569195508957,
    810.7208256660883,
    0.9945144534111023,
    59.95462698340416,
    0.6756006880550736,
  ],
  [
    'd309b3d0886b26876702460c9757bcc3bf36ecd2...',
    66891,
    202002000005,
    '01.02',
    -11.230975,
    220.0,
    -2470.81442,
    1.0,
    60.0,
    -2.059012,
  ],
  [
    '6e253238ec084874a991f407d9843d0586998dd2...',
    66894,
    202002000007,
    '01.02',
    7.70724797059266,
    219.54387587308884,
    1692.0790917789109,
    0.9991574138402939,
    59.91774523258209,
    1.410065909815759,
  ],
  [
    '69260d65c93c4f131f3e95305fa301aa50d765bb...',
    66895,
    202002000005,
    '01.02',
    -12.274212,
    220.0,
    -2700.326677,
    1.0,
    60.0,
    -2.250272,
  ],
  [
    '0731c221c7def62d6b5e2f55e0b85b0952e1c2ab...',
    66896,
    202002000003,
    '01.02',
    3.254718730924317,
    219.64985370635986,
    714.8984931028755,
    0.9945741319656372,
    59.97914230823517,
    0.5957487442523962,
  ],
  [
    'dd5de212e803dff0a96039548044e094d6a5dfa1...',
    66898,
    202002000007,
    '01.02',
    7.233055141745995,
    219.6287266612053,
    1588.5866906519566,
    0.9902842885255814,
    59.956985419988634,
    1.3238222422099637,
  ],
  [
    '9737ac3397891c8f9f4475af42259890ebae8aca...',
    66897,
    202002000006,
    '01.02',
    16.271862242612105,
    219.39409911632538,
    3569.950557662833,
    0.9967479294538498,
    59.94958047866821,
    2.974958798052361,
  ],
  [
    '4ac3b551a44c336241eec7ef6202d84a8852759d...',
    66900,
    202002000003,
    '01.02',
    4.579493973830583,
    219.2278819680214,
    1003.9527643681962,
    0.9946648746728897,
    59.94519350528717,
    0.8366273036401635,
  ],
  [
    '2385daf7367aaf35f96d3c8f3e6f2770f470cedc...',
    66901,
    202002000006,
    '01.02',
    17.70699404190223,
    219.59003299474716,
    3888.2794058991026,
    0.995512855052948,
    59.97189615368843,
    3.2402328382492525,
  ],
  [
    '4ed1f30a118b6506262a0a32284fc8f463abd8ed...',
    66899,
    202002000005,
    '01.02',
    -12.472039,
    220.0,
    -2743.848646,
    1.0,
    60.0,
    -2.286541,
  ],
  [
    'f847c1d50ff87200ef28e2b33da0d38bfa4edba1...',
    66902,
    202002000007,
    '01.02',
    5.790068323308682,
    219.00756132602692,
    1268.068743398912,
    0.9984048068523407,
    59.94653289914131,
    1.0567239528324266,
  ],
  [
    '0b8c418581acca641738ddea5f9ce92f53f2b4be...',
    66905,
    202002000006,
    '01.02',
    19.52089157802523,
    219.0477056503296,
    4276.006512415268,
    0.9998818874359131,
    59.92226465940475,
    3.5633387603460567,
  ],
  [
    '5c14c378756673c8d72db87c939ee1c5850cb607...',
    66903,
    202002000005,
    '01.02',
    -11.148206,
    220.0,
    -2452.605276,
    1.0,
    60.0,
    -2.043838,
  ],
  [
    '515875b033fd58f2ba0d78517d816648c4fb5dfa...',
    66904,
    202002000003,
    '01.02',
    6.613838311780897,
    219.0180094242096,
    1448.549701699827,
    0.9925312262773514,
    59.990499812364575,
    1.2071247514165224,
  ],
  [
    'c394925820f0fbcdc377f53b2dee1a01e6f68c2b...',
    66907,
    202002000005,
    '01.02',
    -8.845776,
    220.0,
    -1946.070766,
    1.0,
    60.0,
    -1.621726,
  ],
  [
    '0d510d0168859a2b67ef829e6d1b7aa6c1fd4ad4...',
    66906,
    202002000007,
    '01.02',
    3.7322760133359667,
    219.2774759531021,
    818.4040637646173,
    0.9900679033994675,
    59.979624015092845,
    0.6820033864705144,
  ],
  [
    '27b2faf857d2452578762845d39931869a59dfce...',
    66910,
    202002000004,
    '01.02',
    3.4871514624581272,
    219.17244106531143,
    764.2874983914185,
    0.9935391056537628,
    59.97270876169205,
    0.6369062486595154,
  ],
  [
    'd782557a2437b8d9f88c1bf6d6304c8eb06bec17...',
    66911,
    202002000004,
    '01.02',
    3.3292418025389723,
    219.56753653287888,
    730.9934211057633,
    0.9976040834188461,
    59.91153250932693,
    0.6091611842548027,
  ],
  [
    'a9ab1b0dbe5d22648ce0f28c21c416b0ae15d01c...',
    66909,
    202002000004,
    '01.02',
    5.0306490753140665,
    219.6245073080063,
    1104.8538246053292,
    0.9919761526584625,
    59.99985013008118,
    0.9207115205044409,
  ],
  [
    '06e4f81610d3b9a887cf2dd6e5b7d5c37aee4fad...',
    66917,
    202002000004,
    '01.02',
    3.772212935299001,
    219.2912761569023,
    827.2133885172923,
    0.9955078822374344,
    59.941006225347515,
    0.6893444904310768,
  ],
  [
    '6da768962db9b25e03f3ac62618dec3f5571ac53...',
    66912,
    202002000004,
    '01.02',
    4.688270155182374,
    219.36687541007996,
    1028.451175020688,
    0.9907993632555008,
    59.9766083419323,
    0.8570426458505733,
  ],
  [
    'ab6d66c50c31e402b6756c69489992a8d5e817ab...',
    66908,
    202002000004,
    '01.02',
    6.887383943718303,
    219.60717868804932,
    1512.5189564213472,
    0.9903716123104095,
    59.98671293258667,
    1.260432463684456,
  ],
  [
    '88f2a7cb068e951dc73a1d0d075e5aaaae4afa19...',
    66913,
    202002000004,
    '01.02',
    6.580602057237728,
    219.87170958518982,
    1446.8882244246765,
    0.9944656705856323,
    59.911306565999986,
    1.205740187020564,
  ],
  [
    'b8fc7987d580b3a7252a0141d3f676ed8be46477...',
    66916,
    202002000004,
    '01.02',
    5.525408989473581,
    219.46273291110992,
    1212.6213572814863,
    0.9962814211845398,
    59.95131768584251,
    1.0105177977345718,
  ],
  [
    '4f1a43bc6b207c0229c164840b6f8fe1ae8cd987...',
    66914,
    202002000004,
    '01.02',
    7.693585763437313,
    219.82146501541138,
    1691.2152937405024,
    0.9908996391296386,
    59.90032818317413,
    1.4093460781170852,
  ],
  [
    'c9d3411998e0c53887fbb54392bceff8283abbc6...',
    66915,
    202002000004,
    '01.02',
    7.225790406650931,
    219.92452710866928,
    1589.128538169065,
    0.9980015242099762,
    59.990745878219606,
    1.324273781807554,
  ],
  [
    'e4a1a8d4e4430e6466978be4789a9b762cfc166e...',
    66919,
    202002000006,
    '01.02',
    20.422612709490227,
    219.9662109017372,
    4492.284734420226,
    0.9917795366048813,
    59.90181632041931,
    3.743570612016855,
  ],
  [
    'e69996fdf252e5a8feb8da8c7b2d6a97c5baa6d8...',
    66920,
    202002000007,
    '01.02',
    3.241624752030261,
    219.020538687706,
    709.9823994130692,
    0.999223290681839,
    59.99211043715477,
    0.591651999510891,
  ],
  [
    '695908dfeb95d52fea6b1ae0be9f0060c49f33f9...',
    66918,
    202002000003,
    '01.02',
    7.728296289427306,
    219.44666349887848,
    1695.9488352455853,
    0.9906165653467178,
    59.98882864117622,
    1.4132906960379876,
  ],
  [
    '0fca1dd34533bbfdf05cf41ddfc076fb97ff21de...',
    66921,
    202002000004,
    '01.02',
    3.206479933051057,
    219.77408730983734,
    704.7012007636044,
    0.993635339140892,
    59.91126184463501,
    0.5872510006363371,
  ],
  [
    'c086ea85a1e200cfb746fc92eb4c1b7306a7dbd4...',
    66923,
    202002000003,
    '01.02',
    7.055300039625495,
    219.67388367652893,
    1549.865160207701,
    0.9901976656913757,
    59.978216636180875,
    1.2915543001730843,
  ],
  [
    'c0f9d3ceb44a8508af3c98570a537a8aff7ce71b...',
    66925,
    202002000007,
    '01.02',
    3.524697818858521,
    219.77482342720032,
    774.6398407738695,
    0.9948663413524628,
    59.995937544107434,
    0.6455332006448912,
  ],
  [
    '2b4ffd7f6b47d28a04a45b9ebeb25d163b148405...',
    66924,
    202002000006,
    '01.02',
    20.053181097818687,
    219.55454277992249,
    4402.767007214566,
    0.9991847664117813,
    59.91861620545387,
    3.6689725060121376,
  ],
  [
    'e9a9883ab76592a055f93a3d39d54768d5e27b9c...',
    66922,
    202002000005,
    '01.02',
    -12.734623,
    220.0,
    -2801.617098,
    1.0,
    60.0,
    -2.334681,
  ],
  [
    '858799490671047914f457ada220fea8d8083661...',
    66926,
    202002000004,
    '01.02',
    4.246938565749849,
    219.33036667108536,
    931.4825928554876,
    0.9977795332670212,
    59.97666298151016,
    0.7762354940462397,
  ],
  [
    '91454b5ca77168227c574b3aabbc8999c90b5f53...',
    66928,
    202002000003,
    '01.02',
    5.128306062126323,
    219.60479110479355,
    1126.2005814946976,
    0.9940929341316223,
    59.9391729414463,
    0.9385004845789147,
  ],
  [
    'b18f69874ab7d13b9cd9c1118ee6936ea2f974c6...',
    66929,
    202002000006,
    '01.02',
    18.508039495064825,
    219.52944791316986,
    4063.0596923067237,
    0.9982989221811295,
    59.97915878891945,
    3.3858830769222696,
  ],
  [
    'fa34d92922356f08695dbaaa71f4ada86a9a99c8...',
    66927,
    202002000005,
    '01.02',
    -12.254814,
    220.0,
    -2696.059095,
    1.0,
    60.0,
    -2.246716,
  ],
  [
    '279dd934e44f595d0efa103aa67171ddfdee2e30...',
    66931,
    202002000004,
    '01.02',
    6.129352345876172,
    219.86454832553864,
    1347.6272850541452,
    0.9997747594118118,
    59.9228972196579,
    1.123022737545121,
  ],
  [
    'e7fd3b8edd5d3ca1b57a4a0d869349b80f661535...',
    66930,
    202002000007,
    '01.02',
    4.8712402561787025,
    219.5840420126915,
    1069.6466250666583,
    0.9968033522367478,
    59.90377596616745,
    0.8913721875555487,
  ],
  [
    'c3f998f264dfb4ce4352281efede79cdc4d01826...',
    66932,
    202002000005,
    '01.02',
    -12.085811,
    220.0,
    -2658.878451,
    1.0,
    60.0,
    -2.215732,
  ],
  [
    'e366336228df3c95c9f4a5a981faed9042e2ea0c...',
    66934,
    202002000006,
    '01.02',
    16.75241538605348,
    219.9194005727768,
    3684.1811498470447,
    0.9900324940681458,
    59.979554468393324,
    3.0701509582058706,
  ],
  [
    '88c97ab42aec742891c76ef935c65c0618752706...',
    66933,
    202002000003,
    '01.02',
    3.4633962415240473,
    219.75193613767624,
    761.0880296868604,
    0.9909347742795944,
    59.95456039905548,
    0.6342400247390504,
  ],
  [
    'e31fd656d7ef3b393483f26388b10ad5ef033816...',
    66936,
    202002000004,
    '01.02',
    7.57831936158233,
    219.0014005303383,
    1659.6625538527094,
    0.9901919829845428,
    59.99098134636879,
    1.383052128210591,
  ],
  [
    '2bf9f65b789aebbe5db80eb181e35eff2797da1a...',
    66935,
    202002000007,
    '01.02',
    6.553171993219632,
    219.01529777050018,
    1435.2449154362998,
    0.9987723875045776,
    59.99640956521034,
    1.1960374295302498,
  ],
  [
    '12a514f9c0123e454ab8424d295b518bc0f68d6f...',
    66939,
    202002000006,
    '01.02',
    15.932465335716152,
    219.77025681734085,
    3501.4819985637196,
    0.9995762079954147,
    59.993277221918106,
    2.9179016654697665,
  ],
  [
    '9de52d7959ce3a4f02a38060b0338a2e118599c8...',
    66938,
    202002000003,
    '01.02',
    3.3924040232831567,
    219.37409222126007,
    744.2055530554929,
    0.9909700381755828,
    59.90393909215927,
    0.6201712942129107,
  ],
  [
    '7cbed2a42349bf0bd625e5ccd07c0d8b1cde8524...',
    66941,
    202002000004,
    '01.02',
    7.509988315946398,
    219.3792449235916,
    1647.5355661373162,
    0.9950019031763077,
    59.95208923220634,
    1.3729463051144302,
  ],
  [
    '2ed76e4a430e5bc9832060dfac1020da72d71656...',
    66937,
    202002000005,
    '01.02',
    -12.266707,
    220.0,
    -2698.675482,
    1.0,
    60.0,
    -2.248896,
  ],
  [
    '28002e98786069f32080acb2aff73836a53cf490...',
    66940,
    202002000007,
    '01.02',
    7.619097853407573,
    219.63870471715927,
    1673.448783635728,
    0.9988909423351288,
    59.9518903195858,
    1.3945406530297735,
  ],
  [
    '24303a96afc3ce9d6b85c30c250cc98c2a964549...',
    66943,
    202002000003,
    '01.02',
    4.9708516596377805,
    219.0666128396988,
    1088.9476360054439,
    0.9997567254304885,
    59.97772011756897,
    0.9074563633378698,
  ],
  [
    '163f5a2eeed004ab14cfb768df5160ff4595d78b...',
    66942,
    202002000005,
    '01.02',
    -12.650862,
    220.0,
    -2783.189663,
    1.0,
    60.0,
    -2.319325,
  ],
  [
    '44b348ec68743d39d272963453a21dcf25b762e6...',
    66946,
    202002000004,
    '01.02',
    6.02371763985832,
    219.10032486915588,
    1319.7984918130228,
    0.9952205955982208,
    59.95349162817001,
    1.0998320765108522,
  ],
  [
    '282b75cd053c5b1e12a4b6d894f2c486d5454a7d...',
    66945,
    202002000007,
    '01.02',
    7.538765993760797,
    219.5666265487671,
    1655.261417590622,
    0.9929309946298599,
    59.94753342270851,
    1.3793845146588517,
  ],
  [
    '9504057987a2be194ffba4b1a0df6455eb48b66d...',
    66944,
    202002000006,
    '01.02',
    16.51360839455669,
    219.72488713264465,
    3628.450740646662,
    0.9902935969829559,
    59.94074860215187,
    3.023708950538885,
  ],
  [
    '4137c7b636a78e147ced5e0398de94896fae3477...',
    66947,
    202002000005,
    '01.02',
    -13.068027,
    220.0,
    -2874.96603,
    1.0,
    60.0,
    -2.395805,
  ],
  [
    'cacb00597090f65160df663c63a409af879ba32a...',
    66950,
    202002000007,
    '01.02',
    5.792198099868515,
    219.19937545061111,
    1269.6462059773949,
    0.9997000986337662,
    59.91096162199974,
    1.0580385049811625,
  ],
  [
    'dfe9db3e8cfb1cc9932555e49d1040255b339cb3...',
    66948,
    202002000003,
    '01.02',
    6.9231562765018415,
    219.81274288892746,
    1521.7979705865637,
    0.9982274371385574,
    59.909143990278245,
    1.2681649754888031,
  ],
  [
    '88026c75c967c7f057b8bf88434433076f0ac0fb...',
    66951,
    202002000004,
    '01.02',
    4.136921425585777,
    219.38472205400467,
    907.5773571113925,
    0.9961678785085678,
    59.93223459720611,
    0.7563144642594939,
  ],
  [
    '5e2cafdf043c93cdaaef88eb5386036178351d9a...',
    66949,
    202002000006,
    '01.02',
    18.18407273403633,
    219.21477150917053,
    3986.217349497912,
    0.9984711766242981,
    59.93843158483505,
    3.32184779124826,
  ],
  [
    'ea7092d68b106da5dc781e27ccbc9a685866eb03...',
    66952,
    202002000005,
    '01.02',
    -12.847674,
    220.0,
    -2826.488228,
    1.0,
    60.0,
    -2.355407,
  ],
  [
    'e1d15ccaf4e1e235875fef920d5a0607c8e34e14...',
    66953,
    202002000003,
    '01.02',
    7.746018917612043,
    219.38182532787323,
    1699.3357691699669,
    0.996444206237793,
    59.95483314394951,
    1.4161131409749725,
  ],
  [
    '539b7e44ad27abaa0978cce98dfc618b0dd6444a...',
    66954,
    202002000006,
    '01.02',
    19.82140014729353,
    219.5881910920143,
    4352.5454032551725,
    0.9943185478448868,
    59.93993110656738,
    3.627121169379311,
  ]
];

export default TRXS;
