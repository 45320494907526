const WIND3 = [
  13.66, 12.67, 15.67, 16.37, 13.06, 11.22, 13.1, 13.08, 15.68, 10.75, 10.48,
  11.62, 11.7, 11.26, 13.01, 12.93, 14.05, 14.56, 15.8, 15.94, 15.45, 15.27,
  14.86, 11.09, 12.23, 12.79, 14.87, 11.85, 12.9, 15.69, 14.81, 15.46, 15.51,
  15.77, 15.28, 15.42, 13.27, 15.11, 15.91, 15.25, 16.15, 16.07, 15.51, 15.44,
  15.41, 16.9, 18.77, 17.01, 16.09, 15.05, 14.21, 14.6, 14.78, 15.15, 14.74,
  14.49, 13.39, 13.31, 15.15, 13.32, 13.92, 16.56, 11.65, 10.77, 14.36, 17.49,
  16.5, 15.87, 15.8, 15.48, 15.25, 15.63, 15.19, 14.25, 14.29, 13.95, 12.91,
  13.56, 13.64, 13.53, 13.45, 13.82, 14.99, 15.47, 14.81, 16.23, 15.06, 12.68,
  13.98, 14.68, 14.34, 13.7, 14.2, 14.06, 14.49, 15.03, 15.77, 15.27, 15.8,
  16.43, 15.76, 16.36, 16.08, 17.35, 15.59, 16.95, 13.7, 13.12, 16.61, 19.92,
  17, 16.07, 15.1, 15.32, 15.32, 15.29, 13.23, 11.06, 9.23, 9.34, 9.21, 9.46,
  8.87, 8.91, 8.85, 8.54, 7.79, 7.77, 8.12, 7.18, 6.74, 6.16, 6.07, 7.44, 5.17,
  6.11, 9.22, 8.37, 10.29, 13.37, 15.34, 15.32, 15.52, 15.9, 16.34, 16.45,
  17.15, 17.22, 16.71, 16.49, 16.79, 16.6, 17.3, 18.07, 18.5, 18.26, 18.17,
  18.21, 18.1, 17.82, 17.61, 16.62, 16.67, 16.18, 16.66, 16.76, 16.46, 16.46,
  15.48, 15.26, 14.21, 15.07, 14.8, 15.68, 16.48, 16.53, 16.54, 16.79, 16.66,
  16.45, 17.15, 17.26, 16.82, 16.85, 16.59, 16.14, 16.01, 16.31, 15.61, 16.09,
  15.28, 13.99, 15.43, 16.97, 17.28, 16.9, 16.52, 16.63, 15.98, 16.12, 15.21,
  15.68, 16.15, 15.57, 15.25, 14.19, 13.11, 14.28, 13.79, 14.03, 14, 14.13,
  15.24, 15.44, 14.55, 15.42, 15.01, 15.37, 16.03, 15.23, 16.55, 15.36, 15,
  13.6, 13.98, 15.03, 15.55, 14.42, 14.04, 13.9, 14.55, 14.68, 15.08, 14.89,
  15.22, 14.67, 14.06, 15.33, 14.83, 14.85, 15.14, 15.64, 16.23, 16.46, 14.63,
  14.73, 17.45, 16.28, 15.18, 15.35, 15.84, 16.54, 16.94, 16.74, 16.36, 16.53,
  17.63, 16.78, 16.39, 15.67, 15.41, 15, 15.69, 16.94, 15.04, 16.31, 16.31,
  16.99, 17.94, 17.81, 16.71, 16.99, 16.43, 16.48, 16.11, 15.27, 16.58, 16.31,
  16.33, 14.9, 15.9, 16.86, 16.44, 18.03, 18.5, 17.73, 18.71, 17.42,
];
const POWER3 = [
  5.04e5, 4.9984e5, 5.04e5, 5.04e5, 5.04e5, 4.57e5, 5.04e5, 5.04e5, 5.04e5,
  4.2368e5, 3.9816e5, 4.7464e5, 4.7817e5, 4.5877e5, 5.04e5, 5.0312e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.5127e5, 4.943e5, 5.0135e5,
  5.04e5, 4.8479e5, 5.0274e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  4.7597e5, 4.2557e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.0287e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.9997e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 4.4995e5, 2.8246e5, 2.9251e5, 2.8063e5, 3.0347e5, 2.5121e5, 2.5436e5,
  2.4964e5, 2.2522e5, 1.6881e5, 1.6749e5, 1.9215e5, 1.2846e5, 1.0508e5, 79506,
  75537, 1.4566e5, 46305, 77301, 2.8155e5, 2.1184e5, 3.802e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5,
];

export { WIND3, POWER3 };
