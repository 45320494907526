
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      menu: {
        dashBoard: sessionStorage.getItem('pmenudash'),
        report: sessionStorage.getItem('pmenurepo'),
        monitoring: sessionStorage.getItem('pmenumoni'),
        roadMap: sessionStorage.getItem('pmenuroad'),
        project: sessionStorage.getItem('pmenuproj'),
        drop: {
          dash: sessionStorage.getItem('pmenudropdash'),
          repo: sessionStorage.getItem('pmenudroprepo'),
          moni: sessionStorage.getItem('pmenudropmoni'),
          road: sessionStorage.getItem('pmenudroproad'),
          proj: sessionStorage.getItem('pmenudropproj'),
        },
        menuStyleD: {
          display: sessionStorage.getItem('pmenustyledash'),
        },
        menuStyleRe: {
          display: sessionStorage.getItem('pmenustylerepo'),
        },
      },
    };
  },
  methods: {
    clickDropMenu(value: string): void {
      if (value === 'dash') {
        if (this.menu.dashBoard === 'true') {
          this.menu.dashBoard = 'false';
          this.menu.drop.dash = 'ic-down-arrow';
          this.menu.menuStyleD.display = 'none';
          sessionStorage.setItem('pmenudash', 'false');
          sessionStorage.setItem('pmenudropdash', 'ic-down-arrow');
          sessionStorage.setItem('pmenustyledash', 'none');
        } else {
          this.menu.dashBoard = 'true';
          this.menu.drop.dash = 'ic-up-arrow';
          this.menu.menuStyleD.display = 'block';
          sessionStorage.setItem('pmenudash', 'true');
          sessionStorage.setItem('pmenudropdash', 'ic-up-arrow');
          sessionStorage.setItem('pmenustyledash', 'block');
        }
      }

      if (value === 'repo') {
        if (this.menu.report === 'false') {
          this.menu.report = 'true';
          this.menu.drop.repo = 'ic-up-arrow';
          this.menu.menuStyleRe.display = 'block';
          sessionStorage.setItem('pmenurepo', 'true');
          sessionStorage.setItem('pmenudroprepo', 'ic-up-arrow');
          sessionStorage.setItem('pmenustylerepo', 'block');
        } else {
          this.menu.report = 'false';
          this.menu.drop.repo = 'ic-down-arrow';
          this.menu.menuStyleRe.display = 'none';
          sessionStorage.setItem('pmenurepo', 'false');
          sessionStorage.setItem('pmenudroprepo', 'ic-down-arrow');
          sessionStorage.setItem('pmenustylerepo', 'none');
        }
      }

      if (value === 'moni') {
        if (this.menu.monitoring === 'false') {
          this.menu.monitoring = 'true';
          this.menu.drop.moni = 'ic-up-arrow';
          sessionStorage.setItem('pmenumoni', 'true');
          sessionStorage.setItem('pmenudropmoni', 'ic-up-arrow');
        } else {
          this.menu.monitoring = 'false';
          this.menu.drop.moni = 'ic-down-arrow';
          sessionStorage.setItem('pmenumoni', 'false');
          sessionStorage.setItem('pmenudropmoni', 'ic-down-arrow');
        }
      }

      if (value === 'road') {
        if (this.menu.roadMap === 'false') {
          this.menu.roadMap = 'true';
          this.menu.drop.road = 'ic-up-arrow';
          sessionStorage.setItem('pmenuroad', 'true');
          sessionStorage.setItem('pmenudroproad', 'ic-up-arrow');
        } else {
          this.menu.roadMap = 'false';
          this.menu.drop.road = 'ic-down-arrow';
          sessionStorage.setItem('pmenuroad', 'false');
          sessionStorage.setItem('pmenudroproad', 'ic-down-arrow');
        }
      }

      if (value === 'proj') {
        if (this.menu.project === 'false') {
          this.menu.project = 'true';
          this.menu.drop.proj = 'ic-up-arrow';
          sessionStorage.setItem('pmenuproj', 'true');
          sessionStorage.setItem('pmenudropproj', 'ic-up-arrow');
        } else {
          this.menu.project = 'false';
          this.menu.drop.proj = 'ic-down-arrow';
          sessionStorage.setItem('pmenuproj', 'false');
          sessionStorage.setItem('pmenudropproj', 'ic-down-arrow');
        }
      }
    },
  },
});
