
import Vue from 'vue';

export default Vue.extend({
  props: ['navValue'],
  data() {
    return {
      menuName: 0,
    };
  },
  computed: {
    menu0Style() {
      return this.menuName === 0
        ? `background: #6B6D7C`
        : `background: transparent`;
    },
    menu1Style() {
      return this.menuName === 1
        ? `background: #6B6D7C`
        : `background: transparent`;
    },
    menu2Style() {
      return this.menuName === 2
        ? `background: #6B6D7C`
        : `background: transparent`;
    },
  },
  methods: {
    onClickNav(menuName: number) {
      if (menuName === 0) {
        this.menuName = 0;
        this.$emit('menuName', this.menuName);
      } else if (menuName === 1) {
        this.menuName = 1;
        this.$emit('menuName', this.menuName);
      } else {
        this.menuName = 2;
        this.$emit('menuName', this.menuName);
      }
    },
  },
});
