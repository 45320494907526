
import Vue from 'vue';
import TheLogo from '@/components/re100/TheLogo.vue';
import TheNav from '@/components/re100/TheNav.vue';
import { TRX_WS_URL } from '@/js/serverProtocol';
import {
  IDX_WIND,
  IDX_PV,
  IDX_BUILDING,
  IDX_FACTORY,
  IDX_GRID,
  MeterIdx,
} from '@/js/sesumeter-id';
import re100Service from '@/js/re100-event';
import ECharts from 'vue-echarts';
import io from 'socket.io-client';

const CAPACITY = 10000;

export default Vue.extend({
  components: { TheLogo, TheNav, ECharts },
  data() {
    return {
      socket: null,
      sesumeterInfo: [],
      sesumeterChart: [],
      isLoadingStyle: {
        display: 'block',
      },
      selected: 'monthly',
      guage1: 0,
      guage2: 0,
      guage3: 0,
      cumulativeToday: {
        wind: 0,
        pv: 0,
        building: 0,
        factory: 0,
        grid: 0,
      },
      cumulativeWeek: {
        wind: 0,
        pv: 0,
        building: 0,
        factory: 0,
        grid: 0,
      },
      cumulativeMonth: {
        wind: 0,
        pv: 0,
        building: 0,
        factory: 0,
        grid: 0,
      },
      windChartOption: {
        title: {
          text: 'wind Chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#B749CA',
        grid: { top: 10, right: 10, bottom: 20, left: 40 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            showSymbol: false,
            zlevel: 9,
            z: 9,
          },
        ],
      },
      pvChartOption: {
        title: {
          text: 'pv Chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#B749CA',
        grid: { top: 10, right: 10, bottom: 20, left: 40 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            showSymbol: false,
            zlevel: 9,
            z: 9,
          },
        ],
      },
      buildingChartOption: {
        title: {
          text: 'building Chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#46BED7',
        grid: { top: 10, right: 10, bottom: 20, left: 40 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            showSymbol: false,
            zlevel: 9,
            z: 9,
          },
        ],
      },
      factoryChartOption: {
        title: {
          text: 'factory Chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#46BED7',
        grid: { top: 10, right: 10, bottom: 20, left: 40 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            showSymbol: false,
            zlevel: 9,
            z: 9,
          },
        ],
      },
      gridChartOption: {
        title: {
          text: 'grid Chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 8,
          },
        },
        color: '#E66649',
        grid: { top: 10, right: 10, bottom: 20, left: 40 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            showSymbol: false,
            zlevel: 9,
            z: 9,
          },
        ],
      },
      trxs: [{ idx: '', data: '' }],
    };
  },
  created() {
    this.socket = io(TRX_WS_URL);
  },
  mounted() {
    re100Service.getSesuMeterInfoData().subscribe({
      next: (info) => {
        this.sesumeterInfo = info;
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    re100Service.getSesuMeterChartData().subscribe({
      next: (data) => {
        this.sesumeterChart = data;
        this.getPeriodData();
      },
      error: (err) => {
        console.log(err.message);
      },
    });
    this.socket.on('trx', (msg) => {
      const trxJsonData = JSON.parse(msg);
      const trxData = trxJsonData.trx;
      const msgData = trxJsonData.msg;

      const trxs = {
        idx: trxData,
        data: msgData,
      };

      if (this.trxs.length > 3) {
        this.trxs.shift();
      }
      this.trxs.push(trxs);
    });
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  watch: {
    sesumeterChart() {
      this.getChartData();
    },
  },
  computed: {
    guage1Style() {
      let value = this.guage1;
      if (this.guage1 <= 0) {
        value = 0;
      } else if (this.guage1 > CAPACITY) {
        value = CAPACITY;
      }
      return `width: ${(value / CAPACITY) * 100}%`;
    },
    guage1Rate() {
      let value = this.guage1;
      if (this.guage1 <= 0) {
        value = 0;
      } else if (this.guage1 > CAPACITY) {
        value = CAPACITY;
      }
      return ((value / CAPACITY) * 100).toFixed(1);
    },
    guage2Style() {
      let value = this.guage2;
      if (this.guage2 <= 0) {
        value = 0;
      } else if (this.guage2 > CAPACITY) {
        value = CAPACITY;
      }
      return `width: ${(value / CAPACITY) * 100}%`;
    },
    guage2Rate() {
      let value = this.guage2;
      if (this.guage2 <= 0) {
        value = 0;
      } else if (this.guage2 > CAPACITY) {
        value = CAPACITY;
      }
      return ((value / CAPACITY) * 100).toFixed(1);
    },
    guage3Style() {
      let value = this.guage3;
      if (this.guage3 <= 0) {
        value = 0;
      } else if (this.guage3 > CAPACITY) {
        value = CAPACITY;
      }
      return `width: ${(value / CAPACITY) * 100}%`;
    },
    guage3Rate() {
      let value = this.guage3;
      if (this.guage3 <= 0) {
        value = 0;
      } else if (this.guage3 > CAPACITY) {
        value = CAPACITY;
      }
      return ((value / CAPACITY) * 100).toFixed(1);
    },
    getProduction() {
      let production = 0;
      if (this.selected == 'monthly') {
        production =
          Number(this.cumulativeMonth.wind) + Number(this.cumulativeMonth.pv);
      }
      if (this.selected == 'weekly') {
        production =
          Number(this.cumulativeWeek.wind) + Number(this.cumulativeWeek.pv);
      }
      if (this.selected == 'daily') {
        production =
          Number(this.cumulativeToday.wind) + Number(this.cumulativeToday.pv);
      }
      return Number(production.toFixed(1)).toLocaleString('ko-KR');
    },
    getConsumption() {
      let consumption = 0;
      if (this.selected == 'monthly') {
        consumption =
          Number(this.cumulativeMonth.building) +
          Number(this.cumulativeMonth.factory);
      }
      if (this.selected == 'weekly') {
        consumption =
          Number(this.cumulativeWeek.building) +
          Number(this.cumulativeWeek.factory);
      }
      if (this.selected == 'daily') {
        consumption =
          Number(this.cumulativeToday.building) +
          Number(this.cumulativeToday.factory);
      }
      return Number(consumption.toFixed(1)).toLocaleString('ko-KR');
    },
    getGrid() {
      let grid = 0;
      if (this.selected == 'monthly') {
        grid = Number(this.cumulativeMonth.grid);
      }
      if (this.selected == 'weekly') {
        grid = Number(this.cumulativeWeek.grid);
      }
      if (this.selected == 'daily') {
        grid = Number(this.cumulativeToday.grid);
      }
      return Number(grid.toFixed(1)).toLocaleString('ko-KR');
    },
    getCo2() {
      let co2 = 0;
      if (this.selected == 'monthly') {
        co2 = Number(
          this.cumulativeMonth.wind * 0.7811 + this.cumulativeMonth.pv * 0.4415
        );
      }
      if (this.selected == 'weekly') {
        co2 = Number(
          this.cumulativeWeek.wind * 0.7811 + this.cumulativeWeek.pv * 0.4415
        );
      }
      if (this.selected == 'daily') {
        co2 = Number(
          this.cumulativeToday.wind * 0.7811 + this.cumulativeToday.pv * 0.4415
        );
      }
      return Number(co2.toFixed(1)).toLocaleString('ko-KR');
    },
    summaryYear() {
      let year;
      if (this.selected == 'monthly') {
        year = 21;
      }
      if (this.selected == 'weekly') {
        year = 28;
      }
      if (this.selected == 'daily') {
        year = 32;
      }
      return year;
    },
    summaryMonth() {
      let year;
      if (this.selected == 'monthly') {
        year = 84;
      }
      if (this.selected == 'weekly') {
        year = 85;
      }
      if (this.selected == 'daily') {
        year = 89;
      }
      return year;
    },
  },
  methods: {
    isLoading() {
      this.isLoadingStyle.display = 'none';
    },
    onClickSelectBox(event) {
      this.selected = event.target.value;
    },
    getChartData() {
      if (this.sesumeterChart != null) {
        const windPower = [];
        const windMeasured = [];
        const pvPower = [];
        const pvMeasured = [];
        const buildingPower = [];
        const buildingMeasured = [];
        const factoryPower = [];
        const factoryMeasured = [];
        const gridPower = [];
        const gridMeasured = [];

        for (let i = 24; i >= 0; i--) {
          const wp = this.sesumeterChart[IDX_WIND].chart[i].power;
          const wm = this.$moment(
            this.sesumeterChart[IDX_WIND].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const pp = this.sesumeterChart[IDX_PV].chart[i].power;
          const pm = this.$moment(
            this.sesumeterChart[IDX_PV].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const bp = this.sesumeterChart[IDX_BUILDING].chart[i].power;
          const bm = this.$moment(
            this.sesumeterChart[IDX_BUILDING].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const fp = this.sesumeterChart[IDX_FACTORY].chart[i].power;
          const fm = this.$moment(
            this.sesumeterChart[IDX_FACTORY].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          const gp = this.sesumeterChart[IDX_GRID].chart[i].power;
          const gm = this.$moment(
            this.sesumeterChart[IDX_GRID].chart[i].measured
          ).format('YYYY.MM.DD HH:mm:ss');
          windPower.push(wp.toFixed(1));
          windMeasured.push(wm);
          pvPower.push(pp.toFixed(1));
          pvMeasured.push(pm);
          buildingPower.push(bp.toFixed(1));
          buildingMeasured.push(bm);
          factoryPower.push(fp.toFixed(1));
          factoryMeasured.push(fm);
          gridPower.push(gp.toFixed(1));
          gridMeasured.push(gm);
          this.guage1 = wp + pp;
          this.guage2 = bp + fp;
          this.guage3 = gp;
        }
        this.windChartOption.xAxis.data = windMeasured;
        this.windChartOption.series[0].data = windPower;
        this.pvChartOption.xAxis.data = pvMeasured;
        this.pvChartOption.series[0].data = pvPower;
        this.buildingChartOption.xAxis.data = buildingMeasured;
        this.buildingChartOption.series[0].data = buildingPower;
        this.factoryChartOption.xAxis.data = factoryMeasured;
        this.factoryChartOption.series[0].data = factoryPower;
        this.gridChartOption.xAxis.data = gridMeasured;
        this.gridChartOption.series[0].data = gridPower;
        this.isLoading();
      }
    },
    getPeriodData() {
      if (this.sesumeterChart != null) {
        if (this.sesumeterChart[0]) {
          this.cumulativeToday.wind = this.sesumeterChart[0].today.wh;
          this.cumulativeWeek.wind = this.sesumeterChart[0].thisweek.wh;
          this.cumulativeMonth.wind = this.sesumeterChart[0].thismonth.wh;
        }
        if (this.sesumeterChart[1]) {
          this.cumulativeToday.pv = this.sesumeterChart[1].today.wh;
          this.cumulativeWeek.pv = this.sesumeterChart[1].thisweek.wh;
          this.cumulativeMonth.pv = this.sesumeterChart[1].thismonth.wh;
        }
        if (this.sesumeterChart[2]) {
          this.cumulativeToday.building = this.sesumeterChart[2].today.wh;
          this.cumulativeWeek.building = this.sesumeterChart[2].thisweek.wh;
          this.cumulativeMonth.building = this.sesumeterChart[2].thismonth.wh;
        }
        if (this.sesumeterChart[3]) {
          this.cumulativeToday.factory = this.sesumeterChart[3].today.wh;
          this.cumulativeWeek.factory = this.sesumeterChart[3].thisweek.wh;
          this.cumulativeMonth.factory = this.sesumeterChart[3].thismonth.wh;
        }
        if (this.sesumeterChart[4]) {
          this.cumulativeToday.grid = this.sesumeterChart[4].today.wh;
          this.cumulativeWeek.grid = this.sesumeterChart[4].thisweek.wh;
          this.cumulativeMonth.grid = this.sesumeterChart[4].thismonth.wh;
        }
      }
    },
    onClickViewMoreButton(idx: MeterIdx) {
      if (idx === 0) {
        this.$store.commit('selectID', 202002000003);
      }
      if (idx === 1) {
        this.$store.commit('selectID', 202002000004);
      }
      if (idx === 2) {
        this.$store.commit('selectID', 202002000006);
      }
      if (idx === 3) {
        this.$store.commit('selectID', 202002000007);
      }
      this.$router.push('/apps/re100/viewdetails');
    },
  },
});
