
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      menu: {
        dashBoard: sessionStorage.getItem('rmenudash'),
        ppa: sessionStorage.getItem('rmenuppa'),
        monitoring: sessionStorage.getItem('rmenumoni'),
        report: sessionStorage.getItem('rmenurepo'),
        roadMap: sessionStorage.getItem('rmenuroad'),
        project: sessionStorage.getItem('rmenuproj'),
        drop: {
          dash: sessionStorage.getItem('rmenudropdash'),
          ppa: sessionStorage.getItem('rmenudropppa'),
          moni: sessionStorage.getItem('rmenudropmoni'),
          repo: sessionStorage.getItem('rmenudroprepo'),
          road: sessionStorage.getItem('rmenudroproad'),
          proj: sessionStorage.getItem('rmenudropproj'),
        },
        menuStyleD: {
          display: sessionStorage.getItem('rmenustyledash'),
        },
        menuStyleP: {
          display: sessionStorage.getItem('rmenustyleppa'),
        },
      },
    };
  },
  methods: {
    clickDropMenu(value: string): void {
      if (value === 'dash') {
        if (this.menu.dashBoard === 'true') {
          this.menu.dashBoard = 'false';
          this.menu.drop.dash = 'ic-down-arrow';
          this.menu.menuStyleD.display = 'none';
          sessionStorage.setItem('rmenudash', 'false');
          sessionStorage.setItem('rmenudropdash', 'ic-down-arrow');
          sessionStorage.setItem('rmenustyledash', 'none');
        } else {
          this.menu.dashBoard = 'true';
          this.menu.drop.dash = 'ic-up-arrow';
          this.menu.menuStyleD.display = 'block';
          sessionStorage.setItem('rmenudash', 'true');
          sessionStorage.setItem('rmenudropdash', 'ic-up-arrow');
          sessionStorage.setItem('rmenustyledash', 'block');
        }
      }

      if (value === 'ppa') {
        if (this.menu.ppa === 'false') {
          this.menu.ppa = 'true';
          this.menu.drop.ppa = 'ic-up-arrow';
          this.menu.menuStyleP.display = 'block';
          sessionStorage.setItem('rmenuppa', 'true');
          sessionStorage.setItem('rmenudropppa', 'ic-up-arrow');
          sessionStorage.setItem('rmenustyleppa', 'block');
        } else {
          this.menu.ppa = 'false';
          this.menu.drop.ppa = 'ic-down-arrow';
          this.menu.menuStyleP.display = 'none';
          sessionStorage.setItem('rmenuppa', 'false');
          sessionStorage.setItem('rmenudropppa', 'ic-down-arrow');
          sessionStorage.setItem('rmenustyleppa', 'none');
        }
      }

      if (value === 'moni') {
        if (this.menu.monitoring === 'false') {
          this.menu.monitoring = 'true';
          this.menu.drop.moni = 'ic-up-arrow';
          sessionStorage.setItem('rmenumoni', 'true');
          sessionStorage.setItem('rmenudropmoni', 'ic-up-arrow');
        } else {
          this.menu.monitoring = 'false';
          this.menu.drop.moni = 'ic-down-arrow';
          sessionStorage.setItem('rmenumoni', 'false');
          sessionStorage.setItem('rmenudropmoni', 'ic-down-arrow');
        }
      }

      if (value === 'repo') {
        if (this.menu.report === 'false') {
          this.menu.report = 'true';
          this.menu.drop.repo = 'ic-up-arrow';
          sessionStorage.setItem('rmenurepo', 'true');
          sessionStorage.setItem('rmenudroprepo', 'ic-up-arrow');
        } else {
          this.menu.report = 'false';
          this.menu.drop.repo = 'ic-down-arrow';
          sessionStorage.setItem('rmenurepo', 'false');
          sessionStorage.setItem('rmenudroprepo', 'ic-down-arrow');
        }
      }

      if (value === 'road') {
        if (this.menu.roadMap === 'false') {
          this.menu.roadMap = 'true';
          this.menu.drop.road = 'ic-up-arrow';
          sessionStorage.setItem('rmenuroad', 'true');
          sessionStorage.setItem('rmenudroproad', 'ic-up-arrow');
        } else {
          this.menu.roadMap = 'false';
          this.menu.drop.road = 'ic-down-arrow';
          sessionStorage.setItem('rmenuroad', 'false');
          sessionStorage.setItem('rmenudroproad', 'ic-down-arrow');
        }
      }

      if (value === 'proj') {
        if (this.menu.project === 'false') {
          this.menu.project = 'true';
          this.menu.drop.proj = 'ic-up-arrow';
          sessionStorage.setItem('rmenuproj', 'true');
          sessionStorage.setItem('rmenudropproj', 'ic-up-arrow');
        } else {
          this.menu.project = 'false';
          this.menu.drop.proj = 'ic-down-arrow';
          sessionStorage.setItem('rmenuproj', 'false');
          sessionStorage.setItem('rmenudropproj', 'ic-down-arrow');
        }
      }
    },
  },
});
