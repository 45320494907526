
import Vue from 'vue';
import { WEB_DEMO_URL } from '@/js/serverProtocol';
import {
  IDX_WIND,
  IDX_PV,
  IDX_BUILDING,
  IDX_FACTORY,
  IDX_GRID,
  SESU_METER_ID,
  MeterIdx,
} from '@/js/sesumeter-id';
import re100Service from '@/js/re100-event';
import axios from 'axios';
import { combineLatest, interval, mergeMap, Observable } from 'rxjs';
import { Route } from 'vue-router';
import { router } from '@/router/index';

/**
 * 서버에서 sesumeter의 info를 가져오는 기능
 * @param idx sesumeter ID 정보
 * @returns sesumeter info 응답을 반환하는 Observable 객체
 */
const getMeterInfo = (idx: MeterIdx) => {
  return new Observable((observer) => {
    const option = {
      method: 'GET',
      url: `${WEB_DEMO_URL}sesumeters/${SESU_METER_ID[idx]}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    };
    axios
      .request(option)
      .then((response) => {
        observer.next(response.data.data);
        observer.complete();
      })
      .catch((error) => {
        observer.error(console.log(error.response.data.message));
        sessionStorage.clear();
        router.push('/');
      });
  });
};

/**
 * 서버에서 sesumeter의 realtime charts를 가져오는 기능
 * @param idx sesumeter ID 정보
 * @returns sesumeter realtime charts 응답을 반환하는 Observable 객체
 */
const getMeterChart = (idx: MeterIdx) => {
  if (sessionStorage.getItem('accessToken') != null) {
    return new Observable((observer) => {
      const option = {
        method: 'GET',
        url: `${WEB_DEMO_URL}sesumeters/${SESU_METER_ID[idx]}/charts/realtime`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      };
      axios
        .request(option)
        .then((response) => {
          observer.next(response.data.data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(console.log(error.response.data.message));
          sessionStorage.clear();
          router.push('/');
        });
    });
  }
};

/**
 * 입력된 vue-router의 이름이 re100 데모인지 판단하는 함수
 * @param route vue-router 객체
 */
const isRe100Route = (route: Route | undefined) => {
  const routeName = route ? route.name : 'undefined';
  return (
    routeName === 're100overviewpage' ||
    routeName === 're100viewdetailspage' ||
    routeName === 're100statisticspage' ||
    routeName === 're100ppapage'
  );
};

export default Vue.extend({
  name: 'App',
  data() {
    return {
      sesuMeterInfoHandler: null,
      sesuMeterChartHandler: null,
      sesumeterInfo: null,
      sesumeterChart: null,
    };
  },
  mounted() {
    if (isRe100Route(this.$router.currentRoute)) {
      if (!this.hasSesuMeterInfo()) {
        this.initSesuMeterInfo();
      } else {
        this.initSesuMeterChart();
      }
    }
  },
  beforeDestroy() {
    re100Service.clearSesuMeterInfoData();
    re100Service.clearSesuMeterChartData();
    if (this.sesuMeterInfoHandler && !this.sesuMeterInfoHandler.closed) {
      this.sesuMeterInfoHandler.unsubscribe();
    }
    this.sesuMeterInfoHandler = null;

    if (this.sesuMeterChartHandler && !this.sesuMeterChartHandler.closed) {
      this.sesuMeterChartHandler.unsubscribe();
    }
    this.sesuMeterChartHandler = null;
  },
  watch: {
    $route(to) {
      if (isRe100Route(to)) {
        if (!this.hasSesuMeterInfo()) {
          this.initSesuMeterInfo();
        } else {
          this.initSesuMeterChart();
        }
      }

      if (to.name == 'loginpage') {
        re100Service.clearSesuMeterChartData();

        if (this.sesuMeterChartHandler && !this.sesuMeterChartHandler.closed) {
          this.sesuMeterChartHandler.unsubscribe();
        }
        this.sesuMeterChartHandler = null;
      }
    },
  },
  methods: {
    hasSesuMeterInfo() {
      if (this.sesumeterInfo != null) {
        return true;
      } else {
        return false;
      }
    },
    initSesuMeterInfo() {
      if (this.sesumeterInfo === null) {
        if (
          this.sesuMeterInfoHandler == null ||
          this.sesuMeterInfoHandler.closed
        ) {
          this.sesuMeterInfoHandler = combineLatest([
            getMeterInfo(IDX_WIND),
            getMeterInfo(IDX_PV),
            getMeterInfo(IDX_BUILDING),
            getMeterInfo(IDX_FACTORY),
            getMeterInfo(IDX_GRID),
          ]).subscribe({
            next: (info) => {
              this.sesumeterInfo = info;
              this.initSesuMeterChart();
              re100Service.emitSesuMeterInfoData(info);
            },
            error: (err) => {
              console.log(err.message);
            },
          });
        }
      }
    },
    initSesuMeterChart() {
      if (this.sesumeterInfo != null) {
        if (
          this.sesuMeterChartHandler == null ||
          this.sesuMeterChartHandler.closed
        ) {
          this.sesuMeterChartHandler = interval(3000)
            .pipe(
              mergeMap(() =>
                combineLatest([
                  getMeterChart(IDX_WIND),
                  getMeterChart(IDX_PV),
                  getMeterChart(IDX_BUILDING),
                  getMeterChart(IDX_FACTORY),
                  getMeterChart(IDX_GRID),
                ])
              )
            )
            .subscribe({
              next: (data) => {
                re100Service.emitSesuMeterChartData(data);
              },
              error: (err) => {
                console.log(err.message);
                sessionStorage.clear();
                this.$router.push('/');
              },
            });
        }
      } else {
        this.initSesuMeterInfo();
      }
    },
  },
});
