
import Vue from 'vue';
import TheLogo from '@/components/plant/TheLogo.vue';
import TheNav from '@/components/plant/TheNav.vue';
import ECharts from 'vue-echarts';

export default Vue.extend({
  components: { TheLogo, TheNav, ECharts },
  data() {
    return {
      totalOption: {
        title: {
          text: 'total chart',
          show: false,
        },
        xAxis: {
          type: 'category',
          data: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Fab'],
          axisLabel: {
            fontSize: 8,
          },
        },
        grid: { top: 10, right: 2, bottom: 15, left: 30 },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 8,
          },
        },
        animationDurationUpdate: 500,
        series: [
          {
            data: [
              424.8, 385.6, 705.4, 565.2, 415.8, 400.7, 601.4, 692.1, 558.7,
            ],
            type: 'bar',
            universalTransition: {
              enabled: true,
              divideShape: 'clone',
            },
            zlevel: 9,
            z: 9,
          },
        ],
      },
      detailInfoTableData: [
        {
          plant: require('@/assets/images/nav/ic-1st.png'),
          id: 'ID202002000003',
          elecCap: 1534.8,
          cap: 504,
          effic: 46.5,
        },
        {
          plant: require('@/assets/images/nav/ic-2nd.png'),
          id: 'ID202002000004',
          elecCap: 1587.1,
          cap: 504,
          effic: 48.1,
        },
        {
          plant: require('@/assets/images/nav/ic-3rd.png'),
          id: 'ID202002000005',
          elecCap: 4749.8,
          cap: 504,
          effic: 47.9,
        },
      ],
      detailInfoTotalData: {
        elecCapTotal: 0,
        capTotal: 0,
        efficTotal: 0,
      },
    };
  },
  mounted() {
    this.getDetailInfoTotalData();
  },
  computed: {
    getElecCapTotal() {
      return Number(
        this.detailInfoTotalData.elecCapTotal.toFixed(1)
      ).toLocaleString('ko-KR');
    },
    getCapTotal() {
      return Number(
        this.detailInfoTotalData.capTotal.toFixed(1)
      ).toLocaleString('ko-KR');
    },
    getEfficTotal() {
      return Number(
        this.detailInfoTotalData.efficTotal.toFixed(1)
      ).toLocaleString('ko-KR');
    },
  },
  methods: {
    getDetailInfoTotalData() {
      const elecCapData = [];
      const capData = [];
      const efficData = [];

      for (let i = 0; i <= this.detailInfoTableData.length - 1; i++) {
        const ec = this.detailInfoTableData[i].elecCap;
        const ca = this.detailInfoTableData[i].cap;
        const ef = this.detailInfoTableData[i].effic;
        elecCapData.push(ec);
        capData.push(ca);
        efficData.push(ef);
      }
      this.detailInfoTotalData.elecCapTotal = elecCapData.reduce(
        (a, b) => a + b
      );
      this.detailInfoTotalData.capTotal = capData.reduce((a, b) => a + b);
      this.detailInfoTotalData.efficTotal =
        efficData.reduce((a, b) => a + b) / this.detailInfoTableData.length;
    },
  },
});
