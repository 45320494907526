import { BehaviorSubject } from 'rxjs';

const sesuMeterInfoSubject = new BehaviorSubject(null);
const sesuMeterChartSubject = new BehaviorSubject(null);

export default {
  emitSesuMeterInfoData: (message) => sesuMeterInfoSubject.next(message),
  clearSesuMeterInfoData: () => sesuMeterInfoSubject.next(null),
  getSesuMeterInfoData: () => sesuMeterInfoSubject.asObservable(),
  emitSesuMeterChartData: (message) => sesuMeterChartSubject.next(message),
  clearSesuMeterChartData: () => sesuMeterChartSubject.next(null),
  getSesuMeterChartData: () => sesuMeterChartSubject.asObservable(),
};
