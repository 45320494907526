import {
  WIND1,
  WIND2,
  WIND3,
  WIND4,
  WIND5,
} from '@/js/plant/viewdetails/wind-data';
import {
  POWER1,
  POWER2,
  POWER3,
  POWER4,
  POWER5,
} from '@/js/plant/viewdetails/power-data';

const DATE1 = 'Aug 1st week, 2020';
const DATE2 = 'Aug 2nd week, 2020';
const DATE3 = 'Aug 3rd week, 2020';
const DATE4 = 'Aug 4th week, 2020';
const DATE5 = 'Aug 5th week, 2020';

const WIND = [WIND1, WIND2, WIND3, WIND4, WIND5];
const POWER = [POWER1, POWER2, POWER3, POWER4, POWER5];
const DATE = [DATE1, DATE2, DATE3, DATE4, DATE5];

export { WIND, POWER, DATE };
