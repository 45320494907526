
import Vue from 'vue';
import axios from 'axios';
import Header from '@/components/vcm/TheHeader.vue';
import Footer from '@/components/vcm/TheFooterMenu.vue';
import { CM_WS_URL } from '@/js/serverProtocol';
import { Asset, Order, BankTransaction } from '@/js/types';

export default Vue.extend({
  components: { Header, Footer },
  props: ['headerValue', 'navValue', 'userInfoValue'],
  data() {
    return {
      menuName: 0,
      userInfoData: this.userInfoValue,
      assetList: [] as Asset[],
      loading: true,
      registrationModal: false,
      orderModal: false,
      confirmModal: false,
      volume: null,
      date: new Date(),
      dateExp: 1,
      assetTypeSell: 1,
      cert: 1,
      orderData: {
        issued: '',
        assetId: null,
        exp: '',
        type: '',
        total: '',
        balance: '',
      },
      orderVolume: null,
      orderPrice: null,
      transactionList: [] as Order[],
      orderId: null,
      payment: null,
      paymentAuto: null,
      ecocredit: null,
      ecocreditAuto: null,
      fundPopup: false,
      ecocreditPopup: false,
      historyList: [] as BankTransaction[],
    };
  },
  mounted() {
    if (this.$store.state.sellerInfoComplete) {
      this.printAssetList();
      this.printTransactionList();
      this.printHistoryList();
    }
  },
  watch: {
    checkInfoComplete() {
      this.printAssetList();
      this.printTransactionList();
      this.printHistoryList();
    },
    payment() {
      return (this.paymentAuto = this.payment);
    },
    ecocredit() {
      return (this.ecocreditAuto = this.ecocredit);
    },
  },
  computed: {
    menu0Style(): string {
      return this.menuName === 0 ? `display: block` : `display: none`;
    },
    menu1Style(): string {
      return this.menuName === 1 ? `display: block` : `display: none`;
    },
    menu2Style(): string {
      return this.menuName === 2 ? `display: block` : `display: none`;
    },
    loadingStyle(): string {
      return this.loading ? `display: block` : `display: none`;
    },
    registrationModalStyle(): string {
      return this.registrationModal ? `display: block` : `display: none`;
    },
    orderModalStyle(): string {
      return this.orderModal ? `display: block` : `display: none`;
    },
    confirmModalStyle(): string {
      return this.confirmModal ? `display: block` : `display: none`;
    },
    checkInfoComplete() {
      return this.$store.getters.getSellerInfoComplete;
    },
    fundPopupStyle(): string {
      return this.fundPopup ? `display:block` : `display:none`;
    },
    ecocreditPopupStyle(): string {
      return this.ecocreditPopup ? `display:block` : `display:none`;
    },
  },
  methods: {
    currentMenu(data: number): void {
      this.menuName = data;
    },
    isLoading(): void {
      this.loading = false;
    },
    handleChange(event) {
      this[event.target.name] = event.target.value;
    },
    onClickSelectBox(event) {
      this.selected = event.target.value;
    },
    // asset menu
    printAssetList(): void {
      const options = {
        method: 'GET',
        url: `${CM_WS_URL}assets`,
        params: { uid: 1 },
      };

      axios
        .request(options)
        .then((response) => {
          this.dateExp = this.$moment(new Date()).unix();
          this.assetList = response.data;
          this.isLoading();
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    requestAssetList(): void {
      const options = {
        method: 'POST',
        url: `${CM_WS_URL}assets`,
        params: { uid: 1 },
        headers: { 'Content-Type': 'application/json' },
        data: {
          uid: 1,
          volume: this.volume * 1000000,
          exp: Math.floor(
            this.$moment(this.date.getTime()).utc().endOf('day') / 1000
          ),
          type: this.assetTypeSell,
          cert: this.cert,
        },
      };

      axios
        .request(options)
        .then((response) => {
          this.assetList.unshift(response.data);
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    requestOrderList() {
      if (!this.isValidVolume(Number(this.orderVolume))) {
        alert('Invalid Quantity');
        return;
      }

      if (!this.isValidPrice(Number(this.orderPrice))) {
        alert('Invalid Unit price');
        return;
      }

      const options = {
        method: 'POST',
        url: `${CM_WS_URL}orders`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          type: 's',
          order: {
            uid: 1,
            asset: this.orderData.assetId,
            price: this.orderPrice,
            volume: this.orderVolume * 1000000,
          },
        },
      };

      axios
        .request(options)
        .then((response) => {
          console.log(response.data);
          this.printAssetList();
          this.printTransactionList();
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    // asset 버튼 클릭 메서드
    clickRegistrationBtn(): void {
      this.registrationModal = true;
    },
    clickRegistrationCancleBtn(): void {
      this.registrationModal = false;
    },
    clickRegistrationRegisterBtn(): void {
      this.requestAssetList();
      this.registrationModal = false;
      this.volume = null;
      this.date = new Date();
    },
    onClickOrderBtn(value: number): void {
      const selectAssetIndex = this.assetList[value];
      if (selectAssetIndex.volume == selectAssetIndex.sold) {
        this.orderModal = false;
      } else {
        this.orderModal = true;
        // 자동입력
        this.orderData.issued = this.changeDateFormat(selectAssetIndex.issued);
        this.orderData.assetId = selectAssetIndex.id;
        this.orderData.exp = this.changeDateFormat(selectAssetIndex.exp);
        this.orderData.total = selectAssetIndex.volume / 1000000;
        this.orderData.balance =
          selectAssetIndex.volume / 1000000 -
          selectAssetIndex.forsale / 1000000;
        if (selectAssetIndex.type == 1) {
          this.orderData.type = 'credit';
        } else {
          this.orderData.type = 'offset';
        }
      }
    },
    onClickOrderCancleBtn(): void {
      this.orderModal = false;
      this.orderPrice = null;
      this.orderVolume = null;
    },
    onClickOrderRegisterBtn(): void {
      this.orderModal = false;
      this.requestOrderList();
      this.orderVolume = null;
      this.orderPrice = null;
    },
    // transaction menu
    printTransactionList(): void {
      const options = {
        method: 'GET',
        url: `${CM_WS_URL}orders`,
        params: { uid: '1', type: 's' },
      };

      axios
        .request(options)
        .then((response) => {
          this.changeOrderItem(response.data);
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    changeOrderItem(data): void {
      let stateA = [];
      let stateB = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].state == 1) {
          stateA.push(data[i]);
          data.splice(i, 1);
          i--;
        } else if (data[i].state == -1) {
          stateB.push(data[i]);
          data.splice(i, 1);
          i--;
        }
      }

      Array.prototype.push.apply(data, stateB);
      Array.prototype.push.apply(data, stateA);

      this.transactionList = data;
    },
    requestDeleteItem(index: number): void {
      const options = {
        method: 'DELETE',
        url: `${CM_WS_URL}orders`,
        params: { uid: '1', type: 's', id: index },
      };

      axios
        .request(options)
        .then((response) => {
          console.log(response.data);
          this.printTransactionList();
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    // transaction 버튼 클릭 메서드
    onClickConfirmBtn(index: number): void {
      this.confirmModal = true;
      this.orderId = index;
    },
    onClickConfirmCancleBtn(): void {
      this.confirmModal = false;
    },
    onClickConfirmDeleteBtn(): void {
      this.requestDeleteItem(this.orderId);
      this.confirmModal = false;
    },
    // banking menu
    onClickFundBtn(): void {
      const options = {
        method: 'POST',
        url: `${CM_WS_URL}funds`,
        headers: { 'Content-Type': 'application/json' },
        data: { uid: 1, amount: this.payment },
      };

      axios
        .request(options)
        .then((response) => {
          this.historyList.unshift(response.data);
          this.payment = null;
          this.fundPopup = true;
          setTimeout(() => (this.fundPopup = false), 3000);
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    onClickReturnCapitalBtn(): void {
      const options = {
        method: 'DELETE',
        url: `${CM_WS_URL}funds`,
        params: { uid: 1, amount: this.ecocredit },
      };

      axios
        .request(options)
        .then((response) => {
          this.historyList.unshift(response.data);
          this.ecocredit = null;
          this.ecocreditPopup = true;
          setTimeout(() => (this.ecocreditPopup = false), 3000);
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    printHistoryList(): void {
      const options = {
        method: 'GET',
        url: `${CM_WS_URL}funds`,
        params: { uid: 1 },
      };

      axios
        .request(options)
        .then((response) => {
          this.historyList = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (!error.response.data) {
            alert('알 수 없는 에러가 발생했습니다.');
          } else {
            alert(`${error.response.data.message}`);
          }
        });
    },
    // 유효성 검사
    isValidVolume(volume: number): boolean {
      return volume !== null && volume <= this.orderData.balance && volume > 0;
    },
    isValidPrice(price: number): boolean {
      return price !== null && price > 0;
    },
    // 포맷 변경 메서드
    changeDateFormat(value: number): string {
      return this.$moment.unix(value).utc().format('DD.MM.YYYY');
    },
    changeAssetTypeFormat(value: number): string {
      if (value === 1) {
        return require('@/assets/images/vcm/user/credit-ic.png');
      } else {
        return require('@/assets/images/vcm/user/offset-ic.png');
      }
    },
    changeNumberFormat(value: number): string {
      return value.toLocaleString('ko-KR');
    },
    changeHistoryTypeFormat(value: number): string {
      if (value === 1) {
        return require('@/assets/images/vcm/user/issuance-ic.png');
      } else {
        return require('@/assets/images/vcm/user/return-ic.png');
      }
    },
    changeEcocreditFormat(typeValue: number, numberValue: number): string {
      if (typeValue === 1) {
        return `+ ${numberValue.toLocaleString('ko-KR')}`;
      } else {
        return `- ${numberValue.toLocaleString('ko-KR')}`;
      }
    },
    changeCurrencyFormat(typeValue: number, numberValue: number): string {
      if (typeValue === 1) {
        return `- ${numberValue.toLocaleString('ko-KR')}`;
      } else {
        return `+ ${numberValue.toLocaleString('ko-KR')}`;
      }
    },
  },
});
