import { render, staticRenderFns } from "./DashBoardPage.vue?vue&type=template&id=46246ef9&scoped=true&"
import script from "./DashBoardPage.vue?vue&type=script&lang=ts&"
export * from "./DashBoardPage.vue?vue&type=script&lang=ts&"
import style0 from "./DashBoardPage.vue?vue&type=style&index=0&id=46246ef9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46246ef9",
  null
  
)

export default component.exports