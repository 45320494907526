const WIND2 = [
  11.19, 11.43, 9.58, 8.85, 8.95, 10.7, 11.37, 10.16, 11.04, 13.45, 13.16,
  13.86, 13.79, 14.65, 15.13, 15.37, 15.1, 15.52, 15.41, 15.76, 15.47, 14.68,
  14.83, 13.79, 16.36, 16.27, 15.54, 15.54, 14.35, 12, 14.68, 15.07, 16.16,
  15.7, 15.44, 16.27, 15.28, 16.32, 16.91, 16.33, 16.1, 15.57, 16.45, 17.36,
  16.97, 19.18, 17.36, 16.84, 12.72, 15.18, 15.28, 15.34, 17.02, 15.55, 16.14,
  13.88, 14.18, 14.5, 15.13, 15.54, 16.32, 15.58, 15.14, 16.2, 17.12, 16.89,
  16.3, 15.59, 15.55, 15.52, 14.93, 14.37, 14.53, 14.85, 14.63, 14.78, 13.95,
  13.34, 12.97, 13.24, 13.77, 14.15, 13.84, 12.21, 12.26, 11.29, 11.1, 11.43,
  12.2, 12.35, 12.39, 12.77, 12.94, 14.18, 14.78, 15.31, 15.74, 15.27, 15.63,
  14.82, 14.84, 15.16, 14.02, 14.51, 11.45, 11.96, 11.51, 9.81, 11.42, 11.74,
  11.71, 12.05, 11.35, 11.22, 12.1, 12.47, 12.44, 11.56, 10.82, 10.01, 9.68,
  10.02, 10.08, 8.59, 8.21, 8.13, 7.35, 6.2, 7.62, 7.37, 6.91, 8.64, 11.66,
  13.17, 15.53, 15.71, 15.61, 15.69, 15.07, 16.39, 17.01, 17.37, 17.14, 16.98,
  17.49, 17.23, 17.36, 18.43, 17.89, 16.83, 17.26, 17.99, 18.77, 18.83, 17.62,
  18.06, 17.95, 17.75, 17.75, 17.8, 17.42, 17.76, 17.68, 17.88, 17.5, 17.64,
  17.23, 16.52, 15.49, 16.42, 17.09, 17.07, 16.77, 17.22, 17.53, 17.28, 16.74,
  16.21, 17.03, 18.31, 17.54, 16.38, 16.51, 17.14, 17, 16.23, 16.45, 17.09,
  16.38, 15.5, 14.57, 15.57, 16.81, 17.21, 16.48, 16.02, 15.11, 14.84, 15.78,
  14.39, 15.18, 15.53, 14.38, 14.55, 14.98, 14.14, 14.48, 14.25, 14.63, 14.96,
  15.39, 15.9, 15.84, 15.48, 15.53, 15.18, 15.21, 16.07, 17.2, 15.4, 14.27,
  14.24, 14.39, 14.61, 14.54, 13.6, 13.3, 12.9, 13.32, 13.97, 14.6, 14.36, 13.8,
  13.78, 14.49, 15.18, 15.63, 16.62, 15.85, 16.93, 16.05, 15.74, 15.47, 16.15,
  17.05, 17.24, 15.51, 16.08, 15.6, 15.02, 16.49, 16.27, 16.86, 17.05, 16.89,
  16.31, 16.07, 15.71, 15.05, 14.53, 14.14, 13.2, 14.19, 18.04, 15.91, 15.83,
  17.34, 18.09, 17.09, 16.13, 15.88, 16.41, 16.19, 15.25, 15.63, 14.91, 15.55,
  15.64, 14.58, 15.93, 15.19, 15.96, 18.08, 17.57, 17.09, 17.81, 16.99, 17.84,
];
const POWER2 = [
  4.5568e5, 4.6626e5, 3.1443e5, 2.4964e5, 2.5751e5, 4.1895e5, 4.6362e5,
  3.6792e5, 4.4906e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.914e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.0047e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.0362e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 4.9405e5, 4.9468e5, 4.6009e5, 4.5171e5,
  4.6626e5, 4.9392e5, 4.9581e5, 4.9631e5, 5.011e5, 5.0324e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  4.6715e5, 4.8964e5, 4.6979e5, 3.3544e5, 4.6582e5, 4.7993e5, 4.7861e5,
  4.9203e5, 4.6274e5, 4.57e5, 4.9266e5, 4.9732e5, 4.9694e5, 4.72e5, 4.3029e5,
  3.5374e5, 3.2357e5, 3.5469e5, 3.6036e5, 2.2916e5, 1.9924e5, 1.9294e5, 1.397e5,
  81270, 1.5756e5, 1.4103e5, 1.1258e5, 2.331e5, 4.7641e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.0274e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
  5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5, 5.04e5,
];

export { WIND2, POWER2 };
