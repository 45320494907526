
import Vue from 'vue';
import TheLogo from '@/components/plant/TheLogo.vue';
import TheNav from '@/components/plant/TheNav.vue';

export default Vue.extend({
  components: { TheLogo, TheNav },
  data() {
    return {
      detailInfoTableData: [
        {
          plant: 'Floating Offshore Wind Farm #1',
          id: 'ID202002000003',
          elecCap: 230.0,
          cap: 504,
          effic: 61.3,
        },
        {
          plant: 'Floating Offshore Wind Farm #2',
          id: 'ID202002000004',
          elecCap: 237.1,
          cap: 504,
          effic: 63.2,
        },
        {
          plant: 'Floating Offshore Wind Farm #2',
          id: 'ID202002000005',
          elecCap: 238.3,
          cap: 504,
          effic: 63.5,
        },
      ],
      detailInfoTotalData: {
        elecCapTotal: 0,
        capTotal: 0,
        efficTotal: 0,
      },
      detailInfoTableSelectIndex: 2,
      geneDetailInfoTableData: [
        {
          date: '2022.08.01',
          plant1: 8.2,
          plant2: 7.8,
          plant3: 6.9,
          capTotal: 22.9,
          cap: 504,
          effic: 63.1,
        },
        {
          date: '2022.08.02',
          plant1: 12.0,
          plant2: 12.1,
          plant3: 12.1,
          capTotal: 36.1,
          cap: 504,
          effic: 99.5,
        },
        {
          date: '2022.08.03',
          plant1: 11.8,
          plant2: 12.1,
          plant3: 12.1,
          capTotal: 36.0,
          cap: 504,
          effic: 99.2,
        },
        {
          date: '2022.08.04',
          plant1: 8.3,
          plant2: 11.3,
          plant3: 11.6,
          capTotal: 31.2,
          cap: 504,
          effic: 86.0,
        },
        {
          date: '2022.08.05',
          plant1: 8.6,
          plant2: 10.2,
          plant3: 10.7,
          capTotal: 29.6,
          cap: 504,
          effic: 81.5,
        },
        {
          date: '2022.08.06',
          plant1: 12.1,
          plant2: 12.1,
          plant3: 12.1,
          capTotal: 36.3,
          cap: 504,
          effic: 99.9,
        },
        {
          date: '2022.08.07',
          plant1: 10.5,
          plant2: 11.4,
          plant3: 11.7,
          capTotal: 33.6,
          cap: 504,
          effic: 92.7,
        },
      ],
    };
  },
  mounted() {
    this.getDetailInfoTotalData();
  },
  computed: {
    getElecCapTotal() {
      return Number(
        this.detailInfoTotalData.elecCapTotal.toFixed(1)
      ).toLocaleString('ko-KR');
    },
    getCapTotal() {
      return Number(
        this.detailInfoTotalData.capTotal.toFixed(1)
      ).toLocaleString('ko-KR');
    },
    getEfficTotal() {
      return Number(
        this.detailInfoTotalData.efficTotal.toFixed(1)
      ).toLocaleString('ko-KR');
    },
  },
  methods: {
    getDetailInfoTotalData() {
      const elecCapData = [];
      const capData = [];
      const efficData = [];

      for (let i = 0; i <= this.detailInfoTableData.length - 1; i++) {
        const ec = this.detailInfoTableData[i].elecCap;
        const ca = this.detailInfoTableData[i].cap;
        const ef = this.detailInfoTableData[i].effic;
        elecCapData.push(ec);
        capData.push(ca);
        efficData.push(ef);
      }
      this.detailInfoTotalData.elecCapTotal = elecCapData.reduce(
        (a, b) => a + b
      );
      this.detailInfoTotalData.capTotal = capData.reduce((a, b) => a + b);
      this.detailInfoTotalData.efficTotal =
        efficData.reduce((a, b) => a + b) / this.detailInfoTableData.length;
    },
  },
});
